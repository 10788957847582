import {
  CircularProgress,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./RoleRights.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import RoleDrawer from "./RoleDrawer/RoleDrawer";
import Drawer from "@material-ui/core/Drawer";
import { isUserHasPermission } from "../../services/AuthService";
import { useHistory } from "react-router-dom";
import RiseLoading from "../Loader/RiseLoader";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function RoleRights() {
  const [roles, setRoles] = useState(null);
  const [rolePermissions, setrolePermissions] = useState(null);

  const [drawer, setDrawer] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [ispermissionUpdate, setIspermissionUpdate] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();
  async function getRoles() {
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "ids/Role")
      .then((response) => {
        console.log(response.data);
        setRoles(response.data);
        if (response.data !== null && response.data.length > 0) {
          //set selected role
          //call permissions for first role
          getRolePermissions(
            selectedIndex != "" ? selectedIndex : response.data[0].name
          );
        }
      });
  }

  async function getRolePermissions(role) {
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "ids/Permission/" + role)
      .then((response) => {
        let updatedPermission = response.data.rolePermissions.map(
          (member, idx) => {
            return {
              ...member,
              disabled: false,
            };
          }
        );
        response.data.rolePermissions = updatedPermission;
        setrolePermissions(response.data);
        setIspermissionUpdate(true);
      });
  }

  useEffect(() => {
    if (rolePermissions != null) {
      rolePermissions.rolePermissions.map((member, idx) => {
        roleSelection(
          member.value,
          member.name,
          member.groupName,
          idx,
          member.selected,
          "render"
        );
      });
    }
    setIspermissionUpdate(false);
  }, [ispermissionUpdate]);

  useEffect(() => {
    //call roles list
    if (isUserHasPermission("RoleRightsRead")) {
      getRoles();
    } else {
      history.push("/Unauthorized");
    }
    return () => { };
  }, []);

  var filterArrayLoop = (array, x, isChecked, action) => {
    for (let i = 0; i < x; i++) {
      let temp = rolePermissions.rolePermissions.findIndex(
        (x) => x.value === array[i].value
      );
      if (action == "render" && isChecked) {
        rolePermissions.rolePermissions[temp].selected = isChecked;
        rolePermissions.rolePermissions[temp].disabled = isChecked;
      } else if (action == "update") {
        rolePermissions.rolePermissions[temp].selected = isChecked;
        rolePermissions.rolePermissions[temp].disabled = isChecked;
      }
    }
  };

  const roleSelection = (
    value,
    permissionname,
    groupName,
    index,
    isChecked,
    action
  ) => {
    var group = groupName.slice(0, 5);

    var filterArray = rolePermissions.rolePermissions.filter((x) => {
      return x.groupName.includes(group);
    });

    // For Looped Permission Category
    if (
      value.includes("SearchCategory") ||
      value.includes("SupplierCommission") ||
      value.includes("Markup")
    ) {
      if (
        value == "SearchCategoryWrite" ||
        value == "SupplierCommissionBlockWrite" ||
        value == "MarkupBlockWrite"
      ) {
        rolePermissions.rolePermissions[index].selected = isChecked;
        filterArrayLoop(filterArray, 3, isChecked, action);
      } else if (
        value == "SearchCategoryRead" ||
        value == "SupplierCommissionBlockRead" ||
        value == "MarkupBlockRead"
      ) {
        rolePermissions.rolePermissions[index].selected = isChecked;
        var groupFilter = filterArray.filter((x) => {
          return x.groupName.includes("Group");
        });
        filterArrayLoop(groupFilter, 2, isChecked, action);
      } else if (
        value == "SearchCategoryGroupWrite" ||
        value == "SupplierCommissionGroupWrite" ||
        value == "MarkupGroupWrite"
      ) {
        rolePermissions.rolePermissions[index].selected = isChecked;
        filterArrayLoop(filterArray, 1, isChecked, action);
      } else {
        rolePermissions.rolePermissions[index].selected = isChecked;
      }
      //For Write Permission
    } else if (permissionname == "Write") {
      if (
        value == "ClientFinanceApproval" ||
        value == "VendorFinanceApproval"
      ) {
        rolePermissions.rolePermissions[index].selected = isChecked;
      } else if (
        groupName == "Client" ||
        groupName == "Vendor" ||
        groupName == "MyBookingQueue"
      ) {
        rolePermissions.rolePermissions[index].selected = isChecked;
        filterArrayLoop(filterArray, 1, isChecked, action);
      } else {
        rolePermissions.rolePermissions[index].selected = isChecked;
        filterArrayLoop(filterArray, 1, isChecked, action);
      }
    }
    // For Read Permission
    else {
      if (value == "MyBookingQueueAccessQueue") {
        var temp = rolePermissions.rolePermissions.findIndex(
          (x) => x.value === "MyBookingQueueRead"
        );
        rolePermissions.rolePermissions[index].selected = isChecked;
        rolePermissions.rolePermissions[temp].selected = isChecked;
        rolePermissions.rolePermissions[temp].disabled = isChecked;
      } else {
        rolePermissions.rolePermissions[index].selected = isChecked;
      }
    }
    setrolePermissions({
      ...rolePermissions,
      [permissionname]: isChecked,
    });
  };

  const handleChange = (value, permissionname, groupName, index, isChecked) => {
    roleSelection(value, permissionname, groupName, index, isChecked, "update");
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(value);
  };

  const openDrawer = (selectedItem) => {
    setDrawer(true);
  };

  const handleSnackbarToggle = () => {
    setDrawer(false);
    setSnackbarOpen(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  async function handleSavePermissions() {
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };

    await axios
      .post(
        process.env.REACT_APP_IDENTITY_SERVER + "ids/Permission/",
        rolePermissions,
        {
          headers,
        }
      )
      .then((res) => {
        console.log(res);
        handleSnackbarToggle();
        setShowLoader(false);
        setIspermissionUpdate(true);
      })
      .catch((err) => {
        console.log(err);
        handleSnackbarToggle();
        setShowLoader(false);
      });

    // your submit logic
    getRoles();
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    getRolePermissions(index);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="role">
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <div className="sidebar-container-top">
              Roles List
              {isUserHasPermission("RoleRightsWrite") ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "15px" }}
                  onClick={() => {
                    setDrawer(true);
                    console.log("im clicked");
                  }}
                >
                  Add
                </Button>
              ) : (
                ""
              )}
            </div>
            <div className="sidebar-container">
              {/* <div className="sidebar-heading">
                Roles List
              </div> */}
              <Divider />
              <input
                placeholder="Search"
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                style={{ width: "100%" }}
              />
              <List component="nav" aria-label="secondary mailbox folders">
                {roles &&
                  roles
                    .filter((item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    .map((row, index) => (
                      <ListItem
                        button
                        selected={selectedIndex === row.name}
                        onClick={(event) =>
                          handleListItemClick(event, row.name)
                        }
                      >
                        <ListItemText primary={row.name} />
                      </ListItem>
                    ))}
              </List>
            </div>
          </Grid>
          <Grid item xs={9}>
            <div className="main-container">
              <div className="sidebar-heading">
                Permissions for role:{" "}
                {rolePermissions && rolePermissions.roleName}
              </div>
              <Divider />
              <div className="permissions-box">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Permission Type</TableCell>
                      <TableCell>Permission</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rolePermissions &&
                      rolePermissions.rolePermissions.map((row, idx) => (
                        <TableRow>
                          <TableCell>{row.groupName}</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={row.selected}
                                  onChange={(event) =>
                                    handleChange(
                                      row.value,
                                      row.name,
                                      row.groupName,
                                      idx,
                                      event.target.checked
                                    )
                                  }
                                  name={row.value}
                                  color="primary"
                                  disabled={
                                    !isUserHasPermission("RoleRightsWrite") ||
                                      row.disabled
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label={row.name}
                            />
                          </TableCell>
                          <TableCell>{row.description}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <div className="permission-action">
                {isUserHasPermission("RoleRightsWrite") ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSavePermissions}
                  >
                    Save Permissions
                  </Button>
                ) : (
                  ""
                )}
                {showLoader && <RiseLoading roles={showLoader} />}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Drawer
        anchor="left"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <RoleDrawer
            toggleDrawer={toggleDrawer}
            handleSnackbarToggle={handleSnackbarToggle}
            getRoles={getRoles}
          ></RoleDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {`New Role has been created`}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default RoleRights;
