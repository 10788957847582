import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
export default function BrandedSingleFareTable({
  result,
  selectedItinerary,
  setSelectedItinerary,
}) {
  // here we check airport priority based on response not Supplier or Pcc
  let ispriorityAvailable = undefined;
  if (result.fareGroups != null && result.fareGroups.length > 0) {
    ispriorityAvailable = result.fareGroups[0].pricedDescription.find((s) =>
      (s.type === "AirportPriority")
    );
  } else {
    if (result.pricedDescription != null && result.pricedDescription !== undefined && result.pricedDescription.length > 0) {
      ispriorityAvailable = result.pricedDescription.find((s) => (s.type === "AirportPriority"));
    }
  }

  //
  return (
    <div className="brandFareDetailsDiv">
      <Table className="fullHeight fareDetailsTable mb-3">
        <TableHead>
          <TableRow>
            <TableCell className="singleFareDetailsHeadCell">
              {" "}
              <p className="fs14 textTransCap">Brand Type</p>
            </TableCell>
            <TableCell className="singleFareDetailsHeadCell">
              <p className="fs14 textTransCap">Carry On </p>
            </TableCell>
            <TableCell className="singleFareDetailsHeadCell">
              <p className="fs14 textTransCap">Checked Bag</p>
            </TableCell>
            <TableCell className="singleFareDetailsHeadCell">
              <p className="fs14 textTransCap">Seat Selection</p>
            </TableCell>
            <TableCell className="singleFareDetailsHeadCell">
              <p className="fs14 textTransCap">Date Change</p>
            </TableCell>
            <TableCell className="singleFareDetailsHeadCell">
              <p className="fs14 textTransCap">Cancellation</p>
            </TableCell>
            <TableCell className="singleFareDetailsHeadCell">
              <p className="fs14 textTransCap">Meals</p>
            </TableCell>
            {result.vendor === "G9" ||
              result.pcc === "G9-Test" ||
              result.pcc === "G9-Test-new" ||
              result.pcc === "G9-Live" ||
              result.vendor === "F3" || result.vendor === "QP" ? null : (
              <TableCell className="singleFareDetailsHeadCell">
                <p className="fs14 textTransCap">Wifi</p>
              </TableCell>
            )}
            {ispriorityAvailable !== undefined ? (
              <TableCell className="singleFareDetailsHeadCell">
                <p className="fs14 textTransCap">Airport Priority</p>
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {result?.fareGroups && result?.fareGroups?.length > 0 ? (
              result?.fareGroups?.map((fare, index) => (
                <>
                  {" "}
                  <TableCell
                    className="fareDetailsBodyCell"
                    onClick={() => setSelectedItinerary(index)}
                  >
                    <p className="fs14 textTransCap">
                      {fare?.priceClass?.toLowerCase()}
                    </p>
                  </TableCell>
                  <TableCell className="fareDetailsBodyCell">
                    <>
                      {fare?.pricedDescription &&
                        fare?.pricedDescription?.length &&
                        fare?.pricedDescription?.filter(
                          (obj) => obj.type === "CarryOn"
                        ).length > 0 ? (
                        fare?.pricedDescription
                          .filter((obj) => obj.type === "CarryOn")
                          .map((item) => (
                            <>
                              {item.type === "CarryOn" &&
                                (result.vendor === "1A" ||
                                  result.vendor === "1S" ||
                                  result.vendor === "1G") ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorGreen">
                                    &#10004;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "CarryOn" &&
                                item.status === "Included" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorGreen">
                                    &#10004;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "CarryOn" &&
                                item.status === "Chargeable" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorRed">
                                    &#36;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "CarryOn" &&
                                item.status === "NotIncluded" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorBlack">
                                    &#10799;
                                  </p>
                                </BootstrapTooltip1>
                              ) : (
                                item.type === "CarryOn" &&
                                (item.status === "None" ||
                                  (item.status !== "Included" &&
                                    item.status !== "Chargeable" &&
                                    item.status !== "NotIncluded")) && (
                                  <>
                                    {result?.content === "GDS" ? (
                                      <BootstrapTooltip1
                                        title={item.description}
                                      >
                                        <p className="fs14 textTransCap colorGreen">
                                          &#10004;
                                        </p>
                                      </BootstrapTooltip1>
                                    ) : (
                                      <BootstrapTooltip1
                                        title={item.description}
                                      >
                                        <p className="fs14 textTransCap colorBlack">
                                          &#10799;
                                        </p>
                                      </BootstrapTooltip1>
                                    )}
                                  </>
                                  // <p className="fs14 textTransCap colorBlack">
                                  //   {item.description}
                                  // </p>
                                )
                              )}
                            </>
                          ))
                      ) : (
                        <>
                          {result?.content === "GDS" ? (
                            <p className="fs14 textTransCap colorGreen">
                              &#10004;
                            </p>
                          ) : (
                            <p className="fs14 textTransCap colorBlack">
                              &#10799;
                            </p>
                          )}
                        </>
                      )}
                    </>
                  </TableCell>
                  <TableCell className="fareDetailsBodyCell">
                    <>
                      {result?.vendor === "G9" &&
                        fare?.priceClass === "Basic" ? (
                        <p className="fs14 textTransCap colorBlack">&#10799;</p>
                      ) : fare?.pricedDescription &&
                        fare?.pricedDescription?.length &&
                        fare?.pricedDescription?.filter(
                          (obj) => obj.type === "CheckInBag"
                        ).length > 0 ? (
                        fare?.pricedDescription
                          .filter((obj) => obj.type === "CheckInBag")
                          .map((item) => (
                            <>
                              {item.type === "CheckInBag" &&
                                item.status === "Included" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorGreen">
                                    &#10004;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "CheckInBag" &&
                                item.status === "Chargeable" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorRed">
                                    &#36;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "CheckInBag" &&
                                item.status === "NotIncluded" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorBlack">
                                    &#10799;
                                  </p>
                                </BootstrapTooltip1>
                              ) : (
                                item.type === "CheckInBag" &&
                                (item.status === "None" ||
                                  (item.status !== "Included" &&
                                    item.status !== "Chargeable" &&
                                    item.status !== "NotIncluded")) && (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      {item.description}
                                    </p>
                                  </BootstrapTooltip1>
                                )
                              )}
                            </>
                          ))
                      ) : (
                        <>
                          {" "}
                          {result.vendor !== "G9" && fare?.baggages ? (
                            fare?.baggages.filter(
                              (item) => item.paxType === "ADT"
                            ).length > 0 ? (
                              <p className="fs14 textTransCap colorGreen">
                                &#10004;
                              </p>
                            ) : (
                              <p className="fs14 textTransCap colorBlack">
                                &#10799;
                              </p>
                            )
                          ) : (
                            <p className="fs14 textTransCap colorBlack">
                              &#10799;
                            </p>
                          )}
                        </>
                      )}
                    </>
                  </TableCell>
                  <TableCell className="fareDetailsBodyCell">
                    <>
                      {fare?.pricedDescription &&
                        fare?.pricedDescription?.length &&
                        fare?.pricedDescription?.filter(
                          (obj) => obj.type === "Seat"
                        ).length > 0 ? (
                        fare?.pricedDescription
                          .filter((obj) => obj.type === "Seat")
                          .map((item) => (
                            <>
                              {item.type === "Seat" &&
                                item.status === "Included" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorGreen">
                                    &#10004;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "Seat" &&
                                item.status === "Chargeable" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorRed">
                                    &#36;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "Seat" &&
                                item.status === "NotIncluded" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorBlack">
                                    &#10799;
                                  </p>
                                </BootstrapTooltip1>
                              ) : (
                                item.type === "Seat" &&
                                (item.status === "None" ||
                                  (item.status !== "Included" &&
                                    item.status !== "Chargeable" &&
                                    item.status !== "NotIncluded")) && (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      {item.description}
                                    </p>
                                  </BootstrapTooltip1>
                                )
                              )}
                            </>
                          ))
                      ) : (
                        <p className="fs14 textTransCap colorBlack">&#10799;</p>
                      )}
                    </>
                  </TableCell>
                  <TableCell className="fareDetailsBodyCell">
                    <>
                      {fare?.pricedDescription &&
                        fare?.pricedDescription?.length &&
                        fare?.pricedDescription?.filter(
                          (obj) => obj.type === "ReBooking"
                        ).length > 0 ? (
                        fare?.pricedDescription
                          .filter((obj) => obj.type === "ReBooking")
                          .map((item) => (
                            <>
                              {item.type === "ReBooking" &&
                                item.status === "Included" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorGreen">
                                    &#10004;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "ReBooking" &&
                                item.status === "Chargeable" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorRed">
                                    &#36;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "ReBooking" &&
                                item.status === "NotIncluded" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorBlack">
                                    &#10799;
                                  </p>
                                </BootstrapTooltip1>
                              ) : (
                                item.type === "ReBooking" &&
                                (item.status === "None" ||
                                  (item.status !== "Included" &&
                                    item.status !== "Chargeable" &&
                                    item.status !== "NotIncluded")) && (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      {item.description}
                                    </p>
                                  </BootstrapTooltip1>
                                )
                              )}
                            </>
                          ))
                      ) : (
                        <>
                          {fare?.miniRules &&
                            fare?.miniRules?.find(
                              (item) =>
                                item.paxType === "ADT" &&
                                item.changeAllowed === true &&
                                item.apply === "Before" &&
                                item.exgAmt > 0
                            ) ? (
                            <p className="fs14 textTransCap colorRed">&#36;</p>
                          ) : fare?.miniRules &&
                            fare?.miniRules?.find(
                              (item) =>
                                item.paxType === "ADT" &&
                                item.changeAllowed === true &&
                                item.apply === "Before" &&
                                item.exgAmt === 0
                            ) ? (
                            <p className="fs14 textTransCap colorGreen">
                              &#10004;
                            </p>
                          ) : (
                            <p className="fs14 textTransCap colorBlack">
                              &#10799;
                            </p>
                          )}
                        </>
                      )}
                    </>
                  </TableCell>
                  <TableCell className="fareDetailsBodyCell">
                    <>
                      {result.vendor === "G9" ? (
                        <p className="fs14 textTransCap colorBlack">&#10799;</p>
                      ) : fare?.pricedDescription &&
                        fare?.pricedDescription?.length &&
                        fare?.pricedDescription?.filter(
                          (obj) => obj.type === "Refund"
                        ).length > 0 ? (
                        fare?.pricedDescription
                          .filter((obj) => obj.type === "Refund")
                          .map((item) => (
                            <>
                              {item.type === "Refund" &&
                                item.status === "Included" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorGreen">
                                    &#10004;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "Refund" &&
                                item.status === "Chargeable" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorRed">
                                    &#36;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "Refund" &&
                                item.status === "NotIncluded" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorBlack">
                                    &#10799;
                                  </p>
                                </BootstrapTooltip1>
                              ) : (
                                item.type === "Refund" &&
                                (item.status === "None" ||
                                  (item.status !== "Included" &&
                                    item.status !== "Chargeable" &&
                                    item.status !== "NotIncluded")) && (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      {item.description}
                                    </p>
                                  </BootstrapTooltip1>
                                )
                              )}
                            </>
                          ))
                      ) : (
                        <>
                          {fare?.miniRules &&
                            fare?.miniRules?.find(
                              (item) =>
                                item.paxType === "ADT" &&
                                item.cancelAllowed === true &&
                                item.apply === "Before" &&
                                item.canAmt > 0
                            ) ? (
                            <p className="fs14 textTransCap colorRed">&#36;</p>
                          ) : fare?.miniRules &&
                            fare?.miniRules?.find(
                              (item) =>
                                item.paxType === "ADT" &&
                                item.cancelAllowed === true &&
                                item.apply === "Before" &&
                                item.canAmt === 0
                            ) ? (
                            <p className="fs14 textTransCap colorGreen">
                              &#10004;
                            </p>
                          ) : fare.refundable === true ? (
                            <p className="fs14 textTransCap colorRed">&#36;</p>
                          ) : (
                            <p className="fs14 textTransCap colorBlack">
                              &#10799;
                            </p>
                          )}
                        </>
                      )}
                    </>
                  </TableCell>
                  <TableCell className="fareDetailsBodyCell">
                    <>
                      {fare?.pricedDescription &&
                        fare?.pricedDescription?.length &&
                        fare?.pricedDescription?.filter(
                          (obj) => obj.type === "Meal"
                        ).length > 0 ? (
                        fare?.pricedDescription
                          .filter((obj) => obj.type === "Meal")
                          .map((item) => (
                            <>
                              {item.type === "Meal" &&
                                item.status === "Included" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorGreen">
                                    &#10004;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "Meal" &&
                                item.status === "Chargeable" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorRed">
                                    &#36;
                                  </p>
                                </BootstrapTooltip1>
                              ) : item.type === "Meal" &&
                                item.status === "NotIncluded" ? (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorBlack">
                                    &#10799;
                                  </p>
                                </BootstrapTooltip1>
                              ) : (
                                item.type === "Meal" &&
                                (item.status === "None" ||
                                  (item.status !== "Included" &&
                                    item.status !== "Chargeable" &&
                                    item.status !== "NotIncluded")) && (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      {item.description}
                                    </p>
                                  </BootstrapTooltip1>
                                )
                              )}
                            </>
                          ))
                      ) : (
                        <p className="fs14 textTransCap colorBlack">&#10799;</p>
                      )}
                    </>
                  </TableCell>
                  {result.vendor === "G9" || result.vendor === "F3" || result.vendor === "QP" ? null : (
                    <>
                      <TableCell className="fareDetailsBodyCell">
                        <>
                          {fare?.pricedDescription &&
                            fare?.pricedDescription?.length &&
                            fare?.pricedDescription?.filter(
                              (obj) => obj.type === "Wifi"
                            ).length > 0 ? (
                            fare?.pricedDescription
                              .filter((obj) => obj.type === "Wifi")
                              .map((item) => (
                                <>
                                  {item.type === "Wifi" &&
                                    item.status === "Included" ? (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorGreen">
                                        &#10004;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : item.type === "Wifi" &&
                                    item.status === "Chargeable" ? (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorRed">
                                        &#36;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : item.type === "Wifi" &&
                                    item.status === "NotIncluded" ? (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        &#10799;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : (
                                    item.type === "Wifi" &&
                                    (item.status === "None" ||
                                      (item.status !== "Included" &&
                                        item.status !== "Chargeable" &&
                                        item.status !== "NotIncluded")) && (
                                      <BootstrapTooltip1
                                        title={item.description}
                                      >
                                        <p className="fs14 textTransCap colorBlack">
                                          {item.description}
                                        </p>
                                      </BootstrapTooltip1>
                                    )
                                  )}
                                </>
                              ))
                          ) : (
                            <p className="fs14 textTransCap colorBlack">
                              &#10799;
                            </p>
                          )}
                        </>
                      </TableCell>{" "}
                    </>
                  )}
                  {ispriorityAvailable == undefined ? null : (
                    <>
                      <TableCell className="fareDetailsBodyCell">
                        <>
                          {fare?.pricedDescription &&
                            fare?.pricedDescription?.length &&
                            fare?.pricedDescription?.filter(
                              (obj) => obj.type === "AirportPriority"
                            ).length > 0 ? (
                            fare?.pricedDescription
                              .filter((obj) => obj.type === "AirportPriority")
                              .map((item) => (
                                <>
                                  {item.type === "AirportPriority" &&
                                    item.status === "Included" ? (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorGreen">
                                        &#10004;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : item.type === "AirportPriority" &&
                                    item.status === "Chargeable" ? (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorRed">
                                        &#36;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : item.type === "AirportPriority" &&
                                    item.status === "NotIncluded" ? (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        &#10799;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : (
                                    item.type === "AirportPriority" &&
                                    (item.status === "None" ||
                                      (item.status !== "Included" &&
                                        item.status !== "Chargeable" &&
                                        item.status !== "NotIncluded")) && (
                                      <BootstrapTooltip1
                                        title={item.description}
                                      >
                                        <p className="fs14 textTransCap colorBlack">
                                          {item.description}
                                        </p>
                                      </BootstrapTooltip1>
                                    )
                                  )}
                                </>
                              ))
                          ) : (
                            <p className="fs14 textTransCap colorBlack">
                              &#10799;
                            </p>
                          )}
                        </>
                      </TableCell>{" "}
                    </>
                  )}
                </>
              ))
            ) : (
              <>
                {" "}
                <TableCell
                  className="fareDetailsBodyCell"
                  onClick={() => setSelectedItinerary(0)}
                >
                  <p className="fs14 textTransCap">
                    {result?.priceClass?.toLowerCase()}
                  </p>
                </TableCell>
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {result?.pricedDescription &&
                      result?.pricedDescription?.length &&
                      result?.pricedDescription?.filter(
                        (obj) => obj.type === "CarryOn"
                      ).length > 0 ? (
                      result?.pricedDescription
                        ?.filter((obj) => obj.type === "CarryOn")
                        .map((item) => (
                          <>
                            {item.type === "CarryOn" &&
                              (result.vendor === "1A" ||
                                result.vendor === "1S" ||
                                result.vendor === "1G") ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorGreen">
                                  &#10004;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "CarryOn" &&
                              item.status === "Included" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorGreen">
                                  &#10004;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "CarryOn" &&
                              item.status === "Chargeable" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorRed">
                                  &#36;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "CarryOn" &&
                              item.status === "NotIncluded" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorBlack">
                                  &#10799;
                                </p>
                              </BootstrapTooltip1>
                            ) : (
                              item.type === "CarryOn" &&
                              (item.status === "None" ||
                                (item.status !== "Included" &&
                                  item.status !== "Chargeable" &&
                                  item.status !== "NotIncluded")) && (
                                <>
                                  {result?.content === "GDS" ? (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorGreen">
                                        &#10004;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        &#10799;
                                      </p>
                                    </BootstrapTooltip1>
                                  )}
                                </>
                              )
                            )}
                          </>
                        ))
                    ) : (
                      <>
                        {result?.content === "GDS" ? (
                          <p className="fs14 textTransCap colorGreen">
                            &#10004;
                          </p>
                        ) : (
                          <p className="fs14 textTransCap colorBlack">
                            &#10799;
                          </p>
                        )}
                      </>
                    )}
                  </>
                </TableCell>
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {/* && fare.priceClass === "Basic" */}
                    {(result.pcc === "G9-Test" ||
                      result.pcc === "G9-Test-new" ||
                      result.pcc === "G9-Live") &&
                      result?.priceClass === "Basic" ? (
                      <p className="fs14 textTransCap colorBlack">&#10799;</p>
                    ) : result?.pricedDescription &&
                      result?.pricedDescription?.length &&
                      result?.pricedDescription?.filter(
                        (obj) => obj.type === "CheckInBag"
                      ).length > 0 ? (
                      result?.pricedDescription
                        ?.filter((obj) => obj.type === "CheckInBag")
                        .map((item) => (
                          <>
                            {item.type === "CheckInBag" &&
                              item.status === "Included" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorGreen">
                                  &#10004;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "CheckInBag" &&
                              item.status === "Chargeable" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorRed">
                                  &#36;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "CheckInBag" &&
                              item.status === "NotIncluded" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorBlack">
                                  &#10799;
                                </p>
                              </BootstrapTooltip1>
                            ) : (
                              item.type === "CheckInBag" &&
                              (item.status === "None" ||
                                (item.status !== "Included" &&
                                  item.status !== "Chargeable" &&
                                  item.status !== "NotIncluded")) && (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorBlack">
                                    {item.description}
                                  </p>
                                </BootstrapTooltip1>
                              )
                            )}
                          </>
                        ))
                    ) : (
                      <>
                        {" "}
                        {result.vendor !== "G9" && result?.baggages ? (
                          result?.baggages.filter(
                            (item) => item.paxType === "ADT"
                          ).length > 0 ? (
                            <p className="fs14 textTransCap colorGreen">
                              &#10004;
                            </p>
                          ) : (
                            <p className="fs14 textTransCap colorBlack">
                              &#10799;
                            </p>
                          )
                        ) : (
                          <p className="fs14 textTransCap colorBlack">
                            &#10799;
                          </p>
                        )}
                      </>
                    )}
                  </>
                </TableCell>
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {result?.pricedDescription &&
                      result?.pricedDescription?.length &&
                      result?.pricedDescription?.filter(
                        (obj) => obj.type === "Seat"
                      ).length > 0 ? (
                      result?.pricedDescription
                        ?.filter((obj) => obj.type === "Seat")
                        .map((item) => (
                          <>
                            {item.type === "Seat" &&
                              item.status === "Included" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorGreen">
                                  &#10004;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "Seat" &&
                              item.status === "Chargeable" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorRed">
                                  &#36;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "Seat" &&
                              item.status === "NotIncluded" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorBlack">
                                  &#10799;
                                </p>
                              </BootstrapTooltip1>
                            ) : (
                              item.type === "Seat" &&
                              (item.status === "None" ||
                                (item.status !== "Included" &&
                                  item.status !== "Chargeable" &&
                                  item.status !== "NotIncluded")) && (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorBlack">
                                    {item.description}
                                  </p>
                                </BootstrapTooltip1>
                              )
                            )}
                          </>
                        ))
                    ) : (
                      <p className="fs14 textTransCap colorBlack">&#10799;</p>
                    )}
                  </>
                </TableCell>
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {result?.pricedDescription &&
                      result?.pricedDescription?.length &&
                      result?.pricedDescription?.filter(
                        (obj) => obj.type === "ReBooking"
                      ).length > 0 ? (
                      result?.pricedDescription
                        ?.filter((obj) => obj.type === "ReBooking")
                        .map((item) => (
                          <>
                            {item.type === "ReBooking" &&
                              item.status === "Included" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorGreen">
                                  &#10004;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "ReBooking" &&
                              item.status === "Chargeable" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorRed">
                                  &#36;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "ReBooking" &&
                              item.status === "NotIncluded" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorBlack">
                                  &#10799;
                                </p>
                              </BootstrapTooltip1>
                            ) : (
                              item.type === "ReBooking" &&
                              (item.status === "None" ||
                                (item.status !== "Included" &&
                                  item.status !== "Chargeable" &&
                                  item.status !== "NotIncluded")) && (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorBlack">
                                    {item.description}
                                  </p>
                                </BootstrapTooltip1>
                              )
                            )}
                          </>
                        ))
                    ) : (
                      <>
                        {result?.miniRules?.find(
                          (item) =>
                            item.paxType === "ADT" &&
                            item.changeAllowed === true &&
                            item.apply === "Before" &&
                            item.exgAmt > 0
                        ) ? (
                          <p className="fs14 textTransCap colorRed">&#36;</p>
                        ) : result?.miniRules?.find(
                          (item) =>
                            item.paxType === "ADT" &&
                            item.changeAllowed === true &&
                            item.apply === "Before" &&
                            item.exgAmt === 0
                        ) ? (
                          <p className="fs14 textTransCap colorGreen">
                            &#10004;
                          </p>
                        ) : (
                          <p className="fs14 textTransCap colorBlack">
                            &#10799;
                          </p>
                        )}
                      </>
                    )}
                  </>
                </TableCell>
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {result.pcc === "G9-Test" ||
                      result.pcc === "G9-Test-new" ||
                      result.pcc === "G9-Live" ? (
                      <p className="fs14 textTransCap colorBlack">&#10799;</p>
                    ) : result?.pricedDescription &&
                      result?.pricedDescription?.length &&
                      result?.pricedDescription?.filter(
                        (obj) => obj.type === "Refund"
                      ).length > 0 ? (
                      result?.pricedDescription
                        ?.filter((obj) => obj.type === "Refund")
                        .map((item) => (
                          <>
                            {item.type === "Refund" &&
                              item.status === "Included" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorGreen">
                                  &#10004;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "Refund" &&
                              item.status === "Chargeable" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorRed">
                                  &#36;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "Refund" &&
                              item.status === "NotIncluded" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorBlack">
                                  &#10799;
                                </p>
                              </BootstrapTooltip1>
                            ) : (
                              item.type === "Refund" &&
                              (item.status === "None" ||
                                (item.status !== "Included" &&
                                  item.status !== "Chargeable" &&
                                  item.status !== "NotIncluded")) && (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorBlack">
                                    {item.description}
                                  </p>
                                </BootstrapTooltip1>
                              )
                            )}
                          </>
                        ))
                    ) : (
                      <>
                        {result?.miniRules?.find(
                          (item) =>
                            item.paxType === "ADT" &&
                            item.cancelAllowed === true &&
                            item.apply === "Before" &&
                            item.canAmt > 0
                        ) ? (
                          <p className="fs14 textTransCap colorRed">&#36;</p>
                        ) : result?.miniRules?.find(
                          (item) =>
                            item.paxType === "ADT" &&
                            item.cancelAllowed === true &&
                            item.apply === "Before" &&
                            item.canAmt === 0
                        ) ? (
                          <p className="fs14 textTransCap colorGreen">
                            &#10004;
                          </p>
                        ) : result.refundable === true ? (
                          <p className="fs14 textTransCap colorRed">&#36;</p>
                        ) : (
                          <p className="fs14 textTransCap colorBlack">
                            &#10799;
                          </p>
                        )}
                      </>
                    )}
                  </>
                </TableCell>
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {result?.pricedDescription &&
                      result?.pricedDescription?.length &&
                      result?.pricedDescription?.filter(
                        (obj) => obj.type === "Meal"
                      ).length > 0 ? (
                      result?.pricedDescription
                        ?.filter((obj) => obj.type === "Meal")
                        .map((item) => (
                          <>
                            {item.type === "Meal" &&
                              item.status === "Included" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorGreen">
                                  &#10004;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "Meal" &&
                              item.status === "Chargeable" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorRed">
                                  &#36;
                                </p>
                              </BootstrapTooltip1>
                            ) : item.type === "Meal" &&
                              item.status === "NotIncluded" ? (
                              <BootstrapTooltip1 title={item.description}>
                                <p className="fs14 textTransCap colorBlack">
                                  &#10799;
                                </p>
                              </BootstrapTooltip1>
                            ) : (
                              item.type === "Meal" &&
                              (item.status === "None" ||
                                (item.status !== "Included" &&
                                  item.status !== "Chargeable" &&
                                  item.status !== "NotIncluded")) && (
                                <BootstrapTooltip1 title={item.description}>
                                  <p className="fs14 textTransCap colorBlack">
                                    {item.description}
                                  </p>
                                </BootstrapTooltip1>
                              )
                            )}
                          </>
                        ))
                    ) : (
                      <p className="fs14 textTransCap colorBlack">&#10799;</p>
                    )}
                  </>
                </TableCell>
                {result.pcc === "G9-Test" ||
                  result.pcc === "G9-Test-new" ||
                  result.pcc === "G9-Live" || result.pcc === "AkasaAir-QP" ? (
                  ""
                ) : (
                  <>
                    <TableCell className="fareDetailsBodyCell">
                      <>
                        {result?.pricedDescription &&
                          result?.pricedDescription?.length &&
                          result?.pricedDescription?.filter(
                            (obj) => obj.type === "Wifi"
                          ).length > 0 ? (
                          result?.pricedDescription
                            ?.filter((obj) => obj.type === "Wifi")
                            .map((item) => (
                              <>
                                {item.type === "Wifi" &&
                                  item.status === "Included" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Wifi" &&
                                  item.status === "Chargeable" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Wifi" &&
                                  item.status === "NotIncluded" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "Wifi" &&
                                  (item.status === "None" ||
                                    (item.status !== "Included" &&
                                      item.status !== "Chargeable" &&
                                      item.status !== "NotIncluded")) && (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.description}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </>
                            ))
                        ) : (
                          <p className="fs14 textTransCap colorBlack">
                            &#10799;
                          </p>
                        )}
                      </>
                    </TableCell>{" "}
                  </>
                )}
                {ispriorityAvailable == undefined ? null : (
                  <>
                    <TableCell className="fareDetailsBodyCell">
                      <>
                        {result?.pricedDescription &&
                          result?.pricedDescription?.length &&
                          result?.pricedDescription?.filter(
                            (obj) => obj.type === "AirportPriority"
                          ).length > 0 ? (
                          result?.pricedDescription
                            .filter((obj) => obj.type === "AirportPriority")
                            .map((item) => (
                              <>
                                {item.type === "AirportPriority" &&
                                  item.status === "Included" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "AirportPriority" &&
                                  item.status === "Chargeable" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "AirportPriority" &&
                                  item.status === "NotIncluded" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "AirportPriority" &&
                                  (item.status === "None" ||
                                    (item.status !== "Included" &&
                                      item.status !== "Chargeable" &&
                                      item.status !== "NotIncluded")) && (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.description}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </>
                            ))
                        ) : (
                          <p className="fs14 textTransCap colorBlack">
                            &#10799;
                          </p>
                        )}
                      </>
                    </TableCell>{" "}
                  </>
                )}
              </>
            )}
          </TableRow>
          <TableRow>
            <TableCell colSpan={9} className="singleFareDetailsHeadCell">
              <p className="fs14 textTransCap ">
                <span className="colorGreen"> &#10004;</span> - Included{" "}
                <span className="colorRed">&#36;</span> - Chargable{" "}
                <span className="colorBlack">&#10799;</span> - Not Included
              </p>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
