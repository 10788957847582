import React, { useState, useEffect } from "react";
import "./SearchCategory.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import SearchCategoryTable from "./SearchCategoryTable/SearchCategoryTable";
import SearchCategoryDrawer from "./SearchCategoryDrawer/SearchCategoryDrawer";
import AddSearchCategoryCard from "./AddSearchCategoryCard/AddSearchCategoryCard";
import axios from "axios";

import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../services/AuthService";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SearchCategory() {
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("new");
  const [categoryAction, setCategoryAction] = useState("view");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const history = useHistory();
  useEffect(() => {
    if (isUserHasPermission("SearchCategoryGroupRead")) {
      loadCategories();
    } else {
      history.push("/Unauthorized");
    }
  }, []);

  async function loadCategories() {
    let filter = "";
    if (name !== "") filter = "?filters=name@=" + name;

    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/SearchCategory" +
        filter
      )
      .then((response) => {
        response.data.map((item) => {
          if (item.searchCategories.isActive) {
            item.searchCategories.status = "active";
          } else {
            item.searchCategories.status = "inactive";
          }
        });
        setCategories(response.data);
      })
      .catch((error) => console.log(error));
  }

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
    if (value === false) {
      // loadCategories();
    }
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };

  const handleSnackbarToggle = () => {
    setDrawer(false);
    setSnackbarOpen(true);
    loadCategories();
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="search-container">
      <div className="search-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-search-container">
              <div className="search-table-container">
                <div className="search-title-add-button-container">
                  <div className="search-heading">Search Category</div>
                  <div className="search-icon-button-container">
                    {/* <SearchIcon
                      fontSize="small"
                      className="search-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon> */}
                    {isUserHasPermission("SearchCategoryGroupWrite") ? (
                      <div
                        className="add-search-button mt-0px"
                        onClick={() => {
                          setAction("new");
                          setDrawer(true);
                        }}
                      >
                        Add New Category
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="search-card-container">
                    <AddSearchCategoryCard
                      name={name}
                      setName={setName}
                      loadCategories={loadCategories}
                    ></AddSearchCategoryCard>
                  </div>
                </Collapse>
                <SearchCategoryTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                  categories={categories}
                ></SearchCategoryTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <SearchCategoryDrawer
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
            action={action}
            setAction={setAction}
            handleSnackbarToggle={handleSnackbarToggle}
          ></SearchCategoryDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {`Setting has been ${action === "new" ? "created" : "updated"}`}
        </Alert>
      </Snackbar>
    </div>
  );
}
