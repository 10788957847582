import { Table } from "@mui/joy";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
export default function SupplierFare({ flight, fopText }) {
    return (
        <div className='flight-itenary'>
            <div className='flight-itenary-header'>
                <div
                    className='flight-itenary-title-container'
                    style={{ width: "100%" }}>
                    <p style={{ marginBottom: "0px", width: "100%" }}>
                        <span style={{ float: "left" }}>Supplier Fare</span>
                        <span style={{ float: "right" }}>
                            {/* Fop - {flight?.supplierFare[0]?.fop} */}
                            FOP - {flight?.supplierFare[0]?.fop && flight?.supplierFare[0]?.fop != null && flight.cardInfoId === 0 && flight?.supplierFare[0]?.fop !== "Cash" && !(flight?.supplierFare[0]?.fop?.includes("PassThrough")) ?
                                ("PC" + (flight?.supplierFare[0]?.fop.length > 4 ? flight?.supplierFare[0]?.fop.substr(flight?.supplierFare[0]?.fop.length - 4) : flight?.supplierFare[0]?.fop))
                                : flight?.supplierFare[0]?.fop?.includes("PassThrough") ?
                                    "PassThrough"
                                    : fopText && fopText !== undefined ?
                                        fopText
                                        : "Cash"}
                        </span>
                        <span style={{ float: "right", marginRight: "10px" }}>
                            Currency - {flight?.supplierFare[0]?.supplierCurrency}
                        </span>
                    </p>
                </div>
            </div>
            {flight?.supplierFare && flight?.supplierFare?.length > 0 ? (
                <Table>
                    <thead className='mui-table-header'>
                        <BootstrapTooltip1 title='PaxType'>
                            <th style={{ textAlign: "center" }}>PaxType</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='Base Fare'>
                            <th style={{ textAlign: "center" }}>Base Fare</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='Tax'>
                            <th style={{ textAlign: "center" }}>Tax</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='Markup'>
                            <th style={{ textAlign: "center" }}>Markup</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='IATA'>
                            <th style={{ textAlign: "center" }}>IATA</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='PLB'>
                            <th style={{ textAlign: "center" }}>PLB</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='DropNet'>
                            <th style={{ textAlign: "center" }}>DropNet</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='Vendor Fee'>
                            <th style={{ textAlign: "center" }}>Vendor Fee</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='Vendor Tax'>
                            <th style={{ textAlign: "center" }}>Vendor Tax</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='Baggage'>
                            <th style={{ textAlign: "center" }}>Baggage</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='Seat'>
                            <th style={{ textAlign: "center" }}>Seat</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='Meal'>
                            <th style={{ textAlign: "center" }}>Meal</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='GST'>
                            <th style={{ textAlign: "center" }}>GST</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='Fare Type'>
                            <th style={{ textAlign: "center" }}>Fare Type</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='Supplier Total (Per Pax)'>
                            <th style={{ textAlign: "center" }}>Supplier Total (Per Pax)</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title='Supplier Total'>
                            <th style={{ textAlign: "center" }}>Supplier Total</th>
                        </BootstrapTooltip1>
                        {/* <BootstrapTooltip1 title="IBoss total">
                                    <th style={{ textAlign: 'center' }}>IBoss total</th>
                                </BootstrapTooltip1> */}
                    </thead>
                    <tbody>
                        {flight?.supplierFare
                            ?.sort(function (a, b) {
                                if (a.paxType < b.paxType) {
                                    return -1;
                                }
                                if (a.paxType > b.paxType) {
                                    return 1;
                                }
                                return 0;
                            })
                            .map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td align='center'>
                                            {item?.paxType} * {item?.paxCount}
                                        </td>
                                        <td align='center'>{(item?.baseFare).toFixed(2)}</td>
                                        <td align='center'>{(item?.tax).toFixed(2)}</td>
                                        <td align='center'>{(item?.markup).toFixed(2)}</td>
                                        <td align='center'>{(item?.iata).toFixed(2)}</td>
                                        <td align='center'>{(item?.plb).toFixed(2)}</td>
                                        <td align='center'>{(item?.dropNet).toFixed(2)}</td>
                                        <td align='center'>{(item?.vendorIssuanceFee).toFixed(2)}</td>
                                        <td align='center'>{item?.vendorTax ? item?.vendorTax.toFixed(2) : '0.00'}</td>
                                        <td align='center'>{(item?.baggagefare).toFixed(2)}</td>
                                        <td align='center'>{(item?.seatFare).toFixed(2)}</td>
                                        <td align='center'>{(item?.mealFare).toFixed(2)}</td>
                                        <td align='center'>{(item?.gst).toFixed(2)}</td>
                                        <td align='center'>{(item?.fareType)}</td>
                                        <td align='center'>{(item?.supplierTotal).toFixed(2)}</td>
                                        <td align='center'>{(item?.supplierTotal * item?.paxCount).toFixed(2)}</td>
                                        {/* <td align="center">{item.ibossTotal}</td> */}
                                    </tr>
                                );
                            })}
                        <tr>
                            <td align='center' className="fw600">
                                <b>Total</b>
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.baseFare || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.tax || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.markup || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.iata || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.plb || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.dropNet || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.vendorIssuanceFee || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.vendorTax || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.baggagefare || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.seatFare || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.mealFare || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.gst || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {/* fareType might need a different handling */}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + ((item?.supplierTotal) || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + ((item?.supplierTotal * item?.paxCount) || 0), 0)).toFixed(2)}
                            </td>
                        </tr>
                    </tbody>
                    <p style={{ margin: "0px", padding: "0px 10px", width: '40vw', color: 'red' }}>* The fares shown above are for one pax </p>
                </Table>
            ) : (
                ""
            )}
        </div>
    );
}
