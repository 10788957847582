import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './PreviewOfflineBooking.css';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";

const PreviewOfflineBooking = ({
  supplierName,
  pcc,
  clientId,
  onwardSegments,
  returnSegments,
  passengers,
  fop,
  clientProfiles,
  clientROE,
  caseID,
  salesStaff,
  clientEmail,
  cabinClass,
  fema,
  cardNumber,
  expiryDate,
  panCard,
  mco,
  pgCharge,
  mcoParted,
  mcoRemark,
  importPNRRemarks,
  fareRows,
  adultBase,
  adultTax,
  adultBaggage,
  childBase,
  childTax,
  childBaggage,
  infantBase,
  infantTax,
  infantBaggage,
  adultVendorIssuanceFee,
  childVendorIssuanceFee,
  infantVendorIssuanceFee,
  adultMarkup,
  childMarkup,
  infantMarkup,
  adultVendorTax,
  childVendorTax,
  infantVendorTax,
  adultVendorTotal,
  childVendorTotal,
  infantVendorTotal,
  adultIata,
  childIata,
  infantIata,
  adultVendorIata,
  childVendorIata,
  infantVendorIata,
  adultPlb,
  childPlb,
  infantPlb,
  adultVendorPlb,
  childVendorPlb,
  infantVendorPlb,
  bookingType,
  selectedTripType,
  userProfiles,
  clientAvailableBalance,
  clientTotalWithClientROE,
  vendorTotal,
  clientCurrency,
  vendorCurrencyCode,
  value,
  endClientName,
  setValidateForm,
  currency,
  clientsCurrency,
  fopOptions,
  source
}) => {




  function getClientNameById(clientID) {
    const profile = clientProfiles.find(profile => profile.id === clientID);
    return profile ? profile.clientName : "";
  }

  const clientName = getClientNameById(clientId);


  const getUserById = (id) => {
    return userProfiles.find(user => user.id === id);
  };
  const user = getUserById(salesStaff);

  const getFopId = (id) => {
    return fopOptions.find(fop => fop.id === id);
  };
  const fopDetail = getFopId(fop);



  const handleClose = () => {
    setValidateForm(false);
  };

  return (
    <div className='tableSide'>
      <div className='d-flex justify-content-between'>
        <div></div>
        <div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <Grid item xs={12} style={{ maxWidth: '90vw', margin: '0px 5vw' }}>
        <div className="offline-flight-itenary">
          <Paper
            elevation={1}
            className="OfflineBookingPaper"
            sx={{ padding: '0px' }}
            style={{ padding: '0px' }}
          >
            <div className="flight-itenary-header">
              <div className="flight-itenary-title-container">Offline Booking - {value}</div>
            </div>
          </Paper>
        </div>


        <Paper className="offline_booking border-label" style={{ padding: '30px 0' }} >
          <div>
            <Typography variant="body2">
              <span className="bold-dark">Booking Type:</span> {bookingType === 'fitBooking' ? 'FIT Booking' : 'Group Booking'}
            </Typography>
          </div>

          <Grid container spacing={2} style={{ padding: '25px 0' }}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2" gutterBottom className="bold-dark">Client</Typography>

              <Typography variant="body2">{clientName + '(' + clientId + ')'}</Typography>

            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2" gutterBottom className="bold-dark">Supplier Name</Typography>
              <Typography variant="body2">{supplierName}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2" gutterBottom className="bold-dark">PCC</Typography>
              <Typography variant="body2">{pcc}</Typography>
            </Grid>

          </Grid>
          <Grid>
            <div className="text-left">
              <Typography variant="body2">
                <span className="bold-dark">Client Available Balance:  {clientCurrency ? clientCurrency : " "}{" "} {clientAvailableBalance}</span>
              </Typography>
            </div>
          </Grid>
        </Paper>



        <Paper className="offline-itenary-container-import" elevation={3} style={{ marginTop: '16px', justifyContent: 'center' }}>
          <div style={{ padding: '15px 0' }}>
            <div>
              <Typography variant="h6" gutterBottom>Itinerary Details</Typography>
              <Typography variant="body2" className="text-center">
                <span className="bold-dark">Trip Type:</span> {selectedTripType === 'onward' ? 'Onward' : 'Return'}
              </Typography>
            </div>


            <Grid container spacing={3} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">Airline</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">Flight No</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">Orig.Airport</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">Dest.Airport</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">Dept.Date</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">Dept.Time</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">Arr.Date</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">Arr.Time</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">Cabin Class</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">Book.Class</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">GDS PNR</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className="headerleft">
                <Typography variant="subtitle2" className="bold-dark">Air.PNR</Typography>
              </Grid>
            </Grid>

            {onwardSegments.map((segment, index) => (
              <Grid key={index} container spacing={3} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{segment.airline}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{segment.flightNo}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{segment.originAirport}</Typography>
                  <Typography variant="subtitle2" className="bold-dark">Orig.Term</Typography>
                  <Typography variant="body2" style={{ fontSize: '0.75rem' }}>{segment.originTerminal}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{segment.destinationAirport}</Typography>
                  <Typography variant="subtitle2" className="bold-dark">Dest.Term</Typography>
                  <Typography variant="body2" style={{ fontSize: '0.75rem' }}>{segment.destinationTerminal}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{dayjs(segment.depDate.$d).format('YYYY-MM-DD')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{dayjs(segment.depTime.$d).format('HH:mm:ss')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{dayjs(segment.arrDate.$d).format('YYYY-MM-DD')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{dayjs(segment.arrTime.$d).format('HH:mm:ss')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{(cabinClass.find(
                    (v) => v.value === segment.cabinClass
                  )?.label)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{segment.bookingClass}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{segment.gdsPNR}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className="headerleft">
                  <Typography variant="body2">{segment.airlinePNR}</Typography>
                </Grid>
              </Grid>
            ))}


            {selectedTripType === 'return' && (
              <>
                <Grid container spacing={3} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">Airline</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">Flight No</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">Orig.Airport</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">Dest.Airport</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">Dept.Date</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">Dept.Time</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">Arr.Date</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">Arr.Time</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">Cabin Class</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">Book.Class</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">GDS PNR</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className="headerleft">
                    <Typography variant="subtitle2" className="bold-dark">Air.PNR</Typography>
                  </Grid>
                </Grid>
                {returnSegments.map((segment, index) => (
                  <Grid key={index} container spacing={3} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{segment.airline}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{segment.flightNo}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{segment.originAirport}</Typography>
                      <Typography variant="subtitle2" className="bold-dark">Orig.Term</Typography>
                      <Typography variant="body2" style={{ fontSize: '0.75rem' }}>{segment.originTerminal}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{segment.destinationAirport}</Typography>
                      <Typography variant="subtitle2" className="bold-dark">Dest.Term</Typography>
                      <Typography variant="body2" style={{ fontSize: '0.75rem' }}>{segment.destinationTerminal}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{dayjs(segment.depDate.$d).format('YYYY-MM-DD')}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{dayjs(segment.depTime.$d).format('HH:mm:ss')}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{dayjs(segment.arrDate.$d).format('YYYY-MM-DD')}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{dayjs(segment.arrTime.$d).format('HH:mm:ss')}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{(cabinClass.find(
                        (v) => v.value === segment.cabinClass
                      )?.label)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{segment.bookingClass}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{segment.gdsPNR}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} className="headerleft">
                      <Typography variant="body2">{segment.airlinePNR}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </div>
        </Paper>




        <Paper className="offline-passanger-container-import" elevation={3} style={{ marginTop: '16px', padding: '16px' }}>
          <div style={{ padding: '15px 0' }}>
            <div><Typography variant="h6" gutterBottom>Passenger Details</Typography></div>


            <Grid container spacing={2} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Pax type</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Title</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">First Name</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Last Name</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Date of Birth</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Gender</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Ticket No</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Passport No</Typography>
              </Grid>
            </Grid>


            {passengers.map((passenger, index) => (
              <Grid key={index} container spacing={2} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{passenger.paxType}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{passenger.title}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{passenger.firstName}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{passenger.lastName}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{passenger.dob}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{passenger.genderType}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{passenger.ticketNumber}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{passenger.passportNumber}</Typography>
                </Grid>
              </Grid>
            ))}
          </div>
        </Paper>



        <Paper className="offline-baggage-container-import" elevation={3} style={{ marginTop: '16px', padding: '16px' }}>
          <div style={{ padding: '15px 0' }}>
            <div><Typography variant="h6" gutterBottom>Baggage</Typography></div>


            <Grid container spacing={2} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Passenger Type</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">First Name</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Last Name</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">CheckIn Bag</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Cabin Bag</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">City Pair</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Amount</Typography>
              </Grid>
            </Grid>


            {passengers.map((passenger, pIndex) => (
              passenger.baggage.map((baggage, bIndex) => (
                <Grid key={`${pIndex}-${bIndex}`} container spacing={2} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
                  <Grid item xs={12} sm={6} md={1} className='headerleft'>
                    <Typography variant="body2">{passenger.paxType}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className='headerleft'>
                    <Typography variant="body2">{passenger.firstName}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className='headerleft'>
                    <Typography variant="body2">{passenger.lastName}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className='headerleft'>
                    <Typography variant="body2">{baggage.checkInBag}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className='headerleft'>
                    <Typography variant="body2">{baggage.cabinBag}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className='headerleft'>
                    <Typography variant="body2">{baggage.cityPair}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1} className='headerleft'>
                    <Typography variant="body2">{baggage.amount}</Typography>
                  </Grid>
                </Grid>
              ))
            ))}
          </div>
        </Paper>


        <Paper className="offline-clientfare-container-import" elevation={3} style={{ marginTop: '16px', padding: '16px' }}>
          <div style={{ padding: '15px 0' }}>
            <div>
              <Typography variant="h6" gutterBottom>Supplier Fare Details ({currency})</Typography>
            </div>
            <Grid container spacing={2} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Pax</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Base Fare</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Tax</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Baggage</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">MarkUp</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Vendor Issuance Fee</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Vendor TAX</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Total</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Vendor IATA</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Vendor PLB</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Client Parting IATA</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Client Parting PLB</Typography>
              </Grid>
            </Grid>

            {fareRows.map((row, index) => (
              <Grid key={index} container spacing={2} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? "Adult" : row.paxType === "CHD" ? "Child" : row.paxType === "INF" ? "Infant" : "Adult"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? adultBase : row.paxType === "CHD" ? childBase : row.paxType === "INF" ? infantBase : adultBase}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? adultTax : row.paxType === "CHD" ? childTax : row.paxType === "INF" ? infantTax : adultTax}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? adultBaggage : row.paxType === "CHD" ? childBaggage : row.paxType === "INF" ? infantBaggage : adultBaggage}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? adultMarkup : row.paxType === "CHD" ? childMarkup : row.paxType === "INF" ? infantMarkup : adultMarkup}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? adultVendorIssuanceFee : row.paxType === "CHD" ? childVendorIssuanceFee : row.paxType === "INF" ? infantVendorIssuanceFee : adultVendorIssuanceFee}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? adultVendorTax : row.paxType === "CHD" ? childVendorTax : row.paxType === "INF" ? infantVendorTax : adultVendorTax}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? adultVendorTotal : row.paxType === "CHD" ? childVendorTotal : row.paxType === "INF" ? infantVendorTotal : adultVendorTotal}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? adultVendorIata : row.paxType === "CHD" ? childVendorIata : row.paxType === "INF" ? infantVendorIata : adultVendorIata}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? adultVendorPlb : row.paxType === "CHD" ? childVendorPlb : row.paxType === "INF" ? infantVendorPlb : adultVendorPlb}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? adultIata : row.paxType === "CHD" ? childIata : row.paxType === "INF" ? infantIata : adultIata}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">{row.paxType === "ADT" ? adultPlb : row.paxType === "CHD" ? childPlb : row.paxType === "INF" ? infantPlb : adultPlb}</Typography>
                </Grid>
              </Grid>
            ))}
          </div>
        </Paper>



        <Paper className="offline-clientfare-container-import" elevation={3} style={{ marginTop: '16px', padding: '16px' }}>
          <div style={{ padding: '15px 0' }}>
            <div>
              <Typography variant="h6" gutterBottom>Client Fare Details ({clientsCurrency})</Typography>
            </div>


            <Grid container spacing={2} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={12} sm={6} md={1} className='headerleft '>
                <Typography variant="subtitle2" className="bold-dark">Pax</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Base Fare</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Tax</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Baggage</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Vendor Issuance Fee</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Vendor TAX</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Total</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Vendor IATA</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Vendor PLB</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Client Parting IATA</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} className='headerleft'>
                <Typography variant="subtitle2" className="bold-dark">Client Parting PLB</Typography>
              </Grid>
            </Grid>


            {fareRows.map((row, index) => (
              <Grid key={index} container spacing={2} style={{ padding: '25px 0', display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">
                    {row.paxType === "ADT"
                      ? "Adult"
                      : row.paxType === "CHD"
                        ? "Child"
                        : row.paxType === "INF"
                          ? "Infant"
                          : "Adult"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">
                    {row.paxType === "ADT" ? ((adultBase + adultMarkup) * clientROE).toFixed(2)
                      : row.paxType === "CHD" ? ((childBase + childMarkup) * clientROE).toFixed(2)
                        : row.paxType === "INF" ? ((infantBase + infantMarkup) * clientROE).toFixed(2)
                          : adultBase}
                    <br></br>
                    <span style={{ fontSize: "8px" }}>
                      (BaseFare + Markup)
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">
                    {row.paxType === "ADT" ? (adultTax * clientROE).toFixed(2)
                      : row.paxType === "CHD" ? (childTax * clientROE).toFixed(2)
                        : row.paxType === "INF" ? (infantTax * clientROE).toFixed(2)
                          : adultTax}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">
                    {row.paxType === "ADT" ? (adultBaggage * clientROE).toFixed(2)
                      : row.paxType === "CHD" ? (childBaggage * clientROE).toFixed(2)
                        : row.paxType === "INF" ? (infantBaggage * clientROE).toFixed(2)
                          : adultBaggage}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">
                    {row.paxType === "ADT" ? (adultVendorIssuanceFee * clientROE).toFixed(2)
                      : row.paxType === "CHD" ? (childVendorIssuanceFee * clientROE).toFixed(2)
                        : row.paxType === "INF" ? (infantVendorIssuanceFee * clientROE).toFixed(2)
                          : adultVendorIssuanceFee}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">
                    {row.paxType === "ADT" ? (adultVendorTax * clientROE).toFixed(2)
                      : row.paxType === "CHD" ? (childVendorTax * clientROE).toFixed(2)
                        : row.paxType === "INF" ? (infantVendorTax * clientROE).toFixed(2)
                          : adultVendorTax}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">
                    {row.paxType === "ADT" ? ((adultVendorTotal + adultMarkup) * clientROE).toFixed(2)
                      : row.paxType === "CHD" ? ((childVendorTotal + childMarkup) * clientROE).toFixed(2)
                        : row.paxType === "INF" ? ((infantVendorTotal + infantMarkup) * clientROE).toFixed(2)
                          : adultVendorTotal}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">
                    {row.paxType === "ADT" ? (adultVendorIata * clientROE).toFixed(2)
                      : row.paxType === "CHD" ? (childVendorIata * clientROE).toFixed(2)
                        : row.paxType === "INF" ? (infantVendorIata * clientROE).toFixed(2)
                          : adultVendorIata}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">
                    {row.paxType === "ADT" ? (adultVendorPlb * clientROE).toFixed(2)
                      : row.paxType === "CHD" ? (childVendorPlb * clientROE).toFixed(2)
                        : row.paxType === "INF" ? (infantVendorPlb * clientROE).toFixed(2)
                          : adultVendorPlb}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">
                    {row.paxType === "ADT" ? (adultIata * clientROE).toFixed(2)
                      : row.paxType === "CHD" ? (childIata * clientROE).toFixed(2)
                        : row.paxType === "INF" ? (infantIata * clientROE).toFixed(2)
                          : adultIata}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1} className='headerleft'>
                  <Typography variant="body2">
                    {row.paxType === "ADT" ? (adultPlb * clientROE).toFixed(2)
                      : row.paxType === "CHD" ? (childPlb * clientROE).toFixed(2)
                        : row.paxType === "INF" ? (infantPlb * clientROE).toFixed(2)
                          : adultPlb}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </div>
        </Paper>




        <Paper className="offline_bookingfooter border-label" elevation={3} style={{ marginTop: '16px', padding: '16px' }}>

          <div style={{ padding: '15px 0' }}>
            <Grid container spacing={2} style={{ padding: '25px 0', justifyContent: 'center' }}>

              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>Fop</Typography>
                <Typography variant="body2">{fopDetail.des}</Typography>
              </Grid>
              {fop === "Passenger Card" && <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }} >
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>Card number</Typography>
                <Typography variant="body2">{cardNumber}</Typography>
              </Grid>}
              {fop === "Passenger Card" && <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>Expiry Date</Typography>
                <Typography variant="body2">{expiryDate}</Typography>
              </Grid>}
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>client ROE</Typography>
                <Typography variant="body2">{clientROE}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>Case ID</Typography>
                <Typography variant="body2">{caseID}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>Sales Staff</Typography>
                <Typography variant="body2">{user.firstName + " " + user.lastName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>End Client Name</Typography>

                <Typography variant="body2">{endClientName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>End Client Email</Typography>
                <Typography variant="body2">{clientEmail}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>Corporate/Retailer</Typography>
                <Typography variant="body2">{fema}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>panCard</Typography>
                <Typography variant="body2">{panCard}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>MCO Number</Typography>
                <Typography variant="body2">{mco}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>MCO Charge</Typography>
                <Typography variant="body2">{pgCharge}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>MCO Parting</Typography>
                <Typography variant="body2">{mcoParted}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>MCO Remark</Typography>
                <Typography variant="body2">{mcoRemark}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom>Source of Booking</Typography>
                <Typography variant="body2">{source}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom></Typography>
                <Typography variant="body2"></Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                <Typography variant="subtitle2" className="bold-dark" gutterBottom></Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} className="full-width-item">
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Typography variant="body2" style={{ marginRight: '20px', marginTop: '20px' }}>
                    <span className="bold-dark clientsupplier1">Client Payable:</span>
                    <span className='bold-dark'> {clientCurrency ? clientCurrency : " "}{" "} {clientTotalWithClientROE}</span>
                  </Typography>
                  <Typography variant="body2" style={{ marginRight: '10px', marginTop: '20px' }}>
                    <span className="bold-dark clientsupplier2">Supplier Payable: </span>
                    <span className='bold-dark'>{vendorCurrencyCode} {vendorTotal}</span>
                  </Typography>
                </div>
              </Grid>


              <Grid item xs={12} className="full-width-item">
                <div className="bordered-item">
                  <Typography variant="subtitle2" className="bold-dark" gutterBottom>Remarks</Typography>
                  <Typography variant="body2">{importPNRRemarks}</Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    </div>
  );
};

export default PreviewOfflineBooking;
