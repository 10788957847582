import React from "react";
import { Row } from "react-bootstrap";
import { getMiniRulesData, filterAdultChangeDate, filterChildChangeDate, filterInfantChangeDate } from "../../functions/getMiniRulesData";

const DateChange = ({ currentTicket, currencyCode, selectedItinerary }) => {
    return (
        <>
            {currentTicket.fareGroups[0].miniRules &&
                getMiniRulesData(
                    currentTicket?.fareGroups[selectedItinerary]?.miniRules,
                    "Date Change"
                ).length === 0 ? (
                <div className="">
                    <table className="fareDetailsTable mb-3">
                        <tr>
                            <th className="top_headers">Pax</th>
                            <th className="top_headers">
                                Applicable For
                            </th>
                            <th className="top_headers">Amount</th>
                            <th className="top_headers">Remarks</th>
                        </tr>
                        <tr>
                            {currentTicket.adtNum >= 1 ? (
                                <>
                                    <td className="right">ADT</td>

                                    <td className="right">
                                        Before and After
                                    </td>

                                    <td className="right">-</td>
                                    <td className="right">
                                        Non-Changeable
                                    </td>
                                </>
                            ) : (
                                ""
                            )}
                        </tr>

                        <tr>
                            {currentTicket.chdNum >= 1 ? (
                                <>
                                    <td className="right">CHD</td>
                                    <td className="right">
                                        Before and After
                                    </td>
                                    <td className="right">-</td>
                                    <td className="right">
                                        Non-Changeable
                                    </td>
                                </>
                            ) : (
                                ""
                            )}
                        </tr>
                        <tr>
                            {currentTicket.infNum >= 1 ? (
                                <>
                                    <td className="right">INF</td>

                                    <td className="right">
                                        Before and After
                                    </td>

                                    <td className="right">-</td>
                                    <td className="right">
                                        Non-Changeable
                                    </td>
                                </>
                            ) : (
                                ""
                            )}
                        </tr>
                    </table>
                    <Row className="mx-0 pb-2">
                        <div className="col-12 flt-highlight-label px-0 py-1">
                            <span className="badge flt-imp-badge-label mx-2">
                                Important Note
                            </span>
                            <ul className="mt-2">
                                <li className="flt-imp-msg">
                                    Additional admin fee applicable.
                                </li>
                                <li className="flt-imp-msg">
                                    Please contact the operations team for
                                    further assistance and clarity on this
                                    if required.
                                </li>
                                <li className="flt-imp-msg">
                                    ROE (Rate of Exchange) is always based
                                    on the current rates.{" "}
                                </li>
                            </ul>
                        </div>
                    </Row>
                </div>
            ) : (
                <div>
                    {currentTicket?.adtNum >= 1 ||
                        currentTicket?.chdNum >= 1 ||
                        currentTicket?.infNum >= 1 ? (
                        <div>
                            {currentTicket?.fareGroups[selectedItinerary]
                                ?.miniRules &&
                                currentTicket?.adtNum >= 1 &&
                                currentTicket?.fareGroups[selectedItinerary]
                                    ?.miniRules.length &&
                                currentTicket?.fareGroups[
                                    selectedItinerary
                                ].miniRules.filter(filterAdultChangeDate)
                                    .length > 0 ? (
                                <>
                                    <div className="">
                                        <table className="fareDetailsTable mb-3">
                                            <tr>
                                                <th className="top_headers">
                                                    Pax
                                                </th>
                                                <th className="top_headers">
                                                    Applicable For
                                                </th>
                                                <th className="top_headers">
                                                    Amount
                                                </th>
                                                <th className="top_headers">
                                                    Remarks
                                                </th>
                                            </tr>
                                            {currentTicket?.fareGroups[
                                                selectedItinerary
                                            ].miniRules &&
                                                currentTicket?.fareGroups[
                                                    selectedItinerary
                                                ].miniRules.length &&
                                                currentTicket?.fareGroups[
                                                    selectedItinerary
                                                ].miniRules.filter(
                                                    filterAdultChangeDate
                                                ).length > 0 &&
                                                currentTicket?.fareGroups[
                                                    selectedItinerary
                                                ].miniRules
                                                    .filter(filterAdultChangeDate)
                                                    .map(
                                                        (listItem, index, element) =>
                                                            listItem.apply ? (
                                                                <tr>
                                                                    <>
                                                                        {index === 0 && (
                                                                            <th
                                                                                className="left_headers"
                                                                                width="10%"
                                                                                rowSpan={
                                                                                    currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ].miniRules &&
                                                                                    currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ].miniRules
                                                                                        .length &&
                                                                                    currentTicket?.fareGroups[
                                                                                        selectedItinerary
                                                                                    ].miniRules.filter(
                                                                                        filterAdultChangeDate
                                                                                    ).length
                                                                                }
                                                                            >
                                                                                ADT
                                                                            </th>
                                                                        )}

                                                                        <td
                                                                            className="left_headers"
                                                                            width="20%"
                                                                        >
                                                                            {listItem.apply}
                                                                        </td>
                                                                        {currentTicket?.adtNum >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="10%"
                                                                            >
                                                                                {listItem?.remarks === "Non-Changeable" ?
                                                                                    "-" :
                                                                                    listItem?.exgAmt !==
                                                                                        undefined &&
                                                                                        listItem?.exgAmt !==
                                                                                        0
                                                                                        ? currencyCode +
                                                                                        "  " +
                                                                                        listItem?.exgAmt
                                                                                        : listItem?.exgAmt === 0 && listItem?.remarks
                                                                                            ? listItem?.remarks === "As per Airline Applicable" ? "-" : "0"
                                                                                            : "-"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {currentTicket?.adtNum >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="30%"
                                                                            >
                                                                                {listItem?.remarks !==
                                                                                    undefined &&
                                                                                    listItem?.remarks !==
                                                                                    ""
                                                                                    ? listItem?.remarks
                                                                                    : listItem?.remarks !==
                                                                                        undefined &&
                                                                                        listItem?.remarks ===
                                                                                        ""
                                                                                        ? "NIL"
                                                                                        : "NIL"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                </tr>
                                                            ) : (
                                                                ""
                                                            )
                                                    )}
                                        </table>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            {currentTicket?.chdNum >= 1 &&
                                currentTicket?.fareGroups[selectedItinerary]
                                    ?.miniRules &&
                                currentTicket?.fareGroups[selectedItinerary]
                                    ?.miniRules.length &&
                                currentTicket?.fareGroups[
                                    selectedItinerary
                                ].miniRules.filter(filterChildChangeDate)
                                    .length > 0 ? (
                                <>
                                    <div className="">
                                        <table className="fareDetailsTable mb-3">
                                            <tr>
                                                <th className="top_headers">
                                                    Pax
                                                </th>
                                                <th className="top_headers">
                                                    Applicable For
                                                </th>
                                                <th className="top_headers">
                                                    Amount
                                                </th>
                                                <th className="top_headers">
                                                    Remarks
                                                </th>
                                            </tr>
                                            {currentTicket?.fareGroups[
                                                selectedItinerary
                                            ].miniRules &&
                                                currentTicket?.fareGroups[
                                                    selectedItinerary
                                                ].miniRules.length &&
                                                currentTicket?.fareGroups[
                                                    selectedItinerary
                                                ].miniRules.filter(
                                                    filterChildChangeDate
                                                ).length > 0 &&
                                                currentTicket?.fareGroups[
                                                    selectedItinerary
                                                ].miniRules
                                                    .filter(filterChildChangeDate)
                                                    .map(
                                                        (listItem, index, element) =>
                                                            listItem.apply ? (
                                                                <tr>
                                                                    <>
                                                                        {index === 0 && (
                                                                            <th
                                                                                className="left_headers"
                                                                                width="10%"
                                                                                rowSpan={
                                                                                    currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ].miniRules &&
                                                                                    currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ].miniRules
                                                                                        .length &&
                                                                                    currentTicket?.fareGroups[
                                                                                        selectedItinerary
                                                                                    ].miniRules.filter(
                                                                                        filterChildChangeDate
                                                                                    ).length
                                                                                }
                                                                            >
                                                                                CHD
                                                                            </th>
                                                                        )}

                                                                        <td
                                                                            className="left_headers"
                                                                            width="20%"
                                                                        >
                                                                            {listItem.apply}
                                                                        </td>
                                                                        {currentTicket?.chdNum >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="10%"
                                                                            >
                                                                                {listItem?.remarks === "Non-Changeable" ?
                                                                                    "-" :
                                                                                    listItem?.exgAmt !==
                                                                                        undefined &&
                                                                                        listItem?.exgAmt !==
                                                                                        0
                                                                                        ? currencyCode +
                                                                                        "  " +
                                                                                        listItem?.exgAmt
                                                                                        : listItem?.exgAmt === 0 && listItem?.remarks
                                                                                            ? listItem?.remarks === "As per Airline Applicable" ? "-" : "0"
                                                                                            : "-"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {currentTicket?.chdNum >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="30%"
                                                                            >
                                                                                {listItem?.remarks !==
                                                                                    undefined &&
                                                                                    listItem?.remarks !==
                                                                                    ""
                                                                                    ? listItem?.remarks
                                                                                    : listItem?.remarks !==
                                                                                        undefined &&
                                                                                        listItem?.remarks ===
                                                                                        ""
                                                                                        ? "NIL"
                                                                                        : "NIL"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                </tr>
                                                            ) : (
                                                                ""
                                                            )
                                                    )}
                                        </table>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            {currentTicket?.fareGroups[selectedItinerary]
                                ?.miniRules &&
                                currentTicket?.fareGroups[selectedItinerary]
                                    ?.miniRules.length &&
                                currentTicket?.infNum >= 1 &&
                                currentTicket?.fareGroups[
                                    selectedItinerary
                                ].miniRules.filter(filterInfantChangeDate)
                                    .length > 0 ? (
                                <>
                                    <div className="">
                                        <table className="fareDetailsTable mb-3">
                                            <tr>
                                                <th className="top_headers">
                                                    Pax
                                                </th>
                                                <th className="top_headers">
                                                    Applicable For
                                                </th>
                                                <th className="top_headers">
                                                    Amount
                                                </th>
                                                <th className="top_headers">
                                                    Remarks
                                                </th>
                                            </tr>
                                            {currentTicket?.fareGroups[
                                                selectedItinerary
                                            ].miniRules &&
                                                currentTicket?.fareGroups[
                                                    selectedItinerary
                                                ].miniRules.length &&
                                                currentTicket?.fareGroups[
                                                    selectedItinerary
                                                ].miniRules.filter(
                                                    filterInfantChangeDate
                                                ).length > 0 &&
                                                currentTicket?.fareGroups[
                                                    selectedItinerary
                                                ].miniRules
                                                    .filter(filterInfantChangeDate)
                                                    .map(
                                                        (listItem, index, element) =>
                                                            listItem.apply ? (
                                                                <tr>
                                                                    <>
                                                                        {index === 0 && (
                                                                            <th
                                                                                className="left_headers"
                                                                                width="10%"
                                                                                rowSpan={
                                                                                    currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ].miniRules &&
                                                                                    currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ].miniRules
                                                                                        .length &&
                                                                                    currentTicket?.fareGroups[
                                                                                        selectedItinerary
                                                                                    ].miniRules.filter(
                                                                                        filterInfantChangeDate
                                                                                    ).length
                                                                                }
                                                                            >
                                                                                INF
                                                                            </th>
                                                                        )}

                                                                        <td
                                                                            className="left_headers"
                                                                            width="20%"
                                                                        >
                                                                            {listItem.apply}
                                                                        </td>
                                                                        {currentTicket?.infNum >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="10%"
                                                                            >
                                                                                {listItem?.remarks === "Non-Changeable" ?
                                                                                    "-" :
                                                                                    listItem?.exgAmt !==
                                                                                        undefined &&
                                                                                        listItem?.exgAmt !==
                                                                                        0
                                                                                        ? currencyCode +
                                                                                        "  " +
                                                                                        listItem?.exgAmt
                                                                                        : listItem?.exgAmt === 0 && listItem?.remarks
                                                                                            ? listItem?.remarks === "As per Airline Applicable" ? "-" : "0"
                                                                                            : "-"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {currentTicket?.infNum >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="30%"
                                                                            >
                                                                                {listItem?.remarks !==
                                                                                    undefined
                                                                                    ? listItem?.remarks
                                                                                    : listItem?.remarks !==
                                                                                        undefined
                                                                                        ? "NIL"
                                                                                        : "NIL"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                </tr>
                                                            ) : (
                                                                ""
                                                            )
                                                    )}
                                        </table>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            {currentTicket?.fareGroups[selectedItinerary]
                                ?.miniRules &&
                                currentTicket?.fareGroups[selectedItinerary]
                                    ?.miniRules.length &&
                                (currentTicket?.fareGroups[
                                    selectedItinerary
                                ].miniRules.filter(filterAdultChangeDate)
                                    .length > 0 ||
                                    currentTicket?.fareGroups[
                                        selectedItinerary
                                    ].miniRules.filter(filterChildChangeDate)
                                        .length > 0 ||
                                    currentTicket?.fareGroups[
                                        selectedItinerary
                                    ].miniRules.filter(filterInfantChangeDate)
                                        .length > 0) ? (
                                <Row className="mx-0 pb-2">
                                    <div className="col-12 flt-highlight-label px-0 py-1">
                                        <span className="badge flt-imp-badge-label mx-2">
                                            Important Note
                                        </span>
                                        <ul className="mt-2">
                                            <li className="flt-imp-msg">
                                                Additional admin fee applicable.
                                            </li>
                                            <li className="flt-imp-msg">
                                                Please contact the operations team
                                                for further assistance and clarity
                                                on this if required.
                                            </li>
                                            <li className="flt-imp-msg">
                                                ROE (Rate of Exchange) is always
                                                based on the current rates.{" "}
                                            </li>
                                        </ul>
                                    </div>
                                </Row>
                            ) : (
                                ""
                            )}
                        </div>
                    ) : (
                        <div className="">
                            <table className="fareDetailsTable mb-3">
                                <tr>
                                    <th className="top_headers"></th>
                                    {currentTicket?.adtNum >= 1 ? (
                                        <td className="right">ADT</td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket?.chdNum >= 1 ? (
                                        <td className="right">CHD</td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket?.infNum >= 1 ? (
                                        <td className="right">INF</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <th
                                        className="left_headers"
                                        width="175"
                                    >
                                        Before Trip
                                    </th>
                                    {currentTicket?.adtNum >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket?.chdNum >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket?.infNum >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <th
                                        className="left_headers"
                                        width="175"
                                    >
                                        After Trip
                                    </th>
                                    {currentTicket?.adtNum >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket?.chdNum >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket?.infNum >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                            </table>
                            <Row className="mx-0 pb-2">
                                <div className="col-12 flt-highlight-label px-0 py-1">
                                    <span className="badge flt-imp-badge-label mx-2">
                                        Important Note
                                    </span>
                                    <ul className="mt-2">
                                        <li className="flt-imp-msg">
                                            Additional admin fee applicable.
                                        </li>
                                        <li className="flt-imp-msg">
                                            Please contact the operations team
                                            for further assistance and clarity
                                            on this if required.
                                        </li>
                                        <li className="flt-imp-msg">
                                            ROE (Rate of Exchange) is always
                                            based on the current rates.{" "}
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default DateChange