import React, { useEffect, useState } from "react";
import "./ImportPNR.css";
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@material-ui/core";
import RiseLoading from "../Loader/RiseLoader";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import findAirportbyCode from "../../functions/findAirportByCode";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import cabinClass from "../../data/CabinClass";
import findAirlinebyCode from "../../functions/findAirlineByCode";
import getAirportByCode from "../../functions/getAirportByCode";
import axios from "axios";
import getTax from "../../functions/getTax";
import getPaxTotal from "../../functions/getPaxTotal";
import getTotalFareBooking from "../../functions/getTotalFareBooking";
import { encryptData } from "../../providers/dataEncryptDecryptor";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom/";
import getClientBalance from "../../functions/getClientBalance";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { checkIfUserStaff } from "../../services/AuthService";
import SearchImportBookingCard from "../ImportBooking/SearchImportBookingCard/SearchImportBookingCard";
import Skeleton from "@material-ui/lab/Skeleton";
import FlightFareDetailsClientTableComponent from "../ImportBooking/FlightImportBooking/FlightFareDetailsClientTableComponent";
import FlightFareDetailsSupplierTableComponent from "../ImportBooking/FlightImportBooking/FlightFareDetailsSupplierTableComponent";
import { Row } from "react-bootstrap";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import loadFop from "../../functions/loadFop";
import endEmailMandatoryClients from "../../data/EndEmailMandatoryClients";
import PricingOptions from "./PricingOptions";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImportPNR = () => {
  const [pnr, setPnr] = useState("");
  const [bookingDetails, setbookingDetails] = useState(null);
  const [searchPNR, setSearchPNR] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [balanceAlert, setBalanceAlert] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [segGroups, setSegGroups] = useState([]);
  const [disableProceed, setDisableProceed] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [supplierName, setsupplierName] = useState("");
  const [clientId, setClientId] = useState("");
  const [pcc, setPCC] = useState("");
  const [fareDetails, setFareDetails] = useState(false);
  const [showRetryBtn, setShowRetryBtn] = useState(false);
  const [ticketingResponse, setTicketingResponse] = useState(false);
  const [clientBalanceSH, setClientBalanceSH] = useState(false);
  const [clientProfiles, setclientProfiles] = useState(null);
  const [vendorArray, setVendorArray] = useState([]);
  const [vendorOrgarray, setVendorOrgarray] = useState([]);
  const [pccList, setPccList] = useState([]);
  const [pccOrgarray, setPccOrgarray] = useState([]);
  const [clientAvailableBalance, setClientAvailableBalance] = useState(0);
  const [clientCurrency, setClientCurrency] = useState(null);
  const [mode, setMode] = useState("importPnr");
  const [clientROE, setClientROE] = useState();
  const [markupROE, setMarkupROE] = useState();
  const [adultVendorIata, setadultVendorIata] = useState();
  const [childVendorIata, setchildVendorIata] = useState();
  const [infantVendorIata, setinfantVendorIata] = useState();
  const [adultVendorPlb, setadultVendorPlb] = useState();
  const [childVendorPlb, setchildVendorPlb] = useState();
  const [infantVendorPlb, setinfantVendorPlb] = useState();
  const [adultClientDropnet, setadultClientDropnet] = useState();
  const [childClientDropnet, setchildClientDropnet] = useState();
  const [infantClientDropnet, setinfantClientDropnet] = useState();
  const [adultVendorDropnet, setadultVendorDropnet] = useState();
  const [childVendorDropnet, setchildVendorDropnet] = useState();
  const [infantVendorDropnet, setinfantVendorDropnet] = useState();
  const [adultVendorIssuanceFee, setadultVendorIssuanceFee] = useState();
  const [childVendorIssuanceFee, setchildVendorIssuanceFee] = useState();
  const [infantVendorIssuanceFee, setinfantVendorIssuanceFee] = useState();
  const [vendorSTApplicable, setVendorSTApplicable] = useState();
  const [adultVendorTax, setadultVendorTax] = useState();
  const [childVendorTax, setchildVendorTax] = useState();
  const [infantVendorTax, setinfantVendorTax] = useState();
  const [adultCommTax, setadultCommTax] = useState();
  const [childCommTax, setchildCommTax] = useState();
  const [infantCommTax, setinfantCommTax] = useState();
  const [adultCount, setadultCount] = useState(0);
  const [childCount, setchildCount] = useState(0);
  const [infantCount, setinfantCount] = useState(0);
  const [adultBase, setadultBase] = useState(0);
  const [childBase, setchildBase] = useState(0);
  const [infantBase, setinfantBase] = useState(0);
  const [adultTax, setadultTax] = useState(0);
  const [childTax, setchildTax] = useState(0);
  const [infantTax, setinfantTax] = useState(0);
  const [adultBaggage, setadultBaggage] = useState(0);
  const [childBaggage, setchildBaggage] = useState(0);
  const [infantBaggage, setinfantBaggage] = useState(0);
  const [vendorServiceTaxType, setVendorServiceTaxType] = useState("");
  const [disableInfantMarkup, setDisableInfantMarkup] = useState("");
  const [adultBookingfee, setadultBookingfee] = useState(0);
  const [childBookingfee, setchildBookingfee] = useState(0);
  const [infantBookingfee, setinfantBookingfee] = useState(0);
  const [adultMarkup, setadultMarkup] = useState(0);
  const [childMarkup, setchildMarkup] = useState(0);
  const [infantMarkup, setinfantMarkup] = useState(0);
  const [adultAdditionalMarkup, setadultAdditionalMarkup] = useState(0);
  const [childAdditionalMarkup, setchildAdditionalMarkup] = useState(0);
  const [infantAdditionalMarkup, setinfantAdditionalMarkup] = useState(0);
  const [adultIata, setadultIata] = useState(0);
  const [childIata, setchildIata] = useState(0);
  const [infantIata, setinfantIata] = useState(0);
  const [adultPlb, setadultPlb] = useState(0);
  const [childPlb, setchildPlb] = useState(0);
  const [infantPlb, setinfantPlb] = useState(0);
  const [adultTotal, setadultTotal] = useState(0);
  const [childTotal, setchildTotal] = useState(0);
  const [infantTotal, setinfantTotal] = useState(0);
  const [adultVendorTotal, setadultVendorTotal] = useState(0);
  const [childVendorTotal, setchildVendorTotal] = useState(0);
  const [infantVendorTotal, setinfantVendorTotal] = useState(0);
  const [clientTotal, setClientTotal] = useState(0);
  const [vendorTotal, setVendorTotal] = useState(0);
  const [vendorDetail, setVendorDetail] = useState(null);
  const [clientTotalWithClientROE, setClientTotalWithClientROE] = useState(0);
  const [vendorTotalWithClientROE, setVendorTotalWithClientROE] = useState(0);
  const [iataType, setiataType] = useState("");
  const [vendorIataType, setVendorIataType] = useState("");
  const [plbType, setPlbType] = useState("");
  const [vendorPlbType, setvendorPlbType] = useState("");
  const [pgCharge, setPGCharge] = useState();
  const [mco, setMCO] = useState();
  const [mcoParted, setMCOParted] = useState();
  const [mcoRemark, setMCORemark] = useState("");
  const [fop, setFOP] = useState("");
  const [selectedFlightIndex, setSelectedFlightIndex] = useState(0);
  const [fareTemplate, setFareTemplate] = useState({});
  const [fopOptions, setFopOption] = useState([]);
  const [userProfiles, setuserProfiles] = useState(null);
  const [expiryDate, setexpiryDate] = useState(null);
  const [cardNumber, setcardNumber] = useState(null);
  const [caseID, setCaseID] = useState("");
  const [salesStaff, setsalesStaff] = useState("");
  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [panCard, setpanCard] = useState("");
  const [fema, setfema] = useState("");
  const femaList = ["Corporate", "Retailer"];
  const [importPNRRemarks, setimportPNRRemarks] = useState("");
  const [supplierPayable, setSupplierPayable] = useState("");
  const [clientPayable, setClientPayable] = useState("");
  const [clientIata, setClientIata] = useState("");
  const [clientPlb, setClientPlb] = useState("");
  const [clientAdultTotal, setClientAdultTotal] = useState("");
  const [clientChildTotal, setClientChildTotal] = useState("");
  const [clientInfantTotal, setClientInfantTotal] = useState("");
  const sourceList = ["Email", "Call", "WhatsApp"];
  const [source, setSource] = useState("");
  const [showPriceOptions, setShowPriceOptions] = useState(false);

  const cabinOptions = cabinClass;
  const history = useHistory();
  const location = useLocation();


  useEffect(() => {
    setSearchPNR(true);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== null && user !== undefined) {
      setClientId(user["clientref_id"]);
    }
    checkIfUserStaff() && getClients();
  }, []);

  useEffect(() => {
    let vendor;
    if (pcc) {
      pccOrgarray?.forEach((element) => {
        if (element.pccCode === pcc) {
          vendorOrgarray.forEach((item) => {
            if (item.id === element.vendorId) {
              vendor = item;
              setVendorDetail(vendor);
            }
          });
        }
      });
    }
  }, [pcc, pccOrgarray, vendorOrgarray]);

  async function getClients() {
    setShowLoader(true);
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile/GetClientList?sorts=created&filters=isactive==true"
      )
      .then((response) => {
        let tempArray = response.data.sort((a, b) =>
          a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
        );
        setclientProfiles((clientProfiles) => tempArray.sort());
        setShowLoader(false);
      });
  }

  async function loadPCCDetails(supplier) {
    let vendorarray = [];
    let selectedSupplier =
      supplier === "Travel Fusion" ? "Travelfusion" : supplier;
    if (selectedSupplier !== "") {
      await axios
        .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
        .then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            vendorarray.push({
              id: response.data[i].bookingSwitches.id,
              name: String(
                response.data[i].bookingSwitches.vendorName
              ).toLowerCase(),
            });
          }

          let temp = [];
          temp = response.data.map((item, i) => {
            return item.bookingSwitches;
          });
          setVendorOrgarray((vendorOrgarray) => temp);
          axios
            .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
            .then((response) => {
              let tmpArray = [];
              let pccarray = [];
              for (var i = 0; i < response.data.length; i++) {
                let t = i;
                pccarray.push(response.data[t].pccConfigs);
                if (
                  response.data[t].pccConfigs.supplierName ===
                  selectedSupplier &&
                  response.data[t].pccConfigs.isActive === true
                ) {
                  if (
                    vendorarray.some(
                      (item) => item.id === response.data[t].pccConfigs.vendorId
                    )
                  ) {
                    let temp = vendorArray.find(
                      (st) => st.id === response.data[t].pccConfigs.vendorId
                    );
                    let name;
                    if (temp && temp.name === "travel fusion") {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (Travel Fusion)";
                    } else if (
                      response.data[t].pccConfigs.supplierName ===
                      "Travelfusion"
                    ) {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (Travel Fusion)";
                    } else if (temp && temp.name) {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (" +
                        temp.name +
                        ")";
                    } else {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (" +
                        response.data[t].pccConfigs.supplierName +
                        ")";
                    }
                    tmpArray.push({
                      value: response.data[t].pccConfigs.pccCode,
                      label: name,
                    });
                  } else {
                    tmpArray.push({
                      value: response.data[t].pccConfigs.pccCode,
                      label: response.data[t].pccConfigs.pccCode,
                    });
                  }
                }
              }

              if (tmpArray.length === 0) {
                setAlert({
                  open: true,
                  message: "There are no Pseudo Codes for this Supplier",
                  severity: "warning",
                });
              }
              setPccOrgarray((pccOrgarray) => pccarray);
              setVendorArray((vendorArray) => vendorarray);
              setPccList((pccList) => tmpArray);
            });
        });
    }
  }



  async function loadPNRDetails() {
    setDisableProceed(false);
    setbookingDetails(null);
    setErrorMsg("");
    setSegGroups([]);
    setIsRefresh(false)
    setFareDetails(false);
    setSearchPNR(true);
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };


    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/ClientRetrievePNR",

        JSON.stringify({
          PNR: pnr,
          Supplier: !checkIfUserStaff() ? location.state.supplier : supplierName,
          PCC: !checkIfUserStaff() ? location.state.pcc : pcc,
          ClientId: parseInt(clientId),
          TicketingInProcess: false

          // PNR: "33E8UA",
          // Supplier: "Amadeus",
          // PCC: "IAH1S212J",
          // ClientId: 22215,
          // TicketingInProcess: false
        }),
        { headers }
      )
      .then((res) => {
        setShowLoader(false);
        if (res.status === 204) {
          setAlert({ open: true, message: "oops!! NO details for this PNR", severity: "warning" });
        } else {
          setbookingDetails((bookingDetails) => res.data);
          setBookingFareDetails(res?.data === "" ? [] : res?.data);
          setSegGroups((segGroups) => res?.data?.flights[0]?.segGroups);
          // CalculateTotalFare();
          checkIfUserStaff() && getStaffUsers();
          checkIfUserStaff() && loadCreditCards();

        }
        setSearchPNR((searchPNR) => false);
        setShowLoader((showLoader) => false);
      })
      .catch((err) => {
        let errorMessage = err.response?.data;
        console.log(err);
        setSearchPNR((searchPNR) => false);
        setShowLoader((showLoader) => false);
        setErrorMsg(errorMessage)
      });
  }

  const proceedToTicket = async () => {
    setDisableProceed(true);
    const clientBalance = await getClientBalance(parseInt(clientId));
    const total = getTotalFareBooking(
      bookingDetails?.flights[0].flightFares,
      bookingDetails?.flights[0].adultCount,
      bookingDetails?.flights[0].childCount,
      bookingDetails?.flights[0].infantCount)
    if (clientBalance.balance >= total) {
      setBalanceAlert(false);
      setShowLoader((showLoader) => true);
      const headers = {
        "Content-Type": "application/json",
      };

      const requestData = {
        PNR: pnr,
        Supplier: checkIfUserStaff() ? supplierName : supplierName === "Travel Fusion" ? "Travelfusion" : location.state.supplier,
        PCC: checkIfUserStaff() ? pcc : location.state.pcc,
        ClientId: clientId,
        TicketingInProcess: true,
      };

      if (checkIfUserStaff()) {
        Object.assign(requestData, {
          CaseId: caseID,
          SalesStaff: salesStaff,
          AdditionalAdtMarkup: adultAdditionalMarkup,
          AdditionalChdMarkup: childAdditionalMarkup,
          AdditionalInfMarkup: infantAdditionalMarkup,
          ClientName: clientName,
          ClientEmail: clientEmail,
          PANCard: panCard,
          IsCorporate: fema === "Corporate",
          Info: importPNRRemarks?.trim() + (source ? " %%%" + source + "%%%" : ""),
          PGCharge: parseFloat(pgCharge),
          MCO: parseFloat(mco),
          MCOParted: parseFloat(mcoParted),
          MCORemark: mcoRemark,
          RevisedAdtMarkup: adultMarkup,
          RevisedChdMarkup: childMarkup,
          RevisedInfMarkup: infantMarkup
        });
      }

      await axios
        .post(
          process.env.REACT_APP_CORE_URL +
          "Flights/Booking/GetBooking/v1/ClientRetrievePNR",

          JSON.stringify(requestData),
          { headers }
        )
        .then((res) => {
          if (res.data.errors && res.data.errors[0] && res.data.errors[0].errorDetail) {
            setAlert({ open: true, message: res.data.errors[0].errorDetail, severity: "warning" });
            setTicketingResponse(res);
            setShowRetryBtn(true);
            setDisableProceed(false);
          }
          else {
            if (res.data.orderId) {
              setDisableProceed(true);
              localStorage.setItem("orderId", encryptData(res.data.orderId));
              history.push("/flight/view-booked-tickets")
            }
          }

          setShowLoader((showLoader) => false);
        })
        .catch((err) => {
          setDisableProceed(false);
          console.log(err);
          setShowLoader((showLoader) => false);
        });
    }
    else {
      setBalanceAlert(true);
    }

  };


  const proceedToRetryTicket = async () => {
    setDisableProceed(true);
    const clientBalance = await getClientBalance(parseInt(clientId));
    const total = getTotalFareBooking(
      bookingDetails?.flights[0].flightFares,
      bookingDetails?.flights[0].adultCount,
      bookingDetails?.flights[0].childCount,
      bookingDetails?.flights[0].infantCount)
    if (clientBalance.balance >= total) {
      setBalanceAlert(false);
      setShowLoader((showLoader) => true);
      const headers = {
        "Content-Type": "application/json",
      };
      await axios
        .post(
          process.env.REACT_APP_CORE_URL +
          "Flights/Booking/GetBooking/v1/ClientRetrievePNRReTicket",

          JSON.stringify({
            traceId: ticketingResponse.data.traceId,
            orderId: ticketingResponse.data.orderId

          }),
          { headers }
        )
        .then((res) => {
          if (res.data.errors && res.data.errors[0] && res.data.errors[0].errorDetail) {
            setAlert({ open: true, message: res.data.errors[0].errorDetail, severity: "warning" });
            setDisableProceed(false);
          }
          else {
            if (res.data.orderId) {
              setDisableProceed(true);
              localStorage.setItem("orderId", encryptData(res.data.orderId));
              history.push("/flight/view-booked-tickets")
            }
          }

          setShowLoader((showLoader) => false);
        })
        .catch((err) => {
          setDisableProceed(false);
          console.log(err);
          setShowLoader((showLoader) => false);
        });
    }
    else {
      setBalanceAlert(true);
    }

  };

  const refreshPNR = () => {
    setPnr("");
    setbookingDetails(null);
    setIsRefresh(true);
    setSegGroups([]);
    setShowRetryBtn(false);
    // loadPNRDetails();

  }


  function CalculateTotalFare(totalPaxFare, getclientROE) {

    let getClientROEVal = 0;

    if (getclientROE !== undefined) {
      getClientROEVal = getclientROE;
    } else {
      getClientROEVal = clientROE;
    }

    let clientTotalFare =
      adultTotal -
      (adultIata + adultPlb) * adultCount +
      (childTotal - (childIata + childPlb) * childCount) +
      (infantTotal - (infantIata - infantPlb) * infantCount);
    let vendorTotalFare =
      adultVendorTotal -
      (adultVendorIata + adultVendorPlb) * adultCount +
      (childVendorTotal - (childVendorIata + childVendorPlb) * childCount) +
      (infantVendorTotal - (infantIata + infantPlb) * infantCount);

    // setClientTotal((clientTotal) => clientTotalFare.toFixed(2));
    // setVendorTotal((vendorTotal) => vendorTotalFare.toFixed(2));

    // setClientTotalWithClientROE((clientTotalWithClientROE) =>
    //   (clientTotalFare * getClientROEVal).toFixed(2)
    // );

    // setVendorTotalWithClientROE((vendorTotalFare) =>
    //   (vendorTotalFare * getClientROEVal).toFixed(2)
    // );
  }


  function setBookingFareDetails(bkngDetails) {
    const temp = bkngDetails?.flights[0]?.commissionDetails;
    const supplierTax = bkngDetails?.flights[0]?.supplierTaxCalculation;
    setadultBase((adultBase) => 0);
    setchildBase((childBase) => 0);
    setinfantBase((infantBase) => 0);

    setadultTax((adultTax) => 0);
    setchildTax((childTax) => 0);
    setinfantTax((infantTax) => 0);

    setadultBaggage((adultBaggage) => 0);
    setchildBaggage((childBaggage) => 0);
    setinfantBaggage((infantBaggage) => 0);

    setVendorServiceTaxType((vendorServiceTaxType) => temp?.vendorServiceTaxType);
    setDisableInfantMarkup((disableInfantMarkup) => temp?.disableInfantMarkup);

    setadultBookingfee((adultBookingfee) => temp?.bookingFee);
    setchildBookingfee((childBookingfee) => 0);
    setinfantBookingfee((infantBookingfee) => 0);

    setadultMarkup((adultMarkup) => temp?.adultMarkup);
    setchildMarkup((childMarkup) => temp?.childMarkup);
    setinfantMarkup((infantMarkup) => temp?.infantMarkup);

    setadultIata((adultIata) => temp?.iata);
    setchildIata((childIata) => temp?.iata);
    setinfantIata((infantIata) => 0);

    setadultPlb((adultPlb) => temp?.plb);
    setchildPlb((childPlb) => temp?.plb);
    setinfantPlb((infantPlb) => 0);

    setadultClientDropnet(temp?.dropNet);
    setchildClientDropnet(temp?.dropNet);
    setinfantClientDropnet(0);


    setadultTotal((adultTotal) => 0);
    setchildTotal((childTotal) => 0);
    setinfantTotal((infantTotal) => 0);
    setiataType((iataType) =>
      bkngDetails?.flights[0]?.commissionDetails?.isIATATypePercentage
        ? "pct"
        : "value"
    );
    setPlbType((plbType) =>
      bkngDetails?.flights[0]?.commissionDetails?.isPLBTypePercentage
        ? "pct"
        : "value"
    );
    setVendorIataType((vendorIataType) =>
      bkngDetails?.flights[0]?.commissionDetails?.isVendorIATATypePercentage
        ? "pct"
        : "value"
    );
    setvendorPlbType((vendorPlbType) =>
      bkngDetails?.flights[0]?.commissionDetails?.isVendorPLBTypePercentage
        ? "pct"
        : "value"
    );

    setClientBalanceSH((clientBalanceSH) => false);
    setClientAvailableBalance((clientAvailableBalance) => 0);
    setClientTotalWithClientROE((clientTotalWithClientROE) => 0);
    setVendorTotalWithClientROE((vendorTotalWithClientROE) => 0);
    setClientTotal((clientTotal) => 0);
    setVendorTotal((vendorTotal) => 0);
    setadultVendorIata((adultVendorIata) => temp?.vendorIATA);
    setchildVendorIata((childVendorIata) => temp?.vendorIATA);
    setinfantVendorIata((infantVendorIata) => 0);

    setadultVendorPlb((adultVendorPlb) => temp?.vendorPLB);
    setchildVendorPlb((childVendorPlb) => temp?.vendorPLB);
    setinfantVendorPlb((infantVendorPlb) => 0);

    setadultVendorDropnet(temp?.vendorDropNet);
    setchildVendorDropnet(temp?.vendorDropNet);
    setinfantVendorDropnet(0);

    setadultVendorIssuanceFee((adultVendorIssuanceFee) => temp?.vendorFee);
    setchildVendorIssuanceFee((childVendorIssuanceFee) => temp?.vendorFee);
    setinfantVendorIssuanceFee((infantVendorIssuanceFee) => temp?.vendorFee);

    setVendorSTApplicable((vendorSTApplicable) => temp?.vendorServiceTaxApplicable);

    const adultVTax = supplierTax?.find(tax => tax.paxType === 'ADT')?.vendorServiceTaxVal;
    const childVTax = supplierTax?.find(tax => tax.paxType === 'CHD')?.vendorServiceTaxVal;
    const infantVTax = supplierTax?.find(tax => tax.paxType === 'INF')?.vendorServiceTaxVal;


    setadultVendorTax(adultVTax || 0);
    setchildVendorTax(childVTax || 0);
    setinfantVendorTax(infantVTax || 0);


    const adultComTax = supplierTax?.find(tax => tax.paxType === 'ADT')?.clientServiceTaxVal;
    const childComTax = supplierTax?.find(tax => tax.paxType === 'CHD')?.clientServiceTaxVal;
    const infantComTax = supplierTax?.find(tax => tax.paxType === 'INF')?.clientServiceTaxVal;


    setadultCommTax(adultComTax || 0);
    setchildCommTax(childComTax || 0);
    setinfantCommTax(infantComTax || 0);


    setadultVendorTotal((adultVendorTotal) => 0);
    setchildVendorTotal((childVendorTotal) => 0);
    setinfantVendorTotal((infantVendorTotal) => 0);

    setPGCharge((pgCharge) => 0);
    setMCO((mco) => 0);
    setMCOParted((mcoParted) => 0);
    setMCORemark((mcoRemark) => "");
    setFOP((fop) => "");
    setClientROE((clientROE) => 0);
    setMarkupROE((clientROE) => 0);
    if (
      bkngDetails !== undefined &&
      bkngDetails?.flights != undefined &&
      bkngDetails?.flights?.length > 0
    ) {
      setClientBalanceSH((clientBalanceSH) => true);
      setClientAvailableBalance((clientAvailableBalance) =>
        bkngDetails?.clientBalance ? bkngDetails?.clientBalance : 0
      );
      for (
        var flightIndex = 0;
        flightIndex < bkngDetails?.flights?.length;
        flightIndex++
      ) {
        let flight = bkngDetails?.flights[flightIndex];
        setadultCount((adultCount) => flight.adultCount);
        setchildCount((childCount) => flight.childCount);
        setinfantCount((infantCount) => flight.infantCount);
        setClientROE((clientROE) => flight.clientROE);
        setMarkupROE((markupROE) => flight.markupROE);

        let totalPaxFare = 0;
        let getclientROE = flight.clientROE ? flight.clientROE : 0;
        let adtBaggage = 0,
          chdBaggage = 0,
          infBaggage = 0;
        if (flight.baggages) {
          let temp = [];
          temp = flight.baggages;
          temp.forEach((item) => {
            item.paxType === "ADT"
              ? (adtBaggage += parseFloat(item.amount))
              : item.paxType === "CHD"
                ? (chdBaggage += parseFloat(item.amount))
                : item.paxType === "INF" &&
                (infBaggage += parseFloat(item.amount));
          });
          (flight.adultCount && adtBaggage) > 0
            ? (totalPaxFare += adtBaggage)
            : (totalPaxFare += 0);
          (flight.childCount && chdBaggage) > 0
            ? (totalPaxFare += chdBaggage)
            : (totalPaxFare += 0);
          (flight.infantCount && infBaggage) > 0
            ? (totalPaxFare += infBaggage)
            : (totalPaxFare += 0);
          let abag = 0,
            cbag = 0,
            ibag = 0;
          abag = parseFloat(adtBaggage) / flight.adultCount;
          cbag = parseFloat(chdBaggage) / flight.childCount;
          ibag = parseFloat(infBaggage) / flight.infantCount;

          (flight.adultCount && adtBaggage) > 0 &&
            setadultBaggage((adultBaggage) => abag.toFixed(2));
          (flight.childCount && chdBaggage) > 0 &&
            setchildBaggage((childBaggage) => cbag.toFixed(2));
          (flight.infantCount && infBaggage) > 0 &&
            setinfantBaggage((infantBaggage) => ibag.toFixed(2));
        }

        if (flight.flightFares !== undefined && flight.flightFares.length > 0) {
          for (
            var fareIndex = 0;
            fareIndex < flight.flightFares.length;
            fareIndex++
          ) {
            let obj = flight.flightFares[fareIndex];
            if (obj.paxType === "ADT") {
              setadultBase((adultBase) => parseFloat(obj.baseFare).toFixed(2));
              setadultTax((adultTax) => getTax(obj).toFixed(2));

              let adtTotal = adtBaggage + getPaxTotal(obj, flight.adultCount);
              setadultTotal((adultTotal) => adtTotal);
              // setadultVendorTotal((adultVendorTotal) => adtTotal);
              totalPaxFare += getPaxTotal(obj, flight.adultCount);
            } else if (obj.paxType === "CHD") {
              setchildBase((childBase) => parseFloat(obj.baseFare).toFixed(2));
              setchildTax((childTax) => getTax(obj).toFixed(2));
              let chdtotal = chdBaggage + getPaxTotal(obj, flight.childCount);
              setchildTotal((childTotal) => chdtotal);
              // setchildVendorTotal((childVendorTotal) => chdtotal);
              totalPaxFare += getPaxTotal(obj, flight.childCount);
            } else if (obj.paxType === "INF") {
              setinfantBase((infantBase) =>
                parseFloat(obj.baseFare).toFixed(2)
              );
              setinfantTax((infantTax) => getTax(obj).toFixed(2));
              let inftotal = infBaggage + getPaxTotal(obj, flight.infantCount);
              setinfantTotal((infantTotal) => inftotal);
              // setinfantVendorTotal((infantVendorTotal) => inftotal);
              totalPaxFare += getPaxTotal(obj, flight.infantCount);
            }
          }


          CalculateTotalFare(totalPaxFare, getclientROE);
        }
      }
    }
  }


  const updatePaxCount = () => {
    const temp = bookingDetails;
    if (temp && temp.flights) {
      let count1 = 0,
        count2 = 0,
        count3 = 0;
      temp?.flights[selectedFlightIndex]?.passengers.map((passenger) => {
        if (passenger.paxType == "ADT") {
          count1++;
        } else if (passenger.paxType == "CHD") {
          count2++;
        } else if (passenger.paxType == "INF") {
          count3++;
        }
      });
      temp.flights[selectedFlightIndex].adultCount = count1;
      temp.flights[selectedFlightIndex].childCount = count2;
      temp.flights[selectedFlightIndex].infantCount = count3;
      setadultCount(temp.flights[selectedFlightIndex]?.adultCount);
      setchildCount(temp.flights[selectedFlightIndex]?.childCount);
      setinfantCount(temp.flights[selectedFlightIndex]?.infantCount);
      setbookingDetails((bookingDetails) => temp);
      setBookingFareDetails(temp);
      setFareTemplate({
        id: 0,
        flightId: 0,
        paxType: "",
        baseFare: 0,
        taxes: [{ id: 0, amt: 0, code: "Other" }],
      });
    }
  };


  async function OnChangeCalculateTotalFare(event, paxtype, field) {

    setiataType(
      bookingDetails.flights[0]?.commissionDetails?.isIATATypePercentage
        ? "pct"
        : "value"
    );
    setPlbType(
      bookingDetails.flights[0]?.commissionDetails?.isPLBTypePercentage
        ? "pct"
        : "value"
    );
    setVendorIataType(
      bookingDetails.flights[0]?.commissionDetails?.isVendorIATATypePercentage
        ? "pct"
        : "value"
    );
    setvendorPlbType(
      bookingDetails.flights[0]?.commissionDetails?.isVendorPLBTypePercentage
        ? "pct"
        : "value"
    );

    if (paxtype === "ADT") {
      let iataValue = adultIata;
      let plbValue = adultPlb;
      let vendorplbValue = adultVendorPlb;
      let vendoriataValue = adultVendorIata;

      if (field === "base") {
        setadultBase(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        setadultTax(event.target.value !== "" ? Number(event.target.value) : 0);
      } else if (field === "baggage") {
        setadultBaggage(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        setadultBookingfee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        setadultMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "additionalmarkup") {
        setadultAdditionalMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        iataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          iataValue = (adultBase * iataValue) / 100;
        }

        setadultIata(iataValue);

      } else if (field === "plb") {
        plbValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          plbValue = ((adultBase - iataValue) * plbValue) / 100;
        }
        setadultPlb(plbValue);
      } else if (field === "vendorIata") {
        vendoriataValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendoriataValue = (adultBase * vendoriataValue) / 100;
        }
        setadultVendorIata(vendoriataValue);
      } else if (field === "vendorPlb") {
        vendorplbValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          vendorplbValue = ((adultBase - vendoriataValue) * plbValue) / 100;
        }
        setadultVendorPlb(vendorplbValue);
      } else if (field === "vendorIssuanceFee") {
        setadultVendorIssuanceFee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        setadultVendorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
    } else if (paxtype === "CHD") {
      let iataValue = childIata;
      let plbValue = childPlb;
      let vendorplbValue = childVendorPlb;
      let vendoriataValue = childVendorIata;

      if (field === "base") {
        setchildBase(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        setchildTax(event.target.value !== "" ? Number(event.target.value) : 0);
      } else if (field === "baggage") {
        setchildBaggage(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        setchildBookingfee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        setchildMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "additionalmarkup") {
        setchildAdditionalMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        iataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          iataValue = (childBase * iataValue) / 100;
        }
        setchildIata(iataValue);
      } else if (field === "plb") {
        plbValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          plbValue = ((childBase - iataValue) * plbValue) / 100;
        }
        setchildPlb(plbValue);
      } else if (field === "vendorIata") {
        vendoriataValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendoriataValue = (adultBase * vendoriataValue) / 100;
        }
        setchildVendorIata(vendoriataValue);
      } else if (field === "vendorPlb") {
        vendorplbValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendorplbValue =
            ((childBase - vendoriataValue) * vendorplbValue) / 100;
        }
        setchildVendorPlb(vendorplbValue);
      } else if (field === "vendorIssuanceFee") {
        setchildVendorIssuanceFee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        setchildVendorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
    } else if (paxtype === "INF") {
      if (field === "base") {
        setinfantBase(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        setinfantTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "baggage") {
        setinfantBaggage(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        setinfantBookingfee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        setinfantMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "additionalmarkup") {
        setinfantAdditionalMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        setinfantIata(0);
      } else if (field === "plb") {
        setinfantPlb(0);
      } else if (field === "vendorIata") {
        setinfantVendorIata(0);

      } else if (field === "vendorPlb") {
        setinfantVendorPlb(0);
      } else if (field === "vendorIssuanceFee") {
        setinfantVendorIssuanceFee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        setinfantVendorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
    }
  }

  useEffect(() => {
    setadultVendorTotal(
      ((adultBase ? parseFloat(adultBase) : 0) +
        (adultTax ? parseFloat(adultTax) : 0) +
        (adultBaggage ? parseFloat(adultBaggage) : 0) +
        (adultVendorIssuanceFee ? parseFloat(adultVendorIssuanceFee) : 0) +
        (adultVendorTax ? parseFloat(adultVendorTax) : 0)
        - (iataType === "pct" ? (((parseFloat(adultVendorIata) || 0) * (parseFloat(adultBase) || 0)) / 100) : (parseFloat(adultVendorIata) || 0))
        - (plbType === "pct" ? (((parseFloat(adultVendorPlb) || 0) * (parseFloat(adultBase) || 0)) / 100) : (parseFloat(adultVendorPlb) || 0))
        - adultVendorDropnet
      ) *
      adultCount
    );
  }, [
    adultBase,
    adultTax,
    adultBaggage,
    adultVendorIssuanceFee,
    adultVendorTax,
    adultVendorIata,
    adultVendorPlb,
    adultVendorDropnet,
    adultCount,
  ]);

  useEffect(() => {

    setchildVendorTotal(
      ((childBase ? parseFloat(childBase) : 0) +
        (childTax ? parseFloat(childTax) : 0) +
        (childBaggage ? parseFloat(childBaggage) : 0) +
        (childVendorIssuanceFee ? parseFloat(childVendorIssuanceFee) : 0) +
        (childVendorTax ? parseFloat(childVendorTax) : 0)
        - (iataType === "pct" ? (((parseFloat(adultVendorIata) || 0) * (parseFloat(childBase) || 0)) / 100) : (parseFloat(adultVendorIata) || 0))
        - (plbType === "pct" ? (((parseFloat(adultVendorPlb) || 0) * (parseFloat(childBase) || 0)) / 100) : (parseFloat(adultVendorPlb) || 0))
        - adultVendorDropnet
      ) *
      childCount
    );
  }, [
    childBase,
    childTax,
    childBaggage,
    childVendorIssuanceFee,
    childVendorTax,
    adultVendorIata,
    adultVendorPlb,
    adultVendorDropnet,
    childCount,
  ]);


  useEffect(() => {

    setinfantVendorTotal(
      ((infantBase ? parseFloat(infantBase) : 0) +
        (infantTax ? parseFloat(infantTax) : 0) +
        (infantBaggage ? parseFloat(infantBaggage) : 0) +
        (infantVendorIssuanceFee ? parseFloat(infantVendorIssuanceFee) : 0) +
        (infantVendorTax ? parseFloat(infantVendorTax) : 0)
      ) *
      infantCount
    );
  }, [
    infantBase,
    infantTax,
    infantBaggage,
    infantVendorIssuanceFee,
    infantVendorTax,
    infantCount,
  ]);




  useEffect(() => {
    setSupplierPayable((adultVendorTotal ? parseFloat(adultVendorTotal) : 0) +
      (childVendorTotal ? parseFloat(childVendorTotal) : 0) +
      (infantVendorTotal ? parseFloat(infantVendorTotal) : 0));

    const cat = (
      ((((parseFloat(adultBase) || 0) +
        (parseFloat(adultTax) || 0) +
        (parseFloat(adultBaggage) || 0) +
        (parseFloat(adultVendorIssuanceFee) || 0) +
        (parseFloat(adultCommTax) || 0) -
        (iataType === "pct" ? ((parseFloat(adultIata) || 0) * (parseFloat(adultBase) || 0) / 100) : (parseFloat(adultIata) || 0))
        - (plbType === "pct"
          ? (adultBase - (iataType === "pct" ? adultBase * (adultIata / 100) : adultIata)) * (adultPlb / 100)
          : (adultBase - (iataType === "pct" ? adultBase * (adultIata / 100) : adultIata)) * adultPlb)
        - adultClientDropnet) * clientROE) + ((parseFloat(adultMarkup) || 0) + (parseFloat(adultAdditionalMarkup) || 0)) * markupROE) * adultCount
    );

    setClientAdultTotal(cat);

    const cct = (
      ((((parseFloat(childBase) || 0) +
        (parseFloat(childTax) || 0) +
        (parseFloat(childBaggage) || 0) +
        (parseFloat(childVendorIssuanceFee) || 0) +
        (parseFloat(childCommTax) || 0) -
        (iataType === "pct" ? ((parseFloat(adultIata) || 0) * (parseFloat(childBase) || 0) / 100) : (parseFloat(adultIata) || 0))
        - (plbType === "pct"
          ? (childBase - (iataType === "pct" ? childBase * (adultIata / 100) : adultIata)) * (adultPlb / 100)
          : (childBase - (iataType === "pct" ? childBase * (adultIata / 100) : adultIata)) * adultPlb)
        - adultClientDropnet) * clientROE) + ((parseFloat(childMarkup) || 0) + (parseFloat(childAdditionalMarkup) || 0)) * markupROE) * childCount
    );

    setClientChildTotal(cct);

    const cit = (
      ((((parseFloat(infantBase) || 0) +
        (parseFloat(infantTax) || 0) +
        (parseFloat(infantBaggage) || 0) +
        (parseFloat(infantVendorIssuanceFee) || 0) +
        (parseFloat(infantCommTax) || 0)) * clientROE) +
        ((disableInfantMarkup ? 0 : (parseFloat(infantMarkup) || 0)) + (disableInfantMarkup ? 0 : (parseFloat(infantAdditionalMarkup) || 0))) * markupROE) * infantCount
    );

    setClientInfantTotal(cit);

  }, [
    adultVendorTotal,
    childVendorTotal,
    infantVendorTotal,
    adultMarkup,
    childMarkup,
    infantMarkup,
    adultAdditionalMarkup,
    childAdditionalMarkup,
    infantAdditionalMarkup
  ]);


  useEffect(() => {
    setClientPayable((clientAdultTotal ? parseFloat(clientAdultTotal) : 0) +
      (clientChildTotal ? parseFloat(clientChildTotal) : 0) +
      (clientInfantTotal ? parseFloat(clientInfantTotal) : 0))
  }, [clientAdultTotal, clientChildTotal, clientInfantTotal])



  async function getStaffUsers() {
    let filter = "ClinetId==" + 0;
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "ids/User?filters=" + filter)
      .then((response) => {
        var newArray = response.data.filter(function (el) {
          return (
            el.emailConfirmed == true &&
            el.firstName != undefined &&
            el.lastName != undefined &&
            el.title != undefined
          );
        });
        // console.log(response.data.result);
        // console.log(newArray);
        setuserProfiles((userProfiles) => newArray);
      });
  }

  const loadCreditCards = async () => {

    var fop = await loadFop();
    setFopOption(fop);
  };

  useEffect(() => {
    if (clientId) {
      let temp = clientProfiles?.find((v) => v?.id === clientId);
      if (temp) {
        setClientCurrency((clientCurrency) => temp.currencycode);
      }
    }
  }, [clientId, clientProfiles]);

  const getLastArrivalTime = (flights) => {
    if (!flights || flights.length === 0) return null;

    let lastArrivalTime = null;

    flights.forEach(flight => {
      flight.segGroups.forEach(segGroup => {
        segGroup.segments.forEach(segment => {
          lastArrivalTime = segment.arrivalOn;
        });
      });
    });

    return lastArrivalTime;
  };

  return (
    <div className="import-pnr-container">
      <Grid container>
        <Grid item xs={10} style={{ minWidth: "100%", padding: "0px 5%" }}>
          <div className="flight-itenary">
            <div className="flight-itenary-header">
              <div className="flight-itenary-title-container">
                Import PNR Ticketing
              </div>
            </div>
          </div>
          {!checkIfUserStaff() && <div className="importbooking-search-search-container">
            <div className="importbooking-search-table-container">
              <div className="search-title-add-button-container">
                <div className="search-heading"></div>
              </div>
              <div className="importbooking-search-card-container">
                <ValidatorForm
                  //   ref={searchPnrSubmit}
                  onError={(errors) => console.log(errors)}
                //   onSubmit={() => loadPNRDetails()}
                >
                  <div className="create-search-contents">
                    <div className="add-search-if-items-container">
                      <div className="add-search-if-item flex-1-input margin-right-16">
                        <TextValidator
                          variant="outlined"
                          label="PNR"
                          className="bg-light"
                          placeholder="Input PNR"
                          size="small"
                          value={pnr}
                          validators={[
                            "required",
                            "matchRegexp:^[a-zA-Z0-9]+$",
                          ]}
                          errorMessages={[
                            "required",
                            "No special characters allowed",
                          ]}
                          onChange={(event) => setPnr(event.target.value)}
                        />
                      </div>
                      <div className="add-search-if-item flex-1-input margin-right-16">
                        <Button
                          variant="contained"
                          className="srn-btn"
                          onClick={() => loadPNRDetails()}
                          disabled={!pnr}
                        >
                          Import
                        </Button>
                      </div>
                      <div className="add-search-if-item flex-1-input margin-right-16"></div>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
            {
              <div>
                {bookingDetails && searchPNR === true ? (
                  <dvi>
                    {showLoader ? <RiseLoading isImport={showLoader} /> : ""}
                  </dvi>
                ) : bookingDetails !== undefined &&
                  bookingDetails?.flights != undefined &&
                  bookingDetails?.flights?.length > 0 ? (
                  bookingDetails?.flights?.map((flight, flightIndex) => (
                    <div>
                      <ValidatorForm
                        // onSubmit={CreateImportBooking}
                        onSubmit={!showRetryBtn ? proceedToTicket : proceedToRetryTicket}
                        onError={(errors) => console.log(errors)}
                      >
                        <div className="import-booking-itenary-container">
                          <div>Flight Itinerary</div>
                          <div className="flight-itenary-details-item">
                            <div className="flight-itenary-details-item-text">
                              {/* {bookingRefNo} */}
                            </div>

                          </div>
                        </div>

                        {segGroups !== undefined && segGroups.length > 0 ? (
                          segGroups.map((segGroup, segGroupIndex) => (
                            <div className="flight-itenary">
                              <div className="flight-itenary-header">
                                <div className="flight-itenary-title-container">
                                  <div>
                                    {findAirportbyCode(segGroup.origin)} (
                                    {segGroup.origin})
                                  </div>
                                  <div className="flight-itenary-icon">
                                    <ArrowRightAltIcon></ArrowRightAltIcon>

                                  </div>
                                  <div>
                                    {findAirportbyCode(segGroup.destination)} (
                                    {segGroup.destination})
                                  </div>
                                </div>
                                <div className="flight-itenary-date">
                                  {moment(segGroup.departureOn).format(
                                    "Do MMMM YYYY"
                                  )}
                                </div>
                              </div>
                              {segGroup.segments !== undefined &&
                                segGroup.segments.length > 0 ? (
                                segGroup.segments.map(
                                  (segment, segmentIndex) => (
                                    <div>
                                      <div className="flight-itenary-details">
                                        <div className="flight-itenary-details-item">
                                          <div className="flight-itenary-details-item-text">
                                            {segment.cabinClass}
                                          </div>
                                          <div className="flight-itenary-details-item-subtext">
                                            Class Of Service
                                          </div>
                                        </div>
                                        <div className="flight-itenary-details-item">
                                          <div className="flight-itenary-details-item-text">
                                            {findAirlinebyCode(
                                              segment.mrkAirline
                                            )}
                                          </div>
                                          <div className="flight-itenary-details-item-subtext">
                                            {segment.mrkAirline}-
                                            {segment.flightNum}
                                          </div>
                                        </div>
                                        <div className="flight-itenary-details-item">
                                          <div className="flight-itenary-details-item-text">
                                            {moment(segment.departureOn).format(
                                              "HH:mm"
                                            )}
                                          </div>
                                          <div className="flight-itenary-details-item-subtext">
                                            Depature
                                          </div>
                                        </div>
                                        <div className="flight-itenary-details-item">
                                          <div className="flight-itenary-details-item-text">
                                            {segment.duration}m
                                          </div>
                                          <div className="flight-itenary-details-item-subtext">
                                            Duration
                                          </div>
                                        </div>
                                        <div className="flight-itenary-details-item">
                                          <div className="flight-itenary-details-item-text">
                                            {moment(segment.arrivalOn).format(
                                              "HH:mm"
                                            )}
                                          </div>
                                          <div className="flight-itenary-details-item-subtext">
                                            Arrival
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flight-confirmation-details">
                                        <div className="flight-confirmation-details-item">
                                          <div className="flight-confirmation-details-item-text">
                                            Origin
                                          </div>
                                          <div className="flight-confirmation-details-item-subtext">
                                            {getAirportByCode(segment.origin)} -{" "}
                                            {segment.origin}
                                          </div>
                                        </div>
                                        <div className="flight-confirmation-details-item">
                                          <div className="flight-confirmation-details-item-text">
                                            Destination
                                          </div>
                                          <div className="flight-confirmation-details-item-subtext">
                                            {getAirportByCode(
                                              segment.destination
                                            )}{" "}
                                            - {segment.destination}
                                          </div>
                                        </div>
                                        <div className="flight-confirmation-details-item">
                                          <div
                                            className="flight-confirmation-details-item-text">
                                            PNR
                                          </div>
                                          <div className="flight-confirmation-details-item-subtext">
                                            <div className="flight-itenary-details-item-text">
                                              {segment.pnr}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flight-confirmation-details-item">
                                          <div className="flight-confirmation-details-item-text">
                                            Segment Status
                                          </div>
                                          <div className="flight-confirmation-details-item-subtext full-opacity">
                                            <div className="itenary-confirmtaion-tag success">
                                              {segment.flightTicketStatus}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <div></div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div></div>
                        )}
                        <div className="import-booking-itenary-container booking-padding-top-40">
                          <div>Passenger Details</div>
                        </div>
                        <div className="importBooking passenger-itenary-details itenary-passenger-details-baggage">
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                              className="itenary-passenger-table"
                            >
                              <TableRow className="itenary-passenger-table-header">
                                <TableCell
                                  align="center"
                                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight itenary-passenger-table-header-item-initial "
                                >
                                  Passenger(s)
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                                >
                                  Gender
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                                >
                                  Date of Birth
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                                >
                                  Passport No
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                                >
                                  Seat
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                                >
                                  Paid Baggage
                                </TableCell>
                              </TableRow>
                              {flight.passengers !== undefined &&
                                flight.passengers.length > 0 ? (
                                flight.passengers
                                  .sort(function (a, b) {
                                    if (a.paxType < b.paxType) {
                                      return -1;
                                    }
                                    if (a.paxType > b.paxType) {
                                      return 1;
                                    }
                                    return 0;
                                  })
                                  .map((passenger, passengerIndex) => (
                                    <TableRow className="itenary-passenger-table-item">
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30"
                                      >
                                        {passenger.title} {passenger.firstName}{" "}
                                        {passenger.lastName}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30"
                                      >
                                        {passenger.genderType}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30"
                                      >
                                        {passenger.dob ? moment(passenger.dob).format(
                                          "Do MMMM YYYY"
                                        ) : "-"}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30"
                                      >
                                        {passenger.passportNumber ? passenger.passportNumber : "-"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30"
                                      >
                                        {passenger.seatPref}
                                      </TableCell>
                                      <TableCell align="center">
                                        {flight.baggages?.filter(
                                          (item) =>
                                            item?.paxName === `${passenger.firstName} ${passenger.lastName}`
                                        ).length > 0 ? (
                                          flight.baggages
                                            ?.filter(
                                              (item) =>
                                                item?.paxName === `${passenger.firstName} ${passenger.lastName}`
                                            )
                                            .map((bag, index, array) => (
                                              <React.Fragment key={index}>
                                                {bag.checkInBag}
                                                {flight.currency}
                                                {bag.amount}
                                                <br />
                                                {index < array.length - 1 ? "\n" : ""}
                                              </React.Fragment>
                                            ))
                                        ) : (
                                          "NA"
                                        )}
                                      </TableCell>

                                    </TableRow>
                                  ))
                              ) : (
                                <TableRow></TableRow>
                              )}
                            </Table>
                          </TableContainer>
                        </div>


                        <div className="import-booking-itenary-container booking-padding-top-40">
                          <div>Fare Details </div>
                        </div>
                        <div className="itenary-passenger-details-import">
                          <Table className="itenary-passenger-table ImportBookingFareDetails">
                            <TableHead>
                              <TableRow className="itenary-passenger-table-header">
                                <TableCell
                                  align="center"
                                  className="itenary-passenger-table-header-pl-12 import-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
                                >
                                  Pax
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
                                >
                                  Base Fare
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
                                >
                                  Tax
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
                                >
                                  GST
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
                                >
                                  Total
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className="ImportBookingFareDetails ">
                              {flight.flightFares !== undefined &&
                                flight.flightFares.length > 0 ? (
                                flight.flightFares
                                  .sort(function (a, b) {
                                    if (a.paxType < b.paxType) {
                                      return -1;
                                    }
                                    if (a.paxType > b.paxType) {
                                      return 1;
                                    }
                                    return 0;
                                  })
                                  .map((fare) => (
                                    <TableRow className="itenary-passenger-table-item price-row">
                                      <TableCell align="center" className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        {fare.paxType === "ADT"
                                          ? "Adult"
                                          : fare.paxType === "CHD"
                                            ? "Child"
                                            : fare.paxType === "INF"
                                              ? "Infant"
                                              : "Adult"}
                                      </TableCell>
                                      <TableCell align="center" className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        {fare.paxType === "ADT"
                                          ? flight.adultCount + " x " + fare.baseFare
                                          : fare.paxType === "CHD"
                                            ? flight.childCount + " x " + fare.baseFare
                                            : fare.paxType === "INF"
                                              ? flight.infantCount + " x " + fare.baseFare
                                              : "0"}
                                      </TableCell>
                                      <TableCell align="center" className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        {fare.paxType === "ADT"
                                          ? flight.adultCount + " x " + getTax(fare).toFixed(2)
                                          : fare.paxType === "CHD"
                                            ? flight.childCount + " x " + getTax(fare).toFixed(2)
                                            : fare.paxType === "INF"
                                              ? flight.infantCount + " x " + getTax(fare).toFixed(2)
                                              : "0"}
                                      </TableCell>
                                      <TableCell align="center" className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        {fare.paxType === "ADT"
                                          ? flight.adultCount + " x 0"
                                          : fare.paxType === "CHD"
                                            ? flight.childCount + " x 0"
                                            : fare.paxType === "INF"
                                              ? flight.infantCount + " x 0"
                                              : "0"}
                                      </TableCell>
                                      <TableCell align="center" className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        {fare.paxType === "ADT"
                                          ? getPaxTotal(fare, flight.adultCount).toFixed(2)
                                          : fare.paxType === "CHD"
                                            ? getPaxTotal(fare, flight.childCount).toFixed(2)
                                            : fare.paxType === "INF"
                                              ? getPaxTotal(fare, flight.infantCount).toFixed(2)
                                              : 0}
                                      </TableCell>
                                    </TableRow>
                                  ))
                              ) : (
                                <TableRow></TableRow>
                              )}
                              <TableRow className="itenary-passenger-table-item price-row">
                                <TableCell
                                  className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                  align="right"
                                ></TableCell>
                                <TableCell
                                  className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                  align="right"
                                ></TableCell>
                                <TableCell
                                  className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                  align="right"
                                ></TableCell>
                                <TableCell
                                  className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 total-price-label"
                                  align="right"
                                >
                                  Total
                                </TableCell>
                                <TableCell align="center" className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 total-price-txt">
                                  {flight.currency}{" "}
                                  {getTotalFareBooking(
                                    flight.flightFares,
                                    flight.adultCount,
                                    flight.childCount,
                                    flight.infantCount
                                  ).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>

                        <div className="create-booking-button-container">
                          <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            style={{
                              textTransform: "none",
                            }}
                            type="submit"
                            className="srn-btn me-3"
                            onClick={refreshPNR}
                          //   disabled={isImportBtnDisabled}
                          >
                            Back
                          </Button>
                          {!showRetryBtn ?
                            (<Button
                              variant="outlined"
                              color="primary"
                              size="large"
                              style={{
                                textTransform: "none",
                              }}
                              type="submit"
                              className="srn-btn"
                              // onClick={() => proceedToTicket()}
                              disabled={disableProceed}
                            >
                              Proceed to Ticket
                            </Button>) :
                            (<Button
                              variant="outlined"
                              color="primary"
                              size="large"
                              style={{
                                textTransform: "none",
                              }}
                              type="submit"
                              className="srn-btn"
                            // onClick={proceedToRetryTicket}
                            >
                              Retry
                            </Button>)
                          }
                          {balanceAlert && <div className="text-danger p-2" style={{ fontSize: "12px" }}>
                            Not enough fund to book Ticket, please top up the wallet
                          </div>}
                        </div>
                        {/* </>
                        )} */}
                      </ValidatorForm>
                    </div>
                  ))
                ) : bookingDetails === null && searchPNR === false && isRefresh === false ? (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "5%",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {errorMsg ? errorMsg + "!" : "No Results!"}
                  </div>
                ) : (
                  <div>
                    {showLoader ? <RiseLoading isImport={showLoader} /> : ""}
                  </div>
                )}
              </div>
            }
          </div>}
          {checkIfUserStaff() && clientProfiles !== undefined && clientProfiles?.length > 0 ? (
            <>
              <div className="importbooking-search-search-container">
                <div className="importbooking-search-table-container">
                  <div className="search-title-add-button-container">
                    <div className="search-heading"></div>
                  </div>
                  <div className="importbooking-search-card-container">
                    <SearchImportBookingCard
                      setsupplierName={setsupplierName}
                      setPCC={setPCC}
                      setPNR={setPnr}
                      setclientId={setClientId}
                      clientProfiles={clientProfiles}
                      pccList={pccList}
                      loadPNRDetails={loadPNRDetails}
                      loadPCCDetails={loadPCCDetails}
                      supplierName={supplierName}
                      clientId={clientId}
                      pcc={pcc}
                      pnr={pnr}
                      setClientAvailableBalance={setClientAvailableBalance}
                      clientAvailableBalance={clientAvailableBalance}
                      clientBalanceSH={clientBalanceSH}
                      showLoader={showLoader}
                      setShowLoader={setShowLoader}
                      clientCurrency={clientCurrency}
                      mode={mode}
                      productType={"Flight"}
                    />
                  </div>
                </div>
              </div>
              {
                <div>
                  {bookingDetails && searchPNR === true ? (
                    <dvi>
                      {showLoader ? <RiseLoading isImport={showLoader} /> : ""}
                    </dvi>
                  ) : bookingDetails !== undefined &&
                    bookingDetails?.flights != undefined &&
                    bookingDetails?.flights?.length > 0 ? (
                    bookingDetails?.flights?.map((flight, flightIndex) => (
                      <div>
                        <ValidatorForm
                          // onSubmit={CreateImportBooking}
                          onSubmit={!showRetryBtn ? proceedToTicket : proceedToRetryTicket}
                          onError={(errors) => console.log(errors)}
                        >
                          <div className="import-booking-itenary-container">
                            <div>Flight Itinerary</div>
                            <div className="flight-itenary-details-item">
                              <div className="flight-itenary-details-item-text">
                                {/* {bookingRefNo} */}
                              </div>

                            </div>
                          </div>

                          {segGroups !== undefined && segGroups.length > 0 ? (
                            segGroups.map((segGroup, segGroupIndex) => (
                              <div className="flight-itenary">
                                <div className="flight-itenary-header">
                                  <div className="flight-itenary-title-container">
                                    <div>
                                      {findAirportbyCode(segGroup.origin)} (
                                      {segGroup.origin})
                                    </div>
                                    <div className="flight-itenary-icon">
                                      {/* <ArrowRightAltIcon></ArrowRightAltIcon> */}
                                      {segGroups[0].origin === segGroups[0].destination ? <SyncAltIcon></SyncAltIcon> : <ArrowRightAltIcon></ArrowRightAltIcon>}
                                    </div>
                                    <div>
                                      {findAirportbyCode(segGroup.destination)} (
                                      {segGroup.destination})
                                    </div>
                                  </div>
                                  <div className="flight-itenary-date">
                                    {segGroups[0].origin === segGroups[0].destination ?
                                      (moment(segGroup.departureOn).format(
                                        "Do MMMM YYYY"
                                      )) + " - " + (moment(getLastArrivalTime(bookingDetails.flights)).format(
                                        "Do MMMM YYYY"
                                      )) : (moment(segGroup.departureOn).format(
                                        "Do MMMM YYYY"
                                      ))}
                                  </div>
                                </div>
                                {segGroup.segments !== undefined &&
                                  segGroup.segments.length > 0 ? (
                                  segGroup.segments.map(
                                    (segment, segmentIndex) => (
                                      <div>
                                        <div className="flight-itenary-details">
                                          <div className="flight-itenary-details-item">
                                            <div className="flight-itenary-details-item-text">
                                              {segment.cabinClass}
                                            </div>
                                            <div className="flight-itenary-details-item-subtext">
                                              Class Of Service
                                            </div>
                                          </div>
                                          <div className="flight-itenary-details-item">
                                            <div className="flight-itenary-details-item-text">
                                              {findAirlinebyCode(
                                                segment.mrkAirline
                                              )}
                                            </div>
                                            <div className="flight-itenary-details-item-subtext">
                                              {segment.mrkAirline}-
                                              {segment.flightNum}
                                            </div>
                                          </div>
                                          <div className="flight-itenary-details-item">
                                            <div className="flight-itenary-details-item-text">
                                              {moment(segment.departureOn).format(
                                                "HH:mm"
                                              )}
                                            </div>
                                            <div className="flight-itenary-details-item-subtext">
                                              Depature
                                            </div>
                                          </div>
                                          <div className="flight-itenary-details-item">
                                            <div className="flight-itenary-details-item-text">
                                              {segment.duration}m
                                            </div>
                                            <div className="flight-itenary-details-item-subtext">
                                              Duration
                                            </div>
                                          </div>
                                          <div className="flight-itenary-details-item">
                                            <div className="flight-itenary-details-item-text">
                                              {moment(segment.arrivalOn).format(
                                                "HH:mm"
                                              )}
                                            </div>
                                            <div className="flight-itenary-details-item-subtext">
                                              Arrival
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flight-confirmation-details">
                                          <div className="flight-confirmation-details-item">
                                            <div className="flight-confirmation-details-item-text">
                                              Origin
                                            </div>
                                            <div className="flight-confirmation-details-item-subtext">
                                              {getAirportByCode(segment.origin)} -{" "}
                                              {segment.origin}
                                            </div>
                                          </div>
                                          <div className="flight-confirmation-details-item">
                                            <div className="flight-confirmation-details-item-text">
                                              Destination
                                            </div>
                                            <div className="flight-confirmation-details-item-subtext">
                                              {getAirportByCode(
                                                segment.destination
                                              )}{" "}
                                              - {segment.destination}
                                            </div>
                                          </div>
                                          <div className="flight-confirmation-details-item">
                                            <div
                                              className="flight-confirmation-details-item-text">
                                              PNR
                                            </div>
                                            <div className="flight-confirmation-details-item-subtext">
                                              <div className="flight-itenary-details-item-text">
                                                {segment.pnr}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="flight-confirmation-details-item">
                                            <div className="flight-confirmation-details-item-text">
                                              Segment Status
                                            </div>
                                            <div className="flight-confirmation-details-item-subtext full-opacity">
                                              <div className="itenary-confirmtaion-tag success">
                                                {segment.flightTicketStatus}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div></div>
                          )}
                          <div className="import-booking-itenary-container booking-padding-top-40">
                            <div>Passenger Details</div>
                          </div>
                          <div className="importBooking passenger-itenary-details itenary-passenger-details-baggage">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                                className="itenary-passenger-table"
                              >
                                <TableRow className="itenary-passenger-table-header">
                                  <TableCell
                                    align="center"
                                    className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight itenary-passenger-table-header-item-initial "
                                  >
                                    Passenger(s)
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                                  >
                                    Gender
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                                  >
                                    Date of Birth
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                                  >
                                    Passport No
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                                  >
                                    Seat
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                                  >
                                    Paid Baggage
                                  </TableCell>
                                </TableRow>
                                {flight.passengers !== undefined &&
                                  flight.passengers.length > 0 ? (
                                  flight.passengers
                                    .sort(function (a, b) {
                                      if (a.paxType < b.paxType) {
                                        return -1;
                                      }
                                      if (a.paxType > b.paxType) {
                                        return 1;
                                      }
                                      return 0;
                                    })
                                    .map((passenger, passengerIndex) => (
                                      <TableRow className="itenary-passenger-table-item">
                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30"
                                        >
                                          {passenger.title} {passenger.firstName}{" "}
                                          {passenger.lastName}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30"
                                        >
                                          {passenger.genderType}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30"
                                        >
                                          {passenger.dob ? moment(passenger.dob).format(
                                            "Do MMMM YYYY"
                                          ) : "-"}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30"
                                        >
                                          {passenger.passportNumber ? passenger.passportNumber : "-"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30"
                                        >
                                          {passenger.seatPref}
                                        </TableCell>
                                        <TableCell align="center">
                                          {flight.baggages?.filter(
                                            (item) =>
                                              item?.paxName === `${passenger.firstName} ${passenger.lastName}`
                                          ).length > 0 ? (
                                            flight.baggages
                                              ?.filter(
                                                (item) =>
                                                  item?.paxName === `${passenger.firstName} ${passenger.lastName}`
                                              )
                                              .map((bag, index, array) => (
                                                <React.Fragment key={index}>
                                                  {bag.checkInBag}
                                                  {flight.currency}
                                                  {bag.amount}
                                                  <br />
                                                  {index < array.length - 1 ? "\n" : ""}
                                                </React.Fragment>
                                              ))
                                          ) : (
                                            "NA"
                                          )}
                                        </TableCell>

                                      </TableRow>
                                    ))
                                ) : (
                                  <TableRow></TableRow>
                                )}
                              </Table>
                            </TableContainer>
                          </div>

                          <FlightFareDetailsSupplierTableComponent
                            flight={flight}
                            CalculateTotalFare={CalculateTotalFare}
                            OnChangeCalculateTotalFare=
                            {OnChangeCalculateTotalFare}
                            adultBase={adultBase}
                            adultTax={adultTax}
                            adultBaggage={adultBaggage}
                            childBase={childBase}
                            childTax={childTax}
                            childBaggage={childBaggage}
                            infantBase={infantBase}
                            infantTax={infantTax}
                            infantBaggage={infantBaggage}
                            adultVendorIssuanceFee={adultVendorIssuanceFee}
                            childVendorIssuanceFee={childVendorIssuanceFee}
                            infantVendorIssuanceFee={infantVendorIssuanceFee}
                            disableInfantMarkup={disableInfantMarkup}
                            adultMarkup={adultMarkup}
                            childMarkup={childMarkup}
                            infantMarkup={infantMarkup}
                            adultAdditionalMarkup={adultAdditionalMarkup}
                            childAdditionalMarkup={childAdditionalMarkup}
                            infantAdditionalMarkup={infantAdditionalMarkup}
                            adultVendorTax={adultVendorTax}
                            childVendorTax={childVendorTax}
                            infantVendorTax={infantVendorTax}
                            adultVendorTotal={adultVendorTotal}
                            childVendorTotal={childVendorTotal}
                            infantVendorTotal={infantVendorTotal}
                            adultIata={adultIata}
                            childIata={childIata}
                            infantIata={infantIata}
                            adultVendorIata={adultVendorIata}
                            childVendorIata={childVendorIata}
                            infantVendorIata={infantVendorIata}
                            adultPlb={adultPlb}
                            childPlb={childPlb}
                            infantPlb={infantPlb}
                            adultClientDropnet={adultClientDropnet}
                            childClientDropnet={childClientDropnet}
                            infantClientDropnet={infantClientDropnet}
                            adultVendorPlb={adultVendorPlb}
                            childVendorPlb={childVendorPlb}
                            infantVendorPlb={infantVendorPlb}
                            adultVendorDropnet={adultVendorDropnet}
                            childVendorDropnet={childVendorDropnet}
                            infantVendorDropnet={infantVendorDropnet}
                            currency={vendorDetail && vendorDetail.currencyCode ? vendorDetail.currencyCode : ""}
                            mode={mode}
                          />

                          <FlightFareDetailsClientTableComponent
                            flight={flight}
                            CalculateTotalFare={CalculateTotalFare}
                            OnChangeCalculateTotalFare={
                              OnChangeCalculateTotalFare
                            }
                            adultBase={adultBase}
                            adultTax={adultTax}
                            adultBaggage={adultBaggage}
                            childBase={childBase}
                            childTax={childTax}
                            childBaggage={childBaggage}
                            infantBase={infantBase}
                            infantTax={infantTax}
                            infantBaggage={infantBaggage}
                            adultVendorIssuanceFee={adultVendorIssuanceFee}
                            childVendorIssuanceFee={childVendorIssuanceFee}
                            infantVendorIssuanceFee={infantVendorIssuanceFee}
                            disableInfantMarkup={disableInfantMarkup}
                            adultMarkup={adultMarkup}
                            childMarkup={childMarkup}
                            infantMarkup={infantMarkup}
                            adultAdditionalMarkup={adultAdditionalMarkup}
                            childAdditionalMarkup={childAdditionalMarkup}
                            infantAdditionalMarkup={infantAdditionalMarkup}
                            adultBookingfee={adultBookingfee}
                            adultVendorTax={adultVendorTax}
                            childVendorTax={childVendorTax}
                            infantVendorTax={infantVendorTax}
                            adultCommTax={adultCommTax}
                            childCommTax={childCommTax}
                            infantCommTax={infantCommTax}
                            adultTotal={adultTotal}
                            childTotal={childTotal}
                            infantTotal={infantTotal}
                            adultIata={adultIata}
                            childIata={childIata}
                            infantIata={infantIata}
                            adultVendorIata={adultVendorIata}
                            childVendorIata={childVendorIata}
                            infantVendorIata={infantVendorIata}
                            adultPlb={adultPlb}
                            childPlb={childPlb}
                            infantPlb={infantPlb}
                            adultClientDropnet={adultClientDropnet}
                            childClientDropnet={childClientDropnet}
                            infantClientDropnet={infantClientDropnet}
                            adultVendorPlb={adultVendorPlb}
                            childVendorPlb={childVendorPlb}
                            infantVendorPlb={infantVendorPlb}
                            adultVendorDropnet={adultVendorDropnet}
                            childVendorDropnet={childVendorDropnet}
                            infantVendorDropnet={infantVendorDropnet}
                            currency={
                              clientCurrency ? clientCurrency : flight.currency
                            }
                            ROE={clientROE}
                            markupROE={markupROE}
                            mode={mode}
                            iataType={iataType}
                            plbType={plbType}
                            vendorIataType={vendorIataType}
                            vendorPlbType={vendorPlbType}
                            clientAdultTotal={clientAdultTotal}
                            clientChildTotal={clientChildTotal}
                            clientInfantTotal={clientInfantTotal}
                            adultCount={adultCount}
                            childCount={childCount}
                            infantCount={infantCount}
                          />
                          <div className="mt-3 d-flex justify-content-between">
                            <div></div>
                            <div>
                              <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                style={{
                                  textTransform: "none",
                                }}
                                className="srn-btn"
                                onClick={() => setShowPriceOptions(true)}
                              >
                                More Price Options
                              </Button>
                            </div>
                          </div>
                          <div className="import-booking-mco mco-dtls-bg mb-4">
                            <Row className="mx-1">
                              {fop == "Passenger Card" && (
                                <div
                                  className="col-md-3"
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <TextValidator
                                    id="outlined-clientROE-input"
                                    required={
                                      fop == "Passenger Card"
                                        ? ["required"]
                                        : false
                                    }
                                    label="Card number"
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    value={cardNumber}
                                    // onBlur={(event) => CalculateTotalFare()}
                                    onChange={(event) =>
                                      setcardNumber(event.target.value)
                                    }
                                    validators={["required"]}
                                    errorMessages={["required"]}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                              )}
                              {fop == "Passenger Card" && (
                                <div
                                  className="col-md-3"
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <TextValidator
                                    id="outlined-clientROE-input"
                                    required={
                                      fop == "Passenger Card"
                                        ? ["required"]
                                        : false
                                    }
                                    label="Expiry Date"
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    value={expiryDate}
                                    onChange={(event) =>
                                      setexpiryDate(event.target.value)
                                    }
                                    validators={["required"]}
                                    errorMessages={["required"]}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                              )}
                              <div
                                className="col-md-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TextValidator
                                  id="outlined-clientROE-input"
                                  label="Client ROE"
                                  required
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  value={clientROE}
                                  onBlur={() => CalculateTotalFare()}
                                  onChange={(event) => {
                                    setClientROE(
                                      (clientROE) => event.target.value
                                    );
                                  }}
                                  validators={["required"]}
                                  errorMessages={["required"]}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                              <div
                                className="col-md-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TextValidator
                                  id="outlined-clientROE-input"
                                  label="Case ID"
                                  variant="outlined"
                                  size="small"
                                  required
                                  color="secondary"
                                  value={caseID}
                                  onChange={(event) =>
                                    setCaseID(event.target.value)
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  validators={["required", 'matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$']}
                                  errorMessages={["This field is required", "This field cannot be blank or contain only spaces"]}
                                />
                              </div>
                              <div
                                className="col-md-3 salesStaff"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                {/* <TextValidator
                                id="outlined-clientROE-input"
                                label="Sales Staff"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={salesStaff}
                                onChange={(event) =>
                                  setsalesStaff(event.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              /> */}
                                <Autocomplete
                                  id="FOP-select"
                                  variant="outlined"
                                  value={
                                    salesStaff !== undefined &&
                                    userProfiles?.find(
                                      (el) => el.id === salesStaff
                                    )
                                  }
                                  onChange={(event, newValue) => {
                                    setsalesStaff(newValue ? newValue.id : "");
                                  }}
                                  options={userProfiles}
                                  getOptionLabel={(option) =>
                                    option.title +
                                    "." +
                                    option.firstName +
                                    " " +
                                    option.lastName
                                  }
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      label="Sales Staff"
                                      required
                                      // salesStaff !== undefined ? userProfiles.find((v) => v.id == salesStaff) : ""
                                      value={salesStaff}
                                      variant="filled"
                                      color="secondary"
                                      size="small"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      validators={["required"]}
                                      errorMessages={["required"]}
                                    />
                                  )}
                                />
                              </div>
                              <div
                                className="col-md-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TextValidator
                                  required={
                                    endEmailMandatoryClients.includes(clientId.toString())
                                  }
                                  id="outlined-clientROE-input"
                                  label="End Client Name"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  value={clientName}
                                  onChange={(event) =>
                                    setclientName(event.target.value)
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                              <div
                                className="col-md-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TextValidator
                                  // required={
                                  //   endEmailMandatoryClients.includes(clientId.toString())
                                  // }
                                  required
                                  id="outlined-clientROE-input"
                                  label="END Client Email / Akbar Online OD"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  value={clientEmail}
                                  onChange={(event) =>
                                    setclientEmail(event.target.value)
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  validators={["required", 'matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$']}
                                  errorMessages={["This field is required", "This field cannot be blank or contain only spaces"]}
                                />
                              </div>
                              <div
                                className="col-md-3 fema"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <Autocomplete
                                  id="FOP-select"
                                  variant="outlined"
                                  value={
                                    fema && femaList.find((el) => el === fema)
                                  }
                                  onChange={(event, newValue) => {
                                    setfema(newValue ? newValue : "");
                                  }}
                                  options={femaList}
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      label="Corporate/Retailer"
                                      value={fema}
                                      variant="filled"
                                      color="secondary"
                                      size="small"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div
                                className="col-md-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TextValidator
                                  required={
                                    fema === "Retailer" ? ["required"] : false
                                  }
                                  id="outlined-clientROE-input"
                                  label="Pan Card"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  value={panCard}
                                  onChange={(event) =>
                                    setpanCard(event.target.value)
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  validators={[
                                    "matchRegexp:^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
                                  ]}
                                  errorMessages={[
                                    "Not in valid format - 1st 5 Letters in CAPS, then Next 4 Letters in Numbers & Last Letter in CAPS",
                                  ]}
                                />
                              </div>
                              {/* MCO fields */}

                              <div
                                className="col-md-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TextValidator
                                  id="outlined-mco-input"
                                  label="MCO Number"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  value={mco}
                                  onChange={(event) => setMCO(event.target.value)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />

                              </div>
                              <div
                                className="col-md-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TextValidator
                                  id="outlined-pgcharge-input"
                                  label="MCO Charge"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  value={pgCharge}
                                  onChange={(event) => setPGCharge(event.target.value)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />

                              </div>
                              <div
                                className="col-md-3 fema"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TextValidator
                                  id="outlined-mcoParted-input"
                                  label="MCO Parting"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  value={mcoParted}
                                  onChange={(event) => setMCOParted(event.target.value)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />

                              </div>
                              <div
                                className="col-md-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TextValidator
                                  id="outlined-mcoRemark-input"
                                  label="MCO Remark"
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  value={mcoRemark}
                                  onChange={(event) => setMCORemark(event.target.value)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>

                              {/* MCO fields */}
                              <div
                                className="col-md-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <Autocomplete
                                  id="FOP-select"
                                  variant="outlined"
                                  value={source && sourceList.find((el) => el === source)}
                                  onChange={(event, newValue) => {
                                    setSource(newValue ? newValue : "");
                                  }}
                                  options={sourceList}
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      label="Source of Booking"
                                      required
                                      value={source}
                                      variant="filled"
                                      color="secondary"
                                      size="small"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      validators={["required"]}
                                      errorMessages={["required"]}
                                    />
                                  )}
                                />
                              </div>
                            </Row>
                            <Row className="mx-1">
                              <div
                                className="col-md-3 align-self-center"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              ></div>
                              <div
                                className="col-md-3 align-self-center"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              ></div>
                              <div
                                className="col-md-3 align-self-center"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <div className="import-booking-fare-item-total">
                                  {" "}
                                  <span style={{ marginRight: "5px" }}>
                                    Client Payable:
                                  </span>{" "}
                                  {clientCurrency ? clientCurrency : " "}{" "}
                                  {/* {clientPayable} */}
                                  {clientPayable ? Number((clientPayable) + ((adultBookingfee * adultCount) + (adultBookingfee * childCount) + (adultBookingfee * infantCount)))?.toFixed(2) : "0.0"}
                                </div>
                              </div>
                              <div
                                className="col-md-3 align-self-center"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <div className="import-booking-fare-item-total">
                                  <span style={{ marginRight: "5px" }}>
                                    Supplier Payable:
                                  </span>{" "}
                                  {vendorDetail && vendorDetail.currencyCode
                                    ? vendorDetail.currencyCode
                                    : " "}{" "}
                                  {supplierPayable ? Number(supplierPayable)?.toFixed(2) : "0.0"}
                                </div>
                              </div>
                            </Row>
                            <div className="import-booking-remarks">
                              <Box
                                component="form"
                                sx={{
                                  "& > :not(style)": { m: 1 },
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <TextField
                                  id="outlined-basic"
                                  label="Remarks"
                                  multiline
                                  maxRows={4}
                                  placeholder="Max 300 Characters"
                                  sx={{
                                    whiteSpace: "pre-wrap",
                                    maxWidth: "98.5%",
                                    minHeight: "100px !important",
                                  }}
                                  variant="outlined"
                                  value={importPNRRemarks}
                                  inputProps={{ maxLength: 300 }}
                                  onChange={(event) => {
                                    setimportPNRRemarks(
                                      (importPNRRemarks) => event?.target?.value
                                    );
                                  }}
                                />
                              </Box>
                            </div>
                            {clientTotalWithClientROE > clientAvailableBalance ? (
                              <>
                                <Row>
                                  <div className="col-6 offset-md-3 mt-4 mb-1 warning-msg-txt text-center">
                                    Unable to create booking, due insufficient
                                    client balance.
                                  </div>
                                </Row>
                              </>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="create-booking-button-container">
                            <Button
                              variant="outlined"
                              color="primary"
                              size="large"
                              style={{
                                textTransform: "none",
                              }}
                              type="submit"
                              className="srn-btn me-3"
                              onClick={refreshPNR}
                            //   disabled={isImportBtnDisabled}
                            >
                              Back
                            </Button>
                            {!showRetryBtn ?
                              (<Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                style={{
                                  textTransform: "none",
                                }}
                                type="submit"
                                className="srn-btn"
                                // onClick={() => proceedToTicket()}
                                disabled={disableProceed}
                              >
                                Proceed to Ticket
                              </Button>) :
                              (<Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                style={{
                                  textTransform: "none",
                                }}
                                type="submit"
                                className="srn-btn"
                              // onClick={proceedToRetryTicket}
                              >
                                Retry
                              </Button>)
                            }
                            {balanceAlert && <div className="text-danger p-2" style={{ fontSize: "12px" }}>
                              Not enough fund to book Ticket, please top up the wallet
                            </div>}
                          </div>
                          {/* </>
                        )} */}
                        </ValidatorForm>
                      </div>
                    ))
                  ) : bookingDetails === null && searchPNR === false && isRefresh === false ? (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "5%",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {errorMsg ? errorMsg + "!" : "No Results!"}
                    </div>
                  ) : (
                    <div>
                      {showLoader ? <RiseLoading isImport={showLoader} /> : ""}
                    </div>
                  )}
                </div>
              }
            </>


          ) : (
            <div>
              {checkIfUserStaff() && <div className="table-loading">
                <div style={{ width: "100%", margin: "5px" }}>
                  <Skeleton width="100%" height={24} animation="wave" />
                </div>
                <div style={{ width: "100%", margin: "5px" }}>
                  <Skeleton width="100%" height={24} animation="wave" />
                </div>
                <div style={{ width: "100%", margin: "5px" }}>
                  <Skeleton width="100%" height={24} animation="wave" />
                </div>
                <div style={{ width: "100%", margin: "5px" }}>
                  <Skeleton width="100%" height={24} animation="wave" />
                </div>
              </div>}
            </div>
          )
          }
        </Grid>
      </Grid>
      <Dialog
        open={alert.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAlert({ open: false, message: "", severity: "warning" })}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ borderBottom: 1, borderColor: 'divider' }}>{"Error"}</DialogTitle>
        <DialogContent sx={{ fontSize: "0.9 rem" }}>
          {alert.message}
        </DialogContent>
        <DialogActions sx={{ borderTop: 1, borderColor: 'divider' }}>
          <Button onClick={() => setAlert({ open: false, message: "", severity: "warning" })}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showPriceOptions}
        // open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowPriceOptions(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <PricingOptions setShowPriceOptions={setShowPriceOptions} />
      </Dialog>

    </div >
  );
};

export default ImportPNR;
