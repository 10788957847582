import React, { useState, useEffect } from "react";
import "./MyQueueTable.css";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../hooks/useConfigurationTableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Chip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../services/AuthService";
import { IconButton, OutlinedInput, InputAdornment } from "@material-ui/core";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Skeleton from "@material-ui/lab/Skeleton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Alert from "@material-ui/lab/Alert";
export default function MyQueueTable({ openDrawer, setAction, categories }) {
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label='first page'>
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label='previous page'>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='next page'>
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='last page'>
          <LastPageIcon />
        </IconButton>
      </>
    );
  }
  const history = useHistory();
  const redirectTo = (route) => {
    history.push(route);
  };
  const [searchedVal, setSearchedVal] = useState("");
  return (
    <div>
      <TableContainer component={Paper} className='table-paper-styles'>
        <Table className='table-background'>
          <TableHead>
            <TableRow>
              <TableCell colSpan={9} className='py-2'>
                <OutlinedInput
                  id='keyword-search'
                  onChange={(e) => {
                    setSearchedVal(e.target.value);
                    setPage(0);
                  }}
                  placeholder='Keyword Search by booking status or Queue name  or status or ID'
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton>
                        <SearchOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Queue Name</TableCell>
              <TableCell>Booking Status</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Client/Vendor</TableCell>
              <TableCell>PCC/Airline</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories !== undefined && categories.length > 0 ? (
              categories.filter((row) => {
                if (
                  searchedVal.toString().toLowerCase() == "active" ||
                  searchedVal.toString().toLowerCase() == "inactive"
                ) {
                  return (
                    row.myBookingQueue.status.toLowerCase() ==
                    searchedVal.toString().toLowerCase()
                  );
                } else {
                  return (
                    row.myBookingQueue.pccCode
                      ?.toLowerCase()
                      .includes(searchedVal.toString().toLowerCase()) ||
                    row.myBookingQueue.bookingStatus
                      ?.toLowerCase()
                      .includes(searchedVal.toString().toLowerCase()) ||
                    row.myBookingQueue.queueName
                      ?.toLowerCase()
                      .includes(searchedVal.toString().toLowerCase()) ||
                    // row.myBookingQueue.status.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row.myBookingQueue.id === searchedVal
                  );
                }
              }).length === 0 ? (
                <>
                  <StyledTableRow>
                    <StyledTableCell
                      component='th'
                      scope='row'
                      colSpan={9}
                      align='center'>
                      <Alert severity='error'>No record found.</Alert>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ) : (
                categories
                  .filter((row) => {
                    if (
                      searchedVal.toString().toLowerCase() == "active" ||
                      searchedVal.toString().toLowerCase() == "inactive"
                    ) {
                      return (
                        row.myBookingQueue.status.toLowerCase() ==
                        searchedVal.toString().toLowerCase()
                      );
                    } else {
                      return (
                        row.myBookingQueue.pccCode
                          ?.toLowerCase()
                          .includes(searchedVal.toString().toLowerCase()) ||
                        row.myBookingQueue.bookingStatus
                          ?.toLowerCase()
                          .includes(searchedVal.toString().toLowerCase()) ||
                        row.myBookingQueue.queueName
                          ?.toLowerCase()
                          .includes(searchedVal.toString().toLowerCase()) ||
                        // row.myBookingQueue.status.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                        row.myBookingQueue.id === searchedVal
                      );
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((group) => (
                    <StyledTableRow key={JSON.stringify(group.myBookingQueue)}>
                      <StyledTableCell component='th' scope='row'>
                        <div className='table-cell-title'>
                          {group.myBookingQueue.queueName}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className='table-cell-title'>
                          {group.myBookingQueue.bookingStatus}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className='table-cell-title'>
                          {group.myBookingQueue.isActive ? (
                            <Chip
                              label='Active'
                              color='primary'
                              size='small'
                              style={{ backgroundColor: "green" }}
                            />
                          ) : (
                            <Chip
                              label='In Active'
                              color='primary'
                              style={{ backgroundColor: "red" }}
                            />
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className='table-cell-title'>
                          {group.myBookingQueue.clientId === 0
                            ? "ALL"
                            : group.clientprofile !== undefined
                            ? group.clientprofile.clientName
                            : "All"}
                        </div>
                        <div className='table-cell-description'>
                          {group.myBookingQueue.vendorId === 0
                            ? "ALL"
                            : group.vendor !== undefined
                            ? group.vendor.vendorName
                            : "All"}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className='table-cell-title'>
                          {group.myBookingQueue.pcc === ""
                            ? "ALL"
                            : group.myBookingQueue.pcc}
                        </div>
                        <div className='table-cell-description'>
                          {group.myBookingQueue.airline === ""
                            ? "ALL"
                            : group.myBookingQueue.airline}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className='table-cell-description'>
                          Created by:{" "}
                          <span style={{ color: "black" }}>
                            {group.createdBy}
                          </span>
                        </div>
                        <div className='table-cell-description'>
                          Created on:{" "}
                          {group.myBookingQueue.created.replace("T", " , ")}
                        </div>
                        <div className='table-cell-description'>
                          Last Modified by:{" "}
                          <span style={{ color: "black" }}>
                            {group.modefiedBy}
                          </span>
                        </div>
                        <div className='table-cell-description'>
                          Last Modified on:{" "}
                          {group.myBookingQueue.modified.replace("T", " , ")}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell component='th' scope='row' align='right'>
                        <div className='table-cell-title'>
                          {isUserHasPermission("MyBookingQueueWrite") ? (
                            <Button
                              variant='outlined'
                              color='primary'
                              size='small'
                              onClick={() => {
                                setAction("view");
                                openDrawer(group);
                              }}>
                              Edit
                            </Button>
                          ) : (
                            ""
                          )}
                          {isUserHasPermission("MyBookingQueueAccessQueue") ? (
                            <Button
                              variant='contained'
                              color='primary'
                              size='small'
                              style={{ marginLeft: "5px" }}
                              onClick={() =>
                                redirectTo(
                                  "/QueueTable?status=" +
                                    group.myBookingQueue.bookingStatus
                                )
                              }>
                              Access Queue
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              )
            ) : (
              <StyledTableRow>
                <StyledTableCell>
                  <div className='table-loading'>
                    <Skeleton width='40%' height={24} animation='wave' />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className='table-loading'>
                    <Skeleton width='40%' height={24} animation='wave' />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className='table-loading'>
                    <Skeleton width='40%' height={24} animation='wave' />
                  </div>
                </StyledTableCell>
                {/* <StyledTableCell>
                <div className="table-loading">
                  <Skeleton width="50%" height={24} animation="wave" />
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <div className="table-loading">
                  <Skeleton width="70%" height={24} animation="wave" />
                </div>
              </StyledTableCell> */}
                <StyledTableCell>
                  <div className='table-loading'>
                    <Skeleton width='800%' height={24} animation='wave' />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className='table-loading'>
                    <Skeleton width='500%' height={24} animation='wave' />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className='table-loading'>
                    <Skeleton width='70%' height={24} animation='wave' />
                  </div>
                </StyledTableCell>
                <StyledTableCell align='right'>
                  <div className='table-loading'>
                    <Skeleton width='80%' height={24} animation='wave' />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={
          categories.filter((row) => {
            if (
              searchedVal.toString().toLowerCase() == "active" ||
              searchedVal.toString().toLowerCase() == "inactive"
            ) {
              return (
                row.myBookingQueue.status.toLowerCase() ==
                searchedVal.toString().toLowerCase()
              );
            } else {
              return (
                row.myBookingQueue.pccCode
                  ?.toLowerCase()
                  .includes(searchedVal.toString().toLowerCase()) ||
                row.myBookingQueue.bookingStatus
                  ?.toLowerCase()
                  .includes(searchedVal.toString().toLowerCase()) ||
                row.myBookingQueue.queueName
                  ?.toLowerCase()
                  .includes(searchedVal.toString().toLowerCase()) ||
                // row.myBookingQueue.status.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                row.myBookingQueue.id === searchedVal
              );
            }
          }).length
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => handleChangePage(event, newPage)}
        onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}
