import React, { useEffect } from "react";
import "./FSHSearch.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@material-ui/core/Button";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import airportDetails from "../../../../data/AirportDetails";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

export default function FSHSearch({
  origin,
  destination,
  fromDate,
  toDate,
  clientId,
  userId,
  setOrigin,
  setDestination,
  setFromDate,
  setToDate,
  setClientId,
  loadFlightSearchDetails,
  errorMessage,
  clientProfiles,
  traceId,
  setTraceId
}) {
  
  const collections = [
    {
      id: 1,
      collectionName: 'Collection 1'
    },
    {
      id: 2,
      collectionName: 'Collection 2'
    }
  ];

  const today = dayjs();

  const airportFilterOptions = (options, { inputValue }) => {
    if (inputValue.length <= 3) {
      return options.filter(
        (option) => option.city_code.toLowerCase() === inputValue.toLowerCase()
      );
    } else {
      return options.filter((option) =>
        option.airport_name.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  };
  function setDayJSDate(calDate) {
    let day = dayjs(calDate);
    let date = day.get("date");
    let month = day.get("month");
    let year = day.get("year");
    let temp = dayjs().set("date", date).set("month", month).set("year", year);
    return dayjs(temp);
  }

  return (
    <div className="create-search-contents fshSearch">
      <div className="add-search-if-items-container">
        <div className="add-search-if-item flex-1-input margin-right-16 ms-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                setFromDate(newValue.$d ? newValue.$d : null);
              }}
              defaultValue={today}
              disableFuture
              format="DD/MM/YYYY"
              maxDate={today}
              value={fromDate ? dayjs(fromDate) : dayjs()}
              label="From Date"
            />
          </LocalizationProvider>
        </div>
        <div className="add-search-if-item flex-1-input margin-right-16">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                setToDate(newValue.$d ? newValue.$d : null);
              }}
              defaultValue={today}
              disableFuture
              format="DD/MM/YYYY"
              maxDate={today}
              value={toDate ? dayjs(toDate) : dayjs()}
              label="To Date"
            />
          </LocalizationProvider>
        </div>
        <div className="add-search-if-item flex-1-input margin-right-16">
          <Autocomplete
            options={airportDetails.filter(
              (option) => option.city_code !== "All"
            )} // Replace with your airline options
            value={
              origin !== ""
                ? airportDetails.find((v) => v.city_code === origin)
                : null
            }
            getOptionLabel={(option) =>
              option.airport_name + " (" + option.city_code + ")"
            }
            onChange={(event, value) =>
              setOrigin(value ? value.city_code : null)
            }
            filterSelectedOptions
            filterOptions={airportFilterOptions}
            required
            renderInput={(params) => (
              <TextField
                label="Origin"
                variant="outlined"
                value={
                  origin
                    ? airportDetails.find((v) => v.city_code === origin)
                    : null
                }
                {...params}
              />
            )}
          />
        </div>
        <div className="add-search-if-item flex-1-input margin-right-16">
          <Autocomplete
            options={airportDetails.filter(
              (option) => option.city_code !== "All"
            )} // Replace with your airline options
            value={
              destination !== null
                ? airportDetails.find((v) => v.city_code === destination)
                : null
            }
            getOptionLabel={(option) =>
              option.airport_name + " (" + option.city_code + ")"
            }
            onChange={(event, value) =>
              setDestination(value ? value.city_code : null)
            }
            filterSelectedOptions
            filterOptions={airportFilterOptions}
            renderInput={(params) => (
              <TextField
                label="Destination"
                variant="outlined"
                value={
                  destination !== null
                    ? airportDetails.find((v) => v.city_code === destination)
                    : null
                }
                {...params}
              />
            )}
          />
        </div>
        <div className="add-search-if-item flex-1-input margin-right-16">
          {/* <TextField
            label="Client ID"
            variant="outlined"
            value={clientId}
            color="secondary"
            size="small"
            type="number"
            onChange={(event, value) => {
              setClientId(event.target.value ? event.target.value : "");
            }}
          /> */}
          <ValidatorForm
            onError={errors => console.log(errors)}
          >
            <Autocomplete
              id="Client select"
              value={
                clientProfiles !== null
                  ? clientProfiles.find((v) => v.id === clientId)
                  : null
              }
              onChange={(event, newValue) => {
                setClientId(newValue ? newValue.id : "");
              }}
              options={clientProfiles || []}
              getOptionLabel={(option) => `${option.clientName} (${option.id})`}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) =>
                  option.clientName.toLowerCase().includes(inputValue.toLowerCase().trim()) ||
                  option.id.toString().includes(inputValue.trim())
                )
              }
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  label="Client"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  validators={["required"]}
                  errorMessages={["Client is required"]}
                />
              )}
            />
          </ValidatorForm>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div style={{ width: "350px" }} className="add-search-if-item flex-1-input margin-right-16 ms-2">
          <TextField
            label="Trace ID"
            variant="outlined"
            value={traceId}
            color="secondary"
            size="small"
            type="text"
            onChange={(event) => {
              setTraceId(event.target.value);
            }}
          />
        </div>
        <div className="add-search-if-item flex-1-input margin-right-16">
          <Autocomplete
            options={collections} // Replace with your airline options
            value={
              collections.find((v) => v.id === 1)               
            }
            getOptionLabel={(option) =>
              option.collectionName
            }
            onChange={(event, value) =>
              () => {}
            }
            
            renderInput={(params) => (
              <TextField
                label="Collection"
                variant="outlined"
                value={
                  collections.find((v) => v.id === 1)
                }
                {...params}
              />
            )}
          />
        </div>
        <div className="add-search-if-item flex-1-input margin-right-16"></div>
      </div>

      <div>
        {errorMessage && errorMessage.length > 0 ? (
          <span className="colorRed pad1vw">{errorMessage}</span>
        ) : (
          ""
        )}
      </div>
      <div className="importbooking-add-search-button-container importbooking-button-table-padding">
        <Button
          variant="outlined"
          color="primary"
          size="large"
          style={{
            textTransform: "none",
            //marginLeft: "405px",
            // marginRight: "25px",
            // marginBottom: "25px",
            // width: "500px",
          }}
          //   type="submit"
          onClick={() => loadFlightSearchDetails()}
        >
          Search
        </Button>
      </div>
    </div>
  );
}
