const SampleItineraryText = `AMADEUS:

One way: (Direct)

1  AI 102 Z 23AUG 5 JFKDEL HK1  1130A 4  1140A+1 *1A/E*

Round Trip (Direct)

1  AI 102 L 23AUG 5 JFKDEL HK1  1130A 4 1140A+1 *1A/E*
2  AI 101 W 29SEP 7 DELJFK HK1  120A 3 220A    *1A/E*

One way: Via 

1  AI 116 V 23AUG 5 JFKBOM HK1  1055A 4 1155A+1 *1A/E*
2  AI 442 V 24AUG 6 BOMDEL HK1  250P 2  335P    *1A/E*

Round Trip: Via

1  AI 116 G 23AUG 5 JFKBOM HK1  1055A 4  1155A+1 *1A/E*
2  AI 442 K 24AUG 6 BOMDEL HK1  250P 2  335P    *1A/E*
3  AI 605 K 23SEP 1 DELBOM HK1  835P 3  920P    *1A/E*
4  AI 119 U 24SEP 2 BOMJFK HK1  1240A2  140A   *1A/E*



********************************************************************************

 SABRE :

One way: (Direct)

1 AI 102L 23AUG F JFKDEL SS1  1230P 1140A  24AUG J /DCAI /E

Round Trip (Direct)

1 AI 102L 23AUG F JFKDEL SS1  1230P 1140A  24AUG J /DCAI /E
2 AI 101V 26SEP Q DELJFK SS1  220A  735A /DCAI /E
 
One way: Via 

1 AI 116V 25AUG S JFKBOM SS1  1155A 1155A  26AUG M /DCAI /E
2 AI 442V 26AUG M BOMDEL SS1  335P  555P /DCAI /E

Round Trip: Via

1 AI 116V 25AUG S JFKBOM SS1  1155A 1155A  26AUG M /DCAI /E
2 AI 442V 26AUG M BOMDEL SS1  335P  555P /DCAI /E
3 AI 605Y 23OCT W DELBOM SS1  920P 1135P /DCAI /E
4 AI 119Y 24OCT Q BOMJFK SS1  140A  740A /DCAI /E


1 QR 702J 20DEC F JFKDOH*HK1   820P  445P M /DCAI /E
2 QR 522J 21DEC J DOHGOX*HK1   830P  235A M /DCAI /E
3 QR 523D 06JAN M GOXDOH*HK1   410A  545A M /DCAI /E
4 QR 701D 06JAN M DOHJFK*HK1   815A  305P M /DCAI /E


 1 QR 702J 20DEC F JFKDOH*HK1   820P  445P M /DCAI /E
 2 QR 522J 21DEC J DOHGOX*HK1   830P  235A M /DCAI /E
 3 QR 523D 06JAN M GOXDOH*HK1   410A  545A M /DCAI /E
 4 QR 701D 06JAN M DOHJFK*HK1   815A  305P M /DCAI /E
​​​​​​​​​​**************************************************************************

GALIELO :

One way: (Direct)
 
 1. AI  102 T  23AUG JFKDEL HS1  1230  #1140  O    E FR 

Round Trip (Direct)

1. AI  102 Y  23AUG JFKDEL HS1  1230  #1140  O    E FR     
2. AI  101 L  23SEP DELJFK HS1  0220  0735  O    E MO 

 
One way: Via 

1. AI  116 Y  23AUG JFKBOM HS1  1155  #1155  O    E FR     
2. AI  442 Y  24AUG BOMDEL HS1  1535  1755  O    E SA

1  LH 757 E 15AUG 4*BOMFRA HK1  245A 810A 15AUG  E  LH/3OYGRE
3  LH 442 E 15AUG 4*FRADTW HK1  1115A 215P 15AUG  E  LH/3OYGRE

Round Trip: Via
 
1. AI  116 Y  23AUG JFKBOM HS1  1155  #1155  O    E FR     
2. AI  442 Y  24AUG BOMDEL HS1  1535  1755  O    E SA     
3. AI  605 Y  23SEP DELBOM HS1  2120  2335  O    E MO     
4. AI  119 Y  24SEP BOMJFK HS1  0140  0740  O    E TU
`
export default SampleItineraryText;