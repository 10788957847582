import RiseLoader from "react-spinners/RiseLoader";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// Loading Styles
const LoadingStyle = {
  position: "fixed",
  top: "0",
  left: "0",
  zIndex: "99999",
  background: "#00000060",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
};

export default function RiseLoading({
  isLoading,
  isBaggageLoading,
  isImport,
  client,
  vendor,
  roles,
  isviewBooking,
  isQueueTable,
  isPendingApprovalQueue,
  DebitTransactions,
  ViewCancellation,
  Supplier,
  isCreditCard,
  Search,
  MarkupGroup,
  StaffUser,
  ClientUser,
  excelExport,
  debitTransactions,
  FSH,
  isReviewBooking,
  selectedProductType,
  isSignIn,
}) {
  // return <RiseLoader color={"#a0155a"} loading={isLoading} size={30} css={LoadingStyle} />
  return isBaggageLoading ? (
    <div
      className="col-12 text-center py-5"
      loading={isLoading}
      style={LoadingStyle}
    >
      <Box className="loader-box-content">
        <div className="flt-main-heading py-3">
          <h3>Same Flight lowest fares!!</h3> We're fetching baggage details for
          you...
        </div>
        <CircularProgress />
      </Box>
    </div>
  ) : isLoading ? (
    <div
      className="col-12 text-center py-5"
      loading={isLoading}
      style={LoadingStyle}
    >
      <Box className="loader-box-content">
        <div className="flt-main-heading py-3">
          <h3>Same Flight lowest fares!!</h3> We're fetching flights for you...
        </div>
        <CircularProgress />
      </Box>
    </div>
  ) : vendor ? (
    <div
      className="col-12 text-center py-5"
      loading={isLoading}
      style={LoadingStyle}
    >
      <Box className="loader-box-content">
        <div className="flt-main-heading py-3">
          <h3>Same Flight lowest fares!!</h3> We're Processing the Request for
          you...
        </div>
        <CircularProgress />
      </Box>
    </div>
  ) : client ? (
    <div
      className="col-12 text-center py-5"
      loading={isLoading}
      style={LoadingStyle}
    >
      <Box className="loader-box-content">
        <div className="flt-main-heading py-3">
          <h3>Same Flight lowest fares!!</h3> We're Processing the Request for
          you...
        </div>
        <CircularProgress />
      </Box>
    </div>
  ) : isCreditCard ? (
    <div
      className="col-12 text-center py-5"
      loading={isLoading}
      style={LoadingStyle}
    >
      <Box className="loader-box-content">
        <div className="flt-main-heading py-3">
          <h3>Same Flight lowest fares!!</h3> We're Processing the Request for
          you...
        </div>
        <CircularProgress />
      </Box>
    </div>
  ) : roles ? (
    <div
      className="col-12 text-center py-5"
      loading={isLoading}
      style={LoadingStyle}
    >
      <Box className="loader-box-content">
        <div className="flt-main-heading py-3">
          <h3>Same Flight lowest fares!!</h3> We're Processing the Request for
          you...
        </div>
        <CircularProgress />
      </Box>
    </div>
  ) : DebitTransactions ? (
    <div
      className="col-12 text-center py-5"
      loading={isLoading}
      style={LoadingStyle}
    >
      <Box className="loader-box-content">
        <div className="flt-main-heading py-3">
          <h3>Same Flight lowest fares!!</h3> We're Processing the Request for
          you...
        </div>
        <CircularProgress />
      </Box>
    </div>
  ) :
    ViewCancellation ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're Processing the Request for
            you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : isImport ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>{selectedProductType === "Flight" ? `Same Flight lowest fares!!` : ""}</h3> We're fetching the Booking Details
            for you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : FSH ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're fetching the Booking History
            for you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : isviewBooking ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're fetching the Booking Details
            for you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : isPendingApprovalQueue ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're fetching the Pending Queue
            Details for you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : isQueueTable ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're fetching Your Search Data...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : Supplier ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're fetching Your Search Data...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : Search ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're Processing the Request for
            you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : MarkupGroup ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're Processing the Request for
            you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : isReviewBooking ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're Processing the Request for
            you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : StaffUser ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're Updating the StaffUsers for
            you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : ClientUser ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're Updating the StaffUsers for
            you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : debitTransactions ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Please Wait !!</h3> While We're Importing the Transactions...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : excelExport ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Please Wait !!</h3> We're Exporting the Transactions...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : isSignIn ? (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Please Wait !!</h3> Getting things ready for you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    ) : (
      <div
        className="col-12 text-center py-5"
        loading={isLoading}
        style={LoadingStyle}
      >
        <Box className="loader-box-content">
          <div className="flt-main-heading py-3">
            <h3>Same Flight lowest fares!!</h3> We're Processing the Request for
            you...
          </div>
          <CircularProgress />
        </Box>
      </div>
    );
}
