import BrandedFareHeadTable from "./BrandedFareHeadTable";
import BrandedFareTable from "./BrandedFareTable";
import getTotalFare from "../../functions/getTotalFare";
import CurrencyCodeComponent from "../../providers/CurrencyCode";
import { Row, Col, Nav, Tab } from "react-bootstrap";

import CancelIcon from "@mui/icons-material/Cancel";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DescriptionIcon from "@mui/icons-material/Description";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LuggageIcon from "@mui/icons-material/Luggage";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


import {
  isUserHasPermission,
  checkIfUserStaff,
  isClientUserHasPermission,
  getCurrentRole,
} from "../../services/AuthService";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import MiniRulesComponent from "../MiniRules/MiniRulesComponent";
export default function BrandedFareComponent({
  arrayOf,
  result,
  selectedItinerary,
  setSelectedItinerary,
  filterAndSortBaggage,
  spliceBagtext,
  currencyCode,
  traceId,
  clientId,
  toggleFDIcon,
  getFareDetails,
  search,
  activeTab,
  setActiveTab,
  fareSegGroups,
  baggageList,
  getAdultPrice,
  getChildrenPrice,
  getInfantPrice,
  getAdultTaxPrice,
  getChildrenTaxPrice,
  getInfantTaxPrice,
  getMiniRulesData,
  filterAdultCancellation,
  filterInfantCancellation,
  filterChildCancellation,
  filterAdultChangeDate,
  filterInfantChangeDate,
  filterChildChangeDate,
  proceedToBook,
  showNetfare,
  itineraryMarkup,
  setItineraryMarkup,
  adultcount,
  childcount,
}) {
  const history = useHistory();
  const [toggleShowHideFareDtls, setToggleShowHideFareDtls] = useState(false);
  const [userRole, setUserRole] = useState("");

  const BootstrapTooltip1 = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.grey,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.grey,
    },
  }));


  useEffect(() => {
    setUserRole(getCurrentRole());
  }, [])


  return (
    <>
      <div className={"more_fare_detail"} id={`multiFareDetails_` + arrayOf}>
        <>
          <Row className={"individual_fares"}>
            <Col sm={2} className="card_class">
              <span>
                {" "}
                {result?.fareGroups[selectedItinerary]?.priceClass !== undefined
                  ? result?.fareGroups[selectedItinerary]?.priceClass
                  : "Economy"}{" "}
              </span>{" "}
            </Col>
            <Col sm={10} className="card_details">
              <Row className="reset_rows">
                {(result?.fareGroups[selectedItinerary]?.baggages &&
                  filterAndSortBaggage(
                    result?.fareGroups[selectedItinerary]?.baggages
                  ) &&
                  filterAndSortBaggage(
                    result?.fareGroups[selectedItinerary]?.baggages
                  )?.checkInBag &&
                  filterAndSortBaggage(
                    result?.fareGroups[selectedItinerary]?.baggages
                  )?.checkInBag !== "Nil" &&
                  filterAndSortBaggage(
                    result?.fareGroups[selectedItinerary]?.baggages
                  )?.checkInBag !== "0PC" &&
                  filterAndSortBaggage(
                    result?.fareGroups[selectedItinerary]?.baggages
                  )?.checkInBag !== "0KG" &&
                  filterAndSortBaggage(
                    result?.fareGroups[selectedItinerary]?.baggages
                  )?.checkInBag !== "0 Kg") ||
                  (result?.fareGroups[selectedItinerary]?.baggages &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    ) &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.cabinBag &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.cabinBag !== "Nil" &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.cabinBag !== "0KG" &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.cabinBag !== "0PC" &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.cabinBag !== "0 Kg") ? (
                  <Col sm={3} className="fare_breakdown_container">
                    <h6>
                      {result?.fareGroups[selectedItinerary]?.refundable
                        ? "Refundable"
                        : "Non-Refundable"}{" "}
                    </h6>
                    <div className="flight-confirmation-details-item">
                      {filterAndSortBaggage(
                        result?.fareGroups[selectedItinerary]?.baggages
                      )?.checkInBag &&
                        filterAndSortBaggage(
                          result?.fareGroups[selectedItinerary]?.baggages
                        )?.checkInBag !== "Nil" &&
                        filterAndSortBaggage(
                          result?.fareGroups[selectedItinerary]?.baggages
                        )?.checkInBag !== "0PC" &&
                        filterAndSortBaggage(
                          result?.fareGroups[selectedItinerary]?.baggages
                        )?.checkInBag !== "0KG" &&
                        filterAndSortBaggage(
                          result?.fareGroups[selectedItinerary]?.baggages
                        )?.checkInBag !== "0 Kg" && (
                          <>
                            <p className="bagText ">
                              Check-In :{" "}
                              {spliceBagtext(
                                filterAndSortBaggage(
                                  result?.fareGroups[selectedItinerary]
                                    ?.baggages
                                )?.checkInBag
                                  ? filterAndSortBaggage(
                                    result?.fareGroups[selectedItinerary]
                                      ?.baggages
                                  )?.checkInBag
                                  : ""
                              )}
                            </p>
                          </>
                        )}
                      {filterAndSortBaggage(
                        result?.fareGroups[selectedItinerary]?.baggages
                      )?.cabinBag &&
                        filterAndSortBaggage(
                          result?.fareGroups[selectedItinerary]?.baggages
                        )?.cabinBag !== "Nil" &&
                        filterAndSortBaggage(
                          result?.fareGroups[selectedItinerary]?.baggages
                        )?.cabinBag !== "0PC" &&
                        filterAndSortBaggage(
                          result?.fareGroups[selectedItinerary]?.baggages
                        )?.cabinBag !== "0KG" &&
                        filterAndSortBaggage(
                          result?.fareGroups[selectedItinerary]?.baggages
                        )?.cabinBag !== "0 Kg" && (
                          <>
                            <p className="bagText ">
                              Cabin :{" "}
                              {spliceBagtext(
                                filterAndSortBaggage(
                                  result?.fareGroups[selectedItinerary]
                                    ?.baggages
                                )?.cabinBag
                                  ? filterAndSortBaggage(
                                    result?.fareGroups[selectedItinerary]
                                      ?.baggages
                                  )?.cabinBag
                                  : ""
                              )}
                            </p>
                          </>
                        )}
                    </div>
                  </Col>
                ) : (
                  <Col sm={3} className="fare_breakdown_container textCenter">
                    <h6>
                      {result?.fareGroups[selectedItinerary]?.refundable
                        ? "Refundable"
                        : "Non-Refundable"}{" "}
                    </h6>
                  </Col>
                )}

                <Col sm={9} className="padd_0">
                  <Row className="reset_rows">
                    <Col className="card_price">
                      <>
                        &nbsp;
                        {result.fareGroups[selectedItinerary]?.netFare > 0 && (
                          <BootstrapTooltip1
                            title={
                              <>
                                <p
                                  style={{
                                    borderBottom: "2px solid grey",
                                  }}
                                >
                                  Fare Details
                                </p>

                                {((!checkIfUserStaff() && (isClientUserHasPermission("IsYouEarn") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                  showNetfare == true &&
                                  result?.fareGroups[selectedItinerary]
                                    ?.clientMarkup > 0 && (
                                    <div className="d-flex justify-content-between">
                                      <div>You Earn</div>
                                      <div>
                                        <CurrencyCodeComponent
                                          currencyCode={currencyCode}
                                        />

                                        {(
                                          parseFloat(
                                            result.fareGroups[selectedItinerary]
                                              ?.clientMarkup
                                          ) +
                                          (result?.fareGroups[selectedItinerary]
                                            ?.commission > 0
                                            ? parseFloat(
                                              result?.fareGroups[
                                                selectedItinerary
                                              ]?.commission
                                            )
                                            : 0) +
                                          (itineraryMarkup
                                            ? itineraryMarkup
                                            : 0) *
                                          adultcount +
                                          (itineraryMarkup
                                            ? itineraryMarkup
                                            : 0) *
                                          childcount
                                        ).toFixed(2)}
                                      </div>
                                    </div>
                                  )}
                                {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                  showNetfare == true &&
                                  result?.fareGroups[selectedItinerary]
                                    ?.clientMarkup > 0 && (
                                    <div
                                      className="d-flex justify-content-between"
                                      style={{
                                        fontSize: "10px",
                                        color: "lightgray",
                                      }}
                                    >
                                      <div>Agent Mark-up</div>

                                      <div>
                                        <CurrencyCodeComponent
                                          currencyCode={currencyCode}
                                        />
                                        {(
                                          result.fareGroups[selectedItinerary]
                                            ?.clientMarkup +
                                          (itineraryMarkup
                                            ? itineraryMarkup * adultcount
                                            : 0) +
                                          (itineraryMarkup
                                            ? itineraryMarkup * childcount
                                            : 0)
                                        ).toFixed(2)}
                                      </div>
                                    </div>
                                  )}
                                {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                  showNetfare == true &&
                                  result?.fareGroups[selectedItinerary]
                                    ?.commission > 0 && (
                                    <div
                                      className="d-flex justify-content-between"
                                      style={{
                                        fontSize: "10px",
                                        color: "lightgray",
                                      }}
                                    >
                                      <div>Commission</div>
                                      <div>
                                        <CurrencyCodeComponent
                                          currencyCode={currencyCode}
                                        />
                                        0.0
                                      </div>
                                    </div>
                                  )}
                                <div
                                  className="d-flex justify-content-between"
                                  style={{
                                    borderTop: "2px solid grey",
                                  }}
                                >
                                  <div>Total Amount</div>
                                  <div>
                                    <CurrencyCodeComponent
                                      currencyCode={currencyCode}
                                    />
                                    {result.fareGroups[selectedItinerary]
                                      ?.grossFare +
                                      (itineraryMarkup
                                        ? itineraryMarkup * adultcount
                                        : 0) +
                                      (itineraryMarkup
                                        ? itineraryMarkup * childcount
                                        : 0)}
                                  </div>
                                </div>

                                {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                  showNetfare == true && (
                                    <div
                                      className="d-flex justify-content-between"
                                    // style={{ fontSize: "9px" }}
                                    >
                                      <div>Net Fare</div>
                                      <div>
                                        <CurrencyCodeComponent
                                          currencyCode={currencyCode}
                                        />
                                        {
                                          result.fareGroups[selectedItinerary]
                                            ?.netFare
                                        }
                                      </div>
                                    </div>
                                  )}
                              </>
                            }
                          >
                            <InfoOutlinedIcon />
                          </BootstrapTooltip1>
                        )}
                        <div className="">
                          <p>
                            <CurrencyCodeComponent
                              currencyCode={currencyCode}
                            />
                            {(
                              getTotalFare(
                                result.fareGroups[selectedItinerary],
                                search.adult,
                                search.children,
                                search.infant
                              ).total +
                              (itineraryMarkup
                                ? itineraryMarkup * adultcount
                                : 0) +
                              (itineraryMarkup
                                ? itineraryMarkup * childcount
                                : 0)
                            ).toFixed(2)}
                          </p>
                          {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                            showNetfare == true &&
                            result.fareGroups[selectedItinerary]?.netFare >
                            0 && (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  fontSize: "13px",
                                  color: "gray",
                                }}
                              >
                                <div>Net Fare : </div>
                                <div>
                                  <CurrencyCodeComponent
                                    currencyCode={currencyCode}
                                  />
                                  {result.fareGroups[selectedItinerary]?.netFare}
                                </div>
                              </div>
                            )}
                        </div>
                        {
                          // !checkIfUserStaff() &&
                          //   !isUserHasPermission("FlightBook") ? null : (
                          <>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                              {result.isHold === true &&
                                <>
                                  <Button
                                    variant="contained"
                                    disabled={!checkIfUserStaff() && !isClientUserHasPermission("IsPrice", "FlightbookDetail") && userRole !== "ClientSuperAdmin"}
                                    className="srn-btn marginBR5"
                                    style={{ margin: '10px' }}
                                    onClick={() => {
                                      proceedToBook("Hold");
                                      localStorage.removeItem("reValTimer");
                                    }}
                                  >
                                    Hold
                                  </Button>

                                </>
                              }
                              <div className="flight-confirmation-details-item-Brandedfare">
                                <Button
                                  variant="contained"
                                  disabled={!checkIfUserStaff() && !isClientUserHasPermission("IsPrice", "FlightbookDetail") && userRole !== "ClientSuperAdmin"}
                                  className="srn-btn marginBR5"
                                  onClick={() => {
                                    proceedToBook("Book");
                                    localStorage.removeItem("reValTimer");
                                  }}
                                >
                                  Book
                                </Button>
                                <span
                                  onClick={() =>
                                    setToggleShowHideFareDtls(
                                      (toggleShowHideFareDtls) =>
                                        !toggleShowHideFareDtls
                                    )
                                  }
                                  className="fs14 linkcolor"
                                >
                                  {toggleShowHideFareDtls ? (
                                    <>
                                      Hide More Fares &nbsp;{" "}
                                      <KeyboardArrowUpRoundedIcon fontSize="small" />
                                    </>
                                  ) : (
                                    <>
                                      Show More Fares &nbsp;{" "}
                                      <KeyboardArrowDownRoundedIcon fontSize="small" />
                                    </>
                                  )}
                                </span>
                              </div>
                            </div>

                          </>
                          // )
                        }
                      </>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {toggleShowHideFareDtls ? (
            <Row className="" id={`fareDetails_`}>
              <Tab.Container
                defaultActiveKey={activeTab}
                onSelect={(k) =>
                  getFareDetails(
                    k,
                    traceId,
                    result?.fareGroups[selectedItinerary].purchaseId,
                    clientId,
                    selectedItinerary,
                    arrayOf
                  )
                }
              >
                <Row className="fare_tab_container px-0">
                  <Col sm={3} className="flex-column fare_header_container">
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="fareDescription">
                          {" "}
                          <DescriptionIcon fontSize="small" /> Brand Description{" "}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fareSummary">
                          {" "}
                          <SummarizeIcon fontSize="small" /> Fare Summary{" "}
                        </Nav.Link>
                      </Nav.Item>
                      {result?.vendor !== "G9" && (
                        <Nav.Item>
                          <Nav.Link eventKey="baggages">
                            {" "}
                            <LuggageIcon fontSize="small" /> Baggages{" "}
                          </Nav.Link>
                        </Nav.Item>
                      )}

                      <Nav.Item>
                        <Nav.Link eventKey="cancel">
                          {" "}
                          <CancelIcon fontSize="small" /> Cancellation{" "}
                        </Nav.Link>
                      </Nav.Item>


                      <Nav.Item>
                        <Nav.Link eventKey="change">
                          {" "}
                          <DateRangeIcon fontSize="small" /> Date Change{" "}
                        </Nav.Link>
                      </Nav.Item>

                    </Nav>
                  </Col>
                  <Col sm={9} className="fare_tab_contents_wrapper px-0">
                    <Tab.Content className="fullHeight">
                      <Tab.Pane
                        eventKey="fareDescription"
                        className="fare_tabs"
                        transition={true}
                      >
                        <BrandedFareTable
                          result={result}
                          selectedItinerary={selectedItinerary}
                          setSelectedItinerary={setSelectedItinerary}
                          search={search}
                        />
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="fareSummary"
                        className="fare_tabs"
                        transition={true}
                      >
                        {" "}
                        <BrandedFareHeadTable
                          result={result}
                          selectedItinerary={selectedItinerary}
                          setSelectedItinerary={setSelectedItinerary}
                          search={search}
                        />{" "}
                        <div className="fullHeight">
                          <table className="fullHeight fareDetailsTable">
                            <tr>
                              <th className="text-start" rowSpan={2}>
                                Travellers
                              </th>
                              <th colSpan={3}>Per Pax ({currencyCode})</th>
                              {/* <th colSpan={2}>Sub Total ({currencyCode})</th> */}
                              <th colSpan={2}>Gross Total ({currencyCode})</th>
                            </tr>
                            <tr>
                              <th>Base</th>
                              <th>Tax</th>
                              <th>Total</th>
                              {/* <th>Base</th>
                            <th>Tax</th> */}
                              <th>{`(Base + Tax)`}</th>
                            </tr>
                            {search.adult > 0 && (
                              <tr>
                                <td className="text-start">
                                  {search.adult} x Adult(s)
                                </td>
                                <td>{getAdultPrice().toFixed(2)}</td>
                                <td>{getAdultTaxPrice().toFixed(2)}</td>
                                <td>{(Number(getAdultPrice()) + Number(getAdultTaxPrice())).toFixed(2)}</td>
                                {/* <td>
                                {(search.adult * getAdultPrice()).toFixed(2)}
                              </td>
                              <td>
                                {(search.adult * getAdultTaxPrice()).toFixed(2)}
                              </td> */}
                                <td>
                                  {(Number(search.adult * getAdultPrice()) + Number(search.adult * getAdultTaxPrice())).toFixed(2)}
                                </td>
                              </tr>
                            )}
                            {search.children > 0 && (
                              <tr>
                                <td className="text-start">
                                  {search.children} x Children
                                </td>
                                <td>{getChildrenPrice().toFixed(2)}</td>
                                <td>{getChildrenTaxPrice().toFixed(2)}</td>
                                <td>
                                  {(Number(getChildrenPrice()) + Number(
                                    getChildrenTaxPrice()
                                  )).toFixed(2)}
                                </td>
                                <td>
                                  {(Number(search.children * getChildrenPrice()
                                  ) + Number(
                                    search.children * getChildrenTaxPrice()
                                  )).toFixed(2)}
                                </td>
                              </tr>
                            )}
                            {search.infant > 0 && (
                              <tr>
                                <td className="text-start">
                                  {search.infant} x Infant
                                </td>
                                <td>{getInfantPrice().toFixed(2)}</td>
                                <td>{getInfantTaxPrice().toFixed(2)}</td>
                                <td>
                                  {(Number(getInfantPrice()) + Number(getInfantTaxPrice())).toFixed(
                                    2
                                  )}
                                </td>
                                <td>
                                  {(Number(search.infant * getInfantPrice()) + Number(search.infant * getInfantTaxPrice())).toFixed(
                                    2
                                  )}
                                </td>
                              </tr>
                            )}
                          </table>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="baggages"
                        className="fare_tabs"
                        transition={true}
                      >
                        {" "}
                        <BrandedFareHeadTable
                          result={result}
                          selectedItinerary={selectedItinerary}
                          setSelectedItinerary={setSelectedItinerary}
                        />
                        <Row>
                          <div className="fullHeight baggDetails">
                            {fareSegGroups.map((segGroup, segGroupIndex) =>
                              segGroup.segs.map((item, index) =>
                                result?.fareGroups[selectedItinerary]
                                  ?.baggages &&
                                  result?.fareGroups[selectedItinerary]?.baggages
                                    .length > 0 ? (
                                  <table className="fullHeight fareDetailsTable mb-3">
                                    <tr>
                                      <th className="text-start" colSpan={3}>
                                        {item.origin} - {item.destination}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th className="text-start">Baggages</th>
                                      <th>Check In</th>
                                      <th>Cabin</th>
                                    </tr>
                                    {result?.fareGroups[
                                      selectedItinerary
                                    ]?.baggages
                                      .filter(
                                        (t) =>
                                          t.cityPair ===
                                          item.origin + item.destination
                                      )
                                      .sort(function (a, b) {
                                        if (a.paxType < b.paxType) {
                                          return -1;
                                        }
                                        if (a.paxType > b.paxType) {
                                          return 1;
                                        }
                                        return 0;
                                      })
                                      .map((listItem, index) => (
                                        <tr>
                                          <td
                                            width={150}
                                            className="text-start"
                                          >
                                            {listItem.paxType}
                                          </td>
                                          <td width={250}>
                                            {listItem.baggageInfo === undefined
                                              ? listItem?.checkInBag
                                                ? listItem?.checkInBag
                                                : "NIL"
                                              : listItem.baggageInfo
                                                ? listItem.baggageInfo
                                                : "NIL"}
                                          </td>
                                          <td width={250}>
                                            {/* {result?.vendor === "1A" ||
                                            result?.vendor === "1S" ||
                                            result?.vendor === "1G"
                                              ? "7KG"
                                              : listItem?.cabinBaggageInfo ===
                                                undefined
                                              ? listItem?.cabinBag
                                                ? listItem?.cabinBag
                                                : "NIL"
                                              : listItem?.cabinBaggageInfo
                                              ? listItem?.cabinBaggageInfo
                                              : "NIL"} */}
                                            {listItem?.cabinBaggageInfo ===
                                              undefined
                                              ? listItem?.cabinBag
                                                ? listItem?.cabinBag
                                                : "NIL"
                                              : listItem?.cabinBaggageInfo
                                                ? listItem?.cabinBaggageInfo
                                                : "NIL"}
                                          </td>
                                        </tr>
                                      ))}
                                  </table>
                                ) : (
                                  <table className="fullHeight fareDetailsTable mb-3">
                                    <tr>
                                      <th className="text-start" colSpan={3}>
                                        {item.origin} - {item.destination}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th className="text-start">Baggages</th>
                                      <th>Check In</th>
                                      <th>Cabin</th>
                                    </tr>
                                    {
                                      <tr>
                                        <td width={150} className="text-start">
                                          ADT,CHD,INF
                                        </td>
                                        <td width={250}>NIL</td>
                                        <td width={250}>
                                          {/* {result?.vendor === "1A" ||
                                            result?.vendor === "1S" ||
                                            result?.vendor === "1G"
                                            ? "7KG"
                                            : "NIL"} */}
                                        </td>
                                      </tr>
                                    }
                                  </table>
                                )
                              )
                            )}
                          </div>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="cancel"
                        className="fullHeight fare_tabs"
                        transition={true}
                      >
                        <BrandedFareHeadTable
                          result={result}
                          selectedItinerary={selectedItinerary}
                          setSelectedItinerary={setSelectedItinerary}
                        />

                        {result && (
                          <MiniRulesComponent value={"Cancellation"} currentTicket={result} currencyCode={currencyCode} selectedItinerary={selectedItinerary} />
                        )}
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="change"
                        className="fullHeight fare_tabs"
                        transition={true}
                      >
                        <BrandedFareHeadTable
                          result={result}
                          selectedItinerary={selectedItinerary}
                          setSelectedItinerary={setSelectedItinerary}
                        />
                        {result && (
                          <>
                            <MiniRulesComponent value={"Date Change"} currentTicket={result} currencyCode={currencyCode} selectedItinerary={selectedItinerary} />
                          </>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Row>
          ) : (
            ""
          )}
        </>
      </div>
    </>
  );
}
