import { Button, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "./ClientPayment.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { IconButton } from "@material-ui/core";
import AddClientBalance from "../ClientDrawer/AddClientBalance";
import { isUserHasPermission } from "../../../services/AuthService";


function ClientPayment({ clientId, PaymentType, Currency, action, getClients, setAction }) {
  const [balance, setBalance] = useState(0);
  const [clientBalance, setclientBalance] = useState(null);
  const [PaymentAction, setPaymentAction] = useState("view");
  const [open, setOpen] = useState(false);

  const [creditLimit, setCreditLimit] = useState("");
  const [threSold, setThreSold] = useState("");
  const [threSoldEmailNotification, setThreSoldEmailNotification] = useState("");
  const [useDays, setUseDays] = useState("");
  const [payDays, setPayDays] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  async function handleSubmit() {
    //Call the add api here
  }

  useEffect(() => {
    getClientBalance();
  }, []);
  // useEffect(() => {
  //   if (clientBalance) {
  //     getClients();
  //   }
  // }, [clientBalance]);

  async function getClientBalance() {
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/Transactions/GetAccountBanalce?clientId=" +
        clientId
      )
      .then((response) => {
        //console.log(response);
        setclientBalance(response.data);
        // getClients();
      });
  }

  return (
    <div style={{ marginTop: "10px" }}>
      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={handleSubmit}
      >
        <div className="configuration-heading-container configuration-title-flex-container">
          <div>Client Account Balance</div>
        </div>
        {action === "edit" || action === "view" ? (
          clientBalance && (
            <div className="configuration-view-edit-drawer-container">
              <div className="configuration-view-edit-drawer-input-container pt-0">
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">Current Account Balance</div>
                  <div className="view-configuration-drawer-description">{Currency} {clientBalance.balance}
                    {/* {isUserHasPermission('ClientWrite') ? */}
                    <span className="px-3"><Button variant="outlined" size="small" onClick={handleOpen}>
                      Add Money
                    </Button></span>
                    {/* :''} */}
                  </div>
                </div>
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">Hold Booking Amount</div>
                  <div className="view-configuration-drawer-description">{Currency} {clientBalance.holdBookingAmound}</div>
                </div>
              </div>
              <div className="configuration-view-edit-drawer-input-container">
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">Account Status</div>
                  <div className="view-configuration-drawer-description">{clientBalance.accountLocked ? "Yes" : "No"}</div>
                </div>
                <div className="view-configuration-drawer config-input-flex-1">
                  <div className="view-configuration-drawer-title">Enable Paymnet Gateway</div>
                  <div className="view-configuration-drawer-description">{clientBalance.enablePGPayment ? "Yes" : "No"}</div>
                </div>
              </div>
              {
                PaymentType === "Postpaid" ?
                  <>
                    <div className="configuration-heading-container configuration-title-flex-container pt-3">
                      <div>Credit Limit Details</div>
                    </div>
                    <div className="configuration-view-edit-drawer-input-container">
                      <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                        <div className="view-configuration-drawer-title">Credit Limit</div>
                        <div className="view-configuration-drawer-description">{Currency} {clientBalance.holdBookingAmound}</div>
                      </div>
                      <div className="view-configuration-drawer config-input-flex-1">
                        <div className="view-configuration-drawer-title">Thresold</div>
                        <div className="view-configuration-drawer-description">{Currency} {clientBalance.holdBookingAmound}</div>
                      </div>
                    </div>
                    <div className="configuration-view-edit-drawer-input-container">
                      <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                        <div className="view-configuration-drawer-title">Thresold Email Notification</div>
                        <div className="view-configuration-drawer-description">-</div>
                      </div>
                    </div>
                    <div className="configuration-view-edit-drawer-input-container">
                      <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                        <div className="view-configuration-drawer-title">Credit Payment Cycle</div>
                        <div className="view-configuration-drawer-description">{clientBalance.creditUseDays} Days use{" "}
                          {clientBalance.creditPayDays} Days to Pay</div>
                      </div>
                    </div>
                  </>
                  :
                  ''
              }
            </div>
          )
        ) : (

          <Alert className="mx-4 my-3" severity="error">
            Please add the client First
          </Alert>
        )}
      </ValidatorForm>
      <AddClientBalance open={open} setOpen={setOpen} clientId={clientId} getClientBalance={getClientBalance} getClients={getClients} />
    </div>
  );
}

export default ClientPayment;
