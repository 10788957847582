import HTTPMethod from "http-method-enum";
import sanitizeAddress from "./sanitizeAddress";
import sanitizePassengers from "./sanitizePassengers";
import sendProtectedJSONRequest from "./sendProtectedJSONRequest";
import axios from "axios";
import { checkIfUserStaff } from "../services/AuthService";

export default function flightBooking(
  traceId,
  clientId,
  orderId,
  purchaseIds,
  passengers,
  address,
  email,
  areaCode,
  mobile,
  isHold,
  passthroughObj,
  ptMarkup, paymentType, PaymentGateWayRequest
) {
  const isStaff = checkIfUserStaff();
  const passengerDetails = sanitizePassengers(
    passengers,
    address,
    email,
    areaCode,
    mobile
  );
  const billingAddress = sanitizeAddress(address);
  const headers = {
    "Content-Type": "application/json",
  };
  if (isStaff === true) {
    return axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Booking/CreatePNR/v1/B2B",
        JSON.stringify({
          flightBookingRq: {
            traceId: traceId,
            purchaseIds: purchaseIds,
            isHold: isHold,
            OrderRefID: orderId,
            passengers: passengerDetails,
            address: billingAddress,
            paymentType: paymentType,
            PaymentGateWayRequest: PaymentGateWayRequest,
            ...(Object.keys(passthroughObj).length > 0 && {
              passThroughMarkup: ptMarkup !== "" ? parseFloat(ptMarkup) : 0, passThrough: passthroughObj
            })
          },
          clientId: clientId,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (typeof response.body === "string") {
          return JSON.parse(response.data);
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
        // return error.response.data.detail;
        // return error.response.data;
        if (error.code === "ERR_NETWORK") {
          const networkError = new Error("Network Error");
          networkError.status = 504;
          throw networkError;
        } else {
          return error.response.data;
        }
      });
  } else {
    return axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Booking/CreatePNR/v1",
        JSON.stringify({
          traceId: traceId,
          purchaseIds: purchaseIds,
          isHold: isHold,
          passengers: passengerDetails,
          address: billingAddress,
          OrderRefID: orderId,
          paymentType: paymentType,
          PaymentGateWayRequest: PaymentGateWayRequest,
          ...(Object.keys(passthroughObj).length > 0 && {
            passThroughMarkup: ptMarkup !== "" ? parseFloat(ptMarkup) : 0, passThrough: passthroughObj
          })
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (typeof response.body === "string") {
          return JSON.parse(response.data);
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          const networkError = new Error("Network Error");
          networkError.status = 504;
          throw networkError;
        } else {
          return error.response.data.detail;
        }

      });
  }
}
