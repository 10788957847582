import React, { useEffect, useState } from "react";
import "./HoldRulesDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import { isUserHasPermission } from "../../services/AuthService";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Modal, Container, Row, Col } from 'react-bootstrap';
const style = {
    position: 'absolute',
    top: '80%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 500,
    overflow:'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    marginBottom:'10px',
    padding:'0px 22px'
    
};
export default function HoldRulesDrawer({ processHold, open, close, holdCharge, isHoldChargeRefundable, currencyCode, total }) {
    const [termsConditionsCheck, setTermsConditionsCheck] = useState(false);
    const [isImportBtnDisabled, setisImportBtnDisabled] = useState(true);

    useEffect(() => {
        console.log("termsConditionsCheck", termsConditionsCheck);
        console.log("isImportBtnDisabled", isImportBtnDisabled);

    }, [termsConditionsCheck])
    useEffect(() => {

    }, [])
    return (
        <Modal
            size="md"
            show={open}
            // onHide={() => close()}
            backdrop="static"
            keyboard={false}
            className="hold-rules-modal"
            centered
        >
            <Box sx={style}>
               <div style={{marginBottom:'10px'}}>
               <Typography id="modal-modal-title" variant="h6" component="h2">
                    {/* <div className="ticket-drawer-container"> */}
                    <div className="hold-close-button-container">
                        {/* <CloseIcon
                                className="icon-fill pointer"
                                fontSize="medium"
                                onClick={close}
                            ></CloseIcon> */}
                        <Button
                            variant="outlined"
                            // color="error"
                            size="small"
                            className="hold-close-button"
                            onClick={close}>
                            Close
                        </Button>
                    </div>
                    {/* </div> */}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div>
                        <div style={{marginBottom:'10px'}}>
                            {
                                // holdCharge > 0 &&
                                (
                                    <div className="holdAmountRow">
                                        <div className="col-6" style={{ padding: '10px',borderRight: '1px dotted #000' }}>
                                            <p>Booking Amount Lock Fee:
                                                <br></br>
                                                <span style={{ marginRight: '5px' }}>
                                                    {currencyCode}
                                                </span>
                                                <span style={{marginRight:'5px'}}>
                                                    {holdCharge}
                                                </span>
                                                {
                                                    isHoldChargeRefundable ?
                                                        <span style={{ color: "green",fontSize:'12px' }}>Refundable</span>
                                                        :
                                                        <span style={{ color: "red",fontSize:'12px'  }}>Non refundable</span>
                                                }
                                            </p>
                                        </div>
                                        <div className="col-6" style={{ padding: '10px' }}>
                                            <p>Amount to be Paid Later:
                                                <br></br>
                                                <span style={{ marginRight: '5px' }}>
                                                    {currencyCode}
                                                </span>
                                                <span>
                                                    {(total - holdCharge)}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    // 
                                )
                            }
                            {
                                isHoldChargeRefundable &&
                                (<p>
                                    Note: Advance Flight booking Amount will  be <span className="refundable">refunded </span> to your future credit,
                                    if booking gets cancelled before issuance and if the ticket is booked,
                                    amount will be adjusted in Final fare. Hold PNR will only create booking at Airline end,
                                    but no tickets will be issued until you confirm ticketing. We are not responsible,
                                    if any changes done at Airline end.
                                </p>)
                            }
                            {
                                !isHoldChargeRefundable &&
                                (<p>
                                    Note: Advance Flight booking Amount will be <span className="nonRefundable"> Non- Refunded</span>,
                                    if booking gets cancelled before issuance and if the ticket is booked,amount will be adjusted in Final fare.
                                    Hold PNR will only create booking at Airline end, but no tickets will be issued until you confirm ticketing.
                                    We are not responsible,if any changes done at Airline end.
                                </p>)
                            }
                        </div>
                        <div>
                            <ul>
                                <li>Given fares and flight timings are as of current availability and are subject to change without prior notice.</li>
                                <li>Hold PNR will only create booking at the Airline end, but no tickets will be issued until you confirm ticketing.</li>
                                <li>Quoted fare is not valid until ticketed/Ticket issued.</li>
                                <li style={{ fontWeight: 'bolder' }}>Ancillary  services needs to be booked at the time of confirmation</li>
                                <li>Fares of Un-ticketed bookings are subject to change if any changes are announced by the airline.</li>
                                <li>Failure to issue a ticket before the given Time limit results in auto cancellation of the reservations.</li>
                                <li>In spite of best efforts, there is a possibility that the airline can cancel your PNR.</li>
                            </ul>
                        </div>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={termsConditionsCheck}
                                    onChange={(event, newValue) => {
                                        setTermsConditionsCheck(newValue);
                                        setisImportBtnDisabled(termsConditionsCheck ? false : true);
                                    }} />
                            }
                                label="I Agree With The Above Mentioned Terms and Conditions" />
                        </FormGroup>
                    </div>
                </Typography>
                <Typography>
                    <Button variant="contained"
                        size="medium"
                        color="primary"
                        className="hold-submit-button"
                        disabled={!termsConditionsCheck}
                        onClick={processHold}>
                        Agree
                    </Button>
                </Typography>
               </div>
            </Box>
        </Modal>
    );
}