import axios from 'axios'

export function setAuthHeader(token) {
  axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : ''
}

export function checkHeader(){
  axios.interceptors.request.use(
    function(request){
      if(request.headers['Authorization'] === '')
      {
        console.log('header verified and reset')
        const token = localStorage.getItem('token')
        axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : ''
      }
      console.log(request.headers['Authorization'])
      console.log(request)
      return request;
    },
    function(error){
      return Promise.reject(error)
    }
  )
}