import React, { useState, useEffect } from "react";
import "./Eticket.css";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import getTax from "../../functions/getTax";
import getPaxMarkupTotal from "../../functions/getPaxMarkupTotal";
import { setAuthHeader } from "../../utils/axiosHeaders";
import html2pdf from "html2pdf.js";
import { checkIfUserStaff } from "../../services/AuthService";
import {
  ValidatorForm,
  TextValidator,
} from "react-material-ui-form-validator";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tickets from "./Tickets";
import Link from "@mui/material/Link";
import { decryptData } from "../../providers/dataEncryptDecryptor";
import TicketQRCode from "../../providers/QRTicketDataProvider/QRTicketDataProvider";

const downloadPDF = async (
  downloadRef,
  title,
  formnumber = "",
  margin = 0.25
) => {
  var result = false;
  var pageElement = downloadRef.current;
  // pageElement.classList.add("print-html");
  let opt = {
    margin: [15, 15, 15, 15],
    jsPDF: { format: "A4", orientation: "portrait" },
    // margin: margin,
    // enableLinks: true,
    // filename: title + ".pdf",
    // image: { type: "jpeg", quality: 0.9 },
    // html2canvas: { scale: 1 },
    // jsPDF: { unit: "px", format: "A4", orientation: "portrait" },
    // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    // pagebreak: { mode: ['css'] }
    // pagebreak: { mode: ['css'], after: 'break-page-after' }
    // pagebreak: { after: 'break-page-after' }
  };
  return await html2pdf()
    .from(pageElement)
    .set(opt)
    .toPdf()
    .get("pdf")
    .then(function (pdfObject) {
      if (formnumber !== "") {
        let pdf_pages = pdfObject.internal.pages;
        for (let i = 1; i <= pdf_pages.length; i++) {
          pdfObject.setPage(i);
          pdfObject.setFontSize(10);
          pdfObject.setTextColor(16, 0, 0);
          pdfObject.text(
            formnumber,
            pdfObject.internal.pageSize.getWidth() - 2,
            pdfObject.internal.pageSize.getHeight() - 0.15
          );
        }
      }
      pdfObject.setProperties({
        title: title,
      });
      // pageElement.classList.remove("print-html");
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(
          pdfObject.output("blob"),
          title + ".pdf"
        );
      } else {
        //   if (isMobile && browserName === 'Firefox' && osName === 'iOS') {
        //       pdfObject.output('dataurlnewwindow');
        //   }
        //  else if (isMobile && browserName === 'Chrome' && osName === 'iOS')
        //   {
        //       const file = pdfObject.output("dataurl");
        //       saveAs(file, title + ".pdf");
        //   }
        //   else {
        var objectUrl = pdfObject.output("bloburl");
        window.open(objectUrl, "_blank");
        //}
      }
      result = true;
      return result;
    });
};

function Eticket() {
  const [bookingDetails, setbookingDetails] = useState([]);
  const [clientProfile, setclientProfile] = useState(null);
  const [displayLogo, setdisplayLogo] = useState(null);
  const downloadSucesseRef = React.createRef(HTMLDivElement);
  const isStaff = checkIfUserStaff();
  const images = require.context("../../client_logo", true);
  // const images = require.context('../../assets', true);
  const [orderId, setorderId] = useState("");
  const fareDecision = localStorage.getItem("fareType");
  const [extraBaggageChrgs, setExtraBaggageChrgs] = useState(0);
  const [extraMealChrgs, setExtraMealsCharge] = useState(0);
  const [seatChrgs, setSeatChrgs] = useState(0);
  const [INFMarkup, setINFMarkup] = useState(0);
  const [ChildMarkup, setChildMarkup] = useState(0);
  const [AdultMarkup, setAdultMarkup] = useState(0);
  // const [INFBaseFare, setINFBaseFare] = useState(0);
  // const [ChildBaseFare, setChildBaseFare] = useState(0);
  // const [AdultBaseFare, setAdultBaseFare] = useState(0);
  const [INFTotal, setINFTotal] = useState(0);
  const [ChildTotal, setChildTotal] = useState(0);
  const [AdultTotal, setAdultTotal] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [hover, setHover] = useState(false);
  const [hideMarkup, setHideMarkup] = useState(false);
  let additionalServices_BaggageChrgs = 0;
  let additionalServices_MealChrgs = 0;
  let additionalServices_SeatChrgs = 0;

  useEffect(() => {
    bookingDetails?.flights?.map((flight) => {
      flight?.flightFares?.map((fare) => {
        let taxes = 0;
        let markup = 0;
        let count =
          fare.paxType === "ADT"
            ? flight.adultCount
            : fare.paxType === "CHD"
              ? flight.childCount
              : fare.paxType === "INF"
                ? flight.infantCount
                : flight.adultCount;
        fare.taxes.forEach((item) => {
          taxes = taxes + item.amt;
        });
        let total = taxes + fare.baseFare;
        fare.paxType === "ADT"
          ? setAdultTotal(total * count + markup)
          : fare.paxType === "CHD"
            ? setChildTotal(total * count + markup)
            : fare.paxType === "INF"
              ? setINFTotal(total * count + markup)
              : setAdultTotal(total * count + markup);
      });
    });
    fixCapitalsNode(document.getElementById("importantNote"));
  }, [bookingDetails]);

  function downloadTicket() {
    setHideMarkup(true);
    if (
      bookingDetails !== undefined &&
      bookingDetails.flights !== undefined &&
      document.getElementById("bookingDetailsId") !== null
    ) {
      // setTimeout(() => {
      downloadPDF(downloadSucesseRef, "Eticket").then((res) => {
        setHideMarkup(false);
        // console.log(res);
      });
      // }, 6000);
    }
  }

  function setAdultValues(fare, Count, values) {
    let value = values ? parseInt(values) : 0;
    setAdultMarkup(value);
    // setAdultBaseFare(value+fare.baseFare)
    setAdultTotal(getPaxMarkupTotal(fare, Count, Count * value));
  }
  function setChildValues(fare, Count, values) {
    let value = values ? parseInt(values) : 0;
    setChildMarkup(value);
    // setChildBaseFare(value+fare.baseFare)
    setChildTotal(getPaxMarkupTotal(fare, Count, Count * value));
  }
  function setINFvalues(fare, Count, values) {
    let value = values ? parseInt(values) : 0;
    setINFMarkup(value);
    // setINFBaseFare(value+fare.baseFare)
    setINFTotal(getPaxMarkupTotal(fare, Count, Count * value));
  }
  useEffect(() => { }, [
    AdultMarkup,
    AdultTotal,
    ChildMarkup,
    ChildTotal,
    INFMarkup,
    INFTotal,
  ]);
  useEffect(() => {
    if (orderId) {
      loadClientDetails();
      loadBookingDetails();
    }
  }, [orderId]);
  useEffect(() => {
    let temp = localStorage.getItem("orderId");
    if (temp) {
      setorderId(decryptData(temp));
    }
  }, []);

  async function loadClientDetails() {
    setAuthHeader(localStorage.getItem("token"));
    // if (isStaff === true) {
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/GetClientByGFSId/" +
        orderId
      )
      .then((response) => {
        //console.log(response);
        setclientProfile(response.data);
        loadImage(response.data.id);
      });
    // }
    // else {
    //   await axios
    //     .get(
    //       process.env.REACT_APP_CORE_URL +
    //       "api/Configuration/ClientProfile/GetClientByToken"
    //     )
    //     .then((response) => {
    //       //console.log(response);
    //       setclientProfile(response.data);
    //     });
    // }
  }

  async function loadBookingDetails() {
    setShowLoader(true);
    setAuthHeader(localStorage.getItem("token"));
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/" +
        orderId
      )
      .then((response) => {
        // console.log(response);
        getAddonCal(response.data);
        setTimeout(() => {
          setbookingDetails(response.data);
          setShowLoader(false);
        }, 1000);
      });
  }

  function loadImage(id) {
    // let url = '../../client_logo/' + id + '.png'
    let url = "https://images.getfares.com/client_logo/" + id + ".png";
    var img = new Image();

    img.onload = function () {
      // console.log("client image ")
      setdisplayLogo(`./` + id + `.png`);
    };

    img.onerror = function () {
      // console.log("default image ")
      setdisplayLogo(null);
    };

    img.src = url;
  }

  const getAddonCal = (bookingDetails) => {
    if (
      bookingDetails &&
      bookingDetails.flights &&
      bookingDetails.flights.length > 0
    ) {
      bookingDetails.flights.map(
        (flight) =>
          flight.baggages &&
          flight.baggages.length > 0 &&
          flight.baggages.forEach((item) => {
            additionalServices_BaggageChrgs =
              additionalServices_BaggageChrgs + parseFloat(item.amount);
          })
      );
      bookingDetails.flights.map(
        (flight) =>
          flight.meals &&
          flight.meals.length > 0 &&
          flight.meals.forEach((item) => {
            additionalServices_MealChrgs =
              additionalServices_MealChrgs + parseFloat(item.amount);
          })
      );
      bookingDetails.flights.map(
        (flight) =>
          flight.seats &&
          flight.seats.length > 0 &&
          flight.seats.forEach((item) => {
            additionalServices_SeatChrgs =
              additionalServices_SeatChrgs + parseFloat(item.amount);
          })
      );
    }
    setSeatChrgs(additionalServices_SeatChrgs)
    setExtraBaggageChrgs(additionalServices_BaggageChrgs);
    setExtraMealsCharge(additionalServices_MealChrgs);
  };

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  return (
    <>
      <div className="marginLR15">
        <div
          className="eticket-container"
          align="centre"
          ref={downloadSucesseRef}
        >
          <div className="eticket-header">
            <TableContainer>
              <Table className="eticket-header-table width100">
                <TableBody>
                  {" "}
                  <TableRow>
                    <TableCell className="width60">
                      {clientProfile && (
                        <Table className="clientDetailsTable">
                          <TableBody>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell> {clientProfile.clientName}</TableCell>
                            </TableRow>
                            <TableRow>
                              {" "}
                              <TableCell>Address</TableCell>
                              <TableCell>
                                {clientProfile.address1}
                                {", "}
                                {clientProfile.address2}
                                {", "} {clientProfile.city}
                                {", "}
                                {clientProfile.state}
                                {", "}
                                {clientProfile.countryCode}
                                {"-"}
                                {clientProfile.postalCode}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {" "}
                              <TableCell>Contact</TableCell>
                              <TableCell>{clientProfile.phoneNumber}</TableCell>
                            </TableRow>
                            <TableRow>
                              {" "}
                              <TableCell>Email</TableCell>
                              <TableCell>{clientProfile.email}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </TableCell>
                    <TableCell className="width40">
                      <div className="eticket-logo-container">
                        {displayLogo ? (
                          <img
                            className="br-4 customerLogo"
                            height={50}
                            width={160}
                            alt="client logo"
                            src={images(displayLogo).default}
                          />
                        ) : (
                          //  <img
                          //     className="br-4 customerLogo"
                          //     height={50}
                          //     width={160}
                          //     src={images(`./gf.png`).default}
                          //   ></img>
                          ""
                        )}
                        {
                          // clientProfile && clientProfile?.id === 21335 ?
                          //   <img
                          //     className="br-4 customerLogo"
                          //     height={50}
                          //     width={160}
                          //     src={images(`./21335.png`).default}
                          //   ></img>
                          //   :
                          //   <img
                          //     className="br-4 customerLogo"
                          //     height={50}
                          //     width={160}
                          //     // src={images(`./getfares.png`).default}
                          //     src={images(`./gf.png`).default}
                          //   ></img>
                        }
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {showLoader && (
            <div style={{ position: "absolute", left: "45%", top: "50%" }}>
              <CircularProgress />
            </div>
          )}
          <div className="eticket-body">
            <div className="eticket-passenger-details">
              {bookingDetails !== undefined &&
                bookingDetails.flights !== undefined &&
                bookingDetails.flights.length > 0 ? (
                bookingDetails.flights.map((flight) => (
                  <>
                    <TableContainer>
                      {" "}
                      <Table className="itenary-passenger-table eticket-header-table">
                        <TableBody>
                          <TableRow className="eticket-passenger-table-header">
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                              Date of Booking:
                              <span className="bold pl-5 fs16 ">
                                {moment(bookingDetails.createdOn).format(
                                  "Do MMM  YYYY"
                                )}
                              </span>
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                              Booking Status:
                              <span
                                className={`bold pl-5 fs16 itenary-confirmtaion-tag eTicket${flight.currentStatus}`}
                              >
                                {flight.currentStatus}
                              </span>
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                              Booking Reference:{" "}
                              <span className="bold pl-5 fs16 ">
                                {bookingDetails.orderRefId}
                              </span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className="eticketFlightSection">
                      <div className="itenary-container eticket-itenary-container booking-padding-top-40 eticket-booking-padding-top-40">
                        <div>Flight Details</div>
                      </div>
                      <Tickets
                        result={flight}
                        baggages={flight.baggages}
                        segGroups={flight.segGroups}
                        source={bookingDetails.bookingSource}
                      ></Tickets>
                    </div>

                    <div
                      id="bookingDetailsId"
                      className="eticket-itenary-passenger-details"
                    >
                      {/* <div className="html2pdf__page-break"></div> */}
                      <div className="itenary-container eticket-itenary-container booking-padding-top-40 eticket-booking-padding-top-40">
                        <div>Passenger Details</div>
                      </div>

                      <div className="eticket-itenary-passenger-details">
                        <Table className="itenary-passenger-table">
                          <TableHead>
                            <TableRow className="eticket-passenger-table-header">
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
                              >
                                Code
                              </TableCell>
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                              >
                                Passenger(s)
                              </TableCell>
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                              >
                                Ticket No.
                              </TableCell>
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight tenary-passenger-table-header-item-final"
                              >
                                Paid Seat
                              </TableCell>
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight tenary-passenger-table-header-item-final"
                              >
                                Paid Baggage
                              </TableCell>
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                              >
                                Paid Meals
                              </TableCell>

                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight itenary-passenger-table-header-item-final"
                              >
                                Priority Check-IN / Sports Equipment
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {flight.passengers !== undefined &&
                            flight.passengers.length > 0 ? (
                            flight.passengers
                              .sort(function (a, b) {
                                if (a.paxType < b.paxType) {
                                  return -1;
                                }
                                if (a.paxType > b.paxType) {
                                  return 1;
                                }
                                return 0;
                              })
                              .map((passenger, index) => (
                                <TableBody>
                                  <TableRow className="eticket-passenger-table-item">
                                    <TableCell
                                      align="left"
                                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                    >
                                      <TicketQRCode passenger={passenger}
                                        bookingDetails={flight} />
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                    >
                                      {passenger.title} {passenger.firstName}{" "}
                                      {passenger.lastName}
                                    </TableCell>

                                    {passenger.ticketNumber ? (
                                      <TableCell
                                        align="left"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        {passenger.ticketNumber}
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        -
                                      </TableCell>
                                    )}

                                    {(!flight?.seats || flight?.seats.length === 0) ? (
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        -
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        {flight?.seats[index]?.seatNo || '-'}
                                      </TableCell>
                                    )}
                                    <TableCell
                                      align="left"
                                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                    >
                                      {flight.baggages?.filter(
                                        (item) =>
                                          item.paxName ===
                                          passenger.firstName +
                                          " " +
                                          passenger.lastName
                                      ) &&
                                        flight.baggages?.filter(
                                          (item) =>
                                            item?.paxName ===
                                            passenger.firstName +
                                            " " +
                                            passenger.lastName
                                        ).length > 1 ? (
                                        <>
                                          {flight.baggages
                                            ?.filter(
                                              (item) =>
                                                item.paxName ===
                                                passenger.firstName +
                                                " " +
                                                passenger.lastName
                                            )
                                            .map((bag, index) => (
                                              <>
                                                {bag.checkInBag}{" "}
                                                {flight.currency} {"  "}{" "}
                                                {bag.amount}
                                                {<br></br>}
                                                {flight.baggages?.filter(
                                                  (item) =>
                                                    item.paxName ===
                                                    passenger.firstName +
                                                    " " +
                                                    passenger.lastName
                                                ).length -
                                                  1 <
                                                  index
                                                  ? "\n"
                                                  : ""}{" "}
                                              </>
                                            ))}
                                        </>
                                      ) : (
                                        <>
                                          {flight.baggages
                                            ?.filter(
                                              (item) =>
                                                item.paxName ===
                                                passenger.firstName +
                                                " " +
                                                passenger.lastName
                                            )
                                            .map(
                                              (bag) =>
                                                `${bag.checkInBag} +( ${flight.currency} ${bag.amount})`
                                            )}
                                        </>
                                      )}
                                    </TableCell>

                                    {flight.meals?.filter(
                                      (item) =>
                                        item.paxName ===
                                        passenger.firstName +
                                        " " +
                                        passenger.lastName
                                    ).length > 0 ? (
                                      <TableCell align="center">
                                        {flight.meals?.filter(
                                          (item) =>
                                            item?.paxName ===
                                            passenger.firstName +
                                            " " +
                                            passenger.lastName
                                        ).length > 0 &&
                                          flight.meals
                                            ?.filter(
                                              (item) =>
                                                item?.paxName ===
                                                passenger.firstName +
                                                " " +
                                                passenger.lastName
                                            )
                                            .map((meal, index) => (
                                              <>
                                                {meal.description}{" "}
                                                {flight.currency} {"  "}{" "}
                                                {meal.amount}
                                                {<br></br>}
                                                {flight.meals?.filter(
                                                  (item) =>
                                                    item.paxName ===
                                                    passenger.firstName +
                                                    " " +
                                                    passenger.lastName
                                                ).length -
                                                  1 <
                                                  index
                                                  ? "\n"
                                                  : ""}{" "}
                                              </>
                                            ))}
                                      </TableCell>
                                    ) : (
                                      <TableCell align="center">
                                        -
                                      </TableCell>
                                    )}


                                    {passenger.spEQ ? (
                                      <TableCell
                                        align="left"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        {passenger.spEQ}
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        -
                                      </TableCell>
                                    )}
                                  </TableRow>
                                </TableBody>
                              ))
                          ) : (
                            <TableRow></TableRow>
                          )}
                        </Table>
                      </div>
                      {fareDecision === 'true' ? (
                        <>
                          <div className="itenary-container eticket-itenary-container booking-padding-top-40 eticket-booking-padding-top-40">
                            <div>Fare Details</div>
                          </div>
                          <div className="eticket-itenary-passenger-details eticket-passenger-details">
                            <Table className="itenary-passenger-table">
                              <TableHead>
                                <TableRow className="eticket-passenger-table-header">
                                  <TableCell
                                    align="center"
                                    className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
                                  >
                                    Pax
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                                  >
                                    Base Fare
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                                  >
                                    Tax
                                  </TableCell>
                                  {/* <TableCell
                                    align="center"
                                    className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                                  >
                                    SSR
                                  </TableCell> */}

                                  {hideMarkup ? (
                                    ""
                                  ) : (
                                    <TableCell
                                      align="center"
                                      className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                                    >
                                      Markup
                                    </TableCell>
                                  )}

                                  <TableCell
                                    align="center"
                                    className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight itenary-passenger-table-header-item-final"
                                  >
                                    Total
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {flight.flightFares !== undefined &&
                                  flight.flightFares.length > 0 ? (
                                  flight.flightFares
                                    .sort(function (a, b) {
                                      if (a.paxType < b.paxType) {
                                        return -1;
                                      }
                                      if (a.paxType > b.paxType) {
                                        return 1;
                                      }
                                      return 0;
                                    })
                                    .map((fare) => (
                                      <TableRow className="eticket-passenger-table-item">
                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                        >
                                          {fare.paxType === "ADT"
                                            ? "Adult"
                                            : fare.paxType === "CHD"
                                              ? "Child"
                                              : fare.paxType === "INF"
                                                ? "Infant"
                                                : "Adult"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                        >
                                          {fare.paxType === "ADT"
                                            ? flight.adultCount +
                                            " x " +
                                            (fare.baseFare + AdultMarkup)
                                            : fare.paxType === "CHD"
                                              ? flight.childCount +
                                              " x " +
                                              (fare.baseFare + ChildMarkup)
                                              : fare.paxType === "INF"
                                                ? flight.infantCount +
                                                " x " +
                                                (fare.baseFare + INFMarkup)
                                                : "0"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                        >
                                          {fare.paxType === "ADT"
                                            ? flight.adultCount +
                                            " x " +
                                            getTax(fare).toFixed(2)
                                            : fare.paxType === "CHD"
                                              ? flight.childCount +
                                              " x " +
                                              getTax(fare).toFixed(2)
                                              : fare.paxType === "INF"
                                                ? flight.infantCount +
                                                " x " +
                                                getTax(fare).toFixed(2)
                                                : "0"}
                                        </TableCell>
                                        {/* <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                        >
                                          {fare.paxType === "ADT"
                                            ? flight.adultCount + " x 0"
                                            : fare.paxType === "CHD"
                                            ? flight.childCount + " x 0"
                                            : fare.paxType === "INF"
                                            ? flight.infantCount + " x 0  "
                                            : "0"}
                                        </TableCell> */}

                                        {hideMarkup ? (
                                          ""
                                        ) : (
                                          <TableCell
                                            align="center"
                                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                          >
                                            <ValidatorForm
                                              onError={(errors) =>
                                                console.log(errors)
                                              }
                                              onSubmit={() => {
                                                "";
                                              }}
                                            >
                                              <TextValidator
                                                variant="outlined"
                                                size="small"
                                                style={{ minWidth: " 90px" }}
                                                color="secondary"
                                                value={
                                                  fare.paxType === "ADT"
                                                    ? AdultMarkup
                                                    : fare.paxType === "CHD"
                                                      ? ChildMarkup
                                                      : INFMarkup
                                                }
                                                onChange={(event) => {
                                                  fare.paxType === "ADT"
                                                    ? setAdultValues(
                                                      fare,
                                                      flight.adultCount,
                                                      event.target.value
                                                    )
                                                    : fare.paxType === "CHD"
                                                      ? setChildValues(
                                                        fare,
                                                        flight.childCount,
                                                        event.target.value
                                                      )
                                                      : setINFvalues(
                                                        fare,
                                                        flight.infantCount,
                                                        event.target.value
                                                      );
                                                }}
                                                validators={[
                                                  "required",
                                                  "matchRegexp:^[0-9\b]+$",
                                                ]}
                                                errorMessages={[
                                                  "required",
                                                  "Only number are allowed",
                                                ]}
                                              />
                                            </ValidatorForm>
                                          </TableCell>
                                        )}

                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                        >
                                          <b>
                                            {" "}
                                            {fare.paxType === "ADT"
                                              ? AdultTotal.toFixed(2)
                                              : fare.paxType === "CHD"
                                                ? ChildTotal.toFixed(2)
                                                : fare.paxType === "INF"
                                                  ? INFTotal.toFixed(2)
                                                  : 0}
                                          </b>
                                          {/* {fare.paxType === "ADT" ? (getPaxTotal(fare, flight.adultCount).toFixed(2)) :
                                        fare.paxType === "CHD" ? (getPaxTotal(fare, flight.childCount).toFixed(2)) :
                                          fare.paxType === "INF" ? (getPaxTotal(fare, flight.infantCount).toFixed(2)) : 0} */}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                ) : (
                                  <TableRow></TableRow>
                                )}
                                {extraBaggageChrgs > 0 || extraMealChrgs > 0 || seatChrgs > 0 ? (
                                  <>


                                    <TableRow className="itenary-passenger-table-item">
                                      {!hideMarkup && (
                                        <>
                                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-baggage-price-txt"></TableCell>
                                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-baggage-price-txt"></TableCell>
                                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-baggage-price-txt"></TableCell>
                                          <TableCell
                                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-baggage-price-label"
                                            align="center"
                                          >
                                            Additional Service Charges
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-baggage-price-txt"
                                          >
                                            {flight.currency} {((extraBaggageChrgs || 0) + (extraMealChrgs || 0) + (seatChrgs || 0)).toFixed(2)}
                                          </TableCell>
                                        </>
                                      )}
                                    </TableRow>




                                    <TableRow className="itenary-passenger-table-item">
                                      {!hideMarkup && (
                                        <>
                                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-total-price-txt"></TableCell>
                                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-total-price-txt"></TableCell>
                                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-total-price-txt"></TableCell>
                                          <TableCell
                                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-total-price-label"
                                            align="center"
                                          >
                                            <b>Total</b>
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-total-price-txt"
                                          >
                                            <b>
                                              {flight.currency} {(
                                                AdultTotal +
                                                ChildTotal +
                                                INFTotal +
                                                extraBaggageChrgs +
                                                extraMealChrgs +
                                                seatChrgs
                                              ).toFixed(2)}
                                            </b>
                                          </TableCell>
                                        </>
                                      )}
                                    </TableRow>
                                  </>
                                ) : null}

                              </TableBody>
                            </Table>
                          </div>
                          {/* <div className="fare-item-total fare-item-total-padding">Total : {flight.currency} {" "}{getTotalFareBooking(flight.flightFares, flight.adultCount, flight.childCount, flight.infantCount).toFixed(2)}</div> */}
                        </>
                      ) : (
                        ""
                      )}
                      {/* </div>
                    <div
                      id="bookingDetailsId"
                      className="eticket-itenary-passenger-details"
                    > */}
                      {/* <div className="html2pdf__page-break"></div> */}
                      <div className="baggage-details">
                        <p id="importantNote" className="importantNote fs10">
                          <span>
                            <b>Important Note: </b>{" "}
                            {"Refund/date change penalties upto 100% may apply.".toLowerCase()}
                          </span>
                          <br></br> <br></br>
                          <span>
                            <b>Note: </b>
                            {"We have passed the Names to the Airline end as per the Airline Guidelines.".toLowerCase()}
                          </span>
                          <br></br> <br></br>
                          <span>
                            <b>Notice: </b>
                            <br></br> <br></br>
                            <b>DANGEROUS GOODS (HAZARDOUS MATERIALS): </b>
                            {"THE CARRIAGE OF CERTAIN HAZARDOUS MATERIALS, LIKE AEROSOLS, FIREWORKS, AND FLAMMABLE LIQUIDS, ABOARD THE AIRCRAFT IS FORBIDDEN.IF YOU DO NOT UNDERSTAND THESE RESTRICTIONS, FURTHER INFORMATION MAY BE OBTAINED FROM YOUR AIRLINE.".toLowerCase()}
                            <br></br> <br></br>
                            <b>DATA PROTECTION NOTICE: </b>
                            {"YOUR PERSONAL DATA WILL BE PROCESSED IN ACCORDANCE WITH THE APPLICABLE CARRIER'S PRIVACY POLICY AND, IF YOUR BOOKING IS MADE VIA A RESERVATION SYSTEM PROVIDER (GDS), WITHITS PRIVACY POLICY.".toLowerCase()}
                            {"THESE ARE AVAILABLE AT".toLowerCase()}
                            <Link href="HTTP://WWW.IATATRAVELCENTER.COM/PRIVACY">
                              {" HTTP://WWW.IATATRAVELCENTER.COM/PRIVACY ".toLowerCase()}
                            </Link>
                            {"OR FROM THE CARRIER OR GDS DIRECTLY.".toLowerCase()}
                            {"YOU SHOULD READ THIS DOCUMENTATION, WHICH APPLIES TO YOUR BOOKING AND SPECIFIES, FOR EXAMPLE, HOW YOUR PERSONAL DATA IS COLLECTED, STORED, USED, DISCLOSED AND TRANSFERRED.".toLowerCase()}
                            <br></br> <br></br>
                            <b>
                              {"ADVICE TO INTERNATIONAL PASSENGERS ON LIMITATIONS OF LIABILITY: ".toLowerCase()}
                            </b>
                            {"PASSENGERS EMBARKING UPON A JOURNEY INVOLVING AN ULTIMATE DESTINATION OR A STOP IN A COUNTRY OTHER THAN THE COUNTRY OF DEPARTURE ARE ADVISED THAT THE PROVISIONS OF AN INTERNATIONAL TREATY (THE WARSAW CONVENTION, THE 1999 MONTREAL CONVENTION, OR OTHER  TREATY),".toLowerCase()}
                            {"AS WELL AS A CARRIER'S OWN CONTRACT OF CARRIAGE OR TARIFF PROVISIONS, MAY BE APPLICABLE TO THEIR ENTIRE JOURNEY, INCLUDING ANY PORTION ENTIRELY WITHIN THE COUNTRIES OF DEPARTURE AND DESTINATION.".toLowerCase()}
                            {"THE APPLICABLE TREATY GOVERNS AND MAY LIMIT THE LIABILITY OF CARRIERS TO PASSENGERS FOR DEATH OR PERSONAL INJURY, DESTRUCTION OR LOSS OF, OR DAMAGE TO, BAGGAGE, AND FOR DELAY OF PASSENGERS AND BAGGAGE. ADDITIONAL PROTECTION CAN USUALLY BE OBTAINED BY PURCHASING INSURANCE FROM A PRIVATE COMPANY.".toLowerCase()}
                            {"SUCH INSURANCE IS NOT AFFECTED BY ANY LIMITATION OF THE CARRIER'S LIABILITY UNDER AN INTERNATIONAL TREATY. FOR FURTHER INFORMATION PLEASE  CONSULT YOUR AIRLINE OR INSURANCE COMPANY  REPRESENTATIVE.".toLowerCase()}
                            <br></br> <br></br>
                            {"CARRIER RESERVES THE RIGHT TO REFUSE CARRIAGE TO ANY PERSON WHO HAS ACQUIRED A TICKET IN VIOLATION OF APPLICABLE LAW OR CARRIER'S TARIFFS, RULES OR REGULATIONS SUBJECT TO TARIFF REGULATIONS.".toLowerCase()}
                          </span>
                        </p>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div className="eticket-itenary-passenger-details"></div>
              )}
            </div>

            {/* <div className="itenary-container eticket-itenary-container booking-padding-top-64">
          <div>Contact Details</div>
        </div>
        <div className="contact-details">
          <div className="contact-details-container eticket-contact-details-container">
            <div className="contact-detail-flex">
              <div className="contact-detail-item">
                <div className="contact-detail-icon contact-detail-opacity">
                  <LocationOnRoundedIcon fontSize="small"></LocationOnRoundedIcon>
                </div>
                <div className="contact-detail-text">MUMBAI MH</div>
              </div>
            </div>
            <div className="contact-detail-flex">
              <div className="contact-detail-item">
                <div className="contact-detail-icon contact-detail-opacity">
                  <LabelIcon fontSize="small"></LabelIcon>
                </div>
                <div className="contact-detail-text">www.GetFares.com</div>
              </div>
            </div>
            <div className="contact-detail-flex">
              <div className="contact-detail-item">
                <div className="contact-detail-icon contact-detail-opacity">
                  <AlternateEmailIcon fontSize="small"></AlternateEmailIcon>
                </div>
                <div className="contact-detail-text">
                  info@akbaroffshore.com
                </div>
              </div>
            </div>
            <div className="contact-detail-flex">
              <div className="contact-detail-item">
                <div className="contact-detail-icon contact-detail-opacity">
                  <PhoneIcon fontSize="small"></PhoneIcon>
                </div>
                <div className="contact-detail-text min-width-101">
                  +91 22 40003434
                </div>
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>{" "}
      </div>
      <div
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        className="pdficonContainer"
        onClick={downloadTicket}
      >
        <PictureAsPdfIcon className=" pdficon" />
      </div>
      {hover ? (
        <div className="hoverTextContainer">
          <p className="hoverText">click to Download</p>
        </div>
      ) : (
        ""
      )}
    </>
  );
  function fixCapitalsText(text) {
    let result = "";

    let sentenceStart = true;
    for (var i = 0; i < text.length; i++) {
      var ch = text.charAt(i);

      if (sentenceStart && ch.match(/^\S$/)) {
        ch = ch.toUpperCase();
        sentenceStart = false;
      } else {
        ch = ch.toLowerCase();
      }

      if (ch.match(/^[.!?]$/)) {
        sentenceStart = true;
      }

      result += ch;
    }

    return result;
  }

  function fixCapitalsNode(node) {
    if (node) {
      if (node.nodeType === 3 || node.nodeType === 4) {
        // Text or CDATA
        node.textContent = fixCapitalsText(node.textContent);
      }

      if (node.nodeType === 1) {
        for (var i = 0; i < node.childNodes.length; i++)
          fixCapitalsNode(node.childNodes.item(i));
      }
    }
  }
}
export default Eticket;
