import React, { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { Button } from "@material-ui/core";
import "./PricingOptions.css"

const PricingOptions = ({ setShowPriceOptions }) => {
    const [selectedOptions, setSelectedOptions] = useState({
        publishFares: true,
        accountFares: false,
        privateFares: false,
        vfrFares: false,
        brandedFares: false,
    });

    const [code, setCode] = useState("");

    const handleCheckboxChange = (e) => {
        const { name } = e.target;
        setSelectedOptions({
            publishFares: false,
            accountFares: false,
            privateFares: false,
            vfrFares: false,
            brandedFares: false,
            [name]: true,
        });
    };

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };

    const handlePriceButtonClick = () => {
        console.log("Selected Options:", selectedOptions);
        console.log("Code:", code);
    };

    return (
        <div className="pricing-options-container" >
            <div className="d-flex justify-content-between align-items-center rounded po-header">
                <div>Select the Price option to get requested fares: </div>
                <div><CloseIcon style={{ cursor: "pointer" }} onClick={() => setShowPriceOptions(false)} /></div>
            </div>

            <div className="d-flex mt-3">
                <div style={{ width: "300px" }} className="d-flex flex-column">
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="publishFares"
                                checked={selectedOptions.publishFares}
                                onChange={handleCheckboxChange}
                                color="primary"
                                className="rounded"
                            />
                        }
                        label={"Publish Fares"}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="vfrFares"
                                checked={selectedOptions.vfrFares}
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                        }
                        label="VFR Fares"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="brandedFares"
                                checked={selectedOptions.brandedFares}
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                        }
                        label="Branded Fares"
                    />
                </div>
                <div style={{ width: "300px" }} className="d-flex flex-column">
                    <div className="d-flex">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="accountFares"
                                    checked={selectedOptions.accountFares}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                />
                            }
                            label="Account Fares"
                        />
                        {selectedOptions.accountFares && < TextField
                            style={{ width: "150px" }}
                            variant="outlined"
                            label="Add Code"
                            className="bg-light rounded"
                            size="small"
                            value={code}
                            onChange={handleCodeChange}
                        />}
                    </div>
                    <div className="d-flex">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="privateFares"
                                    checked={selectedOptions.privateFares}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                />
                            }
                            label="Private Fares"
                        />
                        {selectedOptions.privateFares && <TextField
                            style={{ width: "150px" }}
                            variant="outlined"
                            label="Add Code"
                            className="bg-light rounded"
                            size="small"
                            value={code}
                            onChange={handleCodeChange}
                        />}
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <Button
                    className="srn-btn"
                    onClick={handlePriceButtonClick}
                >
                    Price
                </Button>
            </div>

        </div>
    );
};

export default PricingOptions;
