import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./PassthroughConfiguration.css";
import PassthroughConfigurationTable from "./PassthroughConfigurationTable";
import axios from "axios";
import { NotificationContainer } from "react-notifications";
import Notify from "../../Notifications/Notifications";
import PassthroughConfigurationModal from "./PassthroughConfigurationModal";
import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../services/AuthService";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Button } from "@mui/joy";
import AddIcon from '@mui/icons-material/Add';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PassthroughConfiguration = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [passthroughConfiguration, setPassthroughConfiguration] = useState([]);
    const [pccList, setPccList] = useState([]);
    const [modal, setModal] = useState({ status: false, action: "", data: "" });
    const history = useHistory();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [popUpMsg, setPopUpMsg] = useState(false)

    useEffect(() => {
        // if (isUserHasPermission("PassthroughConfigurationRead")) {
        loadPassthroughConfiguration();
        loadPCCDetails();
        // } else {
        //     history.push("/Unauthorized");
        // }
    }, []);

    // used to repload components
    const reload = () => {
        loadPassthroughConfiguration();
        loadPCCDetails();
    };

    const handleSnackbarToggle = () => {
        setSnackbarOpen(true);
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const loadPassthroughConfiguration = async () => {
        try {
            setShowLoader(true);
            let result = await axios.get(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/PassThroughConfiguration"
            );
            setShowLoader(false);
            setPassthroughConfiguration(result.data);
        } catch (error) {
            setShowLoader(false);
            Notify(
                "error",
                "Unable to fetch Passthrough configuration",
                "Passthrough Configuration"
            );
        }
    };


    const loadPCCDetails = async () => {
        let vendorArray = [];
        let tmpArray = [];
        try {
            setShowLoader(true);

            let result = await axios.get(
                process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor"
            );
            for (var i = 0; i < result.data.length; i++) {
                vendorArray.push({
                    id: result.data[i].bookingSwitches.id,
                    name: result.data[i].bookingSwitches.vendorName,
                });
            }

            let subResult = await axios.get(
                process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig"
            );
            for (var i = 0; i < subResult.data.length; i++) {
                if (
                    vendorArray.some(
                        (item) => item.id === subResult.data[i].pccConfigs.vendorId
                    )
                ) {
                    tmpArray.push({
                        value: subResult.data[i].pccConfigs.pccCode,
                        label:
                            subResult.data[i].pccConfigs.pccCode +
                            " (" +
                            vendorArray.find(
                                (st) => st.id === subResult.data[i].pccConfigs.vendorId
                            ).name +
                            ")",
                    });
                } else {
                    tmpArray.push({
                        value: subResult.data[i].pccConfigs.pccCode,
                        label: subResult.data[i].pccConfigs.pccCode,
                    });
                }
            }
            setShowLoader(false);
            setPccList(tmpArray);
        } catch (error) {
            setShowLoader(false);
            Notify(
                "error",
                "Unable to fetch pcc configuration",
                "Passthrough Configuration"
            );
        }
    };

    const handleAddPassthroughConfig = () => {
        setModal({ status: true, action: "add", data: "" });
    };

    return (
        <>
            <div className="passthroughConfiguration-container">
                {passthroughConfiguration &&
                    passthroughConfiguration.length === 0 &&
                    pccList.length === 0 ? (
                    <></>
                ) : (
                    <>
                        <div className="row passthrough-row">
                            <div className="col-sm-12 passthrough-header">
                                <div className="passthrough-header-text ms-3">
                                    Pass Through Configuration
                                </div>
                                {/* {isUserHasPermission("PassthroughConfigurationWrite") ? ( */}
                                <Button
                                    className="btn add-rules-config me-3"
                                    onClick={() => handleAddPassthroughConfig()}
                                    startDecorator={<AddIcon />}
                                >
                                    Add Rules
                                </Button>
                                {/* ) : (
                                    ""
                                )} */}
                            </div>
                        </div>
                        <div className="row passthrough-row mt-3">
                            <PassthroughConfigurationTable
                                passthroughConfiguration={passthroughConfiguration}
                                setModal={setModal}
                            />
                        </div>
                        <PassthroughConfigurationModal
                            pccList={pccList}
                            status={modal.status}
                            action={modal.action}
                            data={modal.data}
                            modal={modal}
                            setModal={setModal}
                            setShowLoader={setShowLoader}
                            showLoader={showLoader}
                            reload={reload}
                            setPopUpMsg={setPopUpMsg}
                            handleSnackbarToggle={handleSnackbarToggle}
                        />
                    </>
                )}
            </div>
            {/* <RiseLoading isLoading={ showLoader } /> */}
            <NotificationContainer />
            <Snackbar
                style={{ zIndex: "100000" }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackBarClose}
            >
                <Alert onClose={handleSnackBarClose} severity={popUpMsg ? "success" : "warning"}>
                    {popUpMsg ? `Configuration Updated` : `Warning! Something went wrong`}
                </Alert>
            </Snackbar>
        </>
    );
}

export default PassthroughConfiguration