import { Table } from "@mui/joy";

export default function ComissionAndTaxDetails({ flight, clientName, clientEmail }) {
    return (
        <div className="flight-itenary">
            <div className="flight-itenary-header">
                <div className="flight-itenary-title-container">
                    <div>
                        Comission And Tax Details
                    </div>
                </div>
            </div>
            {/* <Table> */}
            <div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>VendorTaxIATA:</span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorTaxIATA}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>VendorTaxPLB: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorTaxPLB}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>VendorTaxType: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorTaxType}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>TDS: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.tds}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>IsTDSApplicable: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.isTDSApplicable}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>ServiceTax: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTax}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>ServiceTaxName: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTaxName}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>ServiceTaxType: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTaxType}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>ServiceTaxApplicableOn: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTaxApplicable}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>CaseId: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.caseId}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>MCO: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.mco}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>MCO Parted: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.mcoParted}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>MCO Charge: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.pgCharge}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>MCO Remark: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.mcoRemark}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>PG Charge: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.pgCharge}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>Corp/Retail: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight.isCorporate ? "Corporate" : "Retail"}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>Client Name: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {clientName}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>Client Email: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {clientEmail}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>Sales Representative: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.salesStaff ? flight?.salesStaff : '-'}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>Source Of Booking: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {
                                (flight?.info && flight?.info?.match(/%%%([^%]+)%%%/)?.[1])
                                    ? (flight?.info?.match(/%%%([^%]+)%%%/)?.[1])
                                    : '-'
                            }
                        </span>
                    </div>
                </div>
            </div>
            {/* </Table> */}
        </div>
    );
}