import React, { useContext, useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "./searchResults.css";
import SearchContext from "../../contexts/Searchbar/SearchContext";
import SearchBarModal from "./Modals/SearchBarModal";
import ModifySearchBar from "../ModifySearchBar/ModifySearchBar";
import { NotificationContainer } from "react-notifications";
import RiseLoading from "../Loader/RiseLoader";
import { Container, Row, Col } from "react-bootstrap";
import getSearchResults from "../../functions/getSearchResults";
import checkStopsFilter from "../../functions/checkStopsFilter";
import checkAirlinesFilter from "../../functions/checkAirlinesFilter";
import checkConnectingInFilter from "../../functions/checkConnectingInFilter";
import checkArrivalDepartFilter from "../../functions/checkArrivalDepartFilter";
import getNumberOfStopsFilter from "../../functions/getNumberOfStopsFilter";
import getFareTypeFilter from "../../functions/getFareTypeFilter";
import getAirlinesFilter from "../../functions/getAirlinesFilter";
import getPricesFilter from "../../functions/getPricesFilter";
import getConnectingInFilter from "../../functions/getConnectingInFilter";
import getArrivalDepartFilters from "../../functions/getArrivalDepartFilters";
import { checkFareTypeFilter, checkPricesFilter } from "./searchUtils";
import ItineraryDetails from "./SearchComponents/ItineraryDetails";
import Filters from "./Filters/Filters";
import findAirlinebyCode from "../../functions/findAirlineByCode";
import filterRecommended from "../../functions/filterRecommended";
import getLayoverTime from "../../functions/getLayoverTime";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import countries from "../../data/Country";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SortComponent from "./SortComponents/SortComponent";
// import TagManager from "react-gtm-module";
import SearchResponseContext from "../../contexts/Searchbar/SearchResponseContext";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router-dom";
import PrevNextComponent from "./PrevNextComponent";


const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function NewSearchResultsPage() {
  const [search, setSearch] = useContext(SearchContext);
  const [showLoader, setShowLoader] = useState(false);
  const [toggleFlightDetailsIcon, setToggleFlightDetailsIcon] = useState("");
  const [activeSearch, setActiveSearch] = useState(null);
  const [searchError, setSearchError] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [traceId, setTraceId] = useState("");
  const [clientId, setClientId] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [currencyCode, setCurrencyCode] = useState("INR");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("price");
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [custCountry, setCustCountry] = useState("");
  const classes = useStyles();
  const [searchData, setSearchData] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [contextFlights, setContextFlights] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [itineraryMarkup, setItineraryMarkup] = useState(0);
  const [markup, setMarkup] = useState();
  const [fsData, setFSData] = useState([]);
  const [showNetfare, setShowNetFare] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [pasteSearchEnabled, setPasteSearchEnabled] = useState(
    false
  )
  const [selectedTripType, setSelectedTripType] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const fetchSearchResults = async (searchContent) => {

    setSearchData(searchContent);
    setClientId(searchContent.clientId);
    if (searchContent) {
      if (
        searchContent.from.country === "Nepal" &&
        searchContent.to.country === "Nepal" &&
        searchContent.infant > 0
      ) {
        setSnackbarOpen(true);
      } else if (
        searchContent.from.country === "Nepal" &&
        searchContent.to.country === "Nepal" &&
        searchContent.infant === 0
      ) {
        setOpen(true);
      } else {
        setCustCountry("");
        let temp = searchContent;
        temp.nationality = "";

        getResults(temp);
      }
    }
  };


  const searchWithINFZero = () => {
    let temp = searchData;
    temp.infant = 0;
    setSearchData(temp);
    setSnackbarOpen(false);
    setOpen(true);
  };
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const handleGetResults = async () => {
    setOpen(false);

    await getResults(searchData);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function getCurrentURL() {
    return window.location.href;
  }

  const pageSize = useRef(40);

  const loadNextItems = () => {
    if (searchResults.length === 0 || isLoading) {
      return;
    }
    setIsLoading(true);
    if (page > 0) {
      setScrollPosition(window.scrollY);
    }


    const nextPage = page + 1;
    const startIndex = (nextPage - 1) * pageSize.current;
    const endIndex = nextPage * pageSize.current;
    let oldSearchResponse = searchResults;

    const sortedResponse = stableSort(
      oldSearchResponse,
      getComparator(order || "asc", orderBy || "price")
    );
    const nextPageFlights = sortedResponse.slice(startIndex, endIndex);

    if (nextPageFlights.length > 0) {
      if (page === 0) {
        setContextFlights(nextPageFlights);
      } else {
        setContextFlights((prevState) => [...prevState, ...nextPageFlights]);
      }
      setPage(nextPage);
    } else {
      setHasMore(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (page > 0) {
      window.scrollTo(0, scrollPosition);
    } else {
      window.scrollTo(0, 0);
    }
  }, [contextFlights]);



  const getResults = async (searchContent) => {

    try {
      setShowLoader(true);
      setSearchResults((prevState) => ([...[]]));
      setContextFlights((prevState) => ([...[]]));
      let flightResponse = await getSearchResults(searchContent);
      debugger;
      if (
        flightResponse === undefined ||
        flightResponse === "Error Doing Search Contact Admin" ||
        flightResponse.flights.length === 0
      ) {
        setShowLoader(false);
        setSearchError(true);
        setSearchData("");
        setCustCountry("");
      } else {
        setSelectedTripType(searchContent.type);
        localStorage.setItem("selectedTripType", searchContent.type);
        setFSData(flightResponse);
        setTraceId(flightResponse.traceId);
        setClientId(searchContent.clientId);
        if (search.pasteSearchEnabled) {
          setPasteSearchEnabled((prevState) => true)
        } else {
          setPasteSearchEnabled((prevState) => false)
        }
        let tempArray = highlightFlightNumMatch(flightResponse.flights, search);
        setSearchResults((prevResults) => ([...tempArray]));
        await setAvailableFilters(flightResponse.flights, searchContent);
        let result = flightResponse.flights.filter((item) =>
          searchInFilters(item, activeSearch)
        );
        setFilteredResults(prevState => ([...result]));
        setShowLoader(false);
        setCurrencyCode(flightResponse.flights[0]?.currency);
        setSearchData("");
        setCustCountry("");
      }
    } catch (err) {

      setShowLoader(false);
      setSearchError(true);
      setSearchData("");
      setCustCountry("");
    }
  };
  function highlightFlightNumMatch(flights, search) {
    for (let i = 0; i < flights.length; i++) {
      let segGroups = flights[i].segGroups;
      flights[i].isHighlightedItem = false;
      flights[i].isTopHighlightedItem = false;

      // if (search.flightNo && search.pasteSearchEnabled) {
      if (search.flightNo) {
        if (typeof search.flightNo === 'string') {
          let foundSingleFlightNumber = false;
          for (let j = 0; j < segGroups.length; j++) {
            let segs = segGroups[j].segs;
            for (let k = 0; k < segs.length; k++) {
              if (segs[k].flightNum === search.flightNo) {
                foundSingleFlightNumber = true;
                break;
              }
            }
            if (foundSingleFlightNumber) {
              break;
            }
          }

          if (foundSingleFlightNumber) {
            flights[i].isHighlightedItem = true;
            flights[i].isTopHighlightedItem = true;
          }
        } else if (Array.isArray(search.flightNo) && search.flightNo.length > 1) {
          let hasAllFlightNumbers = true;
          for (let j = 0; j < search.flightNo.length; j++) {
            const flightNumber = search.flightNo[j];
            let foundInSegments = false;
            for (let j = 0; j < segGroups.length; j++) {
              let segs = segGroups[j].segs;
              for (let k = 0; k < segs.length; k++) {
                if (segs[k].flightNum === flightNumber) {
                  if (search.includeCarrier.includes(segs[k].opAirline) || search.includeCarrier.includes(segs[k].mrkAirline)) {
                    foundInSegments = true;
                    break;
                  }
                }
              }
              if (foundInSegments) {
                break;
              }
            }
            if (!foundInSegments) {
              hasAllFlightNumbers = false;
              break;
            }
          }
          if (hasAllFlightNumbers) {
            flights[i].isTopHighlightedItem = true;
            flights[i].isHighlightedItem = true;
          } else {
            flights[i].isHighlightedItem = true;
            flights[i].isTopHighlightedItem = false;
          }
        }
      }
    }
    return flights;
  }
  useEffect(() => {
    if (!selectedTripType) {
      let temp = localStorage.getItem("selectedTripType");
      if (temp) {
        setSelectedTripType((prevState) => temp);
      }
    }
  }, [selectedTripType])

  const setAvailableFilters = async (flights, searchContent) => {
    let existingSearch = { ...searchContent };
    existingSearch.filters.stops = getNumberOfStopsFilter(flights);
    existingSearch.filters.fareType = getFareTypeFilter(flights);
    existingSearch.filters.airlines = getAirlinesFilter(flights);
    existingSearch.filters.price = getPricesFilter(flights);
    existingSearch.filters.connectingIn = getConnectingInFilter(flights);
    existingSearch.filters.arrivalDepart =
      getArrivalDepartFilters(existingSearch);
    setActiveSearch((prevState) => ({ ...existingSearch }));
    localStorage.setItem("lastSearch", JSON.stringify(existingSearch));
  };

  const searchInFilters = (item, searchParam) => {
    return (
      checkStopsFilter(item, searchParam) &&
      checkFareTypeFilter(item, searchParam) &&
      checkAirlinesFilter(item, searchParam) &&
      checkPricesFilter(item, searchParam) &&
      checkConnectingInFilter(item, searchParam) &&
      checkArrivalDepartFilter(item, searchParam)
    );
  };

  const handleModifySearch = () => {
    localStorage.setItem("lastSearch", JSON.stringify(search));
    setPage(0);
    setOrder("asc");
    setOrderBy("price");
    fetchSearchResults(search);
    setItineraryMarkup(0);
    setMarkup("");
  };

  const fetchPrevNextDayresults = async (flag) => {
    let temp = { ...search };
    if (flag) {
      let date = (temp.depart);
      temp.depart = adjustDate(date, flag === "prev" ? "prev" : "next");
      fetchSearchResults(temp);
    }
  }
  const adjustDate = (dateString, flag) => {
    const date = new Date(dateString);
    if (flag === "prev") {
      date.setDate(date.getDate() - 1);
    } else if (flag === "next") {
      date.setDate(date.getDate() + 1);
    }
    return date.toISOString();
  };

  useEffect(() => {
    localStorage.removeItem("paymentState");
    localStorage.removeItem("travelers");
    localStorage.removeItem("reValTimer");
    localStorage.removeItem("currentTicket");
    localStorage.removeItem("paymentAttempt");
    localStorage.removeItem("revalState");
    localStorage.removeItem('reached-VBFR');
    localStorage.removeItem('reached-SRFR');
    localStorage.removeItem('reached-SFR');
    localStorage.removeItem('reached-RFSR');
    if (location?.state?.searchedResults
      && location?.state?.searchedResults !== undefined
      && location?.state?.searchedResults !== ""
      && location.state.search
      && location.state.search !== undefined
      && location.state.search !== "") {
      const flightResponse = location.state.searchedResults;
      const search = location.state.search;
      setFSData(flightResponse);
      setTraceId(flightResponse.traceId);
      setClientId(search.clientId);
      setCurrencyCode(flightResponse.flights[0]?.currency);
      setAvailableFilters(flightResponse.flights, search);
      setSearchResults((prevResults) => [...flightResponse.flights]);
      setShowLoader(false);
      setSearchData("");
      setCustCountry("");
    }
    else {

      let temp = JSON.parse(localStorage.getItem("lastSearch"));
      if (temp) {
        setSearch((search) => ({ ...temp }));
        setActiveSearch((search) => ({ ...temp }));
      } else {
        setSearch((prevState) => ({ ...search }));
        setActiveSearch((prevState) => ({ ...search }));
      }
      setShowNetFare(false);
    }
  }, []);
  useEffect(() => {
    if (searchResults && searchResults.length < 10) {
      document.body.classList.add("searchScroll");
    }
    return () => {
    };
  }, [open, searchResults]);

  useEffect(() => {
    if (activeSearch) {
      setSearch((search) => ({ ...activeSearch }));
      localStorage.setItem("lastSearch", JSON.stringify(activeSearch));
      if (searchResults && searchResults.length) {
        let result = searchResults.filter((item) =>
          searchInFilters(item, activeSearch)
        );
        setFilteredResults(result);
        setPage(0); // Reset page when filters are applied
        setHasMore(true); // Reset hasMore when filters are applied
        const getCollapseCls = Array.from(
          document.querySelectorAll(".collapse")
        );
        getCollapseCls.forEach((item) => item.classList.remove("show"));
        setToggleFlightDetailsIcon("filter_Active");
        setTimeout(() => {
          setToggleFlightDetailsIcon("");
        }, 1000);
      } else {
        fetchSearchResults(activeSearch);
      }
    }
  }, [activeSearch]);


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [order, orderBy]);


  useEffect(() => {
    if (page === 0) {
      loadNextItems();
    }
  }, [filteredResults, page]);

  const getTotalTripDuration = (segGroup) => {
    let totalDuration = 0;
    let totalLayoverTime = [];
    segGroup.forEach((segGroup) => {
      segGroup.segs.forEach((seg) => {
        totalDuration = totalDuration + seg.duration;
      });
    });

    segGroup.forEach((segGroup) => {
      segGroup.segs.forEach((new_item, new_index) => {
        segGroup.segs.length - 1 !== new_index &&
          totalLayoverTime.push(
            getLayoverTime(
              segGroup.segs[new_index],
              segGroup.segs[new_index + 1]
            )
          );
      });
    });

    let totalLayoverTime_hours_array = [],
      totalLayoverTime_minutes_array = [];
    totalLayoverTime.forEach((array_item, array_index) => {
      const element = array_item.replace(/ /g, "");
      if (element !== "") {
        if (element.includes("h")) {
          let setHours = element.split("h");
          totalLayoverTime_hours_array.push(parseInt(setHours[0]));
        }

        if (element.includes("m")) {
          let setMinutes = element.split("m");
          if (setMinutes[0].includes("h")) {
            setMinutes = setMinutes[0].split("h");
            totalLayoverTime_minutes_array.push(parseInt(setMinutes[1]));
          }
        }
      }
    });

    let totalLayoverTime_hours = 0,
      totalLayoverTime_minutes = 0;

    totalLayoverTime_hours = totalLayoverTime_hours_array.reduce(function (
      hour_prev,
      hour_current
    ) {
      return hour_prev + hour_current;
    },
      0);
    totalLayoverTime_minutes = totalLayoverTime_minutes_array.reduce(function (
      minute_prev,
      minute_current
    ) {
      return minute_prev + minute_current;
    },
      0);

    let setLayoverTime =
      String(totalLayoverTime_hours).padStart(2, "0") +
      ":" +
      String(totalLayoverTime_minutes).padStart(2, "0");
    let setTripDuration =
      String(Math.floor(totalDuration / 60)).padStart(2, "0") +
      ":" +
      String(totalDuration % 60).padStart(2, "0");
    let checkTotalTime =
      moment.duration(setTripDuration).asMinutes() +
      moment.duration(setLayoverTime).asMinutes();
    return checkTotalTime;
  };

  function getTotalPrice(fares) {
    let totalAmount = 0;
    totalAmount = fares.base + getTotalTax(fares.taxes);
    return totalAmount;
  }

  const getTotalTax = (taxes) => {
    let total = 0;
    taxes.forEach((item) => {
      total = total + item.amt;
    });
    return total;
  };

  function descendingComparator(a, b, orderBy) {
    const getValue = (obj, key) => {
      if (key === "airline") {
        return findAirlinebyCode(obj[key]);
      } else if (key === "depart") {
        return obj.segGroups[0].segs[0].departureOn;
      } else if (key === "totalDuration") {
        return getTotalTripDuration(obj.segGroups);
      } else if (key === "arrival") {
        return obj.segGroups[obj.segGroups.length - 1].segs[obj.segGroups[obj.segGroups.length - 1].segs.length - 1].arrivalOn;
      } else if (key === "price") {
        return obj.fareGroups[0].fares.reduce((total, item) => total + getTotalPrice(item), 0);
      } else if (key === "recommended") {
        return filterRecommended(obj, key);
      }
      return 0;
    };

    const valueA = getValue(a, orderBy);
    const valueB = getValue(b, orderBy);

    if (valueA < valueB) {
      return 1;
    }
    if (valueA > valueB) {
      return -1;
    }

    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <Container fluid>
        <Row className="search-results-topbar">
          <Col>
            <ModifySearchBar
              search={search}
              modifySearch={() => setOpenSearchModal(true)}
              loading={showLoader}
            />
          </Col>
        </Row>
        <div className="dialog-container">
          <Dialog
            open={snackbarOpen}
            onClose={handleSnackBarClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {<span>Sorry for the Inconvenience</span>}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <strong>
                  Within Nepal sectors, Infant Tickets need to be purchased at
                  the time of check-in.
                </strong>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSnackBarClose}>Cancel</Button>
              <Button onClick={searchWithINFZero} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          fullScreen={fullScreen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              className="popup-content redirect-popup"
              data-flk-success="atNodeInserted60"
            >
              <div className="close-wrap">
                <CloseIcon
                  className="icon-fill pointer"
                  fontSize="medium"
                  onClick={handleClose}
                ></CloseIcon>
              </div>
              <div className="pp">
                <div className="pp-body">
                  <div className="country-select-modal">
                    <h3>Select your Nationality</h3>
                    <span>
                      Passengers must select nationality and provide relevant
                      documents to avail “within Nepal” domestic fares.Once
                      selected, the nationality cannot be changed at any point
                      during the booking process.
                    </span>
                    <p>
                      Infant Tickets need to be purchased at the time of
                      check-in.
                    </p>
                  </div>
                  <ValidatorForm
                    onError={(errors) => console.log(errors)}
                    onSubmit={handleGetResults}
                  >
                    <div className="country-wrapper">
                      <Autocomplete
                        id="country-select-demo"
                        value={
                          countries.find((v) => v.code === custCountry) || null
                        }
                        onChange={(event, newValue) => {
                          let temp = searchData;
                          temp.nationality = newValue ? newValue.code : "";
                          setSearchData(temp);
                          setCustCountry(newValue ? newValue.code : "");
                        }}
                        options={countries}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) =>
                          option.label + " (" + option.code + ")"
                        }
                        renderOption={(option) => (
                          <React.Fragment>
                            {option.label} ({option.code})
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label=" Select Country"
                            required
                            value={custCountry}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                            validators={["required"]}
                            errorMessages={["This Field is Required."]}
                          />
                        )}
                      />
                    </div>
                    <div className="row no-gutters justify-content-md-end">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className="col col-md-auto btn-md-dark"
                        type="submit"
                      // onClick={handleClose}
                      >
                        Proceed
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </Box>
        </Modal>

        {contextFlights?.length ? (
          <Row style={{ marginTop: "1%" }}>
            <Col sm={3}>
              <Filters
                search={activeSearch}
                setSearch={setActiveSearch}
                results={searchResults}
                currencyCode={currencyCode}
                itineraryMarkup={itineraryMarkup}
                setItineraryMarkup={setItineraryMarkup}
                markup={markup}
                setMarkup={setMarkup}
              />
            </Col>
            {
              <Col sm={9} className="itinerary_wrapper">
                {contextFlights.length > 0 &&
                  searchResults.length > 0 && (
                    <>
                      {
                        (selectedTripType === "OneWay") ?
                          <PrevNextComponent search={search} fetchPrevNextDayresults={fetchPrevNextDayresults} />
                          : ''
                      }
                      <SortComponent
                        results={searchResults}
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        currencyCode={currencyCode}
                        searchResults={searchResults}
                        setContextFlights={setContextFlights}
                        stableSort={stableSort}
                        getComparator={getComparator}
                        showNetfare={showNetfare}
                        setShowNetFare={setShowNetFare}
                        setHasMore={setHasMore}
                        setPage={setPage}
                      />
                    </>
                  )}
                <InfiniteScroll
                  pageStart={page}
                  loadMore={loadNextItems}
                  hasMore={hasMore}
                  loader={<div className="loader" key={0}></div>}
                >
                  <div className="itenaryContainer itineraryCol">
                    {pasteSearchEnabled === false && contextFlights &&
                      contextFlights.length > 0 &&
                      searchResults.length > 0 &&
                      contextFlights
                        .filter((item) => searchInFilters(item, search))
                        .length > 0 ? (
                      contextFlights
                        .filter((item) => searchInFilters(item, search))
                        .map((contextFlights, index) => (
                          <ItineraryDetails
                            key={`${index + 'op'}`}
                            traceId={traceId}
                            clientId={clientId}
                            result={contextFlights}
                            search={activeSearch}
                            arrayOf={index}
                            currencyCode={currencyCode}
                            toggleFDIcon={toggleFlightDetailsIcon}
                            showNetfare={showNetfare}
                            itineraryMarkup={itineraryMarkup}
                            setItineraryMarkup={setItineraryMarkup}
                            markup={markup}
                            setMarkup={setMarkup}
                            adultcount={search.adult}
                            childcount={search.children}
                            fsData={fsData}
                          />
                        ))
                    ) :
                      pasteSearchEnabled === true && contextFlights &&
                        contextFlights.length > 0 &&
                        searchResults.length > 0 ? (
                        <>
                          {

                            contextFlights
                              .filter((item) => searchInFilters(item, search))
                              .filter((item) => item.isTopHighlightedItem === true).length > 0 ?
                              <>
                                <p className="resultHeadText">Your Search results:</p>
                                {contextFlights
                                  .filter((item) => searchInFilters(item, search))
                                  .filter((item) => item.isTopHighlightedItem === true)
                                  .map((contextFlights, index) => (
                                    <>
                                      <ItineraryDetails
                                        key={`${index + 'op'}`}
                                        traceId={traceId}
                                        clientId={clientId}
                                        result={contextFlights}
                                        search={activeSearch}
                                        arrayOf={index}
                                        currencyCode={currencyCode}
                                        toggleFDIcon={toggleFlightDetailsIcon}
                                        showNetfare={showNetfare}
                                        itineraryMarkup={itineraryMarkup}
                                        setItineraryMarkup={setItineraryMarkup}
                                        markup={markup}
                                        setMarkup={setMarkup}
                                        adultcount={search.adult}
                                        childcount={search.children}
                                        fsData={fsData}
                                      />
                                    </>
                                  ))}
                              </> :
                              <p className="resultHeadText">Your Search Results: 0</p>}
                          {contextFlights
                            .filter((item) => searchInFilters(item, search))
                            .filter((item) => item.isTopHighlightedItem === false).length > 0 ?
                            <>
                              <p className="resultHeadText">Other Results:</p>
                              {
                                contextFlights
                                  .filter((item) => searchInFilters(item, search))
                                  .filter((item) => item.isTopHighlightedItem === false)
                                  .sort((a, b) => {
                                    if (a.isHighlightedItem && !b.isHighlightedItem) {
                                      return -1;
                                    }
                                    if (b.isHighlightedItem && !a.isHighlightedItem) {
                                      return 1;
                                    }
                                    return 0;
                                  })
                                  .map((contextFlights, index) => (
                                    <>
                                      <ItineraryDetails
                                        key={`${index + 'op'}`}
                                        traceId={traceId}
                                        clientId={clientId}
                                        result={contextFlights}
                                        search={activeSearch}
                                        arrayOf={index}
                                        currencyCode={currencyCode}
                                        toggleFDIcon={toggleFlightDetailsIcon}
                                        showNetfare={showNetfare}
                                        itineraryMarkup={itineraryMarkup}
                                        setItineraryMarkup={setItineraryMarkup}
                                        markup={markup}
                                        setMarkup={setMarkup}
                                        adultcount={search.adult}
                                        childcount={search.children}
                                        fsData={fsData}
                                      />
                                    </>
                                  ))
                              }
                            </>
                            :
                            <div className="no-results-container">
                              <div className="no-results-icon">
                                <SearchIcon fontSize="large"></SearchIcon>
                              </div>
                              <div className="no-results-text">
                                "No  Results Found"
                              </div>
                            </div>
                          }
                        </>
                      ) : (
                        <div className="no-results-container">
                          <div className="no-results-icon">
                            <SearchIcon fontSize="large"></SearchIcon>
                          </div>
                          <div className="no-results-text">
                            "No Search Results for this Filter"
                          </div>
                        </div>
                      )}
                  </div>
                </InfiniteScroll>
              </Col>
            }
          </Row>
        ) : (
          searchError && (
            <div className="no-results-container">
              <div className="no-results-icon">
                <SearchIcon fontSize="large"></SearchIcon>
              </div>
              <div className="no-results-text">
                <div>No Results Found for your Search</div>
              </div>
            </div>
          )
        )}
      </Container>
      <SearchBarModal
        openSearchModal={openSearchModal}
        setOpenSearchModal={setOpenSearchModal}
        handleModifySearch={handleModifySearch}
      />
      {showLoader ? <RiseLoading isLoading={showLoader} /> : ""}
      <NotificationContainer />
    </>
  );
}
