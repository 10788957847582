import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CheckBox } from "@mui/icons-material";
import { Autocomplete, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

const ReviewBookingTravelerInput = ({
    index,
    traveler,
    selectedPassengers,
    handlePassengerSelection,
    passengerData,
    filterPassengers,
    handleFieldChange,
    activeStep,
    getCorporatePaxDetails,
    title,
    selectDate,
    genders,
    isPassMandate,
    countries,
    currentClientCountryCode,
    femaList,
    travelers,
    setSavePassengerCheck,
    extraSegmentMealInfo,
    extraSegmentBaggageInfo,
    displayMealSelect,
    exMealInfo,
    getExtraMealInfo,
    extraMealInfo = { extraMealInfo },
    getExtraMealSelectedValue,
    handleMealAddService,
    handleMealRemoveService,
    displayBaggageSelect,
    currentTicket,
    exBaggageInfo,
    getExtraBaggageInfo,
    extraBaggageInfo,
    getExtraBaggageSelectedValue,
    handleAddService,
    handleRemoveService,
    onwardBaggageOptions,
    selectBaggageFortraveller,
    returnBaggageOptions,
}) => {

    useEffect(() => {
        ValidatorForm.addValidationRule("isValidNameLength", () => {
            const fullName = `${traveler.title}${traveler.firstName}${traveler.lastName}`;
            return fullName.length <= 57;
        });
    }, [traveler.title, traveler.firstName, traveler.lastName]);

    return (
        <div>
            <Row className="mx-0 mt-3 flt-bkg-heading-bg px-2">
                <div className="col-12 flt-bkg-main-heading px-2">
                    Traveler {index + 1}
                    <span className="pax-type px-2">
                        {" "}
                        {traveler.paxType}
                    </span>
                </div>
            </Row>
            {(index === 0) && <div className="d-flex justify-content-between">
                <div></div>
                <div style={{ width: "300px" }} className="mt-3">
                    <Autocomplete
                        multiple
                        value={selectedPassengers}
                        onChange={(event, newValue) => handlePassengerSelection(event, newValue, "add")}
                        options={passengerData.filter(filterPassengers)}
                        getOptionLabel={(option) => `${option.passengerProfile.firstName} ${option.passengerProfile.lastName} - ${moment(option.passengerProfile.dob).format("DD-MMM-YYYY")}`}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Passenger"
                                variant="outlined"
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <div key={index} {...getTagProps({ index })}>
                                    {`${option.passengerProfile.firstName} ${option.passengerProfile.lastName}`}
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handlePassengerSelection(e, option, "del");
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            ))
                        }

                    />

                </div>
            </div>}

            <div className="passenger-detail-field-container">
                <div className="passenger-fields">
                    <div className="ml-8 mr-8 col-4">
                        {activeStep !== 1 && (
                            <Autocomplete
                                id="title select"
                                value={traveler.title}
                                onChange={(event, value) => {
                                    handleFieldChange(
                                        value,
                                        "title",
                                        index
                                    );
                                }}
                                options={
                                    title.find(
                                        (item) =>
                                            item.type == traveler.paxType
                                    ).values || []
                                }
                                getOptionLabel={(option) =>
                                    option == "" ? "" : option
                                }
                                onBlur={(event) =>
                                    getCorporatePaxDetails()
                                }
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            fontSize: 14,
                                        },
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextValidator
                                        {...params}
                                        required
                                        label="Title"
                                        variant="outlined"
                                        color="secondary"
                                        onBlur={(event) =>
                                            getCorporatePaxDetails()
                                        }
                                        size="small"
                                        value={traveler.title}
                                        validators={["required"]}
                                        errorMessages={["required"]}
                                    />
                                )}
                            />
                        )}
                    </div>
                    <div className="ml-8 mr-8 col-4">
                        {activeStep !== 1 && (
                            <TextValidator
                                required
                                label="First Name"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={traveler.firstName}
                                validators={[
                                    "required",
                                    "matchRegexp:^[a-zA-Z ]+$",
                                    "isValidNameLength",
                                ]}
                                errorMessages={[
                                    "required",
                                    "Only Alphabets are allowed",
                                    "Total name length can up to 57 characters, which includes Title, First name, Last name and spaces used.",
                                ]}
                                onBlur={(event) =>
                                    getCorporatePaxDetails()
                                }
                                onChange={(event) => {
                                    handleFieldChange(
                                        event.target.value,
                                        "firstName",
                                        index
                                    )
                                }
                                }
                            />
                        )}
                    </div>
                    <div className="ml-8 mr-8 col-4">
                        {activeStep !== 1 && (
                            <TextValidator
                                required
                                label="Last Name"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={traveler.lastName}
                                validators={[
                                    "required",
                                    "matchRegexp:^[a-zA-Z ]+$",
                                    "minStringLength:2",
                                    "isValidNameLength",
                                ]}
                                errorMessages={[
                                    "required",
                                    "Only Alphabets are allowed",
                                    "Must be at least 2 characters",
                                    "Total name length can up to 57 characters, which includes Title, First name, Last name and spaces used.",
                                ]}
                                onBlur={(event) =>
                                    getCorporatePaxDetails()
                                }
                                onChange={(event) =>
                                    handleFieldChange(
                                        event.target.value,
                                        "lastName",
                                        index
                                    )
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="passenger-detail-field-container">
                <div className="passenger-fields">
                    <div className="ml-8 mr-8 col-4">
                        {activeStep !== 1 && (
                            <TextValidator
                                required
                                label="Date of birth"
                                placeholder=""
                                variant="outlined"
                                size="small"
                                color="secondary"
                                type="date"
                                value={traveler.dob}
                                validators={["required"]}
                                errorMessages={["required"]}
                                InputProps={{
                                    inputProps: {
                                        min:
                                            traveler.paxType == "ADT"
                                                ? ""
                                                : traveler.paxType == "CHD"
                                                    ? selectDate.childLimit
                                                    : selectDate.infantLimit,
                                        max:
                                            traveler.paxType == "ADT"
                                                ? selectDate.childLimit
                                                : traveler.paxType == "CHD"
                                                    ? selectDate.infantLimit
                                                    : selectDate.today,
                                    },
                                }}
                                onChange={(event) =>
                                    handleFieldChange(
                                        event.target.value,
                                        "dob",
                                        index
                                    )
                                }
                                onKeyDown={(e) => e.preventDefault()}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    </div>
                    <div className="ml-8 mr-8 col-4">
                        {activeStep !== 1 && (
                            <Autocomplete
                                id="passportIssuedCountry select"
                                value={
                                    traveler.gender &&
                                    genders.find(
                                        (el) => el === traveler.gender
                                    )
                                }
                                onChange={(event, value) => {
                                    handleFieldChange(
                                        value,
                                        "gender",
                                        index
                                    );
                                }}
                                options={genders.map((option) => {
                                    return traveler.paxType != "ADT" &&
                                        option == "Transgender"
                                        ? ""
                                        : option;
                                })}
                                getOptionLabel={(option) =>
                                    option == "" ? "" : option
                                }
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            fontSize: 14,
                                        },
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextValidator
                                        {...params}
                                        required
                                        label="Gender"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        value={
                                            traveler.gender &&
                                            genders.find(
                                                (el) => el === traveler.gender
                                            )
                                        }
                                        validators={["required"]}
                                        errorMessages={["required"]}
                                    />
                                )}
                            />
                        )}
                    </div>
                    <div className="ml-8 mr-8 col-4">
                        {activeStep !== 1 && (
                            <TextValidator
                                required={isPassMandate}
                                label="Passport Number"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={traveler.passportNumber}
                                validators={
                                    isPassMandate === true
                                        ? [
                                            "required",
                                            "matchRegexp:^[a-zA-Z0-9]+$",
                                        ]
                                        : false
                                }
                                errorMessages={
                                    isPassMandate === true
                                        ? [
                                            "required",
                                            "No special characters allowed",
                                        ]
                                        : false
                                }
                                onChange={(event) =>
                                    handleFieldChange(
                                        event.target.value,
                                        "passportNumber",
                                        index
                                    )
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="passenger-detail-field-container">
                <div className="passenger-fields">
                    <div className="ml-8 mr-8 col-4">
                        {activeStep !== 1 && (
                            <TextValidator
                                //required={isPassMandate}
                                label="Date Of Issuance"
                                placeholder=""
                                variant="outlined"
                                size="small"
                                color="secondary"
                                type="date"
                                value={traveler.passportDOI}
                                InputProps={{
                                    inputProps: {
                                        min:
                                            traveler.paxType == "ADT"
                                                ? ""
                                                : traveler.paxType == "CHD"
                                                    ? selectDate.childLimit
                                                    : selectDate.infantLimit,
                                        max: selectDate.today,
                                    },
                                }}
                                // validators={
                                //   isPassMandate === true
                                //     ? ["required"]
                                //     : false
                                // }
                                // errorMessages={
                                //   isPassMandate === true
                                //     ? ["required"]
                                //     : false
                                // }
                                onChange={(event) =>
                                    handleFieldChange(
                                        event.target.value,
                                        "passportDOI",
                                        index
                                    )
                                }
                                onKeyDown={(e) => e.preventDefault()}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    </div>
                    <div className="ml-8 mr-8 col-4">
                        {activeStep !== 1 && (
                            <TextValidator
                                required={isPassMandate}
                                label="Date of Expiry"
                                placeholder=""
                                variant="outlined"
                                size="small"
                                color="secondary"
                                type="date"
                                value={traveler.passportDOE}
                                InputProps={{
                                    inputProps: {
                                        min: selectDate.today,
                                    },
                                }}
                                validators={
                                    isPassMandate === true
                                        ? ["required"]
                                        : false
                                }
                                errorMessages={
                                    isPassMandate === true
                                        ? ["required"]
                                        : false
                                }
                                onChange={(event) =>
                                    handleFieldChange(
                                        event.target.value,
                                        "passportDOE",
                                        index
                                    )
                                }
                                onKeyDown={(e) => e.preventDefault()}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    </div>
                    <div className="ml-8 mr-8 col-4">
                        {activeStep !== 1 && (
                            <Autocomplete
                                id="passportIssuedCountry select"
                                value={
                                    traveler.passportIssuedCountry &&
                                    countries.find(
                                        (el) =>
                                            el.code ===
                                            traveler.passportIssuedCountry
                                    )
                                }
                                onChange={(event, value) => {
                                    handleFieldChange(
                                        value,
                                        "passportIssuedCountry",
                                        index
                                    );
                                }}
                                options={countries.filter(
                                    (item) => item.code !== "All"
                                )}
                                getOptionLabel={(option) =>
                                    option == ""
                                        ? ""
                                        : option.label +
                                        " (" +
                                        option.code +
                                        ")"
                                }
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            fontSize: 14,
                                        },
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextValidator
                                        {...params}
                                        required={isPassMandate}
                                        label="Issued Country"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        value={
                                            traveler.passportIssuedCountry &&
                                            countries.find(
                                                (el) =>
                                                    el.code ===
                                                    traveler.passportIssuedCountry
                                            )
                                        }
                                        validators={
                                            isPassMandate === true
                                                ? ["required"]
                                                : false
                                        }
                                        errorMessages={
                                            isPassMandate === true
                                                ? ["required"]
                                                : false
                                        }
                                    />
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="passenger-detail-field-container">
                <div className="passenger-fields mr-24 justify-content-between">
                    <div className="ml-8 mr-8 col-4">
                        {activeStep !== 1 && (
                            <Autocomplete
                                id="passengerNationality select"
                                value={
                                    traveler.passengerNationality &&
                                    countries.find(
                                        (el) =>
                                            el.code ===
                                            traveler.passengerNationality
                                    )
                                }
                                onBlur={(event) =>
                                    getCorporatePaxDetails()
                                }
                                onChange={(event, value) => {
                                    handleFieldChange(
                                        value,
                                        "passengerNationality",
                                        index
                                    );
                                }}
                                options={countries.filter(
                                    (item) => item.code !== "All"
                                )}
                                getOptionLabel={(option) =>
                                    option == ""
                                        ? ""
                                        : option.label +
                                        " (" +
                                        option.code +
                                        ")"
                                }
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            fontSize: 14,
                                        },
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextValidator
                                        {...params}
                                        required={isPassMandate}
                                        label="Nationality"
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        value={
                                            traveler.passengerNationality &&
                                            countries.find(
                                                (el) =>
                                                    el.code ===
                                                    traveler.passengerNationality
                                            )
                                        }
                                        validators={
                                            isPassMandate === true
                                                ? ["required"]
                                                : false
                                        }
                                        errorMessages={
                                            isPassMandate === true
                                                ? ["required"]
                                                : false
                                        }
                                    />
                                )}
                            />
                        )}
                    </div>
                    {traveler.passengerNationality === "IN" &&
                        currentClientCountryCode === "IN" ? (
                        <>
                            <div className="ml-8 mr-8 col-4">
                                {activeStep !== 1 && (
                                    <Autocomplete
                                        id="fema select"
                                        value={
                                            traveler.fema &&
                                            femaList.find(
                                                (el) => el === traveler.fema
                                            )
                                        }
                                        onBlur={(event) =>
                                            getCorporatePaxDetails()
                                        }
                                        onChange={(event, value) => {
                                            handleFieldChange(
                                                value,
                                                "fema",
                                                index
                                            );
                                        }}
                                        options={femaList}
                                        getOptionLabel={(option) =>
                                            option
                                        }
                                        componentsProps={{
                                            paper: {
                                                sx: {
                                                    fontSize: 14,
                                                },
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                required={
                                                    traveler.passengerNationality ===
                                                        "IN" &&
                                                        currentClientCountryCode ===
                                                        "IN"
                                                        ? true
                                                        : false
                                                }
                                                label="Corporate/Retailer"
                                                variant="outlined"
                                                size="small"
                                                color="secondary"
                                                value={
                                                    traveler.fema &&
                                                    femaList.find(
                                                        (el) =>
                                                            el === traveler.fema
                                                    )
                                                }
                                                validators={
                                                    traveler.passengerNationality ===
                                                        "IN" &&
                                                        currentClientCountryCode ===
                                                        "IN"
                                                        ? ["required"]
                                                        : false
                                                }
                                                errorMessages={
                                                    traveler.passengerNationality ===
                                                        "IN" &&
                                                        currentClientCountryCode ===
                                                        "IN"
                                                        ? ["required"]
                                                        : false
                                                }
                                            />
                                        )}
                                    />
                                )}
                            </div>
                            {traveler.fema === "Retailer" ? (
                                <>
                                    <div className="ml-8 mr-8 col-4">
                                        {activeStep !== 1 && (
                                            <TextValidator
                                                required={
                                                    traveler.fema === "Retailer"
                                                        ? ["required"]
                                                        : false
                                                }
                                                label="PAN Card"
                                                variant="outlined"
                                                size="small"
                                                color="secondary"
                                                value={traveler.panCard}
                                                validators={
                                                    traveler.fema === "Retailer"
                                                        ? [
                                                            "required",
                                                            "matchRegexp:^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
                                                        ]
                                                        : false
                                                }
                                                errorMessages={
                                                    traveler.fema === "Retailer"
                                                        ? [
                                                            "required",
                                                            "Not in valid format - 1st 2 letters should be in Numbers, Next 5 letters should be in Uppercase , next 4 letters should be in Numbers, Next Letter should be in Uppercase, 13th,15th letter should be in number or in Uppercase , 14th letter Should be capital",
                                                        ]
                                                        : false
                                                }
                                                onChange={(event) =>
                                                    handleFieldChange(
                                                        event.target.value,
                                                        "panCard",
                                                        index
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        ""
                    )}
                    {((travelers.length - 1) === index) ? (<div className=" d-flex justify-content-between align-items-center border view-flight-details-text" style={{ width: "15rem" }}>
                        <div>
                            <CheckBox onChange={(e) => setSavePassengerCheck(e.target.checked)} />
                        </div>
                        <div style={{ paddingRight: "5rem" }}>Save Passenger Details</div>
                    </div>) : <div className="ml-8 mr-8 col-4"></div>}
                </div>
            </div>
            {((extraSegmentMealInfo &&
                extraSegmentMealInfo.length > 0) ||
                (extraSegmentBaggageInfo &&
                    extraSegmentBaggageInfo.length > 0)) && (
                    <div>
                        <div className="passenger-detail-field-container">
                            <div className="col-12 mx-2 px-2 flt-bkg-sub-heading">
                                <div className="my-2">
                                    Additional Services
                                </div>
                            </div>
                        </div>
                        {traveler.paxType === "INF" ? (
                            <div>
                                This additional service is not available
                                for Infants
                            </div>
                        ) : (
                            <>
                                {extraSegmentMealInfo &&
                                    displayMealSelect &&
                                    extraSegmentMealInfo.length > 0 ? (
                                    <>
                                        <div className="passenger-detail-field-container">
                                            <div className="col-12 mx-2 px-2 flt-bkg-sub-heading">
                                                <div className="my-2">Meal</div>
                                            </div>
                                        </div>
                                        {exMealInfo &&
                                            exMealInfo[index].mealInfo &&
                                            exMealInfo[index].mealInfo.map(
                                                (x, nidx) => {
                                                    const id =
                                                        "extraSegmentOutwardMeal_select_" +
                                                        index +
                                                        "_" +
                                                        nidx;
                                                    const lugId =
                                                        "extraOutwardMeal_select_" +
                                                        index +
                                                        "_" +
                                                        nidx;
                                                    return (
                                                        <div className="passenger-detail-field-container">
                                                            <div class="passenger-fields">
                                                                <div className="ml-8 mr-8 col-5">
                                                                    {activeStep !== 1 && (
                                                                        <Autocomplete
                                                                            id={id}
                                                                            value={
                                                                                extraSegmentMealInfo !=
                                                                                    undefined
                                                                                    ? extraSegmentMealInfo.find(
                                                                                        (el) =>
                                                                                            el.label ===
                                                                                            traveler
                                                                                                .extraOutwardMeal[
                                                                                                nidx
                                                                                            ]
                                                                                                ?.SegmentInfo
                                                                                    )
                                                                                    : {}
                                                                            }
                                                                            onChange={(
                                                                                event,
                                                                                value
                                                                            ) => {
                                                                                getExtraMealInfo(
                                                                                    value,
                                                                                    index,
                                                                                    nidx,
                                                                                    id
                                                                                );
                                                                            }}
                                                                            options={extraSegmentMealInfo.filter(
                                                                                (item) =>
                                                                                    item.label !==
                                                                                    ""
                                                                            )}
                                                                            getOptionLabel={(
                                                                                option
                                                                            ) =>
                                                                                option == ""
                                                                                    ? ""
                                                                                    : option.label
                                                                            }
                                                                            getOptionDisabled={(
                                                                                option
                                                                            ) => {
                                                                                if (
                                                                                    travelers[
                                                                                        index
                                                                                    ].extraOutwardMeal.some(
                                                                                        (day) =>
                                                                                            day.SegmentInfo ===
                                                                                            option.label
                                                                                    )
                                                                                ) {
                                                                                    return true;
                                                                                }
                                                                                return false;
                                                                            }}
                                                                            componentsProps={{
                                                                                paper: {
                                                                                    sx: {
                                                                                        fontSize: 14,
                                                                                    },
                                                                                },
                                                                            }}
                                                                            renderInput={(
                                                                                params
                                                                            ) => (
                                                                                <TextValidator
                                                                                    {...params}
                                                                                    label="Select Segment"
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    size="small"
                                                                                    value={
                                                                                        traveler.extraMealInfo &&
                                                                                        extraMealInfo.find(
                                                                                            (el) =>
                                                                                                el.price ===
                                                                                                traveler.extraOutwardMealChrgs
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    )}
                                                                </div>

                                                                <div className="ml-8 mr-8 col-5">
                                                                    {activeStep !== 1 && (
                                                                        <Autocomplete
                                                                            id={lugId}
                                                                            value={
                                                                                traveler
                                                                                    .extraOutwardMeal
                                                                                    .length > 0 &&
                                                                                getExtraMealSelectedValue(
                                                                                    x.nestedMeal,
                                                                                    traveler.extraOutwardMeal,
                                                                                    nidx
                                                                                )
                                                                            }
                                                                            onChange={(
                                                                                event,
                                                                                value
                                                                            ) => {
                                                                                handleFieldChange(
                                                                                    value,
                                                                                    "extraOutwardMeal",
                                                                                    index,
                                                                                    nidx
                                                                                );
                                                                            }}
                                                                            options={
                                                                                x.nestedMeal
                                                                            }
                                                                            getOptionLabel={(
                                                                                option
                                                                            ) =>
                                                                                option.label ||
                                                                                ""
                                                                            }
                                                                            componentsProps={{
                                                                                paper: {
                                                                                    sx: {
                                                                                        fontSize: 14,
                                                                                    },
                                                                                },
                                                                            }}
                                                                            renderInput={(
                                                                                params
                                                                            ) => (
                                                                                <TextValidator
                                                                                    {...params}
                                                                                    label={
                                                                                        x.nestedMeal &&
                                                                                            x.nestedMeal
                                                                                                .length >
                                                                                            0
                                                                                            ? "Select Meal"
                                                                                            : "No Meal Options Available"
                                                                                    }
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    size="small"
                                                                                />
                                                                            )}
                                                                            disabled={
                                                                                x.nestedMeal
                                                                                    .length > 0
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>

                                                                <div className="ml-8 mr-8 col-1">
                                                                    <div
                                                                        className="plus-minu-button ml-0"
                                                                        onClick={() =>
                                                                            handleMealAddService(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        +
                                                                    </div>
                                                                </div>
                                                                <div className="ml-8 mr-8 col-1">
                                                                    <div
                                                                        className="plus-minu-button ml-0"
                                                                        onClick={() =>
                                                                            handleMealRemoveService(
                                                                                index,
                                                                                nidx
                                                                            )
                                                                        }
                                                                    >
                                                                        -
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </>
                                ) : (
                                    ""
                                )}
                                {extraSegmentBaggageInfo &&
                                    displayBaggageSelect &&
                                    extraSegmentBaggageInfo.length > 0 ? (
                                    <>
                                        <div className="passenger-detail-field-container">
                                            <div className="col-12 mx-2 px-2 flt-bkg-sub-heading">
                                                <div className="my-2">
                                                    Baggage
                                                </div>
                                            </div>{" "}
                                        </div>

                                        {currentTicket.vendor !== "AT" &&
                                            exBaggageInfo &&
                                            exBaggageInfo[index].bagInfo &&
                                            exBaggageInfo[index].bagInfo.map(
                                                (x, nidx) => {
                                                    const id =
                                                        "extraSegmentOutwardLuggage_select_" +
                                                        index +
                                                        "_" +
                                                        nidx;
                                                    const lugId =
                                                        "extraOutwardLuggage_select_" +
                                                        index +
                                                        "_" +
                                                        nidx;
                                                    return (
                                                        <div className="passenger-detail-field-container">
                                                            <div class="passenger-fields">
                                                                <div className="ml-8 mr-8 col-5">
                                                                    {activeStep !== 1 && (
                                                                        <Autocomplete
                                                                            id={id}
                                                                            value={
                                                                                extraSegmentBaggageInfo !=
                                                                                    undefined
                                                                                    ? extraSegmentBaggageInfo.find(
                                                                                        (el) =>
                                                                                            el.label ===
                                                                                            traveler
                                                                                                .extraOutwardLuggage[
                                                                                                nidx
                                                                                            ]
                                                                                                ?.SegmentInfo
                                                                                    )
                                                                                    : {}
                                                                            }
                                                                            onChange={(
                                                                                event,
                                                                                value
                                                                            ) => {
                                                                                getExtraBaggageInfo(
                                                                                    value,
                                                                                    index,
                                                                                    nidx,
                                                                                    id
                                                                                );
                                                                            }}
                                                                            options={extraSegmentBaggageInfo.filter(
                                                                                (item) =>
                                                                                    item.label !==
                                                                                    ""
                                                                            )}
                                                                            getOptionLabel={(
                                                                                option
                                                                            ) =>
                                                                                option == ""
                                                                                    ? ""
                                                                                    : option.label
                                                                            }
                                                                            getOptionDisabled={(
                                                                                option
                                                                            ) => {
                                                                                if (
                                                                                    travelers[
                                                                                        index
                                                                                    ].extraOutwardLuggage.some(
                                                                                        (day) =>
                                                                                            day.SegmentInfo ===
                                                                                            option.label
                                                                                    )
                                                                                ) {
                                                                                    return true;
                                                                                }
                                                                                return false;
                                                                            }}
                                                                            componentsProps={{
                                                                                paper: {
                                                                                    sx: {
                                                                                        fontSize: 14,
                                                                                    },
                                                                                },
                                                                            }}
                                                                            renderInput={(
                                                                                params
                                                                            ) => (
                                                                                <TextValidator
                                                                                    {...params}
                                                                                    label="Select Segment"
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    size="small"
                                                                                    value={
                                                                                        traveler.extraBaggageInfo &&
                                                                                        extraBaggageInfo.find(
                                                                                            (el) =>
                                                                                                el.price ===
                                                                                                traveler.extraOutwardLuggageChrgs
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    )}
                                                                </div>

                                                                <div className="ml-8 mr-8 col-5">
                                                                    {activeStep !== 1 && (
                                                                        <Autocomplete
                                                                            id={lugId}
                                                                            onChange={(
                                                                                event,
                                                                                value
                                                                            ) => {
                                                                                handleFieldChange(
                                                                                    value,
                                                                                    "extraOutwardLuggage",
                                                                                    index,
                                                                                    nidx
                                                                                );
                                                                            }}
                                                                            options={
                                                                                x.nestedBag
                                                                            }
                                                                            getOptionLabel={(
                                                                                option
                                                                            ) =>
                                                                                option.label ||
                                                                                ""
                                                                            }
                                                                            componentsProps={{
                                                                                paper: {
                                                                                    sx: {
                                                                                        fontSize: 14,
                                                                                    },
                                                                                },
                                                                            }}
                                                                            renderInput={(
                                                                                params
                                                                            ) => (
                                                                                <TextValidator
                                                                                    {...params}
                                                                                    label={
                                                                                        x.nestedBag &&
                                                                                            x.nestedBag
                                                                                                .length >
                                                                                            0
                                                                                            ? "Select Luggage"
                                                                                            : "No Luggage Options Available"
                                                                                    }
                                                                                    value={getExtraBaggageSelectedValue(
                                                                                        x.nestedBag,
                                                                                        traveler.extraOutwardLuggage,
                                                                                        nidx
                                                                                    )}
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    size="small"
                                                                                />
                                                                            )}
                                                                            disabled={
                                                                                x.nestedBag &&
                                                                                    x.nestedBag
                                                                                        .length > 0
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                                {extraSegmentBaggageInfo.length >
                                                                    exBaggageInfo[index]
                                                                        .bagInfo.length && (
                                                                        <div className="ml-8 mr-8 col-1">
                                                                            <div
                                                                                className="plus-minu-button ml-0"
                                                                                onClick={() =>
                                                                                    handleAddService(
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                +
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {exBaggageInfo[index]
                                                                    .bagInfo.length >
                                                                    1 && (
                                                                        <div className="ml-8 mr-8 col-1">
                                                                            <div
                                                                                className="plus-minu-button ml-0"
                                                                                onClick={() =>
                                                                                    handleRemoveService(
                                                                                        index,
                                                                                        nidx
                                                                                    )
                                                                                }
                                                                            >
                                                                                -
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        {currentTicket.vendor === "AT" && (
                                            <div className="">
                                                {activeStep !== 1 && (
                                                    <div class="">
                                                        <Table>
                                                            {currentTicket.segGroups !==
                                                                undefined &&
                                                                currentTicket.segGroups
                                                                    .length &&
                                                                onwardBaggageOptions &&
                                                                onwardBaggageOptions.length &&
                                                                returnBaggageOptions &&
                                                                returnBaggageOptions.length ? (
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <TextValidator
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                size="small"
                                                                                label={"Onward"}
                                                                                value={
                                                                                    currentTicket
                                                                                        .segGroups[0]
                                                                                        .origin +
                                                                                    currentTicket
                                                                                        .segGroups[0]
                                                                                        .destination
                                                                                }
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Autocomplete
                                                                                value={
                                                                                    traveler.selectedBaggage
                                                                                }
                                                                                onChange={(
                                                                                    event,
                                                                                    value
                                                                                ) => {
                                                                                    selectBaggageFortraveller(
                                                                                        value,
                                                                                        index, 'onward'
                                                                                    );
                                                                                }}
                                                                                options={
                                                                                    onwardBaggageOptions
                                                                                }
                                                                                getOptionLabel={(
                                                                                    option
                                                                                ) =>
                                                                                    option.label ||
                                                                                    ""
                                                                                }
                                                                                componentsProps={{
                                                                                    paper: {
                                                                                        sx: {
                                                                                            fontSize: 14,
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                renderInput={(
                                                                                    params
                                                                                ) => (
                                                                                    <TextValidator
                                                                                        {...params}
                                                                                        label={
                                                                                            onwardBaggageOptions &&
                                                                                                onwardBaggageOptions.length >
                                                                                                0
                                                                                                ? "Select Luggage"
                                                                                                : "No Luggage Options Available"
                                                                                        }
                                                                                        variant="outlined"
                                                                                        color="secondary"
                                                                                        size="small"
                                                                                        value={
                                                                                            traveler.selectedBaggage
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                disabled={
                                                                                    onwardBaggageOptions &&
                                                                                        onwardBaggageOptions.length >
                                                                                        0
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <TextValidator
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                size="small"
                                                                                label={"Return"}
                                                                                value={
                                                                                    currentTicket
                                                                                        .segGroups[1]
                                                                                        .origin +
                                                                                    currentTicket
                                                                                        .segGroups[1]
                                                                                        .destination
                                                                                }
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Autocomplete
                                                                                value={
                                                                                    traveler.selectedBaggage
                                                                                }
                                                                                onChange={(
                                                                                    event,
                                                                                    value
                                                                                ) => {
                                                                                    selectBaggageFortraveller(
                                                                                        value,
                                                                                        index, 'return'
                                                                                    );
                                                                                }}
                                                                                options={
                                                                                    returnBaggageOptions
                                                                                }
                                                                                getOptionLabel={(
                                                                                    option
                                                                                ) =>
                                                                                    option.label ||
                                                                                    ""
                                                                                }
                                                                                componentsProps={{
                                                                                    paper: {
                                                                                        sx: {
                                                                                            fontSize: 14,
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                renderInput={(
                                                                                    params
                                                                                ) => (
                                                                                    <TextValidator
                                                                                        {...params}
                                                                                        label={
                                                                                            returnBaggageOptions &&
                                                                                                returnBaggageOptions.length >
                                                                                                0
                                                                                                ? "Select Luggage"
                                                                                                : "No Luggage Options Available"
                                                                                        }
                                                                                        variant="outlined"
                                                                                        color="secondary"
                                                                                        size="small"
                                                                                        value={
                                                                                            traveler.selectedBaggage
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                disabled={
                                                                                    returnBaggageOptions &&
                                                                                        returnBaggageOptions.length >
                                                                                        0
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            ) : (
                                                                <>
                                                                    {onwardBaggageOptions &&
                                                                        onwardBaggageOptions.length ? (
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <TextValidator
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    size="small"
                                                                                    label={
                                                                                        "Onward"
                                                                                    }
                                                                                    value={
                                                                                        currentTicket
                                                                                            .segGroups[0]
                                                                                            .origin +
                                                                                        currentTicket
                                                                                            .segGroups[0]
                                                                                            .destination
                                                                                    }
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Autocomplete
                                                                                    value={
                                                                                        traveler.selectedBaggage
                                                                                    }
                                                                                    onChange={(
                                                                                        event,
                                                                                        value
                                                                                    ) => {
                                                                                        selectBaggageFortraveller(
                                                                                            value,
                                                                                            index, 'onward'
                                                                                        );
                                                                                    }}
                                                                                    options={
                                                                                        onwardBaggageOptions
                                                                                    }
                                                                                    getOptionLabel={(
                                                                                        option
                                                                                    ) =>
                                                                                        option.label ||
                                                                                        ""
                                                                                    }
                                                                                    componentsProps={{
                                                                                        paper: {
                                                                                            sx: {
                                                                                                fontSize: 14,
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                    renderInput={(
                                                                                        params
                                                                                    ) => (
                                                                                        <TextValidator
                                                                                            {...params}
                                                                                            label={
                                                                                                onwardBaggageOptions &&
                                                                                                    onwardBaggageOptions.length >
                                                                                                    0
                                                                                                    ? "Select Luggage"
                                                                                                    : "No Luggage Options Available"
                                                                                            }
                                                                                            variant="outlined"
                                                                                            color="secondary"
                                                                                            size="small"
                                                                                            value={
                                                                                                traveler.selectedBaggage
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                    disabled={
                                                                                        onwardBaggageOptions &&
                                                                                            onwardBaggageOptions.length >
                                                                                            0
                                                                                            ? false
                                                                                            : true
                                                                                    }
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                            )}
                                                        </Table>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        )}
                    </div>
                )}
        </div>
    )
}

export default ReviewBookingTravelerInput;