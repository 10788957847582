import React, { useState } from "react";
import "./ConfigurationTable.css";
import ConfigurationMock from "../../../data/mock/ConfigurationMock";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import useConfigurationTableRow from "../../../hooks/useConfigurationTableRow";
import useConfigurationTableCell from "../../../hooks/useConfigurationTableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";

export default function ConfigurationTable({ openDrawer, setAction }) {
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <TableContainer component={Paper} className="table-paper-styles">
        <Table className="table-background">
          <TableHead>
            <TableRow>
              <TableCell>Origin</TableCell>
              <TableCell>Destination</TableCell>
              <TableCell>Cabin</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell>Includes</TableCell>
              <TableCell>Excludes</TableCell>
              <TableCell align="right">Modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ConfigurationMock.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).map((row) => (
              <StyledTableRow
                key={JSON.stringify(row)}
                onClick={() => {
                  setAction("view");
                  openDrawer(row);
                }}
              >
                <StyledTableCell component="th" scope="row">
                  <div className="table-cell-title">
                    {row.originCountry.country}
                  </div>
                  <div className="table-cell-description">
                    {row.originCountry.airports}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-cell-title">
                    {row.destinationCountry.country}
                  </div>
                  <div className="table-cell-description">
                    {row.destinationCountry.airports}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-cell-title">{row.class.cabin}</div>
                  <div className="table-cell-description">{row.class.type}</div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-cell-title">
                    {row.provider.supplier}
                  </div>
                  <div className="table-cell-description">
                    {row.provider.pcc}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  {/* <div className="table-cell-title">
                    {row.provider.supplier}
                  </div> */}
                  <div className="table-cell-description">{row.inclusion}</div>
                </StyledTableCell>
                <StyledTableCell>
                  {/* <div className="table-cell-title">
                    {row.provider.supplier}
                  </div> */}
                  <div className="table-cell-description">{row.exclusion}</div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div className="table-cell-description">{row.owner}</div>
                  <div className="table-cell-description">
                    created at {row.created.toISOString().split("T")[0]}
                  </div>
                  <div className="table-cell-description">
                    modified at {row.modified.toISOString().split("T")[0]}
                  </div>
                </StyledTableCell>
                {/* <StyledTableCell align="right">
                  <div className="table-cell-description">Created by <span style={{color:"black"}}>{row.owner}</span></div>
                  <div className="table-cell-description">
                    created at {row.created.toISOString().split("T")[0]}
                  </div>
                  <div className="table-cell-description">
                    modified at {row.modified.toISOString().split("T")[0]} 
                  </div>
                </StyledTableCell>
                */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={ConfigurationMock.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => handleChangePage(event, newPage)}
        onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
      />
    </div>
  );
}
