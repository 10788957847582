import React, { useEffect, useState, useRef } from "react";
import "./QueueTable.css";
import {
  Button,
  Container,
  Grid,
  Table,
  TableCell,
  TableRow,
  Divider,
  Paper,
} from "@material-ui/core";
import SearchBar from "../SearchBar/SearchBar";
import Results from "../Results/Results";
import Pager from "../Pager/Pager";
import Facets from "../Facets/Facets";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import "./QueueTable.css";
import { CSVLink } from "react-csv";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import RiseLoading from "../../Loader/RiseLoader";
import { checkIfUserStaff, getCurrentRole, isClientUserHasPermission } from "../../../services/AuthService";
import { formatDate } from "../../../services/dateFormatter";
import generateExcelFile from "../../../providers/ExcelExportProvider/ExcelExportProvider";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TrainIcon from "@mui/icons-material/Train";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// import HotelIcon from "@material-ui/icons/Hotel";
function Alerts(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function QueueTable() {
  let location = useLocation();
  let history = useHistory();
  const csvLink = useRef();

  const [results, setResults] = useState([]);
  const [exportresults, setExportResults] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [actualCount, setActualCount] = useState(0);
  const [dbCount, setDBCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [q, setQ] = useState(
    new URLSearchParams(location.search).get("q") ?? ""
  );
  const [top] = useState(new URLSearchParams(location.search).get("top") ?? 8);
  const [skip, setSkip] = useState(
    new URLSearchParams(location.search).get("skip") ?? 0
  );
  const [filters, setFilters] = useState([]);
  const [facets, setFacets] = useState({});
  const [railFacets, setRailFacets] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [DateFrom, setDateFrom] = useState();
  const [DateTo, setDateTo] = useState();
  const [inputDateFrom, setinputDateFrom] = useState();
  const [inputDateTo, setinputDateTo] = useState();
  const [validationmessage, setValidationMessage] = useState();
  const [isPageNav, setPageNav] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [serviceType, setServiceType] = useState('Flight');
  const [ifNoDataExport, setIfNoDataExport] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  let resultsPerPage = top;

  useEffect(() => {
    validateDate();
  }, [DateFrom, inputDateFrom, DateTo, inputDateTo]);


  useEffect(() => {

    const receivedData = history?.location?.state?.data;
    if (receivedData == "BookingOnHold") {
      const newFilters = filters?.concat(
        {
          field: "Status",
          value: "Booking_On_Hold",
        },
        {
          field: "DateFrom",
          value: "",
        },
        {
          field: "DateTo",
          value: "",
        }
      );
      setFilters((filters) => newFilters);
      // if (serviceType === 'Flight') {
      //   loadMyQueue(0, "", "", newFilters)
      // } else if (serviceType === 'Rail') {
      //   loadRailQueue(0, "", "", newFilters)
      // }
    } else {
      setDateFrom(formatDate(new Date()));
      setDateTo(formatDate(new Date()));
      setinputDateFrom(new Date());
      setinputDateTo(new Date());
      // postSearchHandler("", new Date(), new Date(), false);
    }

    setInitialLoad(true);

    checkPermissions();
  }, []);
  useEffect(() => {

  }, [results])
  useEffect(() => {

    var skip = (currentPage - 1) * top;
    setSkip(skip);

    if (filters.length !== 0 && currentPage !== "1") {
      if (!isPageNav) {
        setCurrentPage(1);
      }
      if (serviceType === 'Flight') {
        loadMyQueue(skip, "", q, [])
      } else if (serviceType === 'Rail') {
        loadRailQueue(skip, "", q, [])
      }
    }
    if (filters.length !== 0 && currentPage === "1" && isPageNav === true) {
      if (!isPageNav) {
        setCurrentPage(1);
      }
      if (serviceType === 'Flight') {
        loadMyQueue(skip, "", q, [])
      } else if (serviceType === 'Rail') {
        loadRailQueue(skip, "", q, [])
      }
    }
    if (filters.length === 0 && isPageNav === true) {
      if (serviceType === 'Flight') {
        loadMyQueue(skip, "", q, [])
      } else if (serviceType === 'Rail') {
        loadRailQueue(skip, "", q, [])
      }
    }
  }, [currentPage, isPageNav, top]);

  let postSearchHandler = (searchTerm, dateFrom, dateTo, serviceChange) => {
    if (validateDate()) {
      const updateFilter = filters.map((element) =>
        element.field === "DateFrom"
          ? {
            field: "DateFrom",
            value:
              dateFrom instanceof Date
                ? moment(dateFrom).format("YYYY-MM-DD") + "T00:00:00.000Z"
                : "",
            displayvalue: dateFrom,
          }
          : element.field === "DateTo"
            ? {
              field: "DateTo",
              value:
                dateTo instanceof Date
                  ? moment(dateTo).format("YYYY-MM-DD") + "T23:59:00.000Z"
                  : "",
              displayvalue: dateTo,
            }
            : element
      );

      if (filters.findIndex((x) => x.field === "DateFrom") === -1) {
        updateFilter.push({
          field: "DateFrom",
          value:
            dateFrom instanceof Date
              ? moment(dateFrom).format("YYYY-MM-DD") + "T00:00:00.000Z"
              : "",
          displayvalue: dateFrom,
        });
      }

      if (filters.findIndex((x) => x.field === "DateTo") === -1) {
        updateFilter.push({
          field: "DateTo",
          value:
            dateTo instanceof Date
              ? moment(dateTo).format("YYYY-MM-DD") + "T23:59:00.000Z"
              : "",
          displayvalue: dateTo,
        });
      }
      setQ((q) => searchTerm);
      setFilters((filters) => updateFilter);
      if (serviceType === 'Flight' && serviceChange) {
        loadMyQueue(skip, "", searchTerm, [])
        setFilters([])
      }
      else if (serviceType === 'Flight' && !serviceChange) {
        loadMyQueue(skip, "", searchTerm, updateFilter)
        setFilters(updateFilter)
      }
      else if (serviceType === 'Rail' && serviceChange) {
        loadRailQueue(skip, "", searchTerm, []); setFilters([])
      }
      else if (serviceType === 'Rail' && !serviceChange) {
        loadRailQueue(skip, "", searchTerm, updateFilter)
        setFilters(updateFilter)
      }
    }
  };

  function addDays(date, days) {
    if (date !== undefined && date !== null) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    } else {
      return undefined;
    }
  }
  const validateDate = () => {
    let valid = true;
    if (
      (inputDateTo === undefined || inputDateTo === null) &&
      (inputDateFrom === null || inputDateFrom === undefined)
    ) {
      setValidationMessage("");
    } else if (
      new Date(inputDateFrom) !== null &&
      (inputDateTo === undefined || inputDateTo === null)
    ) {
      setValidationMessage("Please select To Date.");
      valid = false;
    } else if (
      (inputDateFrom === null || inputDateFrom === undefined) &&
      new Date(inputDateTo) !== null
    ) {
      setValidationMessage("Please select From Date.");
      valid = false;
    } else if (new Date(inputDateFrom) === new Date(inputDateTo)) {
      valid = true;
      setValidationMessage("");
    } else if (new Date(inputDateFrom) > new Date(inputDateTo)) {
      setValidationMessage("From date should not be greater than  To Date");
      valid = false;
    } else {
      setValidationMessage("");
    }
    return valid;
  };

  let loadRailQueue = (skiprecord, clear, term, updateFilter) => {

    setIsLoading(true);
    const body = {
      q: clear === "clear" ? "" : term,
      top: top,
      skip: skiprecord,
      filters: updateFilter && updateFilter.length ? updateFilter : filters,
    };
    const headers = { "Content-Type": "application/json" };
    axios
      .post(
        process.env.REACT_APP_CORE_URL + "rail/Configuration/RailOrderQueue",
        // process.env.REACT_APP_CORE_URL + "api/Configuration/BookingOrderQueue",
        body,
        { headers }
      )
      .then((response) => {
        setResults((prev) => response.data.results);
        setRailFacets((prev) => response.data.facets);
        setResultCount((prev) => response.data.count);
        setIsLoading((isLoading) => false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading((isLoading) => false);
      });
  };
  let loadMyQueue = (skiprecord, clear, term, updateFilter) => {

    const url = process.env.REACT_APP_CORE_URL + "api/Configuration/OrderQueue"
    setIsLoading(true);
    const body = {
      q: clear === "clear" ? "" : term,
      top: top,
      skip: skiprecord,
      filters: updateFilter?.length ? updateFilter : filters,
    };
    const headers = { "Content-Type": "application/json" };
    axios
      .post(
        url,
        body,
        { headers }
      )
      .then((response) => {
        setResults((prev) => response.data.results);
        setFacets((prev) => response.data.facets);
        setResultCount((prev) => response.data.count);
        setActualCount((prev) => response.data.actualcount);
        setDBCount((prev) => response.data.dbcount);
        setIsLoading((isLoading) => false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading((isLoading) => false);
      });
  };
  const clientExportExcel = (filteredArray, openingbalance, closingbalance) => {

    if (filteredArray.length === 0) {
      setIsLoading(false);
      setIfNoDataExport(true);
    }
    else {
      setIfNoDataExport(false);
      let resultlist = [];
      let opendataArr = [];
      let openingBalance = openingbalance;
      let closingBalance = closingbalance;
      let clientName;
      let statementText = '';
      if (inputDateFrom && inputDateTo) {
        statementText = `Account Statement from ${moment(inputDateFrom).format(
          "MM/DD/YYYY"
        )} till ${moment(inputDateTo).format(
          "MM/DD/YYYY"
        )} - Report Generated on ${moment().format("MM/DD/YYYY HH:mm")}`
      } else {
        statementText = `Account Statement from ${moment(filteredArray[0]?.document?.CreatedOnDate).format(
          "MM/DD/YYYY"
        )} till ${moment(filteredArray[(filteredArray.length - 1)]?.document?.CreatedOnDate).format(
          "MM/DD/YYYY"
        )} - Report Generated on ${moment().format("MM/DD/YYYY HH:mm")}`
      }
      filteredArray.forEach((item, index) => {
        clientName =
          item?.document?.ClientName + " (" + item?.document?.ClientId + ") ";
        const onwardDate =
          item?.document?.OnwardDate !== null
            ? item?.document?.OnwardDate.split("T")[0]
            : '';
        const ReturnDate =
          item?.document?.ReturnDate !== null
            ? item?.document?.ReturnDate.split("T")[0]
            : '';
        let pnr = '';

        if (item.document.Services === 'flight') {
          pnr = item?.document?.OrderSegment?.length && item?.document?.OrderSegment[0]?.airlinePNR ? item?.document?.OrderSegment[0]?.airlinePNR : ''
        } else if (item.document.Services === 'Rail') {
          pnr = item?.document?.RailOrderSegment?.length && item?.document?.RailOrderSegment[0]?.pnr ? item?.document?.RailOrderSegment[0]?.pnr : ''
        }
        let SalesData = {
          "Searchable Date": item?.document?.Status === "Recharge" ? moment(item?.document?.CreatedOnDate).format('MM/DD/YYYY') : item?.document?.SearchableDate ? moment(item?.document?.SearchableDate).format('MM/DD/YYYY') : moment(item?.document?.CreatedOnDate).format('MM/DD/YYYY'),
          'Services': item?.document?.Services ? item?.document?.Services : "",
          "Order Ref Number": item?.document?.OrderRefNumber ? item?.document?.OrderRefNumber : "",
          'GDS PNR': item?.document?.GdsPNR ? item?.document?.GdsPNR : "",
          "Airline/Rail PNR": pnr,
          'Booking Status': item?.document?.BookingStatus ? item?.document?.BookingStatus : "",
          'Current Status': item?.document?.Status ? item?.document?.Status : "",
          'Journey Type': item?.document?.JourneyType ? item?.document?.JourneyType : "",
          'Origin': item?.document?.Origin ? item?.document?.Origin : "",
          'Destination': item?.document?.Destination ? item?.document?.Destination : "",
          'Onward Date': onwardDate,
          'Return Date': ReturnDate,
          'Cabin Class': item?.document?.OrderSegment?.length && item?.document?.OrderSegment[0]?.CabinClass ? item?.document?.OrderSegment[0]?.CabinClass : '',
          'Pax Count': item?.document?.PaxCount ? item?.document?.PaxCount : "",
          'Currency': item?.document?.Currency ? item?.document?.Currency : "",
          'Base Fare': item?.document?.BaseFare ? item?.document?.BaseFare : "",
          'Taxes': item?.document?.Taxes ? item?.document?.Taxes : "",
          'SSR Amount': item?.document?.SSRamount ? item?.document?.SSRamount : "",
          'Service Charge': item?.document?.ServiceCharge ? item?.document?.ServiceCharge : "",
          'Gross Fare': item?.document?.GrossFare ? item?.document?.GrossFare : "",
          'Cancel Ref No': item?.document?.CancelRefNo ? item?.document?.CancelRefNo : "",
          'Total Net Refund Amount': item?.document?.TotalNetRefundAmount ? item?.document?.TotalNetRefundAmount : "",
          'Ledger Balance': item?.document?.LedgerBalance ? item?.document?.LedgerBalance : "",
          'Amount Printed On Ticket': item?.document?.AmountPrintedOnTicket ? item?.document?.AmountPrintedOnTicket : "",
          'User': item?.document?.User ? item?.document?.User : "",
        };

        let array = [];
        for (let key in SalesData) {
          array.push(SalesData[key]);
        }
        resultlist.push(array);
      });
      opendataArr.push([]);
      opendataArr.push([
        "",
        "",
        "",
        clientName,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);
      opendataArr.push([
        "",
        "",
        "",
        statementText,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);
      opendataArr.push([]);
      opendataArr.push([]);
      opendataArr.push([
        "Date",
        "Service",
        "Booking Ref.",
        "GDS PNR",
        "Airline/Rail PNR",
        "Booking Status",
        "Current Status",
        "JourneyType",
        "Origin",
        "Destinations",
        "Onwards dates",
        "Return Dates",
        "Cabin Class",
        "Passenger Name*1",
        "Currency",
        "Base Fare",
        "Taxes",
        "SSR Amount",
        "Service Charge",
        "Gross Fare",
        "Cancellation/raf",
        "Net Fare",
        "Ledger Balance",
        "Amount Printed on Ticket",
        "User",
      ]);
      opendataArr.push([]);
      opendataArr.push([]);
      opendataArr.push([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Opening Balance",
        "",
        "",
        openingBalance,
        "",
        "",
      ]);
      opendataArr.push([]);
      resultlist.forEach((item) => {
        opendataArr.push(item);
      });
      opendataArr.push([]);
      opendataArr.push([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Closing Balance",
        "",
        "",
        closingBalance,
        "",
        "",
      ]);
      opendataArr.push([]);
      opendataArr.push([]);
      opendataArr.push([]);
      opendataArr.push([]);
      opendataArr.push([
        "",
        "",
        "Opening Balance",
        "",
        "",
        openingBalance,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);
      opendataArr.push([
        "",
        "",
        "Closing Balance",
        "",
        "",
        closingBalance,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);
      opendataArr.push([]);
      generateExcelFile(opendataArr, resultlist.length);
      setIsLoading(false);
    }
  };
  const getFormattedTicketNumber = (item) => {
    if (item.OrderPax.length > 1) {
      let temp = '';
      item.OrderPax.forEach((pax, paxindex) => {
        if (pax.ETicketNumber) { paxindex === 0 ? temp = pax.ETicketNumber : temp = temp + "/" + pax.ETicketNumber }
      })
      return temp;
    } else {
      return item.OrderPax[0]?.ETicketNumber ? item.OrderPax[0].ETicketNumber : ""
    }
  }
  const staffExportExcel = (filteredArray) => {

    if (filteredArray.length === 0) {
      setIsLoading(false);
      setIfNoDataExport(true);
    } else {
      setIfNoDataExport(false);
      let resultlist = [];
      filteredArray.forEach((item) => {
        const CreatedOnDate =
          item.document.CreatedOnDate !== null
            ? item.document.CreatedOnDate.split("T")[0]
            : null;
        const CreatedOnTime =
          item.document.CreatedOnDate !== null
            ? moment(item.document.CreatedOnDate).utc().format("hh:mm:ss")
            // ? (item.document.CreatedOnDate.split("T")[1]).split("Z")[0]
            : null;
        const onwardDate =
          item.document.OnwardDate !== null
            ? item.document.OnwardDate.split("T")[0]
            : null;
        const TicketedDate =
          item.document.TicketedDate !== null
            ? item.document.TicketedDate.split("T")[0]
            : null;
        const ReturnDate =
          item.document.ReturnDate !== null
            ? item.document.ReturnDate.split("T")[0]
            : null;
        const SalesData = {
          'Created Date': CreatedOnDate,
          'Created Time': CreatedOnTime.toString(),
          'Client Name': item.document.ClientName,
          'Ticketed Date': TicketedDate,
          'Ticketed Month': item.document.TicketedMonth,
          'Order Ref No': item.document.OrderRefNumber,
          'Booking Status': item.document.BookingStatus,
          'Current Status': item.document.Status,
          'Gds PNR': item.document.GdsPNR,
          'Supplier': item.document.Supplier,
          'PCC': item.document.PCC,
          'Origin': item.document.Origin,
          'Origin Country': item.document.OriginCountry,
          'Destination': item.document.Destination,
          'Destination Country': item.document.DestinationCountry,
          'Client Type': item.document.ClientType,
          'Vendor Name': item.document.VendorName,
          'Country Code': item.document.CountryCode,
          'Journey Type': item.document.JourneyType,
          'Booking Type': item.document.BookingType,
          'Onward Date': onwardDate,
          'Return Date': ReturnDate,
          'Fare Basis': item.document.FareBasis,
          'Client IBoss': item.document.ClientIBoss,
          'Booking Source': item.document.BookingSource,
          'Sales Staff Name': item.document.SalesStaffName,
          'Vendor Amount': item.document.VendorAmount,
          Profit: item.document.Profit,
          'Total Fare': item.document.TotalFare,
          'Airline Name': item?.document?.OrderSegment[0]?.AirlineName ? item?.document?.OrderSegment[0]?.AirlineName : '',
          'Airline PNR': item?.document?.OrderSegment[0]?.AirlinePNR ? item?.document?.OrderSegment[0]?.AirlinePNR : '',
          'Cabin Class': item?.document?.OrderSegment[0]?.CabinClass ? item?.document?.OrderSegment[0]?.CabinClass : '',
          'Pax Count': item?.document?.OrderPax?.length ? item?.document?.OrderPax?.length : 0,
          'ETicket Number': getFormattedTicketNumber(item?.document),
        };

        resultlist.push(SalesData);
      });
      setExportResults(exportresults => resultlist);
      setTimeout(() => {
        csvLink.current.link.click();
        setIsLoading((isLoading) => false);
      }, 3000);
    }
  };
  let myqueueExportExcel = () => {
    setIsLoading(true);
    const url = checkIfUserStaff() ? process.env.REACT_APP_CORE_URL + "api/Configuration/OrderQueue" : process.env.REACT_APP_CORE_URL + "api/Configuration/OrderQueue/GetClientAccountStatement"
    const body = { q: q, top: resultCount, skip: 0, filters: filters };
    const headers = { "Content-Type": "application/json" };
    axios
      .post(
        url,
        body,
        { headers }
      )
      .then((response) => {
        if (checkIfUserStaff() === true) {
          staffExportExcel(response.data.results);
        } else {
          clientExportExcel(response.data.results, response.data.openingbalance, response.data.closingbalance);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  let body;
  if (isLoading) {
    body = (
      <div className="col-md-12">
        <RiseLoading isQueueTable={true} />
      </div>
    );
  } else if (resultCount > 0) {
    body = (
      <div className="col-md-12">
        <Results
          documents={results}
          top={top}
          skip={skip}
          count={resultCount}
          serviceType={serviceType}
        ></Results>
        <Pager
          className="pager-style"
          currentPage={currentPage}
          resultCount={resultCount}
          resultsPerPage={resultsPerPage}
          setCurrentPage={setCurrentPage}
          setPageNav={setPageNav}
        ></Pager>
      </div>
    );
  } else {
    body = (
      <div className="col-md-12">
        <Results
          documents={results}
          top={top}
          skip={skip}
          count={resultCount}
          serviceType={serviceType}
        ></Results>
        <Table style={{ width: "100%" }}>
          <TableRow>
            <TableCell component="th" scope="row" align="justify">
              <Alert severity="error">No record found.</Alert>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    );
  }
  useEffect(() => {
    if (serviceType === 'Rail') {
      setDateFrom(formatDate(new Date()));
      setDateTo(formatDate(new Date()));
      setinputDateFrom(new Date());
      setinputDateTo(new Date());
      postSearchHandler("", new Date(), new Date(), false);
    }
    else if (serviceType === 'Flight') {

      const receivedData = history?.location?.state?.data;
      if (receivedData == "BookingOnHold") {
        const newFilters = filters?.concat(
          {
            field: "Status",
            value: "Booking_On_Hold",
          },
          {
            field: "DateFrom",
            value: "",
          },
          {
            field: "DateTo",
            value: "",
          }
        );
        setFilters((filters) => newFilters);
        if (serviceType === 'Flight') {
          loadMyQueue(0, "", "", newFilters)
        } else if (serviceType === 'Rail') {
          loadRailQueue(0, "", "", newFilters)
        }
      } else {
        setDateFrom(formatDate(new Date()));
        setDateTo(formatDate(new Date()));
        setinputDateFrom(new Date());
        setinputDateTo(new Date());
        postSearchHandler("", new Date(), new Date(), false);
      }

      history.push({
        state: { data: "" },
      });

      setInitialLoad(true);
    }

  }, [serviceType])
  const handleChange = (event, newValue) => {
    setServiceType(newValue);
  };
  let beginDocNumber = Math.min(skip + 1, resultCount);
  let endDocNumber = Math.min(skip + top, resultCount);

  const getAzureDiscrepency = async () => {
    const state = {
      dateFrom: inputDateFrom,
      dateTo: inputDateTo,
      queueTable: "queueTableData",
    };

    localStorage.setItem('orderTraceReportData', JSON.stringify(state));

    window.open('/OrderTraceReport', '_blank');

  }

  const checkPermissions = () => {
    const userRole = getCurrentRole();
    if ((!checkIfUserStaff() && isClientUserHasPermission("IsAccountStatement")) || userRole === "ClientSuperAdmin" || checkIfUserStaff()) {
      setIsDisabled(false);
    }
    else {
      setIsDisabled(true);
    }
  }


  return (
    <div>
      {
        ifNoDataExport ?
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={ifNoDataExport}
            autoHideDuration={4000}
            onClose={() => { setIfNoDataExport(false) }}
          >
            <Alerts onClose={() => { setIfNoDataExport(false) }} severity="error">
              {`No Data To Export`}
            </Alerts>
          </Snackbar> : ''
      }
      <Container>
        <Grid container spacing={0} className="searchSticky">
          <Grid item xs={12}>
            <Paper
              style={{
                padding: "10px",
                paddingBottom: "20px",
                marginTop: "10px",
                borderRadius: "10px",
              }}
            >
              <SearchBar
                postSearchHandler={postSearchHandler}
                loadMyQueue={loadMyQueue}
                loadRailQueue={loadRailQueue}
                serviceType={serviceType}
                q={q}
                setQ={setQ}
                DateFrom={DateFrom}
                DateTo={DateTo}
                inputDateTo={inputDateTo}
                inputDateFrom={inputDateFrom}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                setinputDateFrom={setinputDateFrom}
                setinputDateTo={setinputDateTo}
                setFilters={setFilters}
                filters={filters}
                setPageNav={setPageNav}
                setCurrentPage={setCurrentPage}
                isPageNav={isPageNav}
                initialLoad={initialLoad}
              ></SearchBar>
              <div className="invalid">{validationmessage}</div>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={0} style={{ position: 'relative' }}>
          <Grid item xs={3} className="FilterContainerSticky">

            <div className="sidebar-container1">

              <div className="filter-sidebar-heading filterHeadSticky">
                <Tabs value={serviceType} onChange={handleChange} aria-label="icon label tabs example">
                  <Tab value='Flight' icon={<FlightTakeoffIcon />} label="Flight" />
                  <Tab value='Rail' icon={<TrainIcon />} label="Rail" />
                  {/* <Tab icon={<PersonPinIcon />} label="Hotel" /> */}
                </Tabs>
                <p
                  style={{
                    margin: "0px",
                    padding: " 5px 0px 5px 0px",
                    marginRight: "4.5em",
                  }}
                >
                  Filters
                </p>

              </div>
              <Facets
                facets={facets}
                railFacets={railFacets}
                serviceType={serviceType}
                filters={filters}
                setFilters={setFilters}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                setinputDateFrom={setinputDateFrom}
                setinputDateTo={setinputDateTo}
                setPageNav={setPageNav}
              ></Facets>
            </div>
          </Grid>
          <Grid item xs={9} md={9}>
            <div className="main-container1">
              <div className="exportSticky">
                <div className="search-sidebar-heading">
                  Search: Showing {beginDocNumber}-{endDocNumber} of{" "}
                  {resultCount.toLocaleString()} results
                </div>
                {checkIfUserStaff() && serviceType === 'Flight' && (actualCount !== dbCount) &&
                  <div style={{ cursor: "pointer" }}
                    onClick={() => getAzureDiscrepency()}
                    className={`${(actualCount !== dbCount) ? "dbcount-css-diff" : "dbcount-css-same"}  border rounded`}>
                    <div className="border rounded me-2">Count: {actualCount.toLocaleString()}</div>
                    <div className="border rounded">DB Count: {dbCount.toLocaleString()}</div>
                    <div className="border rounded"></div>
                  </div>}
                <div className="search-sidebar-heading"></div>
                <div className="search-sidebar-heading"></div>
                <div className="search-sidebar-heading"></div>
                <div className="search-sidebar-heading"></div>
                <div className="sidebar-heading-right">
                  {/* {serviceType === 'Flight' &&*/}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginBottom: "5px" }}
                    onClick={() => myqueueExportExcel()}
                    disabled={isDisabled}
                  >
                    Export Queue
                  </Button>
                  {/*  } */}
                  <CSVLink
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                    data={exportresults}
                    filename="Account Statement"
                  />
                </div>
              </div>
              <Divider />
              <div className="permissions-box">{body}</div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default QueueTable;
