import React, { useEffect, useState } from 'react';
import { Button, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';

const CobDocVerification = ({ docByRefId, formatDate, setVerificationPopUp, viewVerificationDashboard }) => {

    const [documentStatus, setDocumentStatus] = useState('');
    const [remark, setRemark] = useState('');
    const [showResMsg, setShowResMsg] = useState('');


    const handleDocumentVerifyChange = (e) => {
        setDocumentStatus(e.target.value);
    };

    const handleAddRemark = (e) => {
        setRemark(e.target.value);
    };

    const handleCreateGFS = async () => {
        if (remark !== "" && documentStatus !== "") {
            setShowResMsg("");

            const headers = {
                "Content-Type": "application/json",
            };

            await axios
                .post(
                    process.env.REACT_APP_CORE_URL +
                    "api/Configuration/LeadClients/CreateGFS",
                    JSON.stringify({
                        verifierRemark: remark,
                        isVerified: documentStatus === "verified" ? true : false,
                        refId: docByRefId.leadClients.id
                    }),
                    { headers }
                )
                .then((res) => {
                    console.log('res:', res.data);
                    // setShowResMsg("GFS Created Successfully");
                    setVerificationPopUp(false);
                    viewVerificationDashboard();
                })
                .catch((err) => {
                    console.log('Error:', err);
                    // setShowResMsg('Error: ' + err);
                });
        }
        else {
            setShowResMsg("*Please fill the mandatory fields");
        }

    }

    return (
        <div className="lead-details-container">
            <div className="d-flex justify-content-between">
                <div>
                    <div>
                        <div>Reference No: {docByRefId.leadClients.id}</div>
                        <div>Date: {formatDate(docByRefId.leadClients.added_on)}</div>
                        <div>Product Required: {docByRefId.leadClients.product_required}</div>
                    </div>

                    <div className="mt-4">
                        <div className='text-bold'>Lead Details:</div>
                        <div>First Name and Last Name: {" " + docByRefId.leadClients.fname + " " + docByRefId.leadClients.lname}</div>
                        <div>Organization Name: {" " + docByRefId.leadClients.org_name}</div>
                        <div>Email: {" " + docByRefId.leadClients.email}</div>
                        <div>Phone: {" " + docByRefId.leadClients.phone}</div>
                    </div>

                    <div className="document-section mt-4">
                        <div className='text-bold'>Document Received: </div>
                        {docByRefId.docs.length > 0 ? (
                            docByRefId.docs.map((doc, index) => (
                                <div>
                                    <a href={doc.imagePath} download={doc.fileName}>
                                        {doc.fileType}
                                    </a>
                                </div>
                            ))
                        ) : (
                            <div>No documents received</div>
                        )}
                    </div>
                </div>

                <div style={{ width: "350px" }}>
                    <div className="document-verification">
                        <FormControl required fullWidth className='border rounded'>
                            <InputLabel id="docVerify-label">Document Status</InputLabel>
                            <Select
                                labelId="docVerify-label"
                                id="docVerify"
                                value={documentStatus}
                                label="Document Status"
                                onChange={handleDocumentVerifyChange}
                            >
                                <MenuItem value="" disabled>Select Status</MenuItem>
                                <MenuItem value="verified">Docs Verified</MenuItem>
                                <MenuItem value="unable">Unable to Verify</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="add-remark mt-4">
                        <TextField
                            required
                            className='rounded border'
                            label="Add Remark"
                            multiline
                            fullWidth
                            style={{ height: "100px" }}
                            value={remark}
                            onChange={handleAddRemark}
                        />
                    </div>
                    <div className='text-danger'>{showResMsg}</div>
                </div>

            </div>

            <div className="create-gfs mt-4 d-flex justify-content-between">
                <div></div>
                <Button className='srn-btn' variant="contained" color="primary" onClick={handleCreateGFS}>
                    Create GFS
                </Button>
            </div>
            {/* <div className='d-flex justify-content-between'>
                <div></div>
                <div className={"mb-5 " + showResMsg.toLowerCase().includes() === "error" ? "text-danger" : "text-success"}>{showResMsg}</div>
            </div> */}
        </div>
    );
};

export default CobDocVerification;
