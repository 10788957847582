import React, { useEffect, useState } from "react";
import "./VendorDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import {
  FormControl,
  IconButton,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import moment from "moment";

import Button from "@material-ui/core/Button";
import countries from "../../../../data/Country";
import TimeZones from "../../../../data/TimeZones";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Currencies from "../../../../data/Currencies";
import axios from "axios";
import AntTabs from "../../../../hooks/useTabsStyles";
import AntTab from "../../../../hooks/useTabStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import cabinClassList from "../../../../data/CabinClass";
import fareBasisTypeList from "../../../../data/FareBasisType";
import fareTypeList from "../../../../data/FareType";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import useConfigurationTableCell from "../../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../../hooks/useConfigurationTableRow";
import Alert from "@material-ui/lab/Alert";
import { Tab } from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { isUserHasPermission } from "../../../../services/AuthService";
import Snackbar from "@material-ui/core/Snackbar";
import RiseLoading from "../../../Loader/RiseLoader";
import FlightCommertialConfiguration from "../CommertialConfiguration/FlightCommertialConfiguration";
import RailCommertialConfiguration from "../CommertialConfiguration/RailCommertialConfiguration";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const calTypes = ["Percentage", "Value"];
const applicableOnList = [
  "Base",
  "Tax",
  "Total",
  "Opportunity",
  "ServiceFee",
  "Commission",
  "CommissionAndServiceFee",
];
const taxList = ["AIT", "GST", "Other", "VAT"];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="ml-8 mr-8">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function getStylesMultiselect(value, cabinClassType, theme) {
  return {
    fontWeight:
      cabinClassType !== undefined && cabinClassType.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const SelectProps = {
  multiple: true,
};

export default function VendorDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
  countryAndStationData,
  loadVendor
}) {

  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;

  const classes = useStyles();
  const [vendorName, setVendorName] = useState("");
  const [fax, setFax] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [timeZone, setTimeZone] = useState("0.00");
  const [cabinClass, setcabinClass] = useState([]);
  const [workingHoursFrom, setWorkingHoursFrom] = useState("");
  const [workingHoursTo, setWorkingHoursTo] = useState("");
  const [workingDays, setWorkingDays] = useState("");
  const [holidays, setHolidays] = useState("");
  const [tat, setTat] = useState("");
  const [vendorFee, setVendorFee] = useState("");
  const [voidingFee, setVoidingFee] = useState("0.0");
  const [refundFee, setRefundFee] = useState("");
  const [reissueFee, setReissueFee] = useState("");
  const [afterWorkingHoursFee, setAfterWorkingHoursFee] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [fareType, setfareType] = useState([]);
  const [GSTOnCommission, setGSTOnCommission] = useState("");
  const [MiscellaneousFee, setMiscellaneousFee] = useState("");
  const [MiscellaneousFeeApplicableOn, setMiscellaneousFeeApplicableOn] =
    useState("Base");
  const [ServiceCharge, setServiceCharge] = useState("");
  const [ServiceChargeApplicableOn, setServiceChargeApplicableOn] =
    useState("Base");
  const [VendorApprovalStatus, setVendorApprovalStatus] = useState("");
  const [mode, setMode] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [id, setId] = useState("");
  const [value, setValue] = useState(0);
  const [originCityOptions, setOriginCityOptions] = useState([]);
  const [destinationCityOptions, setDestinationCityOptions] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [documentType, setdocumentType] = useState();
  const saveFile = (e) => {
    // console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const [filesList, setFilesList] = useState(null);
  const [historyList, setHistoryList] = useState(null);

  const [iBossBooksCountry, setIBossBooksCountry] = useState(0);
  const [iBossRefId, setIBossRefId] = useState(0);
  const [alertText, setalertText] = useState("");
  const [isUpdateBtnDisabled, setisUpdateBtnDisabled] = useState(false);
  const [isApprovalBtnDisabled, setisApprovalBtnDisabled] = useState(false);
  const [isuploadBtnDisabled, setisuploadBtnDisabled] = useState(false);
  const [stockType, setstockType] = useState("None");
  const [productType, setProductType] = useState("Flight");

  const initialoriginCountryInputList = [
    {
      originCountry: "",
      originStation: "",
      destinationCountry: "",
      destinationStation: "",
      cabinClass: "",
      fareType: "",
      issuanceFees: "",
      serviceCurrency: '',
      serviceFees: '',
    },
  ]

  const [originCountryInputList, setoriginCountryInputList] = useState(initialoriginCountryInputList);
  const [railCommertialConfiguration, setRailCommertialConfiguration] = useState(initialoriginCountryInputList);


  const [exOriginCountryInputList, setexOriginCountryInputList] = useState([
    {
      excludedOriginCountry: "",
      excludedDestinationCountry: "",
      exCabinClass: "",
      exFareType: "",
      exIssuanceFees: "",
    },
  ]);

  // Below Code for Origin Country related Functions
  const handleAddOriginCountryRowClick = (productType) => {
    if (productType === 'Rail') {
      setRailCommertialConfiguration((prevState) => {
        return (
          [
            ...prevState,
            initialoriginCountryInputList,
          ]
        )
      })
    } else if (productType === 'Flight') {
      setoriginCountryInputList((prevState) => {
        return (
          [
            ...prevState,
            initialoriginCountryInputList,
          ]
        )
      })
    }

    //console.log(originCountryInputList);
  };

  const handleOriginCountryInputChange = (productType, e, index) => {
    const { name, value } = e.target;
    if (productType === 'Rail') {
      setRailCommertialConfiguration((prevState) => {
        const originCountryData = [...prevState];
        originCountryData[index][name] = value;
        return originCountryData;
      })
      return;
    }
    else if (productType === 'Flight') {
      setoriginCountryInputList((prevState) => {
        const originCountryData = [...prevState];
        originCountryData[index][name] = value;
        return originCountryData;
      })

      return;
    }
  };

  const handleRemoveOriginCountryRow = (productType, index) => {
    if (productType === 'Flight') {
      const originCountryListItem = [...originCountryInputList];
      originCountryListItem.splice(index, 1);
      setoriginCountryInputList(originCountryListItem);
    } else if (productType === 'Rail') {
      const originCountryListItem = [...originCountryInputList];
      originCountryListItem.splice(index, 1);
      setRailCommertialConfiguration(originCountryListItem);
    }
  };

  // Below Code for ExOrigin Country related Functions
  const handleAddExoriginCountryRowClick = () => {
    setexOriginCountryInputList([
      ...exOriginCountryInputList,
      {
        excludedOriginCountry: "",
        excludedDestinationCountry: "",
        exCabinClass: "",
        exFareType: "",
        exIssuanceFees: "",
      },
    ]);
    // console.log(exOriginCountryInputList);
  };

  const handleExoriginCountryInputChange = (e, index) => {
    const { name, value } = e.target;
    const exOriginCountryListItem = [...exOriginCountryInputList];
    exOriginCountryListItem[index][name] = value;
    setexOriginCountryInputList(exOriginCountryListItem);
  };

  const handleRemoveExoriginCountryRow = (index) => {
    const exOriginCountryListItem = [...exOriginCountryInputList];
    exOriginCountryListItem.splice(index, 1);
    setexOriginCountryInputList(exOriginCountryListItem);
  };

  // const [originAirport, setoriginAirport] = useState([]);
  // const [exOriginAirport, setexOriginAirport] = useState([]);
  // const [destinationAirport, setdestinationAirport] = useState([]);
  // const [exDestinationAirport, setexDestinationAirport] = useState([]);
  // const [originCountry, setoriginCountry] = useState([]);
  // const [excludedOriginCountry, setexcludedOriginCountry] = useState([]);
  // const [destinationCountry, setdestinationCountry] = useState([]);
  // const [excludedDestinationCountry, setexcludedDestinationCountry] = useState([]);

  // const [fareBasisType, setfareBasisType] = useState("");
  // const [fareBasis, setfareBasis] = useState("");
  // const [exFareBasisType, setexFareBasisType] = useState("");
  // const [exFareBasis, setexFareBasis] = useState("");

  const [reissueVoluntary, setReissueVoluntary] = useState("");
  const [reissueInvoluntary, setReissueInvoluntary] = useState("");

  const [refundVoluntary, setRefundVoluntary] = useState("");
  const [refundInvoluntary, setRefundInvoluntary] = useState("");

  const [csTaxName, setcsTaxName] = useState("Other");
  const [csTaxType, setcsTaxType] = useState("Percentage");
  const [csTaxValue, setcsTaxValue] = useState("");
  const [csTaxApplicableOn, setcsTaxApplicableOn] = useState("Base");

  const [isTDSApplicable, setIsTDSApplicable] = useState(false);
  const [TDSPercentage, setTDSPercentage] = useState("");
  const [vendorROE, setVendorROE] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    console.log(action);
    if (action && toggleDrawer) {
      if (action === "new") {
        setoriginCountryInputList(initialoriginCountryInputList);
        setRailCommertialConfiguration(initialoriginCountryInputList);
        setValue(0);       
        setoriginCountryInputList(initialoriginCountryInputList);
        setRailCommertialConfiguration(initialoriginCountryInputList);
        setVendorName("");
        setFax("");
        setContactEmail("");
        setCurrencyCode("");
        setTimeZone("0.00");
        setcabinClass([]);
        setfareType([]);
        setWorkingHoursFrom("0.00");
        setWorkingHoursTo("0.00");
        setWorkingDays("0");
        setHolidays("0");
        setTat("0");
        setVendorFee("0.00");
        setVoidingFee("0.00");
        setRefundFee("0.00");
        setReissueFee("0.00");
        setAfterWorkingHoursFee("0.00");
        setGSTOnCommission("0.00");
        setMiscellaneousFee("0.00");
        setMiscellaneousFeeApplicableOn("Base");
        setServiceCharge("0.00");
        setServiceChargeApplicableOn("Base");
        setVendorApprovalStatus("");
        setIsActive(true);
        setId("");
        setIBossBooksCountry("IN");
        setIBossRefId("");
        setProductType("Flight");
        setReissueVoluntary("0.00");
        setReissueInvoluntary("0.00");

        setRefundVoluntary("0.00");
        setRefundInvoluntary("0.00");

        setcsTaxName("Other");
        setcsTaxType("Percentage");
        setcsTaxValue("0.00");
        setcsTaxApplicableOn("Base");
        setIsTDSApplicable(false);
        setTDSPercentage("0.00");
        setVendorROE("0.000000");
        setstockType("None");
      }
    }
  }, [action, toggleDrawer]);

  useEffect(() => {
    setFile("");
    setFileName("");
    setdocumentType("");

    if (action === "edit" || action === "view") {
      setValue(0);
      setVendorName(selectedItem?.vendorName);
      setFax(selectedItem?.fax);
      setContactEmail(selectedItem?.contactEmail);
      setCurrencyCode(selectedItem?.currencyCode);
      setTimeZone(selectedItem?.timeZone);

      if (
        selectedItem?.cabinClass !== undefined &&
        selectedItem?.cabinClass !== ""
      ) {
        let list = [];
        selectedItem?.cabinClass?.split(",")?.map((cabin) =>
          list.push(cabinClassList.find((el) => el.value === cabin))
        );
        setcabinClass(list);
      } else {
        setcabinClass([]);
      }

      if (selectedItem?.fareType !== undefined && selectedItem?.fareType !== "") {
        let list = [];
        selectedItem?.fareType?.split(",")?.map((fair) => list.push(fareTypeList.find((el) => el === fair)));
        setfareType(list);
      } else {
        setfareType([]);
      }

      setWorkingHoursFrom(selectedItem?.workingHoursFrom);
      setWorkingHoursTo(selectedItem?.workingHoursTo);
      setWorkingDays(selectedItem?.workingDays);
      setHolidays(selectedItem?.holidays);
      setTat(selectedItem?.tat);
      setVendorFee(selectedItem?.vendorFee);
      setVoidingFee(selectedItem?.voidingFee);
      setRefundFee(selectedItem?.refundFee);
      setReissueFee(selectedItem?.reissueFee);
      setAfterWorkingHoursFee(selectedItem?.afterWorkingHoursFee);

      setGSTOnCommission(selectedItem?.gstOnCommission);
      setMiscellaneousFee(selectedItem?.miscellaneousFee);
      setMiscellaneousFeeApplicableOn(
        selectedItem?.miscellaneousFeeApplicableOn
      );
      setServiceCharge(selectedItem?.serviceCharge);
      setServiceChargeApplicableOn(selectedItem?.serviceChargeApplicableOn);
      setVendorApprovalStatus(selectedItem?.vendorApprovalStatus);

      setIsActive(selectedItem?.isActive);
      setId(selectedItem?.id);
      setIBossBooksCountry(selectedItem?.iBossBooksCountry);
      setIBossRefId(selectedItem?.iBossRefId);
      setProductType(selectedItem?.productType ? selectedItem?.productType : "Flight");
      setVendorROE(selectedItem?.vendorROE);
      setstockType(selectedItem?.stockType);

      const arrayList = [];
      arrayList.push(
        selectedItem?.originCountry
          ? selectedItem?.originCountry?.split(",")?.length
          : 0
      );
      arrayList.push(
        selectedItem?.destinationCountry
          ? selectedItem?.destinationCountry?.split(",")?.length
          : 0
      );
      arrayList.push(
        selectedItem?.originStation
          ? selectedItem?.originStation?.split(",")?.length
          : 0
      );
      arrayList.push(
        selectedItem?.destinationStation
          ? selectedItem?.destinationStation?.split(",")?.length
          : 0
      );
      arrayList.push(
        selectedItem?.serviceCurrencyCode
          ? selectedItem?.serviceCurrencyCode?.split(",")?.length
          : 0
      );
      arrayList.push(
        selectedItem?.cabinClass ? selectedItem?.cabinClass?.split(",")?.length : 0
      );
      arrayList.push(
        selectedItem?.fareType ? selectedItem?.fareType?.split(",")?.length : 0
      );
      arrayList.push(
        selectedItem?.vendorFee ? selectedItem?.vendorFee?.split(",")?.length : 0
      );

      if (selectedItem?.productType === 'Flight') {
        setRailCommertialConfiguration(initialoriginCountryInputList);
      } else if (selectedItem?.productType === 'Rail') {
        setoriginCountryInputList(initialoriginCountryInputList);
      }

      // console.log(arrayList);
      // console.log("arrayList : " + Math.max(...arrayList));
      originCountryInputList.splice(0, originCountryInputList.length);
      // console.log("originCountryInputList : " + originCountryInputList.length);
      if (Math.max(...arrayList) !== 0) {
        for (var i = 0; i < Math.max(...arrayList); i++) {
          let setValues = {
            originCountry: "",
            originStation: "",
            destinationCountry: "",
            destinationStation: "",
            cabinClass: "",
            fareType: "",
            issuanceFees: "",
            serviceCurrency: '',
            serviceFees: '',
          };
          originCountryInputList.push(setValues);
        }

        // console.log(
        //   "originCountryInputList : " + originCountryInputList.length
        // );
        // console.log(originCountryInputList);
        selectedItem?.originCountry?.split(",")?.map((itemOC, i) => {
          const originCountryListItem = [...originCountryInputList];
          originCountryListItem[i]["originCountry"] = itemOC;
          if (itemOC) {
            let temp = countryAndStationData.find(
              (v) =>
                v.country ===
                originCountryListItem[i]["originCountry"]
            );
            if (temp) {

            }
          } else {

          }
          if (selectedItem?.productType === 'Flight') {
            setoriginCountryInputList(originCountryListItem);
          } else if (selectedItem?.productType === 'Rail') {
            setRailCommertialConfiguration(originCountryListItem);
          }
        });

        selectedItem?.destinationCountry?.split(",")?.map((itemDC, i) => {
          const destinationCountryListItem = [...originCountryInputList];
          destinationCountryListItem[i]["destinationCountry"] = itemDC;
          if (itemDC) {
            let temp = countryAndStationData.find(
              (v) =>
                v.country ===
                destinationCountryListItem[i]["destinationCountry"]
            )
            if (temp) {

            }
          } else {

          }
          if (selectedItem?.productType === 'Flight') {
            setoriginCountryInputList(destinationCountryListItem);
          } else if (selectedItem?.productType === 'Rail') {
            setRailCommertialConfiguration(destinationCountryListItem);
          }
        });
        selectedItem?.originStation?.split(",")?.map((itemOC, i) => {
          const originCountryListItem = [...originCountryInputList];
          originCountryListItem[i]["originStation"] = itemOC;
          if (selectedItem?.productType === 'Flight') {
            setoriginCountryInputList(originCountryListItem);
          } else if (selectedItem?.productType === 'Rail') {
            setRailCommertialConfiguration(originCountryListItem);
          }
        });

        selectedItem?.destinationStation?.split(",")?.map((itemDC, i) => {
          const destinationCountryListItem = [...originCountryInputList];
          destinationCountryListItem[i]["destinationStation"] = itemDC;
          if (selectedItem?.productType === 'Flight') {
            setoriginCountryInputList(destinationCountryListItem);
          } else if (selectedItem?.productType === 'Rail') {
            setRailCommertialConfiguration(destinationCountryListItem);
          }
        });

        selectedItem?.cabinClass?.split(",")?.map((itemCC, i) => {
          const cabinClassListItem = [...originCountryInputList];
          cabinClassListItem[i]["cabinClass"] = itemCC;
          if (selectedItem?.productType === 'Flight') {
            setoriginCountryInputList(cabinClassListItem);
          } else if (selectedItem?.productType === 'Rail') {
            setRailCommertialConfiguration(cabinClassListItem);
          }
        });

        selectedItem?.fareType?.split(",")?.map((itemFT, i) => {
          const fareTypeListItem = [...originCountryInputList];
          fareTypeListItem[i]["fareType"] = itemFT;
          if (selectedItem?.productType === 'Flight') {
            setoriginCountryInputList(fareTypeListItem);
          } else if (selectedItem?.productType === 'Rail') {
            setRailCommertialConfiguration(fareTypeListItem);
          }
        });

        selectedItem?.vendorFee?.split(",")?.map((itemVF, i) => {
          const vendorFeeListItem = [...originCountryInputList];
          if (selectedItem?.productType === 'Flight') {
            vendorFeeListItem[i]["issuanceFees"] = itemVF;
            setoriginCountryInputList(vendorFeeListItem);
          } else if (selectedItem?.productType === 'Rail') {
            vendorFeeListItem[i]["serviceFees"] = itemVF;
            setRailCommertialConfiguration(vendorFeeListItem);
          }
        });
        selectedItem?.serviceCurrencyCode?.split(",")?.map((itemVF, i) => {
          const serviceCurrencyListItem = [...originCountryInputList];
          serviceCurrencyListItem[i]["serviceCurrency"] = itemVF;
          if (selectedItem?.productType === 'Flight') {
            setoriginCountryInputList(serviceCurrencyListItem);
          } else if (selectedItem?.productType === 'Rail') {
            setRailCommertialConfiguration(serviceCurrencyListItem);
          }
        });
      } else {
        let setValues = {
          originCountry: "",
          originStation: "",
          destinationCountry: "",
          destinationStation: "",
          cabinClass: "",
          fareType: "",
          issuanceFees: "",
          serviceCurrency: '',
          serviceFees: '',
        };
        originCountryInputList.push(setValues);
      }

      setReissueVoluntary(selectedItem?.reissueVoluntary);
      setReissueInvoluntary(selectedItem?.reissueInVoluntary);

      setRefundVoluntary(selectedItem?.refundvoluntary);
      setRefundInvoluntary(selectedItem?.refundInvoluntary);

      setcsTaxName(selectedItem?.countrySpecificTaxName);
      setcsTaxType(selectedItem?.countrySpecificTaxType);
      setcsTaxValue(selectedItem?.countrySpecificTax);
      setcsTaxApplicableOn(selectedItem?.countrySpecificTaxApplicableOn);

      setIsTDSApplicable(selectedItem?.isTDSApplicable);
      setTDSPercentage(selectedItem?.TDSPercentage);

      getFiles();
    } else {
      setValue(0);
      originCountryInputList.splice(0, originCountryInputList.length);
      let setValues = {
        originCountry: "",
        originStation: "",
        destinationCountry: "",
        destinationStation: "",
        cabinClass: "",
        fareType: "",
        issuanceFees: "",
        serviceCurrency: '',
        serviceFees: '',
      };
      originCountryInputList.push(setValues);
      setoriginCountryInputList(initialoriginCountryInputList);
      setRailCommertialConfiguration(initialoriginCountryInputList);
      setVendorName("");
      setFax("");
      setContactEmail("");
      setCurrencyCode("");
      setTimeZone("0.00");
      setcabinClass([]);
      setfareType([]);
      setWorkingHoursFrom("0.00");
      setWorkingHoursTo("0.00");
      setWorkingDays("0");
      setHolidays("0");
      setTat("0");
      setVendorFee("0.00");
      setVoidingFee("0.00");
      setRefundFee("0.00");
      setReissueFee("0.00");
      setAfterWorkingHoursFee("0.00");
      setGSTOnCommission("0.00");
      setMiscellaneousFee("0.00");
      setMiscellaneousFeeApplicableOn("Base");
      setServiceCharge("0.00");
      setServiceChargeApplicableOn("Base");
      setVendorApprovalStatus("");
      setIsActive(true);
      setId("");
      setIBossBooksCountry("IN");
      setIBossRefId("");
      setProductType("Flight");
      setReissueVoluntary("0.00");
      setReissueInvoluntary("0.00");

      setRefundVoluntary("0.00");
      setRefundInvoluntary("0.00");

      setcsTaxName("Other");
      setcsTaxType("Percentage");
      setcsTaxValue("0.00");
      setcsTaxApplicableOn("Base");
      setIsTDSApplicable(false);
      setTDSPercentage("0.00");
      setVendorROE("0.000000");
      setstockType("None");
    }
  }, [selectedItem]);


  async function getFiles() {
    let filter = "RefId==" + selectedItem?.id.toString();
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/File?filters=" +
        filter
      )
      .then((response) => {
        // console.log(response.data);
        setFilesList(response.data);
        getHistory();
      });
  }

  async function getHistory() {
    let filter = "ConfigurationRefId==" + selectedItem?.id.toString();
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ConfigurationHistory?sorts=created&filters=" +
        filter
      )
      .then((response) => {
        // console.log(response.data);
        setHistoryList(response.data);
      });
  }
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const uploadFile = async (e) => {
    if (file) {
      setisuploadBtnDisabled(true);
      // console.log(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);
      formData.append("fileBelongsTo", "Vendor");
      formData.append("refId", selectedItem?.id.toString());
      formData.append("fileType", documentType);
      try {
        const res = await axios.post(
          process.env.REACT_APP_CORE_URL + "api/Configuration/File/",
          formData
        );
        // console.log(res);
        setisuploadBtnDisabled(false);
        if (res) {
          getFiles();
          setFile("");
          setFileName("");
        }
      } catch (ex) {
        setisuploadBtnDisabled(false);
        // console.log(ex);
      }
    } else {
      setisuploadBtnDisabled(false);
      setalertText("Kindly Upload a File");
      setMode("error");
      setSnackbarOpen(true);
    }
  };

  const handleArrayToString = (arrayList, field) => {
    let strValue = "";
    if (arrayList !== undefined && arrayList.length > 0) {
      if (field === "value") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.value)
            : (strValue = strValue + "," + arr.value)
        );
      }
    }
    return strValue;
  };

  useEffect(() => {
    // console.log("mode", mode);
    // console.log("alertText", alertText);
  }, [alertText, mode]);
  async function handleUpdate() {

    const headers = {
      "Content-Type": "application/json",
    };

    const originCountryCommaSepValue = productType === 'Flight' ? originCountryInputList.map(({ originCountry }) => originCountry).join(",")
      : railCommertialConfiguration.map(({ originCountry }) => originCountry).join(",");

    const destinationCountryCommaSepValue = productType === 'Flight' ? originCountryInputList.map(({ destinationCountry }) => destinationCountry).join(",")
      : railCommertialConfiguration.map(({ destinationCountry }) => destinationCountry).join(",");

    const originCityCommaSepValue = productType === 'Flight' ? originCountryInputList.map(({ originStation }) => originStation).join(",")
      : railCommertialConfiguration.map(({ originStation }) => originStation).join(",");

    const destinationCityCommaSepValue = productType === 'Flight' ? originCountryInputList.map(({ destinationStation }) => destinationStation).join(",")
      : railCommertialConfiguration.map(({ destinationStation }) => destinationStation).join(",");
    const ServiceCurrencyCodeCommaSepValue = productType === 'Flight' ? originCountryInputList.map(({ serviceCurrency }) => serviceCurrency).join(",")
      : railCommertialConfiguration.map(({ serviceCurrency }) => serviceCurrency).join(",");
    const cabinClassCommaSepValue = productType === 'Flight' ? originCountryInputList.map(({ cabinClass }) => cabinClass).join(",")
      : railCommertialConfiguration.map(({ cabinClass }) => cabinClass).join(",");

    const fareTypeCommaSepValue = productType === 'Flight' ? originCountryInputList.map(({ fareType }) => fareType).join(",")
      : railCommertialConfiguration.map(({ fareType }) => fareType).join(",");

    const issuanceFeesCommaSepValue = productType === 'Flight' ? originCountryInputList.map(({ issuanceFees }) => issuanceFees).join(",")
      : railCommertialConfiguration.map(({ serviceFees }) => serviceFees).join(",");


    const exOriginCountryCommaSepValue = exOriginCountryInputList
      .map(({ excludedOriginCountry }) => excludedOriginCountry)
      .join(",");

    const exDestinationCountryCommaSepValue = exOriginCountryInputList
      .map(({ excludedDestinationCountry }) => excludedDestinationCountry)
      .join(",");

    const exCabinClassCommaSepValue = exOriginCountryInputList
      .map(({ exCabinClass }) => exCabinClass)
      .join(",");

    const exFareTypeCommaSepValue = exOriginCountryInputList
      .map(({ exFareType }) => exFareType)
      .join(",");

    const exIssuanceFeesCommaSepValue = exOriginCountryInputList
      .map(({ exIssuanceFees }) => exIssuanceFees)
      .join(",");

    if (action === "edit" || action === "view") {
      setShowLoader(true);
      setisUpdateBtnDisabled(true);
      await axios
        .put(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/Vendor/" +
          selectedItem?.id,
          JSON.stringify({
            id: selectedItem?.id,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy:
              selectedItem?.createdBy === undefined
                ? ""
                : selectedItem?.createdBy,
            created:
              selectedItem?.created === undefined ? "" : selectedItem?.created,
            modifiedBy: "",
            modified:
              selectedItem?.created === undefined ? "" : selectedItem?.created,
            vendorName: vendorName === undefined ? "" : vendorName,
            fax: fax === undefined ? "" : fax,
            contactEmail: contactEmail === undefined ? "" : contactEmail,
            currencyCode: currencyCode === undefined ? "" : currencyCode,
            timeZone: timeZone === undefined ? "0" : timeZone,
            workingHoursFrom:
              workingHoursFrom === undefined ? "0.00" : workingHoursFrom,
            workingHoursTo:
              workingHoursTo === undefined ? "0.00" : workingHoursTo,
            workingDays: workingDays === undefined ? "0" : workingDays,
            holidays: holidays === undefined ? "0" : holidays,
            tat: tat === undefined ? "0" : tat,
            voidingFee: voidingFee === undefined ? "0.00" : voidingFee,
            refundFee: refundFee === undefined ? "0.00" : refundFee,
            reissueFee: reissueFee === undefined ? "0.00" : reissueFee,
            afterWorkingHoursFee:
              afterWorkingHoursFee === undefined
                ? "0.00"
                : afterWorkingHoursFee,
            gstOnCommission:
              GSTOnCommission === undefined ? "0.00" : GSTOnCommission,
            miscellaneousFee:
              MiscellaneousFee === undefined ? "0.00" : MiscellaneousFee,
            miscellaneousFeeApplicableOn:
              MiscellaneousFeeApplicableOn === undefined
                ? "Base"
                : MiscellaneousFeeApplicableOn,
            serviceCharge: ServiceCharge === undefined ? "0.00" : ServiceCharge,
            serviceChargeApplicableOn:
              ServiceChargeApplicableOn === undefined
                ? "Base"
                : ServiceChargeApplicableOn,
            iBossRefId: iBossRefId === undefined ? "0" : iBossRefId,
            iBossBooksCountry: iBossBooksCountry,
            serviceCurrencyCode: ServiceCurrencyCodeCommaSepValue,
            productType: productType,
            // originCountry: handleArrayToString(originCountry, "countrycode"),
            // destinationCountry: handleArrayToString(destinationCountry, "countrycode"),
            // cabinClass: handleArrayToString(cabinClass, "value"),
            // fareType: handleArrayToString(fareType, "raw"),
            // vendorFee: vendorFee === undefined ? "0.00" : vendorFee,

            // excludedOriginCountry: handleArrayToString(excludedOriginCountry, "countrycode"),
            // excludedDestinationCountry: handleArrayToString(excludedDestinationCountry, "countrycode"),
            // exCabinClass: handleArrayToString(cabinClass, "value"),
            // exFareType: handleArrayToString(fareType, "raw"),
            // exVendorFee: vendorFee === undefined ? "0.00" : vendorFee,

            originCountry:
              originCountryCommaSepValue === undefined
                ? ""
                : originCountryCommaSepValue,
            destinationCountry:
              destinationCountryCommaSepValue === undefined
                ? ""
                : destinationCountryCommaSepValue,
            originStation:
              originCityCommaSepValue === undefined
                ? ""
                : originCityCommaSepValue,
            destinationStation:
              destinationCityCommaSepValue === undefined
                ? ""
                : destinationCityCommaSepValue,
            cabinClass:
              cabinClassCommaSepValue === undefined
                ? ""
                : cabinClassCommaSepValue,
            fareType:
              fareTypeCommaSepValue === undefined ? "" : fareTypeCommaSepValue,
            vendorFee:
              issuanceFeesCommaSepValue === undefined
                ? "0.00"
                : issuanceFeesCommaSepValue,

            excludedOriginCountry:
              exOriginCountryCommaSepValue === undefined
                ? ""
                : exOriginCountryCommaSepValue,
            excludedDestinationCountry:
              exDestinationCountryCommaSepValue === undefined
                ? ""
                : exDestinationCountryCommaSepValue,
            exCabinClass:
              exCabinClassCommaSepValue === undefined
                ? ""
                : exCabinClassCommaSepValue,
            exFareType:
              exFareTypeCommaSepValue === undefined
                ? ""
                : exFareTypeCommaSepValue,
            exVendorFee:
              exIssuanceFeesCommaSepValue === undefined
                ? "0.00"
                : exIssuanceFeesCommaSepValue,

            reissueVoluntary:
              reissueVoluntary === undefined ? "" : reissueVoluntary,
            reissueInVoluntary:
              reissueInvoluntary === undefined ? "" : reissueInvoluntary,

            refundvoluntary:
              refundVoluntary === undefined ? "" : refundVoluntary,
            refundInvoluntary:
              refundInvoluntary === undefined ? "" : refundInvoluntary,

            countrySpecificTaxName:
              csTaxName === undefined ? "Other" : csTaxName,
            countrySpecificTaxType: csTaxType === undefined ? "" : csTaxType,
            countrySpecificTax: csTaxValue === undefined ? "0.00" : csTaxValue,
            countrySpecificTaxApplicableOn:
              csTaxApplicableOn === undefined ? "Base" : csTaxApplicableOn,

            isTDSApplicable:
              String(isTDSApplicable).toLowerCase() === "true" ? true : false,
            tdsPercentage:
              TDSPercentage === undefined
                ? "0.00"
                : parseFloat(TDSPercentage).toFixed(2),
            vendorROE: vendorROE === undefined ? "0.000000" : vendorROE,
            stockType: stockType === undefined ? "None" : stockType,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          setValue(1);
          setisApprovalBtnDisabled(false);
          setShowLoader(false);
          setisUpdateBtnDisabled(false);
          loadVendor();
        })
        .catch((err) => {
          console.log(err);
          setisApprovalBtnDisabled(false);
          handleSnackbarToggle();
          setShowLoader(false);
          setisUpdateBtnDisabled(false);
        });
    } else {
      setShowLoader(true);
      await axios
        .post(
          process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor",
          JSON.stringify({
            id: 0,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy: "",
            created: moment().format(),
            modifiedBy: "",
            modified: moment().format(),
            vendorName: vendorName === undefined ? "" : vendorName,
            fax: fax === undefined ? "" : fax,
            contactEmail: contactEmail === undefined ? "" : contactEmail,
            currencyCode: currencyCode === undefined ? "" : currencyCode,
            timeZone: timeZone === undefined ? "0" : timeZone,
            //cabinClass: handleArrayToString(cabinClass, "value"),
            workingHoursFrom:
              workingHoursFrom === undefined ? "0.00" : workingHoursFrom,
            workingHoursTo:
              workingHoursTo === undefined ? "0.00" : workingHoursTo,
            workingDays: workingDays === undefined ? "0" : workingDays,
            holidays: holidays === undefined ? "0" : holidays,
            tat: tat === undefined ? "0" : tat,
            //vendorFee: vendorFee === undefined ? "0.00" : vendorFee,
            voidingFee: voidingFee === undefined ? "0.00" : voidingFee,
            refundFee: refundFee === undefined ? "0.00" : refundFee,
            reissueFee: reissueFee === undefined ? "0.00" : reissueFee,
            afterWorkingHoursFee:
              afterWorkingHoursFee === undefined
                ? "0.00"
                : afterWorkingHoursFee,
            gstOnCommission:
              GSTOnCommission === undefined ? "0.00" : GSTOnCommission,
            miscellaneousFee:
              MiscellaneousFee === undefined ? "0.00" : MiscellaneousFee,
            miscellaneousFeeApplicableOn:
              MiscellaneousFeeApplicableOn === undefined
                ? "Base"
                : MiscellaneousFeeApplicableOn,
            serviceCharge: ServiceCharge === undefined ? "0.00" : ServiceCharge,
            serviceChargeApplicableOn:
              ServiceChargeApplicableOn === undefined
                ? "Base"
                : ServiceChargeApplicableOn,
            iBossRefId: iBossRefId === undefined ? "0" : iBossRefId,
            iBossBooksCountry: iBossBooksCountry,
            serviceCurrencyCode: ServiceCurrencyCodeCommaSepValue,
            productType: productType,
            originCountry:
              originCountryCommaSepValue === undefined
                ? ""
                : originCountryCommaSepValue,
            destinationCountry:
              destinationCountryCommaSepValue === undefined
                ? ""
                : destinationCountryCommaSepValue,
            originStation:
              originCityCommaSepValue === undefined
                ? ""
                : originCityCommaSepValue,
            destinationStation:
              destinationCityCommaSepValue === undefined
                ? ""
                : destinationCityCommaSepValue,
            cabinClass:
              cabinClassCommaSepValue === undefined
                ? ""
                : cabinClassCommaSepValue,
            fareType:
              fareTypeCommaSepValue === undefined ? "" : fareTypeCommaSepValue,
            vendorFee:
              issuanceFeesCommaSepValue === undefined
                ? "0.00"
                : issuanceFeesCommaSepValue,

            excludedOriginCountry:
              exOriginCountryCommaSepValue === undefined
                ? ""
                : exOriginCountryCommaSepValue,
            excludedDestinationCountry:
              exDestinationCountryCommaSepValue === undefined
                ? ""
                : exDestinationCountryCommaSepValue,
            exCabinClass:
              exCabinClassCommaSepValue === undefined
                ? ""
                : exCabinClassCommaSepValue,
            exFareType:
              exFareTypeCommaSepValue === undefined
                ? ""
                : exFareTypeCommaSepValue,
            exVendorFee:
              exIssuanceFeesCommaSepValue === undefined
                ? "0.00"
                : exIssuanceFeesCommaSepValue,

            reissueVoluntary:
              reissueVoluntary === undefined ? "" : reissueVoluntary,
            reissueInVoluntary:
              reissueInvoluntary === undefined ? "" : reissueInvoluntary,

            refundvoluntary:
              refundVoluntary === undefined ? "" : refundVoluntary,
            refundInvoluntary:
              refundInvoluntary === undefined ? "" : refundInvoluntary,

            countrySpecificTaxName: csTaxName === undefined ? "" : csTaxName,
            countrySpecificTaxType: csTaxType === undefined ? "" : csTaxType,
            countrySpecificTax: csTaxValue === undefined ? "0.00" : csTaxValue,
            countrySpecificTaxApplicableOn:
              csTaxApplicableOn === undefined ? "Base" : csTaxApplicableOn,

            isTDSApplicable:
              String(isTDSApplicable).toLowerCase() === "true" ? true : false,
            tdsPercentage:
              TDSPercentage === undefined
                ? "0.00"
                : parseFloat(TDSPercentage).toFixed(2),
            vendorROE: vendorROE === undefined ? "0.000000" : vendorROE,
            stockType: stockType === undefined ? "None" : stockType,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          // console.log(res);
          // setValue(1);
          setShowLoader(false);
          loadVendor();
          handleSnackbarToggle("Vendor Creation Successful", "success");
        })
        .catch((err) => {
          console.log(err);
          // handleSnackbarToggle();
          handleSnackbarToggle(
            err.response.data == undefined
              ? "please Contact Admin"
              : err.response.data,
            "error"
          );
          setShowLoader(false);
        });
    }
  }

  async function handleSendApproval() {
    setShowLoader(true);
    setisApprovalBtnDisabled(true);
    await axios
      .get(
        process.env.REACT_APP_IDENTITY_SERVER +
        "ids/User/GetAllUsersInPermission?permission=VendorFinanceApproval"
      )
      .then((response) => {
        setisApprovalBtnDisabled(false);
        // console.log(response.data);
        var roles = [];
        for (var i in response.data) {
          let approver = {
            userId: response.data[i].userId,
            email: response.data[i].email,
          };
          roles.push(approver);
        }

        PendingApprovalQueue(roles);
        setShowLoader(false);
      })
      .catch((err) => {
        const error =
          err.response.data == undefined
            ? "please Contact Admin"
            : err.response.data;
        handleSnackbarToggle(`${error}`, "error");
        setShowLoader(false);
        setisApprovalBtnDisabled(false);
      });
  }

  async function PendingApprovalQueue(approverdetails) {
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/PendingApprovalQueue/SendApprovalRequest",
        JSON.stringify({
          approvalConfigId: selectedItem?.id.toString(),
          approvalConfigurationType: "Vendor",
          approvalMessage:
            "Please approve this vendor: " + selectedItem?.vendorName,
          approvalPermissionType: "VendorFinanceApproval",
          pendingApprovalQueueId: 0,
          historyStatus: "WaitingForApproval",
          ApproverDetails: approverdetails,
        }),
        {
          headers,
        }
      )
      .then((res) => {
        // console.log(res);
        handleSnackbarToggle("Approval sent Successfully.", "Success");
        setShowLoader(false);
      })
      .catch((err) => {
        const error =
          err.response.data == undefined
            ? "please Contact Admin"
            : err.response.data;
        handleSnackbarToggle(`${error}`, "error");
        setShowLoader(false);
      });
  }

  const productChangeHandler = (e) => {
    if (e.target.value === 'flight') {
      setProductType('Flight');
    } else if (e.target.value === 'rail') {
      setProductType('Rail');
    }
  }

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <AntTabs value={value} onChange={handleChange}>
        <AntTab label="Vendor Details" />
        <AntTab
          label="Document Vault"
          disabled={action === "new" ? true : false}
        />
        <AntTab
          label="Approval History"
          disabled={action === "new" ? true : false}
        />
      </AntTabs>
      <TabPanel value={value} index={0}>
        <div className="vendor-heading-container vendor-title-flex-container">
          <div>Vendor</div>
          {action === "view" && (
            <div className="vendor-heading-icons">
              {isUserHasPermission("VendorOnBoarding") ? (
                <IconButton fontSize="small">
                  <EditRoundedIcon
                    fontSize="small"
                    className="icon-blue"
                    onClick={() => setAction("edit")}
                  ></EditRoundedIcon>
                </IconButton>
              ) : null}
            </div>
          )}
        </div>
        <ValidatorForm
          onError={(errors) => console.log(errors)}
          onSubmit={handleUpdate}
        >
          <div className="vendor-view-edit-drawer-container">
            <div className="vendor-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="VendorName"
                    size="small"
                    color="secondary"
                    value={vendorName}
                    onChange={(event) => setVendorName(event.target.value)}
                    validators={["required"]}
                    errorMessages={["This Field is Required."]}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="ContactEmail"
                    size="small"
                    color="secondary"
                    value={contactEmail}
                    onChange={(event) => setContactEmail(event.target.value)}
                    validators={["required"]}
                    errorMessages={["This Field is Required."]}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                  <div className="view-vendor-drawer-title">VendorName</div>
                  <div className="view-vendor-drawer-description ">
                    {vendorName}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">ContactEmail</div>
                  <div className="view-vendor-drawer-description">
                    {contactEmail}
                  </div>
                </div>
              )}
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <Autocomplete
                    id="timezone-select-demo"
                    value={
                      Currencies.find((v) => v.code === currencyCode) || null
                    }
                    onChange={(event, newValue) => {
                      setCurrencyCode(newValue ? newValue.code : "");
                    }}
                    options={Currencies}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.name + " (" + option.code + ")"
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="Currency"
                        value={currencyCode}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        validators={["required"]}
                        errorMessages={["This Field is Required."]}
                      />
                    )}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                  <Autocomplete
                    id="timezone-select-demo"
                    value={
                      TimeZones.find((v) => v.value === timeZone.toString()) ||
                      null
                    }
                    onChange={(event, newValue) => {
                      setTimeZone(newValue ? newValue.value : "");
                    }}
                    options={TimeZones}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.text}
                    renderOption={(option) => (
                      <React.Fragment>{option.text})</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="TimeZone"
                        value={timeZone}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        validators={["required"]}
                        errorMessages={["This Field is Required."]}
                      />
                    )}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                  <div className="view-vendor-drawer-title">CurrencyCode</div>
                  <div className="view-vendor-drawer-description ">
                    {currencyCode}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">TimeZone</div>
                  <div className="view-vendor-drawer-description">
                    {timeZone}
                  </div>
                </div>
              )}
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="WorkingHoursFrom"
                    size="small"
                    color="secondary"
                    value={workingHoursFrom}
                    onChange={(event) =>
                      setWorkingHoursFrom(event.target.value)
                    }
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="WorkingHoursTo"
                    size="small"
                    color="secondary"
                    value={workingHoursTo}
                    onChange={(event) => setWorkingHoursTo(event.target.value)}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                  <div className="view-vendor-drawer-title">
                    WorkingHoursFrom
                  </div>
                  <div className="view-vendor-drawer-description ">
                    {workingHoursFrom}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">WorkingHoursTo</div>
                  <div className="view-vendor-drawer-description">
                    {workingHoursTo}
                  </div>
                </div>
              )}
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="WorkingDays"
                    size="small"
                    color="secondary"
                    value={workingDays}
                    onChange={(event) => setWorkingDays(event.target.value)}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Holidays"
                    size="small"
                    color="secondary"
                    value={holidays}
                    onChange={(event) => setHolidays(event.target.value)}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                  <div className="view-vendor-drawer-title">WorkingDays</div>
                  <div className="view-vendor-drawer-description ">
                    {workingDays}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">Holidays</div>
                  <div className="view-vendor-drawer-description">
                    {holidays}
                  </div>
                </div>
              )}
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="TAT"
                    size="small"
                    color="secondary"
                    value={tat}
                    onChange={(event) => setTat(event.target.value)}
                    InputProps={{
                      inputProps: {
                        type: "number",
                      },
                    }}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="VoidingFee"
                    size="small"
                    color="secondary"
                    value={voidingFee}
                    onChange={(event) =>
                      setVoidingFee(parseFloat(event.target.value).toFixed(2))
                    }
                    InputProps={{
                      inputProps: {
                        type: "number",
                      },
                    }}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                  <div className="view-vendor-drawer-title">TAT</div>
                  <div className="view-vendor-drawer-description ">{tat}</div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                  <div className="view-vendor-drawer-title">VoidingFee</div>
                  <div className="view-vendor-drawer-description ">
                    {voidingFee}
                  </div>
                </div>
              )}
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              {/* {action !== "view" && (
                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="VendorFee"
                    size="small"
                    color="secondary"
                    value={vendorFee}
                    onChange={(event) =>
                      setVendorFee(parseFloat(event.target.value).toFixed(2))
                    }
                    InputProps={{
                      inputProps: {
                        type: "number",
                      },
                    }}
                  />
                </div>
              )} */}
              {/* {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="AfterWorkingHoursFee"
                    size="small"
                    color="secondary"
                    value={afterWorkingHoursFee}
                    onChange={(event) =>
                      setAfterWorkingHoursFee(
                        parseFloat(event.target.value).toFixed(2)
                      )
                    }
                    InputProps={{
                      inputProps: {
                        type: "number",
                      },
                    }}
                  />
                </div>
              )} */}
              {/* {action !== "view" && (
                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="RefundFee"
                    size="small"
                    color="secondary"
                    value={refundFee}
                    onChange={(event) =>
                      setRefundFee(parseFloat(event.target.value).toFixed(2))
                    }
                    InputProps={{
                      inputProps: {
                        type: "number",
                      },
                    }}
                  />
                </div>
              )} */}
              {/* {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">VndorFee</div>
                  <div className="view-vendor-drawer-description">
                    {vendorFee}
                  </div>
                </div>
              )} */}
              {/* {action === "view" && (
                <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                  <div className="view-vendor-drawer-title">
                    AfterWorkingHoursFee
                  </div>
                  <div className="view-vendor-drawer-description ">
                    {afterWorkingHoursFee}
                  </div>
                </div>
              )} */}
              {/* {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">RefundFee</div>
                  <div className="view-vendor-drawer-description">
                    {refundFee}
                  </div>
                </div>
              )} */}
            </div>
            {/* <div className="vendor-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Fax"
                    size="small"
                    color="secondary"
                    value={fax}
                    onChange={(event) => setFax(event.target.value)}
                  />
                </div>
              )} 
               {action === "view" && (
                <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                  <div className="view-vendor-drawer-title">Fax</div>
                  <div className="view-vendor-drawer-description ">{fax}</div>
                </div>
              )}
            </div> */}
            <div className="vendor-view-edit-drawer-input-container">
              {/* {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="GST On ServiceFee"
                    size="small"
                    color="secondary"
                    value={GSTOnCommission}
                    onChange={(event) => setGSTOnCommission(event.target.value)}
                  />
                </div>
              )} */}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Ancillary/EMD/Miscellaneous"
                    size="small"
                    color="secondary"
                    value={MiscellaneousFee}
                    onChange={(event) =>
                      setMiscellaneousFee(event.target.value)
                    }
                  />
                </div>
              )}

              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Status
                    </InputLabel>
                    <NativeSelect
                      variant="outlined"
                      label="Status"
                      size="small"
                      color="secondary"
                      value={isActive}
                      onChange={(event) => setIsActive(event.target.value)}
                    >
                      <option value={true}>true</option>
                      <option value={false}>false</option>
                    </NativeSelect>
                  </FormControl>
                </div>
              )}
              {/* {action === "view" && (
                <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                  <div className="view-vendor-drawer-title">GST On Commission</div>
                  <div className="view-vendor-drawer-description ">{GSTOnCommission}</div>
                </div>
              )} */}
              {action === "view" && (
                <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                  <div className="view-vendor-drawer-title">
                    Ancillary/EMD/Miscellaneous
                  </div>
                  <div className="view-vendor-drawer-description ">
                    {MiscellaneousFee}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">Status</div>
                  <div className="view-vendor-drawer-description">
                    {isActive.toString()}
                  </div>
                </div>
              )}
            </div>
            {/* <div className="vendor-view-edit-drawer-input-container">              
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Miscellaneous Fee ApplicableOn
                    </InputLabel>
                    <NativeSelect
                      variant="outlined"
                      label="Miscellaneous Fee ApplicableOn"
                      size="small"
                      color="secondary"
                      value={MiscellaneousFeeApplicableOn}
                      onChange={(event) => setMiscellaneousFeeApplicableOn(event.target.value)}
                    >
                      <option value={"Base"}>Base</option>
                      <option value={"Tax"}>Tax</option>
                      <option value={"Base"}>Total</option>
                    </NativeSelect>
                  </FormControl>
                </div>
              )} 
              
               {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">MiscellaneousFeeApplicableOn</div>
                  <div className="view-vendor-drawer-description">
                    {MiscellaneousFeeApplicableOn}
                  </div>
                </div>
              )}
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Service Charge"
                    size="small"
                    color="secondary"
                    value={ServiceCharge}
                    onChange={(event) => setServiceCharge(event.target.value)}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Service Charge ApplicableOn
                    </InputLabel>
                    <NativeSelect
                      variant="outlined"
                      label="Service Charge ApplicableOn"
                      size="small"
                      color="secondary"
                      value={ServiceChargeApplicableOn}
                      onChange={(event) => setServiceChargeApplicableOn(event.target.value)}
                    >
                      <option value={"Base"}>Base</option>
                      <option value={"Tax"}>Tax</option>
                      <option value={"Base"}>Total</option>
                    </NativeSelect>
                  </FormControl>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                  <div className="view-vendor-drawer-title">Service Charge</div>
                  <div className="view-vendor-drawer-description ">{ServiceCharge}</div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">Service Charge ApplicableOn</div>
                  <div className="view-vendor-drawer-description">
                    {ServiceChargeApplicableOn}
                  </div>
                </div>
              )}
            </div> */}
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      IBoss Country
                    </InputLabel>
                    <Select
                      defaultValue="IN"
                      labelId="IBossCountry"
                      id="demo-simple-select"
                      value={iBossBooksCountry}
                      label="IBoss Country"
                      onChange={(event) =>
                        setIBossBooksCountry(event.target.value)
                      }
                    >
                      <MenuItem value="IN">India</MenuItem>
                      <MenuItem value="US">United States</MenuItem>
                      <MenuItem value="IT">Italy</MenuItem>
                      <MenuItem value="UAE">UAE</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="IBoss Reference Id"
                    size="small"
                    color="secondary"
                    value={iBossRefId}
                    onChange={(event) => setIBossRefId(event.target.value)}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    IBoss Country
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {iBossBooksCountry}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    IBoss Reference Id
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {iBossRefId}
                  </div>
                </div>
              )}
            </div>
            <div className="configuration-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Vendor ROE"
                    size="small"
                    color="secondary"
                    value={vendorROE}
                    validators={[
                      "required",
                      "minNumber:0",
                      "maxNumber:9999999999",
                      "matchRegexp:^\\d{0,9}.?\\d{0,6}$",
                    ]}
                    errorMessages={[
                      "required",
                      "Min value is 0",
                      "",
                      "Only 6 digits after decimal is allowed",
                    ]}
                    onChange={(event) => setVendorROE(event.target.value)}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      Stock type
                    </InputLabel>
                    <Select
                      defaultValue="None"
                      labelId="Stocktype"
                      id="demo-simple-select"
                      value={stockType}
                      label="Stocktype"
                      onChange={(event) => setstockType(event.target.value)}
                    >
                      <MenuItem value="None">None</MenuItem>
                      <MenuItem value="B">B- BSP /ARC</MenuItem>
                      <MenuItem value="C"> CR -BSP /ARC /LCC</MenuItem>
                      <MenuItem value="A">O -Airline/XO</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}

              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    VendorROE
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {vendorROE}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                  <div className="view-configuration-drawer-title">
                    Stock type
                  </div>
                  <div className="view-configuration-drawer-description ">
                    {stockType}
                  </div>
                </div>
              )}
            </div>            
            <div className="vendor-view-edit-drawer-if-container">
              <div className="vendor-view-edit-drawer-if-line"></div>
              <div className="text-wrap-cls">Product Type</div>
              <div className="vendor-view-edit-drawer-if-line"></div>
            </div>
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1 align-center">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-labelledby="position"
                  defaultValue="flight"
                  name="radio-buttons-group"
                >
                  <FormControlLabel disabled={action === "view" ? true : false} value='flight' name="flight" control={<Radio />} label="Flight" onChange={productChangeHandler} checked={productType === 'Flight'} />
                  <FormControlLabel disabled={action === "view" ? true : false} value='rail' name="rail" control={<Radio />} label="Rail" onChange={productChangeHandler} checked={productType === 'Rail'} />
                </RadioGroup>
              </FormControl>
            </div>
            {
              productType === 'Rail' ?
                <RailCommertialConfiguration countryAndStationData={countryAndStationData}
                  action={action} originCountryInputList={railCommertialConfiguration} countries={countries} cabinClassList={cabinClassList}
                  handleOriginCountryInputChange={handleOriginCountryInputChange.bind(this, 'Rail')} fareTypeList={fareTypeList}
                  handleRemoveOriginCountryRow={handleRemoveOriginCountryRow.bind(this, 'Rail')} handleAddOriginCountryRowClick={handleAddOriginCountryRowClick.bind(this, 'Rail')}
                  productType={productType} />
                :
                <FlightCommertialConfiguration countryAndStationData={countryAndStationData}
                  action={action} originCountryInputList={originCountryInputList} countries={countries} cabinClassList={cabinClassList}
                  handleOriginCountryInputChange={handleOriginCountryInputChange.bind(this, 'Flight')} fareTypeList={fareTypeList}
                  handleRemoveOriginCountryRow={handleRemoveOriginCountryRow.bind(this, 'Flight')} handleAddOriginCountryRowClick={handleAddOriginCountryRowClick.bind(this, 'Flight')}
                  productType={productType} />
            }

            <div className="vendor-view-edit-drawer-if-container">
              <div className="vendor-view-edit-drawer-if-line"></div>
              <div className="text-wrap-cls">Reissue</div>
              <div className="vendor-view-edit-drawer-if-line"></div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Reissue Voluntary"
                    size="small"
                    color="secondary"
                    value={reissueVoluntary}
                    onChange={(event) =>
                      setReissueVoluntary(
                        parseFloat(event.target.value).toFixed(2)
                      )
                    }
                    InputProps={{
                      inputProps: {
                        type: "number",
                      },
                    }}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Reissue Involuntary"
                    size="small"
                    color="secondary"
                    value={reissueInvoluntary}
                    onChange={(event) =>
                      setReissueInvoluntary(
                        parseFloat(event.target.value).toFixed(2)
                      )
                    }
                    InputProps={{
                      inputProps: {
                        type: "number",
                      },
                    }}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">
                    Reissue Voluntary
                  </div>
                  <div className="view-vendor-drawer-description">
                    {reissueVoluntary}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">
                    Reissue Involuntary
                  </div>
                  <div className="view-vendor-drawer-description">
                    {reissueInvoluntary}
                  </div>
                </div>
              )}
            </div>
            <div className="vendor-view-edit-drawer-if-container">
              <div className="vendor-view-edit-drawer-if-line"></div>
              <div className="text-wrap-cls">Refund</div>
              <div className="vendor-view-edit-drawer-if-line"></div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Refund Voluntary"
                    size="small"
                    color="secondary"
                    value={refundVoluntary}
                    onChange={(event) =>
                      setRefundVoluntary(
                        parseFloat(event.target.value).toFixed(2)
                      )
                    }
                    InputProps={{
                      inputProps: {
                        type: "number",
                      },
                    }}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Refund Involuntary"
                    size="small"
                    color="secondary"
                    value={refundInvoluntary}
                    onChange={(event) =>
                      setRefundInvoluntary(
                        parseFloat(event.target.value).toFixed(2)
                      )
                    }
                    InputProps={{
                      inputProps: {
                        type: "number",
                      },
                    }}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">
                    Refund Voluntary
                  </div>
                  <div className="view-vendor-drawer-description">
                    {refundVoluntary}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">
                    Refund Involuntary
                  </div>
                  <div className="view-vendor-drawer-description">
                    {refundInvoluntary}
                  </div>
                </div>
              )}
            </div>
            <div className="vendor-view-edit-drawer-if-container">
              <div className="vendor-view-edit-drawer-if-line"></div>
              <div className="text-wrap-cls">Country Specific Tax</div>
              <div className="vendor-view-edit-drawer-if-line"></div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <Autocomplete
                    id="TaxName"
                    value={taxList.find((v) => v === csTaxName) || null}
                    onChange={(event, newValue) => {
                      setcsTaxName(newValue ? newValue : "");
                    }}
                    options={taxList}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    disableClearable
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="Tax Name"
                        value={csTaxName}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Tax Value"
                    size="small"
                    color="secondary"
                    value={csTaxValue}
                    validators={[
                      "required",
                      "minNumber:0",
                      csTaxType == "Percentage"
                        ? "maxNumber:100"
                        : "maxNumber:9999999999999999",
                      "matchRegexp:^\\d{0,9}.?\\d{0,2}$",
                    ]}
                    errorMessages={[
                      "required",
                      "Min value is 0",
                      csTaxType == "Percentage" ? "Max value is 100" : "",
                      "Only 2 digits after decimal is allowed",
                    ]}
                    onChange={(event) => setcsTaxValue(event.target.value)}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <Autocomplete
                    id="TaxType"
                    value={calTypes.find((v) => v === csTaxType) || null}
                    onChange={(event, newValue) => {
                      setcsTaxType(newValue ? newValue : "");
                      setcsTaxValue("0.00");
                    }}
                    options={calTypes}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    disableClearable
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="Tax Type"
                        value={csTaxType}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <Autocomplete
                    id="TaxApplicableOn"
                    value={
                      applicableOnList.find((v) => v === csTaxApplicableOn) ||
                      null
                    }
                    onChange={(event, newValue) => {
                      setcsTaxApplicableOn(newValue ? newValue : "");
                    }}
                    options={applicableOnList}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    disableClearable
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="Applicable On"
                        value={csTaxApplicableOn}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">Tax Name</div>
                  <div className="view-vendor-drawer-description">
                    {csTaxName}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">Tax Value</div>
                  <div className="view-vendor-drawer-description">
                    {csTaxValue}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">Tax Type</div>
                  <div className="view-vendor-drawer-description">
                    {csTaxType}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">
                    Tax ApplicableOn
                  </div>
                  <div className="view-vendor-drawer-description">
                    {csTaxApplicableOn}
                  </div>
                </div>
              )}
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              {action !== "view" && (
                <div
                  className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1"
                  style={{ maxWidth: "307px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      TDS Applicable
                    </InputLabel>
                    <NativeSelect
                      variant="outlined"
                      label="TDS Applicable"
                      size="small"
                      color="secondary"
                      value={isTDSApplicable}
                      onChange={(event) => {
                        if (event.target.value !== "true") {
                          setTDSPercentage("0.00");
                        }
                        setIsTDSApplicable(event.target.value);
                      }}
                    >
                      <option value={true}>true</option>
                      <option value={false}>false</option>
                    </NativeSelect>
                  </FormControl>
                </div>
              )}
              {action !== "view" &&
                (isTDSApplicable !== "false" && isTDSApplicable ? (
                  <>
                    <div
                      className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1"
                      style={{ maxWidth: "298px" }}
                    >
                      <TextValidator
                        variant="outlined"
                        label="TDS Percentage"
                        size="small"
                        color="secondary"
                        value={TDSPercentage}
                        validators={[
                          "required",
                          "minNumber:0",
                          "maxNumber:100",
                          "matchRegexp:^\\d{0,9}.?\\d{0,2}$",
                        ]}
                        errorMessages={[
                          "required",
                          "Min value is 0",
                          "Max value is 100",
                          "Only 2 digits after decimal is allowed",
                        ]}
                        onChange={(event) => {
                          setTDSPercentage(event.target.value);
                          // console.log(
                          //   "tds percentage",
                          //   parseFloat(event.target.value).toFixed(2)
                          // );
                        }}
                      />
                    </div>
                  </>
                ) : (
                  ""
                ))}
              {action === "view" && (
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">TDS Applicable</div>
                  <div className="view-vendor-drawer-description">
                    {isTDSApplicable.toString()}
                  </div>
                </div>
              )}
              {action === "view" &&
                (isTDSApplicable !== "false" && isTDSApplicable ? (
                  <>
                    <div className="view-vendor-drawer config-input-flex-1">
                      <div className="view-vendor-drawer-title">
                        TDS Percentage
                      </div>
                      <div className="view-vendor-drawer-description">
                        {TDSPercentage
                          ? parseFloat(TDSPercentage).toFixed(2)
                          : "0.00"}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                ))}
            </div>
          </div>
          {action !== "view" && (
            <div>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                style={{
                  textTransform: "none",
                  marginLeft: "25px",
                  marginRight: "25px",
                  marginBottom: "25px",
                  width: "500px",
                }}
                type="submit"
                disabled={isUpdateBtnDisabled}
              >
                {action === "edit" ? "Update" : "Create"}
              </Button>
              {showLoader && <RiseLoading vendor={showLoader} />}
            </div>
          )}
        </ValidatorForm>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {isUserHasPermission("VendorOnBoarding") ? (
          <div>
            <div className="vendor-heading-container vendor-title-flex-container">
              <div>Vendor Document Vault</div>
            </div>
            <ValidatorForm
              onError={(errors) => console.log(errors)}
              onSubmit={uploadFile}
            >
              <div className="vendor-view-edit-drawer-container">
                <div className="vendor-view-edit-drawer-input-container">
                  <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                    <TextValidator
                      placeholder="Company Incorporation, PAN, GST, ect.."
                      variant="outlined"
                      label="Document Type"
                      size="small"
                      color="secondary"
                      value={documentType}
                      onChange={(event) => setdocumentType(event.target.value)}
                      validators={["required"]}
                      errorMessages={["This Field is Required."]}
                    />
                  </div>
                </div>
                <div className="vendor-view-edit-drawer-input-container">
                  <div className="vendor-view-edit-drawer-input config-input-flex-1">
                    <Button
                      variant="contained"
                      component="label"
                      size="small"
                      style={{
                        textTransform: "none",
                      }}
                    >
                      Choose File
                      <input type="file" hidden onChange={saveFile} />
                    </Button>{" "}
                    {fileName}
                  </div>
                </div>
              </div>
              <div className="vendor-view-edit-drawer-input-container">
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    style={{
                      textTransform: "none",
                      marginLeft: "25px",
                      marginRight: "25px",
                      marginBottom: "25px",
                      width: "500px",
                    }}
                    disabled={isuploadBtnDisabled}
                    type="submit"
                  >
                    Upload file to vault
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </div>
        ) : (
          ""
        )}
        <div className="vendor-heading-container vendor-title-flex-container">
          <div>Vendor Documents List</div>
        </div>
        <div className="vendor-view-edit-drawer-input-container">
          {filesList ? (
            filesList.length === 0 ? (
              <Alert severity="error">
                There are no files, please upload files.
              </Alert>
            ) : (
              <TableContainer component={Paper} className="table-paper-styles">
                <Table className="table-background">
                  <TableHead>
                    <TableRow>
                      <TableCell>File</TableCell>
                      <TableCell>File Type</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filesList.map((row) => (
                      <StyledTableRow>
                        <StyledTableCell>
                          <div className="table-cell-title">
                            {row.clientpforile.fileName}
                          </div>
                          <div className="table-cell-description">
                            {row.clientpforile.fileBelongsTo}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className="table-cell-title">
                            {row.clientpforile.fileType}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="right"
                        >
                          <div className="table-cell-title">
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                textTransform: "none",
                              }}
                              href={row.clientpforile.imagePath}
                              target="_blank"
                              download
                            >
                              Download
                            </Button>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          ) : (
            <RiseLoading vendor={true} />
          )}
        </div>
        {isUserHasPermission("VendorOnBoarding") ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{
              textTransform: "none",
              marginTop: "25px",
              marginLeft: "25px",
              marginRight: "25px",
              marginBottom: "25px",
              width: "500px",
            }}
            disabled={isApprovalBtnDisabled}
            onClick={handleSendApproval}
          >
            Send For Approval
          </Button>
        ) : (
          ""
        )}
        {showLoader && <RiseLoading vendor={showLoader} />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="vendor-heading-container vendor-title-flex-container">
          <div>Vendor Approval History</div>
        </div>
        <div className="vendor-view-edit-drawer-input-container">
          {historyList ? (
            historyList.length === 0 ? (
              <Alert severity="error">
                There is no history for this vendor.
              </Alert>
            ) : (
              <TableContainer component={Paper} className="table-paper-styles">
                <Table className="table-background">
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historyList.map((row) => (
                      <StyledTableRow>
                        <StyledTableCell>
                          <div className="table-cell-title">
                            {row.configurationHistory.description}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className="table-cell-title">
                            <Chip
                              label={row.configurationHistory.historyStatus}
                              size="small"
                            />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <div className="table-cell-description">
                            Created by:{" "}
                            <span style={{ color: "black" }}>
                              {row.createdBy}
                            </span>
                          </div>
                          <div className="table-cell-description">
                            Created on{" "}
                            {row.configurationHistory.created.replace(
                              "T",
                              " , "
                            )}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          ) : (
            <RiseLoading vendor={true} />
          )}
        </div>
      </TabPanel>
      <div className="vendorDrawerSnackbar">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleSnackBarClose}
        >
          {alertText && mode == "error" ? (
            <Alert onClose={handleSnackBarClose} severity="error">
              {alertText}
            </Alert>
          ) : (
            <Alert onClose={handleSnackBarClose} severity="success">
              {alertText}
            </Alert>
          )}
        </Snackbar>
      </div>
    </div>
  );
}
