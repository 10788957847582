import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";
// import SessionTimeoutDialog from "./SessionTimeoutDialog";
import { Redirect } from "react-router-dom";

let countdownInterval;
let timeout;

export default function SessionTimeout() {
  // export default function SessionTimeout({ logOut, setManualRefresh }) {
  // const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  // const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const idleTimer = useRef(null);
  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };
  // document.addEventListener("visibilitychange", function () {
  //   console.log(document.hidden, document.visibilityState);
  //   let myTimeout;
  //   if (document.hidden || document.webkitHidden) {
  //     myTimeout = setTimeout(() => {
  //       if (localStorage.getItem("isActive") === 'true') {
  //         clearTimeout(myTimeout);
  //       } else {
  //         onIdle();
  //       }
  //       // }, 30000); //30secs
  //       // }, 60000); //60secs
  //     }, 120000); // 2mins
  //     // }, 1800000); // 30mins
  //   } else {
  //     clearTimeout(myTimeout);
  //   }
  // }, false);

  const handleLogout = async (isTimedOut = false) => {

    clearSessionInterval();
    clearSessionTimeout();
    localStorage.clear();
    clearInterval(countdownInterval);
    sessionStorage.clear();
    // setManualRefresh(true);
    window.open(window.location.origin, "_parent");
  };

  // const handleContinue = () => {
  //   // setTimeoutModalOpen(false);
  //   clearSessionInterval();
  //   clearSessionTimeout();
  // };

  const onActive = () => {

    console.log("useronActive");
    // console.log(new Date(), "time")
    localStorage.setItem("isActive", true);
    return () => clearInterval(countdownInterval);
  };
  const onAction = () => {
    // console.log("useronAction");
    localStorage.setItem("isActive", true);
    return () => clearInterval(countdownInterval);
  };
  const onIdle = () => {
    // 
    if (localStorage.getItem("user")) {
      // 
      localStorage.setItem("isActive", false);
      let countDowninit = new Date().getTime() + 1800000;
      let now = new Date().getTime();
      var distance = countDowninit - now;
      countdownInterval = setInterval(() => {
        // 
        now = new Date().getTime();
        distance = countDowninit - now;
        let temp = localStorage.getItem("isActive");
        // 
        if (temp === "true") {
          // 
          // console.log("user active timer reset");
          // console.log(new Date(), "time");
          clearInterval(countdownInterval);
          return;
        } else if (distance < 2) {
          // 
          // console.log(distance, "distance < 2");
          // console.log(new Date(), "time");
          countDowninit = null;
          now = null;
          handleLogout(true);
          clearInterval(countdownInterval);
          return;
        }
      }, 1000);
      return () => clearInterval(countdownInterval);
    } else {

      handleLogout(true);
    }
  };
  function clearAll() {
    clearInterval(countdownInterval);
    clearTimeout(timeout);
    // clearSessionInterval();
    // clearSessionTimeout();
  }
  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        // timeout={5000} //5 secs
        // timeout={30000} //30 secs
        // timeout={120000} //120 secs
        // timeout={60000} //60 secs
        // timeout={105000} //1.75 secs
        // timeout={300000} // 5 mins
        timeout={1800000} // 30 mins
      // timeout={1740000} // 29 mins
      />
      {/* <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={() => handleLogout(false)}
        open={timeoutModalOpen}
      /> */}
    </>
  );
}
