import * as React from 'react';
import "./TravelSchedule.css";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ScheduleCalendar from './ScheduleCalendar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// import scheduledBookingsData from '../../data/scheduledBookingsData';
import moment from 'moment';
import TrainIcon from '@mui/icons-material/Train';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import RiseLoading from '../Loader/RiseLoader';
import axios, { Axios } from 'axios';
import { encryptData } from '../../providers/dataEncryptDecryptor';
import { useHistory } from "react-router";

export default function TravelSchedule() {
    const history = useHistory();
    const [scheduleState, setScheduleState] = React.useState({
        selectedDate: new Date(),
        currentMonth: new Date(),
        bookings: [],
        bookingTypes: [],
        filteredBookings: []

    })
    const [isLoading, setIsLoading] = React.useState(false);
    const setData = (scheduledBookingsData) => {

        let bookingTypesArray = [{
            type: "flight",
            count: 0
        }, {
            type: "rail",
            count: 0
        }];
        scheduledBookingsData?.forEach((booking) => {
            if ((booking.Services === 'Flight' || booking.Services === 'flight') && !bookingTypesArray.includes("flight")) {
                bookingTypesArray[0].count++;
            } else if ((booking.Services === 'Rail' || booking.Services === 'rail') && !bookingTypesArray.includes("rail")) {
                bookingTypesArray[1].count++;
            }
        })
        let temp = scheduledBookingsData?.filter(booking => moment(booking.TravelDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'));
        setScheduleState(prevState => ({
            ...prevState,
            bookings: scheduledBookingsData,
            bookingTypes: bookingTypesArray,
            selectedDate: new Date(),
            filteredBookings: temp
        }))
    }
    React.useEffect(() => {
        loadBookingDetails();
    }, [scheduleState.currentMonth]);
    React.useEffect(() => {
        console.log("scheduleState", scheduleState)
    }, [scheduleState])
    let loadBookingDetails = () => {
        setIsLoading(true);
        const body = {
            "traveldate": moment(scheduleState.currentMonth).format("YYYY-MM-DD") + "T23:59:00.000Z"
        };
        const headers = { "Content-Type": "application/json" };
        axios
            .post(
                process.env.REACT_APP_CORE_URL + "api/Configuration/OrderQueue/TravelSchedule",
                body,
                { headers }
            )
            .then((response) => {
                console.log(response);
                setData(response?.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };
    function filterBookingsByTravelDate(selectedDate) {
        let temp = scheduleState.bookings.filter(booking => moment.utc(booking.TravelDate).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD'));
        setScheduleState({ ...scheduleState, filteredBookings: temp, selectedDate: selectedDate })
    }
    function formatTime(date) {
        let temp = moment.utc(date);
        const formattedTime = temp.format('HH:mm');
        return formattedTime;

    }
    const navigateToViewBooking = (index) => {
        let item = scheduleState.filteredBookings[index];
        if (item.OrderRefNumber) {
            localStorage.setItem("orderId", encryptData(item.OrderRefNumber));
            openNewTab(item);
        }
    }
    function openNewTab(item) {
        if (item.Services === 'Flight' || item.Services === 'flight') {
            let link = "/flight/view-booked-tickets";
            window.open(link, "_blank", "noreferrer");
        } else if (item.Services === 'Rail' || item.Services === 'rail') {
            let link = "/rail/ViewBookedTickets";
            window.open(link, "_blank", "noreferrer");
        }
    }
    return (
        <>
            {isLoading ?
                <div className="col-md-12">
                    <RiseLoading isQueueTable={true} />
                </div>
                :
                <Grid container spacing={2} sx={{ mt: '5%', padding: '0 5%' }}>
                    <Grid item xs={9}>
                        <div>
                            <h3>Travel Schedules</h3>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div>
                            <h3>Itineraries</h3>
                        </div>
                    </Grid>

                    <Grid item xs={9} style={{ background: '#fff', padding: '0 5vw' }}>
                        {/* <Item> */}
                        <ScheduleCalendar scheduleState={scheduleState} setScheduleState={setScheduleState} filterBookingsByTravelDate={filterBookingsByTravelDate} />
                        {/* </Item> */}
                    </Grid>
                    <Grid item xs={3} style={{ padding: '0 1vw' }}>
                        {/* <Item> */}
                        <List sx={{ width: '100%', background: '#fff', maxWidth: 360, bgcolor: 'background.paper', padding: '0 1vw' }}>
                            {
                                scheduleState?.filteredBookings && scheduleState?.filteredBookings.length > 0 ? scheduleState?.filteredBookings?.map((booking, index) => (
                                    <>
                                        <ListItem onClick={() => navigateToViewBooking(index)} alignItems="flex-start">
                                            <ListItemAvatar>
                                                {
                                                    booking.Services === "flight" ? <Avatar alt="flight" style={{ backgroundColor: '#fff', color: '#d63384', border: '1px solid #ddd' }}><FlightTakeoffIcon /></Avatar>
                                                        : booking.Services === "rail" ? <Avatar style={{ backgroundColor: '#fff', color: '#d63384', border: '1px solid #ddd' }} alt="rail" src={TrainIcon} ><TrainIcon /></Avatar>
                                                            : <Avatar alt="" />
                                                }
                                            </ListItemAvatar>
                                            {
                                                booking.JourneyType === 'OW' ?
                                                    <ListItemText
                                                        primary={<p style={{ textAlign: 'center', margin: '0px', color: '#000' }}><strong>{booking.Origin + " - " + booking.Destination}</strong></p>}
                                                        secondary={
                                                            <React.Fragment className="ScheduleItienaryText">
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    <p style={{ margin: '0px', color: '#333131' }}><strong>Travel Date: </strong>{moment.utc(booking.OnwardDate).format('DD MMM YYYY')} | {formatTime(booking.TravelDate)}</p>
                                                                </Typography>
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    <p style={{ margin: '0px', color: '#333131' }}><strong>PNR: </strong>{booking?.OrderSegment[0]?.AirlinePNR}</p>
                                                                </Typography>
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    <p style={{ margin: '0px', color: '#333131' }}><strong>{booking?.OrderPax && booking.OrderPax?.length > 1 ? 'Passengers: ' : 'Passenger: '} </strong>{booking?.OrderPax.sort(function (a, b) {
                                                                        if (a.PaxType < b.PaxType) {
                                                                            return -1;
                                                                        }
                                                                        if (a.PaxType > b.PaxType) {
                                                                            return 1;
                                                                        }
                                                                        return 0;
                                                                    })[0]?.PaxName} {booking?.OrderPax?.length > 1 ? ('& ' + (booking?.OrderPax?.length - 1) + ' others') : ''} </p>
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                    :
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <ListItemText
                                                            primary={<p style={{ textAlign: 'center', margin: '0px', color: '#000' }}><strong>{booking.Origin + " - " + booking.Destination}</strong></p>}
                                                            secondary={
                                                                <React.Fragment className="ScheduleItienaryText">
                                                                    <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        <p style={{ margin: '0px', color: '#333131' }}><strong>Onward Date: </strong>{moment.utc((booking.OnwardDate).split('T')[0]).format('DD MMM YYYY')} | {formatTime(booking.OnwardDate)}</p>
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        <p style={{ margin: '0px', color: '#333131' }}><strong>PNR: </strong>{booking?.OrderSegment[0]?.AirlinePNR}</p>
                                                                    </Typography>

                                                                </React.Fragment>
                                                            }
                                                        />
                                                        <ListItemText
                                                            primary={<p style={{ textAlign: 'center', margin: '0px', color: '#000' }}><strong>{booking.Destination + " - " + booking.Origin}</strong></p>}
                                                            secondary={
                                                                <React.Fragment className="ScheduleItienaryText">
                                                                    <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        <p style={{ margin: '0px', color: '#333131' }}><strong>Return Date: </strong>{moment.utc((booking.ReturnDate).split('T')[0]).format('DD MMM YYYY')} | {formatTime(booking.ReturnDate)}</p>
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        <p style={{ margin: '0px', color: '#333131' }}><strong>PNR: </strong>{booking?.OrderSegment[1]?.AirlinePNR}</p>
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        <p style={{ margin: '0px', color: '#333131' }}><strong>{booking?.OrderPax && booking.OrderPax?.length > 1 ? 'Passengers: ' : 'Passenger: '} </strong>{booking?.OrderPax && booking?.OrderPax.sort(function (a, b) {
                                                                            if (a.PaxType < b.PaxType) {
                                                                                return -1;
                                                                            }
                                                                            if (a.PaxType > b.PaxType) {
                                                                                return 1;
                                                                            }
                                                                            return 0;
                                                                        })[0]?.PaxName} {booking?.OrderPax && booking?.OrderPax?.length > 1 ? ('& ' + (booking?.OrderPax?.length - 1) + ' others') : ''} </p>
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </div>

                                            }
                                        </ListItem>
                                        {
                                            index !== scheduleState.filteredBookings.length - 1 && <Divider variant="inset" component="li" />
                                        }
                                    </>
                                ))
                                    : <p style={{ textAlign: 'center', margin: '0px', color: '#000' }}><strong>No Bookings For the Day </strong></p>

                            }

                        </List>
                        {/* </Item> */}
                    </Grid>
                </Grid>
            }
        </>
    );
}