import React from 'react'
import Cancellation from './Cancellation';
import DateChange from './DateChange';

const MiniRulesComponent = ({ value, currentTicket, currencyCode, selectedItinerary }) => {
    return (
        <div>
            {value === "Cancellation" ? <Cancellation currentTicket={currentTicket} currencyCode={currencyCode} selectedItinerary={selectedItinerary} /> : ""}
            {value === "Date Change" ? <DateChange currentTicket={currentTicket} currencyCode={currencyCode} selectedItinerary={selectedItinerary} /> : ""}
        </div>
    )
}

export default MiniRulesComponent