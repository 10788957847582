import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@material-ui/core/Button";
import "./FlightBaggageDetail.css";
import airlineDetails from "../../../../data/AirlineDetails";

export default function FlightBaggageDrawer({
    baggAction,
    index,
    setBaggDrawer,
    segGroups,
    baggageDetails,
    setBaggageDetails,
    passengers,
    flight
}) {
    const pax = ["ADT", "CHD", "INF"];
    const [paxName, setPaxName] = useState("");
    const [paxType, setPaxType] = useState("");
    const [airline, setAirline] = useState("");
    const [checkinBag, setCheckinBag] = useState("");
    const [cabinBag, setCabinBag] = useState("");
    const [cityPair, setCityPair] = useState("");
    const [amount, setAmount] = useState("");
    const [formError, setFormError] = useState(false);


    const cityPairs = segGroups?.length > 1 ?
        ([segGroups[0].origin + segGroups[0].destination, segGroups[0].destination + segGroups[0].origin]) :
        segGroups?.length !== 0 ?
            segGroups?.length === 1 && (segGroups[0].origin !== segGroups[0].destination) ?
                ([segGroups[0].origin + segGroups[0].destination]) :
                segGroups[0].segments.map(segment => segment.origin + segment.destination) : '';


    const paxTypes = [...new Set(passengers.map(p => p.paxType))];
    const distinctMrkAirlines = flight.segGroups.map(segGroup => segGroup.segments.map(segment => segment.mrkAirline));
    const flattenedAirlineCodes = distinctMrkAirlines.flat();
    const filteredAirlineDetails = airlineDetails.filter(airline => flattenedAirlineCodes.includes(airline.Code));
    const passengerNames = flight.passengers.map(pax => `${pax.firstName} ${pax.lastName}`);

    useEffect(() => {
        if (baggAction === "edit" || baggAction === "view") {
            let selected = structuredClone(baggageDetails[index]);
            if (selected) {
                setPaxType(selected.paxType ? selected.paxType : "");
                setPaxName(selected.paxName ? selected.paxName : "");
                setAirline(selected.airline ? selected.airline : "");
                setCheckinBag(selected.checkinBag ? selected.checkinBag : "");
                setCabinBag(selected.cabinBag ? selected.cabinBag : "");
                setCityPair(selected.cityPair ? selected.cityPair : "");
                setAmount(selected.amount ? selected.amount : 0);
            }
        } else {
            resetForm();
        }
    }, [baggAction, index]);

    const resetForm = () => {
        setPaxType("");
        setPaxName("");
        setAirline("");
        setCheckinBag("");
        setCabinBag("");
        setCityPair("");
        setAmount("");
    };

    const handleUpdate = () => {
        if (!validateForm()) {
            setFormError(true);
            return;
        }
        setFormError(false);

        const newBaggage = {
            paxType: paxType,
            paxName: paxName,
            airline: airline,
            checkinBag: checkinBag,
            cabinBag: cabinBag,
            cityPair: cityPair,
            amount: amount
        };

        if (baggAction === "edit") {
            const updatedBaggageDetails = [...baggageDetails];
            updatedBaggageDetails[index] = newBaggage;
            setBaggageDetails(updatedBaggageDetails);
        } else {
            setBaggageDetails([...baggageDetails, newBaggage]);
        }

        resetForm();

        setBaggDrawer(false);
    };

    const validateForm = () => {
        return paxType !== "" && paxName !== "" && airline !== "" && cityPair !== "" && amount !== 0;
    };


    useEffect(() => {
        console.log('baggageDetails', baggageDetails)
    }, [baggageDetails])


    return (
        <div className="bagg-drawer-container">
            <div className="close-button-container">
                <CloseIcon
                    className="icon-fill pointer"
                    fontSize="medium"
                    onClick={() => setBaggDrawer(false)}
                ></CloseIcon>
            </div>
            <div className="bagg-heading-container bagg-title-flex-container">
                <div>Add Baggage</div>
            </div>
            <ValidatorForm
                onError={(errors) => console.log(errors)}
            >
                <div className="bagg-view-edit-drawer-container addPassenger">
                    <div className="bagg-view-edit-drawer-input-container">
                        {baggAction !== "view" && (
                            <>
                                <div className="bagg-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <Autocomplete
                                        id="timezone-select-demo"
                                        value={paxType}
                                        onChange={(event, newValue) => {
                                            setPaxType(newValue);
                                        }}
                                        required
                                        options={paxTypes}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                label="Pax Type"
                                                value={paxType}
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                required
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="bagg-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <Autocomplete
                                        options={passengerNames}
                                        value={paxName}
                                        onChange={(event, newValue) => setPaxName(newValue)}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                variant="outlined"
                                                label="Pax Name"
                                                size="small"
                                                color="secondary"
                                                required
                                            />
                                        )}
                                    />
                                </div>
                            </>
                        )}
                    </div></div>
                <div className="bagg-view-edit-drawer-container addPassenger">
                    <div className="bagg-view-edit-drawer-input-container">
                        {console.log('filteredAirlineDetails', filteredAirlineDetails)}
                        {baggAction !== "view" && (
                            <>
                                <div className="bagg-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <Autocomplete
                                        id="airline-select-demo"
                                        value={airline}
                                        onChange={(event, newValue) => {
                                            setAirline(newValue);
                                        }}
                                        required
                                        options={filteredAirlineDetails.length > 0 ? filteredAirlineDetails : airlineDetails}
                                        getOptionLabel={(option) => option.Name}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                label="Airline"
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                required
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="bagg-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <TextValidator
                                        variant="outlined"
                                        label="Check-in Bag"
                                        size="small"
                                        color="secondary"
                                        value={checkinBag}
                                        onChange={(event) => setCheckinBag(event.target.value)}
                                    />
                                </div>
                            </>
                        )}
                    </div></div>
                <div className="bagg-view-edit-drawer-container addPassenger">
                    <div className="bagg-view-edit-drawer-input-container">
                        {baggAction !== "view" && (
                            <>
                                <div className="bagg-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <TextValidator
                                        variant="outlined"
                                        label="Cabin Bag"
                                        size="small"
                                        color="secondary"
                                        value={cabinBag}
                                        onChange={(event) => setCabinBag(event.target.value)}
                                    />
                                </div>
                                <div className="bagg-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <Autocomplete
                                        id="timezone-select-demo"
                                        value={cityPair}
                                        onChange={(event, newValue) => {
                                            setCityPair(newValue);
                                        }}
                                        required
                                        options={cityPairs}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                label="City Pair"
                                                value={cityPair}
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                required
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                            />
                                        )}
                                    />
                                </div>
                            </>
                        )}
                    </div></div>
                <div className="bagg-view-edit-drawer-container addPassenger">
                    <div className="bagg-view-edit-drawer-input-container">
                        {baggAction !== "view" && (
                            <>
                                <div className="bagg-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <TextValidator
                                        variant="outlined"
                                        label="Amount"
                                        size="small"
                                        color="secondary"
                                        value={amount}
                                        required
                                        onChange={(event) => setAmount(event.target.value)}
                                        validators={["required", "isNumber"]}
                                        errorMessages={["This field is required", "Amount should be a number"]}
                                    />
                                </div>
                                <div className="bagg-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="mt-5">
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        style={{
                            textTransform: "none",
                            marginLeft: "25px",
                            marginRight: "25px",
                            marginBottom: "25px",
                            width: "500px",
                        }}
                        onClick={() => handleUpdate()}
                    >
                        {baggAction === "edit" ? "Update" : "Add"}
                    </Button>
                </div>
            </ValidatorForm>
            {formError ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                    <span style={{ fontSize: "16px", color: "red", paddingLeft: "10px" }}>
                        *Please fill all required fields
                    </span>
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
