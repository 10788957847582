import React, { useState, useEffect } from "react";
import "./ReceiptTable.css";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../hooks/useConfigurationTableRow";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Paper from "@material-ui/core/Paper";
import { Chip } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import Alert from "@material-ui/lab/Alert";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { OutlinedInput, InputAdornment } from "@material-ui/core";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

export default function ReceiptTable({
  openDrawer,
  setAction,
  categories,
}) {
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [searchedVal, setSearchedVal] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="receipt-category-listing">
      <TableContainer component={Paper} className="table-paper-styles">
        <Table className="table-background">
          <TableHead>
            <TableRow>
              <TableCell colSpan={5} className="py-2">
                <OutlinedInput
                  id="keyword-search"
                  onChange={(e) => { setSearchedVal(e.target.value); setPage(0) }}
                  placeholder="Keyword Search"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GFC</TableCell>
              <TableCell>TransactionId</TableCell>
              <TableCell>Transaction Amount</TableCell>
              <TableCell>Transaction Type</TableCell>
              <TableCell align="right">Transaction Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories !== undefined && categories.length > 0 ? (
              categories.filter((group) => {
                return group.searchCategories.name?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                  group.searchCategories.id == searchedVal;
              }
              ).length === 0 ?
                <>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      colSpan={5}
                    >
                      <Alert severity="error">
                        No record found.
                      </Alert>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
                :
                categories.filter((group) => {
                  return group.searchCategories.name?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    group.searchCategories.id == searchedVal;
                })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((group) => (
                    <StyledTableRow key={JSON.stringify(group.searchCategories)}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                      >
                        <div className="table-cell-title pointer">
                          {group.searchCategories.id}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                      >
                        <div className="table-cell-title pointer" onClick={() => { setAction("view"); openDrawer(group.searchCategories) }}>
                          {group.searchCategories.name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {group.searchCategories.description}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {group.searchCategories.isActive ? (
                            <Chip
                              label="Active"
                              color="primary"
                              size="small"
                              style={{ backgroundColor: "green" }}
                            />
                          ) : (
                            <Chip
                              label="In Active"
                              color="primary"
                              style={{ backgroundColor: "red" }}
                            />
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div className="table-cell-description">Created by <span style={{ color: "black" }}>{group.createdBy}</span></div>
                        <div className="table-cell-description">
                          created at{" "}
                          {group.searchCategories.created.split("T")[0]}
                        </div>
                        <div className="table-cell-description">
                          modified at{" "}
                          {group.searchCategories.modified.split("T")[0]}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
            ) : (
              <StyledTableRow>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="50%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="70%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="800%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="500%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div className="table-loading">
                    <Skeleton width="80%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={categories.filter((group) => {
          return group.searchCategories.name?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
            group.searchCategories.id == searchedVal;
        }
        ).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => handleChangePage(event, newPage)}
        onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}
