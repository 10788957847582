import React, { useEffect, useState, useReducer, useRef } from "react";
import { Modal, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ticketingTypes from "../../../data/TicketingType";
import cabinClassList from "../../../data/CabinClass";
import fareTypeList from "../../../data/FareType";
import Autocomplete from "@material-ui/lab/Autocomplete";
import airlineDetails from "../../../data/AirlineDetails";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CircularProgress, Button } from "@material-ui/core";
import Notify from '../../Notifications/Notifications';
import Box from '@mui/material/Box';
import fopOptions from '../../../data/FopOptions';
import statusOptions from '../../../data/statusOptions';
import { ticketingConfigUpdate, ticketingConfigInsert, loadCreditCardDetails } from '../../../services/apiService'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
export default function TicketConfigModal({ status, action, data, setModal, pccList, setShowLoader, showLoader, reload }) {
    const ticketConfigForm = useRef();
    const initialTicketConfigStates = getInitialTicketConfigStates();
    const initialcardState = getInitialCardState();

    function getInitialTicketConfigStates() {
        return {
            ticketDesignator: "",
            pcc: "",
            cabinClass: "",
            ticketingType: "",
            includeAirlines: "",
            excludeAirlines: "",
            fareTypes: "",
            queueNumber: "",
            queueFormat: "",
            printerId: "",
            fop: "",
            cardInfoId: '',
            remarks: "",
            isActive: "true",
            id: 0
        };
    }

    function getInitialCardState() {
        return {
            key: '',
            addressLine1: "",
            addressLine2: "",
            cardHolderName: "",
            cardNumber: "",
            cardType: "",
            city: "",
            code: "",
            country: "",
            cvc: "",
            email: "",
            expDate: "",
            id: 0,
            isActive: true,
            modified: "",
            modifiedBy: "",
            phone: "",
            state: "",
            zipcode: ""
        };
    }
    const [form, setForm] = useState(initialTicketConfigStates);
    const [creditCardsList, setCreditCardsList] = useState([]);
    const [selectedcard, dispatch] = useReducer(reducer, initialcardState);
    const [isConfigActive, dispatchisActive] = useReducer(reducerIsActive, 'Active');
    const [includeAirlines, dispatchIncludeAirlines] = useReducer(reducerIncludeAirlines, '')
    const [excludeAirlines, dispatchExcludeAirlines] = useReducer(reducerExcludeAirlines, '')
    const [fareType, dispatchFareType] = useReducer(reducerFareType, '')
    const [cabinClass, dispatchCabinClass] = useReducer(reducerCabinClass, '')
    function reducer(state, action) {
        switch (action.type) {
            case 'card':
                return action.newValue.cardinfo ? action.newValue.cardinfo : action.newValue
            default:
                return action.newValue
        }
    }

    function reducerIsActive(state, action) {
        switch (action.type) {
            case 'isConfigActive':
                return action.newValue
            default:
                return action.newValue
        }
    }
    function reducerIncludeAirlines(state, action) {

        switch (action.type) {
            case 'includeAirlines':
                return action.newValue
            default:
                return action.newValue
        }
    }
    function reducerExcludeAirlines(state, action) {

        switch (action.type) {
            case 'excludeAirlines':
                return action.newValue
            default:
                return action.newValue
        }
    }
    function reducerFareType(state, action) {

        switch (action.type) {
            case 'fareType':
                return action.newValue
            default:
                return action.newValue
        }
    }
    function reducerCabinClass(state, action) {
        switch (action.type) {
            case 'cabinClass':
                return action.newValue
            default:
                return action.newValue
        }
    }

    useEffect(() => {
        dispatch({ type: 'card', newValue: getInitialCardState() });
        dispatchCabinClass({ type: 'cabinClass', newValue: [] });
        dispatchFareType({ type: 'fareType', newValue: [] });
        dispatchIncludeAirlines({ type: 'includeAirlines', newValue: [] });
        dispatchExcludeAirlines({ type: 'excludeAirlines', newValue: [] });
        if (status === true) {
            if (action === "edit" || action === "view") {
                setForm({
                    ticketDesignator: data.ticketDesignator,
                    pcc: data.pcc,
                    cabinClass: data.cabinClass,
                    ticketingType: data.ticketingType,
                    includeAirlines: data.includeAirlines,
                    excludeAirlines: data.excludeAirlines,
                    fareTypes: data.fareTypes,
                    queueNumber: data.queueNumber,
                    queueFormat: data.queueFormat,
                    printerId: data.printerId,
                    fop: data.fop,
                    remarks: data.remarks,
                    isActive: data.isActive,
                    id: data.id,
                    cardInfoId: data.cardInfoId
                })
                setCabinClassData()
                setFareTypeData()
                setIncludeAirlineData()
                setExcludeAirlineData()
                dispatchisActive({
                    type: 'isConfigActive', newValue: data.isActive ? 'Active' : 'In-Active'
                })

            }
            // loadCreditCards();
        }
    }, [status, action, data.ticketDesignator, data.pcc, data.cabinClass, data.ticketingType, data.includeAirlines, data.excludeAirlines, data.fareTypes, data.queueNumber, data.queueFormat, data.printerId, data.fop, data.remarks, data.isActive, data.id, data.accountNumber, data.creditCardCode, data.expiryDate]);
    const setFareTypeData = async () => {
        if (data.fareTypes !== undefined && data.fareTypes !== "") {
            let list = [];
            data.fareTypes
                .split(",")
                .map((fair) => list.push(fareTypeList.find((el) => el === fair)));
            dispatchFareType({ type: 'fareType', newValue: list });
        } else {
            dispatchFareType({ type: 'fareType', newValue: [] });
        }
    }
    const setIncludeAirlineData = async () => {
        if (
            data.includeAirlines !== undefined &&
            data.includeAirlines !== ""
        ) {
            let list = [];
            data.includeAirlines
                .split(",")
                .map((air) =>
                    list.push(airlineDetails.find((el) => el.Code === air))
                );
            dispatchIncludeAirlines({ type: 'includeAirlines', newValue: list });
        } else {
            dispatchIncludeAirlines({ type: 'includeAirlines', newValue: [] });
        }

    }
    const setExcludeAirlineData = async () => {
        if (
            data.excludeAirlines !== undefined &&
            data.excludeAirlines !== ""
        ) {
            let list = [];
            data.excludeAirlines
                .split(",")
                .map((air) =>
                    list.push(airlineDetails.find((el) => el.Code === air))
                );
            dispatchExcludeAirlines({ type: 'excludeAirlines', newValue: list });
        } else {
            dispatchExcludeAirlines({ type: 'excludeAirlines', newValue: [] });
        }

    }
    const setCabinClassData = async () => {
        if (
            data.cabinClass !== undefined &&
            data.cabinClass !== ""
        ) {
            let list = [];
            data.cabinClass
                .split(",")
                .map((cabin) =>
                    list.push(cabinClassList.find((el) => el.label === cabin))
                );
            dispatchCabinClass({ type: 'cabinClass', newValue: list });
        } else {
            dispatchCabinClass({ type: 'cabinClass', newValue: [] });
        }

    }


    const handleModalClose = () => {
        setForm(initialTicketConfigStates);
        dispatchisActive({
            type: 'isConfigActive', newValue: 'Active'
        })
        dispatch({ type: 'card', newValue: getInitialCardState() });
        setModal({ status: false, action: "", data: "" })
    }
    const handleOnChange = (type, value) => {
        let tempForm = { ...form };
        if (type == "isActive") {
            if (value == "false") {
                tempForm[type] = false;
            }
            else {
                tempForm[type] = true;
            }
            dispatchisActive({
                type: 'isActive', newValue: value == "true" ? 'Active' : 'In-Active'
            })
        }
        // else if (type === 'fop' && value === 'Credit Card') {

        //     tempForm[type] = value;
        //     dispatch({
        //         type: 'card', newValue: {
        //             key: '',
        //             addressLine1: "",
        //             addressLine2: "",
        //             cardHolderName: "",
        //             cardNumber: "",
        //             cardType: "",
        //             city: "",
        //             code: "",
        //             country: "",
        //             cvc: "",
        //             email: "",
        //             expDate: "",
        //             id: 0,
        //             isActive: true,
        //             modified: "",
        //             modifiedBy: "",
        //             phone: "",
        //             state: "",
        //             zipcode: ""
        //         },
        //     })
        // }
        else {
            tempForm[type] = value;
        }

        setForm(tempForm);
    }
    const getCabinValue = async () => {
        let temptext = '';
        if (cabinClass && cabinClass.length) {
            cabinClass.map(cabin => {
                temptext = !temptext.length ? cabin.label : temptext + ',' + cabin.label
            })
            return temptext
        } else {
            return ''
        }
    }
    const getFareTypeValue = async () => {
        let temptext = '';
        if (fareType && fareType.length) {
            fareType.map(fare => {
                temptext = !temptext.length ? fare : temptext + ',' + fare
            })
            return temptext
        } else {
            return ''
        }
    }
    const getIncludeAirlinesValue = async () => {
        let temptext = '';
        if (includeAirlines && includeAirlines.length) {
            includeAirlines.map(airline => {
                temptext = !temptext.length ? airline.Code : temptext + ',' + airline.Code
            })
            return temptext
        } else {
            return ''
        }
    }
    const getExcludeAirlinesValue = async () => {
        let temptext = '';
        if (excludeAirlines && excludeAirlines.length) {
            excludeAirlines.map(airline => {
                temptext = !temptext.length ? airline.Code : temptext + ',' + airline.Code
            })
            return temptext
        } else {
            return ''
        }
    }
    const handleSubmit = async () => {

        let reqObj = { ...form };
        reqObj.cabinClass = await getCabinValue()
        reqObj.includeAirlines = await getIncludeAirlinesValue()
        reqObj.excludeAirlines = await getExcludeAirlinesValue()
        reqObj.fareTypes = await getFareTypeValue()
        reqObj.accountNumber = selectedcard.cardNumber
        reqObj.creditCardCode = selectedcard.code
        reqObj.expiryDate = selectedcard.expDate
        reqObj.isActive = isConfigActive === "Active" ? true : false
        reqObj.cardInfoId = selectedcard.id
        reqObj.createdBy = selectedcard.createdBy ? selectedcard.createdBy : ""
        reqObj.created = selectedcard.created ? selectedcard.created : ""
        try {
            setShowLoader(true);
            if (action == "edit") {

                let resp = await ticketingConfigUpdate(data, reqObj)
                setShowLoader(false);
                Notify("success", "Ticketing configuration updated successfully", "Ticketing Configuration");
            } else {

                let resp = await ticketingConfigInsert(data, reqObj)
                setShowLoader(false);
                Notify("success", "Ticketing configuration added successfully", "Ticketing Configuration");
            }
            handleModalClose();
            reload()
        } catch (error) {
            setShowLoader(false);
            if (action == "edit") {
                Notify("error", "Unable to update ticketing configuration", "Ticketing Configuration");
            } else {
                Notify("error", "Unable to add ticketing configuration", "Ticketing Configuration");
            }
        }
    }
    const loadCreditCards = async () => {
        try {
            let temp = [];
            setShowLoader(true);
            let result = await loadCreditCardDetails();
            if (result.length) {
                temp = result.filter(item => {
                    if (item.cardinfo.isActive === true) {
                        return item
                    }
                });
                temp.map(item => {
                    if (!item.cardinfo.cardCode) {
                        item.cardinfo.code = item.cardinfo.cardType == 'American Express' ? 'AX' : item.cardinfo.cardType == 'Visa' ? 'VI' : item.cardinfo.cardType == 'MasterCard' ? 'CA' : item.cardinfo.cardType == 'Diners Club' ? 'DC' : item.cardinfo.cardType == 'Discover' ? 'DS' : item.cardinfo.cardType == 'JCB' ? 'JC' : item.cardinfo.cardType == 'Maestro' ? 'TO' : item.cardinfo.cardType == 'UATP' ? 'TP' : ''
                    } else {
                        item.cardinfo.code = item.cardinfo.cardCode
                    }
                })
                temp.map(item => {
                    if (data.cardInfoId === item.cardinfo.id) {
                        dispatch({
                            type: 'card', newValue: item
                        })
                        setForm({
                            ticketDesignator: data.ticketDesignator,
                            pcc: data.pcc,
                            cabinClass: data.cabinClass,
                            ticketingType: data.ticketingType,
                            includeAirlines: data.includeAirlines,
                            excludeAirlines: data.excludeAirlines,
                            fareTypes: data.fareTypes,
                            queueNumber: data.queueNumber,
                            queueFormat: data.queueFormat,
                            printerId: data.printerId,
                            fop: data.fop,
                            remarks: data.remarks,
                            isActive: data.isActive,
                            id: data.id,
                            cardInfoId: data.cardInfoId
                        })
                    } else if (data.cardInfoId === 0) {
                        setForm({
                            ticketDesignator: data.ticketDesignator,
                            pcc: data.pcc,
                            cabinClass: data.cabinClass,
                            ticketingType: data.ticketingType,
                            includeAirlines: data.includeAirlines,
                            excludeAirlines: data.excludeAirlines,
                            fareTypes: data.fareTypes,
                            queueNumber: data.queueNumber,
                            queueFormat: data.queueFormat,
                            printerId: data.printerId,
                            fop: 'Cash',
                            remarks: data.remarks,
                            isActive: data.isActive,
                            id: data.id,
                            cardInfoId: data.cardInfoId
                        })
                    }
                })
                setCreditCardsList(temp);
            }
            setShowLoader(false)

        } catch (error) {
            setShowLoader(false);
            Notify("error", "Unable to fetch ticketing configuration", "Ticketing Configuration");
        }
    }
    const onSubmitClick = () => {
        ticketConfigForm.current && ticketConfigForm.current.submit();
    }


    return (
        <>

            <Modal
                size="lg"
                show={status}
                onHide={() => handleModalClose()}
                backdrop="static"
                keyboard={false}
                className="ticket-config-modal"
                centered
            >
                <Box sx={{ ...style, width: 700 }}>
                    <Modal.Header>
                        <Modal.Title>
                            <span className="action-text">{action}</span> Ticketing Configuration
                        </Modal.Title>
                        <button type="button" className="btn-close pull-right modal_close" onClick={() => handleModalClose()}></button>
                    </Modal.Header>
                    <Modal.Body>
                        <ValidatorForm
                            ref={ticketConfigForm}
                            onError={() => Notify("error", "Form contains error", "Ticketing Configuration")}
                            onSubmit={handleSubmit}
                        >
                            <Container className="ticketModalContainer">
                                <Row className="">
                                    <Col sm={6} className="">
                                        <Autocomplete
                                            id="PCC-select"
                                            value={form.pcc != "" ? pccList.find((v) => v.value == form.pcc) : null}
                                            onChange={(event, newValue) => { handleOnChange("pcc", newValue ? newValue.value : "") }}
                                            options={pccList}
                                            disabled={action === "view"}
                                            getOptionLabel={option => option.label}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    className={action === "view" ? "ticket-config-inputs-disabled" : "ticket-config-inputs"}
                                                    label="Pseudo Code"
                                                    value={form.pcc}
                                                    variant="filled"
                                                    color="secondary"
                                                    size="small"
                                                    validators={["required"]}
                                                    errorMessages={["required"]}
                                                    disabled={action === "view"}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col sm={6} className="">
                                        <Autocomplete
                                            id="Ticketing-type-select"
                                            value={form.ticketingType != "" ? ticketingTypes.find((v) => v === form.ticketingType) : null}
                                            onChange={(event, newValue) => { handleOnChange("ticketingType", newValue ? newValue : "") }}
                                            options={ticketingTypes}
                                            disabled={action === "view"}
                                            getOptionLabel={option => option}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="Ticketing Type"
                                                    className="ticket-config-inputs"
                                                    value={form.ticketingType}
                                                    variant="filled"
                                                    color="secondary"
                                                    size="small"
                                                    validators={["required"]}
                                                    errorMessages={["required"]}
                                                    disabled={action === "view"}
                                                />
                                            )}
                                        />
                                    </Col>
                                    {
                                        action === "view" &&
                                        <Col sm={6} className="">
                                            <Autocomplete
                                                id="cabin select"
                                                multiple
                                                value={cabinClass && cabinClass}
                                                onChange={(event, value) => {
                                                    dispatchCabinClass({ type: 'cabinClass', newValue: value });
                                                }}
                                                disabled={action === "view"}
                                                options={cabinClassList}
                                                autoHighlight
                                                getOptionLabel={(option) => option.label}
                                                filterSelectedOptions
                                                getOptionDisabled={(option) => {
                                                    if (cabinClass && cabinClass.length) {
                                                        if (cabinClass.some((cabin) => cabin.label === "All") && option.label !== 'All') {
                                                            return true;
                                                        } else if ((cabinClass.some((cabin) => cabin.label !== "All")) && option.label === 'All') {
                                                            return true;
                                                        } else if ((cabinClass.some((cabin) => cabin.label === "None")) && option.label !== 'None') {
                                                            return true;
                                                        } else if ((cabinClass.some((cabin) => cabin.label !== "None")) && option.label === 'None') {
                                                            return true;
                                                        }
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        label="Cabin Class"
                                                        value={cabinClass}
                                                        variant="filled"
                                                        color="secondary"
                                                        size="small"
                                                        disabled={action === "view"}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password",
                                                        }}
                                                    />
                                                )}
                                                style={{ maxWidth: "34vw" }}
                                            />
                                        </Col>
                                    }
                                    {
                                        action !== 'view' && <Col sm={6} className="">
                                            <Autocomplete
                                                id="cabin select"
                                                multiple
                                                limitTags={1}
                                                value={cabinClass && cabinClass}
                                                onChange={(event, value) => {
                                                    dispatchCabinClass({ type: 'cabinClass', newValue: value });
                                                }}
                                                disabled={action === "view"}
                                                options={cabinClassList}
                                                autoHighlight
                                                getOptionLabel={(option) => option.label}
                                                filterSelectedOptions
                                                getOptionDisabled={(option) => {
                                                    if (cabinClass && cabinClass.length) {
                                                        if (cabinClass.some((cabin) => cabin.label === "All") && option.label !== 'All') {
                                                            return true;
                                                        } else if ((cabinClass.some((cabin) => cabin.label !== "All")) && option.label === 'All') {
                                                            return true;
                                                        } else if ((cabinClass.some((cabin) => cabin.label === "None")) && option.label !== 'None') {
                                                            return true;
                                                        } else if ((cabinClass.some((cabin) => cabin.label !== "None")) && option.label === 'None') {
                                                            return true;
                                                        }
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        label="Cabin Class"
                                                        value={cabinClass}
                                                        variant="filled"
                                                        color="secondary"
                                                        size="small"
                                                        disabled={action === "view"}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password",
                                                        }}
                                                    />
                                                )}
                                                style={{ maxWidth: "34vw" }}
                                            />
                                        </Col>
                                    }
                                    {
                                        action === "view" &&
                                        <Col sm={6} className="">

                                            <Autocomplete
                                                id="faretype select"
                                                multiple
                                                value={fareType && fareType}
                                                onChange={(event, value) => { dispatchFareType({ type: 'fareType', newValue: value }); }}
                                                options={fareTypeList}
                                                autoHighlight
                                                disabled={action === "view"}
                                                getOptionLabel={(option) => option}
                                                filterSelectedOptions
                                                getOptionDisabled={(option) => {
                                                    if (fareType && fareType.length) {
                                                        if (fareType.some((fare) => fare === "All") && option !== 'All') {
                                                            return true;
                                                        } else if ((fareType.some((fare) => fare !== "All")) && option === 'All') {
                                                            return true;
                                                        }
                                                    }

                                                }}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        className="ticket-config-inputs"
                                                        label="Fare Type"
                                                        placeholder=""
                                                        value={fareType}
                                                        variant="filled"
                                                        color="secondary"
                                                        size="small"
                                                        disabled={action === "view"}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password",
                                                        }}
                                                    />
                                                )}
                                                style={{ maxWidth: "34vw" }}
                                            />

                                        </Col>
                                    }
                                    {
                                        action !== "view" &&
                                        <Col sm={6} className="">

                                            <Autocomplete
                                                id="faretype select"
                                                multiple
                                                limitTags={1}
                                                disabled={action === "view"}
                                                value={fareType && fareType}
                                                onChange={(event, value) => { dispatchFareType({ type: 'fareType', newValue: value }); }}
                                                options={fareTypeList}
                                                autoHighlight
                                                getOptionLabel={(option) => option}
                                                filterSelectedOptions
                                                getOptionDisabled={(option) => {
                                                    if (fareType && fareType.length) {
                                                        if (fareType.some((fare) => fare === "All") && option !== 'All') {
                                                            return true;
                                                        } else if ((fareType.some((fare) => fare !== "All")) && option === 'All') {
                                                            return true;
                                                        }
                                                    }

                                                }}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        className="ticket-config-inputs"
                                                        label="Fare Type"
                                                        placeholder=""
                                                        disabled={action === "view"}
                                                        value={fareType}
                                                        variant="filled"
                                                        color="secondary"
                                                        size="small"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password",
                                                        }}
                                                    />
                                                )}
                                                style={{ maxWidth: "34vw" }}
                                            />

                                        </Col>
                                    }

                                    {
                                        action === "view" &&
                                        <Col sm={6} className="">

                                            <Autocomplete
                                                id="ex airline select"
                                                multiple
                                                value={includeAirlines && includeAirlines}
                                                onChange={(event, value) => {
                                                    dispatchIncludeAirlines({ type: 'includeAirlines', newValue: value });
                                                }}
                                                disabled={action === "view"}
                                                options={airlineDetails}
                                                autoHighlight
                                                getOptionLabel={(option) =>
                                                    option.Code === "All"
                                                        ? "All"
                                                        : option.Name + " (" + option.Code + ")"
                                                } filterSelectedOptions
                                                getOptionDisabled={(option) => {
                                                    if (includeAirlines && includeAirlines.length) {
                                                        if (includeAirlines.some((airline) => airline.Code === "All") && option.Code !== 'All') {
                                                            return true;
                                                        } else if ((includeAirlines.some((airline) => airline.Code !== "All")) && option.Code === 'All') {
                                                            return true;
                                                        }
                                                    }

                                                }}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        label="Include Airlines"
                                                        value={includeAirlines}
                                                        variant="filled"
                                                        disabled={action === "view"}
                                                        color="secondary"
                                                        size="small"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password",
                                                        }}
                                                    />
                                                )}
                                                style={{ maxWidth: "34vw" }}
                                            />
                                        </Col>
                                    }
                                    {
                                        action !== "view" &&
                                        <Col sm={6} className="">

                                            <Autocomplete
                                                id="ex airline select"
                                                multiple
                                                limitTags={1}
                                                value={includeAirlines && includeAirlines}
                                                onChange={(event, value) => {
                                                    dispatchIncludeAirlines({ type: 'includeAirlines', newValue: value });
                                                }}
                                                disabled={action === "view"}
                                                options={airlineDetails}
                                                autoHighlight
                                                getOptionLabel={(option) =>
                                                    option.Code === "All"
                                                        ? "All"
                                                        : option.Name + " (" + option.Code + ")"
                                                }
                                                filterSelectedOptions
                                                getOptionDisabled={(option) => {
                                                    if (includeAirlines && includeAirlines.length) {
                                                        if (includeAirlines.some((airline) => airline.Code === "All") && option.Code !== 'All') {
                                                            return true;
                                                        } else if ((includeAirlines.some((airline) => airline.Code !== "All")) && option.Code === 'All') {
                                                            return true;
                                                        }
                                                    }

                                                }}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        label="Include Airlines"
                                                        value={includeAirlines}
                                                        variant="filled"
                                                        color="secondary"
                                                        size="small"
                                                        disabled={action === "view"}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password",
                                                        }}
                                                    />
                                                )}
                                                style={{ maxWidth: "34vw" }}
                                            />
                                        </Col>
                                    }

                                    {
                                        action === "view" &&
                                        <Col sm={6} className="">

                                            <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                options={airlineDetails}
                                                disabled={action === "view"}
                                                value={excludeAirlines && excludeAirlines}
                                                getOptionLabel={(option) =>
                                                    option.Code === "All"
                                                        ? "All"
                                                        : option.Name + " (" + option.Code + ")"
                                                }
                                                onChange={(event, newValue) => { dispatchExcludeAirlines({ type: 'excludeAirlines', newValue: newValue }); }}
                                                // defaultValue={[airlineDetails[0]]}
                                                filterSelectedOptions
                                                getOptionDisabled={(option) => {
                                                    if (excludeAirlines && excludeAirlines.length) {
                                                        if (excludeAirlines.some((airline) => airline.Code === "All") && option.Code !== 'All') {
                                                            return true;
                                                        } else if ((excludeAirlines.some((airline) => airline.Code !== "All")) && option.Code === 'All') {
                                                            return true;
                                                        }
                                                    }

                                                }}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        variant="filled"
                                                        color="secondary"
                                                        size="small"
                                                        disabled={action === "view"}
                                                        value={excludeAirlines}
                                                        className="ticket-config-inputs"
                                                        label=" Exclude Airlines"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password",
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    }
                                    {
                                        action !== "view" &&
                                        <Col sm={6} className="">

                                            <Autocomplete
                                                multiple
                                                limitTags={1}
                                                id="tags-outlined"
                                                options={airlineDetails}
                                                disabled={action === "view"}
                                                value={excludeAirlines && excludeAirlines}
                                                getOptionLabel={(option) =>
                                                    option.Code === "All"
                                                        ? "All"
                                                        : option.Name + " (" + option.Code + ")"
                                                }
                                                onChange={(event, newValue) => { dispatchExcludeAirlines({ type: 'excludeAirlines', newValue: newValue }); }}
                                                // defaultValue={[airlineDetails[0]]}
                                                filterSelectedOptions
                                                getOptionDisabled={(option) => {
                                                    if (excludeAirlines && excludeAirlines.length) {
                                                        if (excludeAirlines.some((airline) => airline.Code === "All") && option.Code !== 'All') {
                                                            return true;
                                                        } else if ((excludeAirlines.some((airline) => airline.Code !== "All")) && option.Code === 'All') {
                                                            return true;
                                                        }
                                                    }

                                                }}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        variant="filled"
                                                        color="secondary"
                                                        size="small"
                                                        disabled={action === "view"}
                                                        value={excludeAirlines}
                                                        className="ticket-config-inputs"
                                                        label=" Exclude Airlines"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password",
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    }

                                    <Col sm={6} className="">
                                        <TextValidator
                                            variant="filled"
                                            className="ticket-config-inputs"
                                            label="Queue Number"
                                            size="small"
                                            color="secondary"
                                            value={form.queueNumber}
                                            onChange={(event) => handleOnChange("queueNumber", event.target.value)}
                                            disabled={action === "view"}
                                        />
                                    </Col>
                                    <Col sm={6} className="">
                                        <TextValidator
                                            variant="filled"
                                            label="Queue Format"
                                            className="ticket-config-inputs"
                                            size="small"
                                            color="secondary"
                                            value={form.queueFormat}
                                            onChange={(event) => handleOnChange("queueFormat", event.target.value)}
                                            disabled={action === "view"}
                                        />
                                    </Col>
                                    <Col sm={6} className="">
                                        <TextValidator
                                            variant="filled"
                                            className="ticket-config-inputs"
                                            label="Ticket Designator"
                                            size="small"
                                            color="secondary"
                                            value={form.ticketDesignator}
                                            onChange={(event) => handleOnChange("ticketDesignator", event.target.value)}
                                            disabled={action === "view"}
                                        />
                                    </Col>
                                    <Col sm={6} className="">
                                        <TextValidator
                                            variant="filled"
                                            label="Printer Id"
                                            className="ticket-config-inputs"
                                            size="small"
                                            color="secondary"
                                            value={form.printerId}
                                            onChange={(event) => handleOnChange("printerId", event.target.value)}
                                            disabled={action === "view"}
                                        />
                                    </Col>
                                    <Col sm={6} className="">
                                        <Autocomplete
                                            id="FOP-select"
                                            value={form.fop != "" ? fopOptions.find((v) => v === form.fop) : null}
                                            onChange={(event, newValue) => { handleOnChange("fop", newValue ? newValue : "") }}
                                            options={fopOptions}
                                            disabled={action === "view"}
                                            getOptionLabel={(option) =>
                                                option
                                            }
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="FOP"
                                                    className="ticket-config-inputs"
                                                    value={form.fop}
                                                    variant="filled"
                                                    color="secondary"
                                                    size="small"
                                                    validators={["required"]}
                                                    errorMessages={["required"]}
                                                    disabled={action === "view"}
                                                />
                                            )}
                                        />
                                    </Col>
                                    {form.fop === 'Credit Card' &&

                                        <>
                                            <Col sm={6} className="">
                                                <Autocomplete
                                                    id="PCC-select"
                                                    value={selectedcard}
                                                    // value={selectedcard ? creditCardsList?.find((v) => v.cardNumber == selectedcard.cardNumber ? v : null) : creditCardsList?.find((v) => v.cardNumber == form.accountNumber ? v : null)}
                                                    onChange={(event, newValue) => { dispatch({ type: 'card', newValue: newValue }); }}
                                                    options={creditCardsList}
                                                    disabled={action === "view"}
                                                    getOptionLabel={option => !option.cardinfo ? (option.code + ' ' + option.cardNumber) : (option.cardinfo.code + ' ' + option.cardinfo.cardNumber)}
                                                    disableClearable
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            className="ticket-config-inputs"
                                                            label="Credit Cards"
                                                            value={selectedcard.code + ' ' + selectedcard.cardNumber}
                                                            variant="filled"
                                                            color="secondary"
                                                            size="small"
                                                            validators={["required"]}
                                                            errorMessages={["required"]}
                                                            disabled={action === "view"}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        </>

                                    }
                                    <Col sm={6} className="">
                                        <TextValidator
                                            variant="filled"
                                            label="Remarks"
                                            className="ticket-config-inputs"
                                            size="small"
                                            color="secondary"
                                            value={form.remarks}
                                            onChange={(event) => handleOnChange("remarks", event.target.value)}
                                            validators={["required"]}
                                            errorMessages={["required"]}
                                            disabled={action === "view"}
                                        />
                                    </Col>
                                    <Col sm={6} className="">
                                        <Autocomplete
                                            id="Status select"
                                            value={isConfigActive}
                                            onChange={(event, newValue) => { dispatchisActive({ type: 'isConfigActive', newValue: newValue ? newValue : '' }) }}
                                            options={statusOptions}
                                            disabled={action === "view"}
                                            getOptionLabel={option => option}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    className="ticket-config-inputs"
                                                    label="Status"
                                                    value={isConfigActive}
                                                    variant="filled"
                                                    color="secondary"
                                                    size="small"
                                                    validators={["required"]}
                                                    errorMessages={["required"]}
                                                    disabled={action === "view"}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </ValidatorForm>

                    </Modal.Body>
                    {
                        action !== "view" ?
                            <Modal.Footer>
                                <Row className="">
                                    <Col className="">
                                        {
                                            action != "view" &&
                                            <Button
                                                className="modal_submit_btn"
                                                variant="filled"
                                                color="primary"
                                                size="large"
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%"
                                                }}
                                                onClick={onSubmitClick}
                                            >
                                                {action == "edit" ? "Update" : "Create"}
                                            </Button>
                                        }
                                        {showLoader && <div style={{ position: 'absolute', top: '45%', left: '50%' }}><CircularProgress /></div>}
                                    </Col>
                                </Row>
                            </Modal.Footer>
                            : ""
                    }
                </Box>

            </Modal>
        </>
    );
}
