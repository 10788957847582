import moment from "moment";
import { checkIfUserStaff } from "../../services/AuthService";
import CachedIcon from "@mui/icons-material/Cached";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/joy/Button";
import Popover from "@material-ui/core/Popover";

export default function FlightItinerary({
    bookingDetails,
    getClientName,
    flight,
    orderId,
    pccList,
    handleRefresh,
    countDownText,
    isRefreshEnabled,
    isEditTimeEnabled,
    setShowAddTimeLimit,
    handleClickOpenPNRDialog,
    errorMsgTkt,
    updateCancel,
    fareChangeCheck,
    updateProcess,
    goToTicket,
    updateCancelInvoice,
    IBossInvoiceURL,
    setShowInvoicePopover,
    DownloadInvoiceOpen,
    goToInvoice,
    showInvoicePopover,
    setShowTicketPopover,
    DownloadTicketOpen,
    showTicketPopover,
    tktTimeLimitInfo,
}) {
    return (
        <>
            <div className='flight-itenary'>
                <div className='flight-itenary-header'>
                    <div className='flight-itenary-title-container'>
                        <div>Flight Itinerary</div>
                    </div>
                    <div className='flight-itenary-date'>
                        Created On:{" "}
                        {bookingDetails.createdOn &&
                            moment(bookingDetails.createdOn).format("Do MMM  YYYY hh:mm A")}
                    </div>
                </div>

                <div className='row'>
                    <div
                        className={`col-4 vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2  `}>
                        {checkIfUserStaff() && (
                            <>
                                <div className='' style={{ textAlign: "center" }}>
                                    <p style={{ paddingTop: "10px" }}>
                                        <span>Client Name: </span>
                                    </p>
                                </div>
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        color: "black",
                                        textAlign: "center",
                                    }}>
                                    <p className='flight-itenary-details-item-text '>
                                        {getClientName && getClientName}
                                    </p>
                                </div>
                            </>
                        )}

                        <div className='flight-itenary-details-item '>
                            <p
                                className={`itenary-confirmtaion-tag ${!checkIfUserStaff() &&
                                    flight.currentStatus === "Refund_Initiated"
                                    ? "Cancelled"
                                    : !checkIfUserStaff() &&
                                        flight.currentStatus === "Void_Refunded"
                                        ? "Refunded"
                                        : !checkIfUserStaff() && flight.currentStatus === "Voided"
                                            ? "Cancel_Requested"
                                            : flight.currentStatus
                                    }`}>
                                {!checkIfUserStaff() &&
                                    flight.currentStatus === "Refund_Initiated"
                                    ? "Cancelled"
                                    : !checkIfUserStaff() &&
                                        flight.currentStatus === "Void_Refunded"
                                        ? "Refunded"
                                        : !checkIfUserStaff() && flight.currentStatus === "Voided"
                                            ? "Cancel_Requested"
                                            : flight.currentStatus}
                                {bookingDetails.cancelRefNo && (
                                    <span style={{ fontWeight: "bold", color: "black" }}>
                                        {" "}
                                        {bookingDetails.cancelRefNo}
                                    </span>
                                )}
                                {bookingDetails.isFullRefund && (
                                    <span
                                        style={{
                                            color: "red",
                                            fontWeight: "500",
                                            fontSize: "16px",
                                        }}>
                                        {bookingDetails.isFullRefund}
                                    </span>
                                )}
                            </p>
                        </div>
                    </div>

                    <div
                        className={`col-4 vb-highlight-txt dispFlex flex-column   justify-content-center  pt-2  `}>
                        <div className='' style={{ textAlign: "center" }}>
                            {checkIfUserStaff() && (
                                <p style={{ paddingTop: "10px" }}>
                                    <span>Booking Source: </span>
                                    <span>
                                        {bookingDetails.bookingSource &&
                                            bookingDetails.bookingSource}
                                    </span>
                                </p>
                            )}
                            <p style={{ paddingTop: "10px" }}>
                                <span>Payment By: </span>
                                {console.log('bookingDetails.paymentMethodType', bookingDetails?.flights[0]?.paymentMethodType)}
                                <span>
                                    {bookingDetails && bookingDetails?.flights[0] && bookingDetails?.flights[0]?.paymentMethodType &&
                                        bookingDetails?.flights[0]?.paymentMethodType === "PG" ? "Payment Gateway" : bookingDetails?.flights[0]?.paymentMethodType}
                                </span>
                            </p>
                        </div>

                        <div className='flight-itenary-details-item '>
                            <p
                                style={{
                                    fontWeight: "bold",
                                    color: "black",
                                    textAlign: "center",
                                }}
                                className={`flight-itenary-details-item-text ${!checkIfUserStaff()
                                    ? " m0 dispFlex alignitemsCenter"
                                    : " pt-2 "
                                    }"}`}>
                                {orderId}
                            </p>
                        </div>
                        {checkIfUserStaff() && (
                            <div className='flight-itenary-details-item '>
                                <p className='flight-itenary-details-item-subtext pt-2'>
                                    {bookingDetails.traceId}
                                </p>
                            </div>
                        )}
                    </div>
                    <div
                        className={`col-4 vb-highlight-txt dispFlex flex-column justify-content-center  pt-2  `}>
                        {checkIfUserStaff() && (
                            <div className='' style={{ textAlign: "center" }}>
                                <p style={{ paddingTop: "10px" }}>
                                    ({flight.pcc && flight.pcc}
                                    {" - "}
                                    {flight.pcc &&
                                        pccList
                                            .filter((id) => id.value.startsWith(flight.pcc + ","))
                                            .map(({ label }) => {
                                                return label?.match(/\(([^)]+)\)/)?.length > 1
                                                    ? label?.match(/\(([^)]+)\)/)[1]
                                                    : label?.match(/\(([^)]+)\)/)?.length === 1
                                                        ? label?.match(/\(([^)]+)\)/)[0]
                                                        : "";
                                            })}
                                    )
                                </p>
                            </div>
                        )}

                        <div className='flight-itenary-details-item '>
                            <p
                                style={{
                                    fontWeight: "bold",
                                    color: "black",
                                    textAlign: "center",
                                }}
                                className={`flight-itenary-details-item-text ${!checkIfUserStaff()
                                    ? " m0 dispFlex alignitemsCenter"
                                    : " pt-2 "
                                    }"}`}>
                                {flight.pnr}
                            </p>
                        </div>
                        {checkIfUserStaff() && (
                            <>
                                <div className='flight-itenary-details-item '>
                                    <p className={`flight-itenary-details-item-subtext pt-2`}>
                                        {flight.pnr ? "PNR/Booking Reference" : ""}
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className='row'>
                    <div className='col-4 vb-highlight-txt pt-2  '>
                        <div className='d-flex justify-content-center'>
                            {checkIfUserStaff() &&
                                bookingDetails.flights &&
                                bookingDetails.flights[0] &&
                                bookingDetails.flights[0].currentStatus !== "Ticketed" &&
                                bookingDetails.flights[0].currentStatus !==
                                "Hold_Cancelled" &&
                                bookingDetails?.bookingSource !== "ImportPNR" &&
                                bookingDetails.bookingSource !== "OfflineBooking" && (
                                    <>
                                        <Button
                                            className='update-btn'
                                            onClick={handleClickOpenPNRDialog}>
                                            Update PNR and Ticket Number
                                        </Button>
                                        <p>{errorMsgTkt}</p>
                                    </>
                                )}
                        </div>
                    </div>
                    <div className='col-4 vb-highlight-txt pt-2  '>
                        {!updateProcess &&
                            bookingDetails &&
                            bookingDetails?.bookingSource !== "ImportPNR" &&
                            bookingDetails.bookingSource !== "OfflineBooking" &&
                            flight.currentStatus === "Booking_On_Hold" ? (
                            <>
                                <div className='d-flex justify-content-center'>
                                    {/* <div> */}
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        className='update-btn '
                                        onClick={() => updateCancel(bookingDetails)}>
                                        Hold Cancel
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        className='update-btn ms-2 '
                                        onClick={() => fareChangeCheck(bookingDetails)}>
                                        Ticketing
                                    </Button>
                                </div>
                                {/* </div> */}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    {flight.currentStatus === "Booking_On_Hold" && checkIfUserStaff() && (
                        <div className='col-4 vb-highlight-txt pt-2  '>
                            <div
                                className='flight-itenary-details-item'
                                style={{ textAlign: "center", marginBottom: "1rem" }}>
                                {
                                    <>
                                        <div className='flight-itenary-details-item-text'>
                                            {flight.tktTimeLimit || countDownText ? (
                                                <>
                                                    {flight?.tktTimeLimit && (
                                                        <>
                                                            <div
                                                                className='flight-itenary-details-item-text'
                                                                style={{ color: "red" }}>
                                                                {moment(new Date(flight.tktTimeLimit)).format("MM-DD-YYYY HH:mm:ss")}
                                                            </div>
                                                            {countDownText && (
                                                                <p
                                                                    className='flight-itenary-details-item-text mt-3'
                                                                    style={{ color: "red" }}>
                                                                    {countDownText}
                                                                </p>
                                                            )}
                                                        </>
                                                    )}
                                                    <div className='flight-itenary-details-item-subtext'>
                                                        Ticketing Timelimit
                                                    </div>
                                                    {flight.tktTimeLimitInfo &&
                                                        countDownText !== "Expired" && (
                                                            <div className='flight-itenary-details-item-subtext'>
                                                                Booking Time limit is valid as{" "}
                                                                <span
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        color: "black",
                                                                    }}>
                                                                    {flight.tktTimeLimitInfo}
                                                                </span>{" "}
                                                                timezone
                                                            </div>
                                                        )}
                                                </>
                                            ) : (
                                                <>
                                                    {flight.currentStatus === "Booking_On_Hold" && (
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            {isRefreshEnabled ? (
                                                                <Button
                                                                    variant='outlined'
                                                                    className='update-btn'
                                                                    onClick={handleRefresh}
                                                                    startDecorator={
                                                                        <CachedIcon
                                                                            color='warning'
                                                                            fontSize='large'
                                                                            className='zoomable-icon'
                                                                        />
                                                                    }>
                                                                    Ticketing Timelimit
                                                                </Button>
                                                            ) : isEditTimeEnabled ? (
                                                                <Button
                                                                    variant='outlined'
                                                                    onClick={() => setShowAddTimeLimit(true)}
                                                                    className='update-btn'
                                                                    startDecorator={
                                                                        <EditIcon className='app-icon-color ms-2 zoomable-icon' />
                                                                    }>
                                                                    Ticketing Timelimit
                                                                </Button>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    )}
                </div>

                {/* <div className='flight-itenary'> */}
                <div className='row'>
                    <div className='col-6 text-danger text-bold d-flex'>
                        {bookingDetails && bookingDetails?.supplierName === "Easyres" &&
                            <>
                                <div className="ms-5 me-5"></div>
                                <div className="ms-5 mt-3">Group Fares</div>
                            </>
                        }
                    </div>
                    <div
                        className={`col-6 vb-highlight-txt dispFlex  justify-content-evenly  pt-2 padB8 `}>
                        <div className='' style={{ textAlign: "center" }}>
                            {!updateProcess &&
                                (flight.currentStatus === "Ticketed" ||
                                    flight.currentStatus === "Cancel_Requested" ||
                                    flight.currentStatus === "Refund_Initiated" ||
                                    flight.currentStatus === "Refunded" ||
                                    flight.currentStatus === "Cancelled" ? (
                                    <Button
                                        variant='contained'
                                        className='print-btn'
                                        onClick={() => updateCancelInvoice()}>
                                        Cancel Booking
                                    </Button>
                                ) : (
                                    ""
                                ))}
                        </div>
                        {/* </div>
                    <div className={`col-4 vb-highlight-txt dispFlex flex-column justify-content-center  pt-2  `}> */}
                        <div className='' style={{ textAlign: "center" }}>
                            {flight.currentStatus !== "Booking_Failed" &&
                                flight.currentStatus !== "Booked" &&
                                flight.currentStatus !== "Booking_On_Hold" &&
                                flight.currentStatus !== "Ticketing_In_Process" &&
                                flight.currentStatus !== "Booking_Cancelled" &&
                                flight.currentStatus !== "Booking_In_Process" &&
                                flight.currentStatus !== "Booking_Failed" &&
                                flight.currentStatus !== "Ticket_Failed" &&
                                flight.currentStatus !== "Booking_Rejected" &&
                                flight.currentStatus !== "Hold_Cancelled" && (
                                    <>
                                        {IBossInvoiceURL ? (
                                            <>
                                                <Button
                                                    variant='contained'
                                                    className='print-btn'
                                                    onClick={(event) =>
                                                        setShowInvoicePopover(event.currentTarget)
                                                    }>
                                                    Print Invoice
                                                </Button>
                                                <Popover
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    open={DownloadInvoiceOpen}
                                                    anchorEl={showInvoicePopover}
                                                    onClose={() => setShowInvoicePopover(null)}>
                                                    <div className='ticket-list'>
                                                        <div
                                                            className='ticket-list-item'
                                                            onClick={() => goToInvoice()}>
                                                            GFS Invoice
                                                        </div>
                                                        <div className='ticket-list-item'>
                                                            <a
                                                                className='ticket-list-item'
                                                                rel='noreferrer'
                                                                href={IBossInvoiceURL}
                                                                target='_blank'>
                                                                IBoss Invoice
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Popover>
                                            </>
                                        ) : (
                                            <Button
                                                variant='contained'
                                                className='print-btn'
                                                onClick={(event) => goToInvoice()}>
                                                Print Invoice
                                            </Button>
                                        )}
                                    </>
                                )}
                        </div>
                        {/* </div>
                    <div className={`col-4 vb-highlight-txt dispFlex flex-column justify-content-center  pt-2  `}> */}
                        <div className='' style={{ textAlign: "center" }}>
                            {flight.currentStatus !== "Booking_Failed" &&
                                flight.currentStatus !== "Booked" &&
                                flight.currentStatus !== "Booking_On_Hold" &&
                                flight.currentStatus !== "Ticketing_In_Process" &&
                                flight.currentStatus !== "Booking_Cancelled" &&
                                flight.currentStatus !== "Booking_In_Process" &&
                                flight.currentStatus !== "Booking_Failed" &&
                                flight.currentStatus !== "Ticket_Failed" &&
                                flight.currentStatus !== "Booking_Rejected" &&
                                flight.currentStatus !== "Hold_Cancelled" && (
                                    <>
                                        <Button
                                            variant='contained'
                                            className='print-btn'
                                            onClick={(event) =>
                                                setShowTicketPopover(event.currentTarget)
                                            }>
                                            Print E Ticket
                                        </Button>
                                        <Popover
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                            }}
                                            open={DownloadTicketOpen}
                                            anchorEl={showTicketPopover}
                                            onClose={() => setShowTicketPopover(null)}>
                                            <div className='ticket-list'>
                                                <div
                                                    className='ticket-list-item'
                                                    onClick={() => {
                                                        goToTicket(true);
                                                    }}>
                                                    With Fare Details
                                                </div>
                                                <div
                                                    className='ticket-list-item'
                                                    onClick={() => {
                                                        goToTicket(false);
                                                    }}>
                                                    Without Fare Details
                                                </div>
                                            </div>
                                        </Popover>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </>
    );
}
