import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

export default function FlightCommertialConfiguration({ action, originCountryInputList, countries, cabinClassList,
    handleOriginCountryInputChange, fareTypeList, handleRemoveOriginCountryRow, handleAddOriginCountryRowClick,
    productType, countryAndStationData
}) {


    const classes = useStyles();
    return (<>
        <div className="vendor-view-edit-drawer-if-container">
            <div className="vendor-view-edit-drawer-if-line"></div>
            <div className="text-wrap-cls">Commertial Configuration</div>
            <div className="vendor-view-edit-drawer-if-line"></div>
        </div>
        <div className="vendor-view-edit-drawer-input-container">
            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                {" "}
                Origin Country
            </div>
            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                {" "}
                Destination Country{" "}
            </div>


            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                {" "}
                Cabin Class
            </div>
            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                {" "}
                Fare Type{" "}
            </div>


            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                Issuance Fees
            </div>
            {action !== "view" && (
                <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                    {" "}
                    Action
                </div>
            )}
        </div>
        <>
            {originCountryInputList.map((x, i) => {
                // alert(x)
                return (
                    <>
                        {
                            //action !== "view" && (
                            <>
                                <div className="vendor-view-edit-drawer-input-container">
                                    {action !== "view" && (
                                        <>

                                            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                                <Autocomplete
                                                    id="originCountry"
                                                    name="originCountry"
                                                    value={
                                                        countries.find(
                                                            (v) =>
                                                                v.code ===
                                                                originCountryInputList[i].originCountry
                                                        ) || null
                                                    }
                                                    onChange={(event, value) => {
                                                        originCountryInputList[i].originCountry =
                                                            value.code ? value.code : "";
                                                        handleOriginCountryInputChange(event, i);
                                                        //originCountryInputList[i].originCountry = Object.keys(value).map(function(k){return value[k].code}).join(',');
                                                    }}
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option.code === "All"
                                                            ? "All"
                                                            : option.label + " (" + option.code + ")"
                                                    }
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            label="Origin Country"
                                                            value={
                                                                originCountryInputList[i].originCountry
                                                            }
                                                            variant="outlined"
                                                            color="secondary"
                                                            size="small"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password",
                                                                required:
                                                                    originCountryInputList[i]
                                                                        .originCountry &&
                                                                    originCountryInputList[i]
                                                                        .originCountry.length === 0,
                                                            }}
                                                            required={true}
                                                        />
                                                    )}
                                                    style={{ maxWidth: "34vw" }}
                                                />
                                            </div>
                                            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                                <Autocomplete
                                                    id="destinationCountry"
                                                    value={
                                                        countries.find(
                                                            (v) =>
                                                                v.code ===
                                                                originCountryInputList[i]
                                                                    .destinationCountry
                                                        ) || null
                                                    }
                                                    onChange={(event, value) => {
                                                        originCountryInputList[
                                                            i
                                                        ].destinationCountry = value.code
                                                                ? value.code
                                                                : "";
                                                        handleOriginCountryInputChange(event, i);
                                                        //originCountryInputList[i].destinationCountry = Object.keys(value).map(function(k){return value[k].code}).join(',');
                                                    }}
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option.code === "All"
                                                            ? "All"
                                                            : option.label + " (" + option.code + ")"
                                                    }
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            label="Destination Country"
                                                            value={
                                                                originCountryInputList[i]
                                                                    .destinationCountry
                                                            }
                                                            variant="outlined"
                                                            color="secondary"
                                                            size="small"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password",
                                                                required:
                                                                    originCountryInputList[i]
                                                                        .destinationCountry &&
                                                                    originCountryInputList[i]
                                                                        .destinationCountry.length === 0,
                                                            }}
                                                            required={true}
                                                        />
                                                    )}
                                                    style={{ maxWidth: "34vw" }}
                                                />
                                            </div>



                                            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                                <Autocomplete
                                                    required
                                                    id="cabinClass"
                                                    name="cabinClass"
                                                    value={
                                                        cabinClassList.find(
                                                            (v) =>
                                                                v.value ===
                                                                originCountryInputList[i].cabinClass
                                                        ) || null
                                                    }
                                                    onChange={(event, value) => {
                                                        originCountryInputList[i].cabinClass =
                                                            value.value ? value.value : "";
                                                        //originCountryInputList[i].cabinClass = Object.keys(value).map(function(k){return value[k].value}).join(',');
                                                        handleOriginCountryInputChange(event, i);
                                                        //console.log(originCountryInputList[i].cabinClass.split(','))
                                                        // console.log(value);
                                                    }}
                                                    options={cabinClassList}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.label}
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            label="cabinClass"
                                                            value={
                                                                originCountryInputList[i].cabinClass
                                                            }
                                                            variant="outlined"
                                                            color="secondary"
                                                            size="small"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password",
                                                                required:
                                                                    originCountryInputList[i]
                                                                        .cabinClass &&
                                                                    originCountryInputList[i].cabinClass
                                                                        .length === 0,
                                                            }}
                                                            required={true}
                                                        />
                                                    )}
                                                    style={{ maxWidth: "34vw" }}
                                                />
                                            </div>
                                            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                                <Autocomplete
                                                    id="fareType"
                                                    name="fareType"
                                                    value={
                                                        fareTypeList.find(
                                                            (v) =>
                                                                v === originCountryInputList[i].fareType
                                                        ) || null
                                                    }
                                                    onChange={(event, value) => {
                                                        originCountryInputList[i].fareType = value
                                                            ? value
                                                            : "";
                                                        handleOriginCountryInputChange(event, i);
                                                    }}
                                                    options={fareTypeList}
                                                    classes={{
                                                        option: classes.option,
                                                    }}
                                                    getOptionLabel={(option) => option}
                                                    autoHighlight
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            label="Fare Type"
                                                            value={originCountryInputList[i].fareType}
                                                            variant="outlined"
                                                            color="secondary"
                                                            size="small"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password", // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                                <TextValidator
                                                    id="issuanceFees"
                                                    name="issuanceFees"
                                                    variant="outlined"
                                                    label="Issuance Fees"
                                                    size="small"
                                                    color="secondary"
                                                    value={originCountryInputList[i].issuanceFees}
                                                    onChange={(e) =>
                                                        handleOriginCountryInputChange(e, i)
                                                    }
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                    }}
                                                />
                                            </div>
                                            <div className="search-view-edit-drawer-input config-input-flex-1">
                                                {originCountryInputList.length !== 1 && (
                                                    <RemoveCircleOutlineIcon
                                                        style={{
                                                            fontSize: "31px",
                                                            color: "red",
                                                            cursor: "pointer",
                                                            paddingRight: "5px",
                                                        }}
                                                        onClick={() =>
                                                            handleRemoveOriginCountryRow(i)
                                                        }
                                                    />
                                                )}
                                                {originCountryInputList.length - 1 === i && (
                                                    <AddCircleOutlineIcon
                                                        style={{
                                                            fontSize: "31px",
                                                            color: "green",
                                                            cursor: "pointer",
                                                            paddingRight: "5px",
                                                        }}
                                                        onClick={handleAddOriginCountryRowClick}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {action === "view" && (
                                        <>
                                            <div className="view-configuration-drawer config-input-flex-1 px-3">
                                                <span className="view-configuration-drawer-description">
                                                    {originCountryInputList[i].originCountry}
                                                </span>
                                            </div>
                                            <div className="view-configuration-drawer config-input-flex-1 px-3">
                                                <span className="view-configuration-drawer-description">
                                                    {originCountryInputList[i].destinationCountry}
                                                </span>
                                            </div>
                                            <div className="view-configuration-drawer config-input-flex-1 px-3">
                                                <span className="view-configuration-drawer-description">
                                                    {cabinClassList
                                                        .filter(
                                                            (id) =>
                                                                id.value ===
                                                                originCountryInputList[i].cabinClass
                                                        )
                                                        .map(({ label }) => label)}
                                                </span>
                                            </div>
                                            <div className="view-configuration-drawer config-input-flex-1 px-3">
                                                <span className="view-configuration-drawer-description">
                                                    {originCountryInputList[i].fareType}
                                                </span>
                                            </div>
                                            <div className="view-configuration-drawer config-input-flex-1 px-3">
                                                <span className="view-configuration-drawer-description">
                                                    {originCountryInputList[i].issuanceFees}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                            //)
                        }
                    </>
                );
            })}
        </>
        {/* <div className="vendor-view-edit-drawer-if-container">
              <div className="vendor-view-edit-drawer-if-line"></div>
              <div className="text-wrap-cls">Ex Origin/Destination Country</div>
              <div className="vendor-view-edit-drawer-if-line"></div>
            </div>
            <div className="vendor-view-edit-drawer-input-container"> 
                <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label"> Ex Origin Country</div>
                <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label"> Ex Destination Country </div>
                <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label"> Ex Cabin Class</div>
                <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label"> Ex Fare Type </div>
                <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label"> Ex Issuance Fees</div>
                {action !== "view" && (
                <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label"> Action</div>
                )}
            </div> 
            <>
                {
                  exOriginCountryInputList.map( (y,j)=>{
                      return(
                        <>
                        {
                           
                            //action !== "view" && (
                              <>
                              <div className="vendor-view-edit-drawer-input-container"> 
                              {action !== "view" && (
                                <>
                                 <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <Autocomplete
                                        id="excludedOriginCountry"
                                        name="excludedOriginCountry"
                                        value={
                                          countries.find((v) => v.code === exOriginCountryInputList[j].excludedOriginCountry) ||
                                          null
                                        }                                        
                                        onChange={(event, value) => { 
                                          exOriginCountryInputList[j].excludedOriginCountry = value.code? value.code : "";
                                          handleExoriginCountryInputChange(event,j); 
                                        }}
                                        options={countries}
                                        autoHighlight
                                        getOptionLabel={(option) => option.code === "All" ? "All" : option.label + " (" + option.code + ")"}
                                        renderInput={(params) => (  
                                          <TextValidator
                                            {...params}
                                            label="Ex Origin Country"
                                            value={exOriginCountryInputList[j].excludedOriginCountry}
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            inputProps={{
                                              ...params.inputProps,
                                              autoComplete: "new-password",
                                              required: exOriginCountryInputList[j].excludedOriginCountry && exOriginCountryInputList[j].excludedOriginCountry.length === 0
                                            }}
                                            required={true}
                                          />
                                        )}
                                        style={{ maxWidth: "34vw" }}
                                      />
                                 </div>
                                 <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                      <Autocomplete
                                          id="excludedDestinationCountry"
                                          value={
                                            countries.find((v) => v.code === exOriginCountryInputList[j].excludedDestinationCountry) ||
                                            null
                                          }                                        
                                          onChange={(event, value) => { 
                                              exOriginCountryInputList[j].excludedDestinationCountry = value.code? value.code : "";
                                              handleExoriginCountryInputChange(event,j);
                                          }}
                                          options={countries}
                                          autoHighlight
                                          getOptionLabel={(option) => option.code === "All" ? "All" : option.label + " (" + option.code + ")"}
                                          renderInput={(params) => (
                                            <TextValidator
                                              {...params}
                                              label="Ex Destination Country"
                                              value={exOriginCountryInputList[j].excludedDestinationCountry}
                                              variant="outlined"
                                              color="secondary"
                                              size="small"
                                              inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password",
                                                required: exOriginCountryInputList[j].excludedDestinationCountry && exOriginCountryInputList[j].excludedDestinationCountry.length === 0
                                              }}
                                              required={true}
                                            />
                                          )}
                                          style={{ maxWidth: "34vw" }}
                                        />       
                                 </div>
                                 <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1"> 
                                      <Autocomplete
                                          required
                                          id="exCabinClass"
                                          name="exCabinClass"
                                          value={
                                            cabinClassList.find((v) => v.value === exOriginCountryInputList[j].exCabinClass) ||
                                            null
                                          }
                                          onChange={(event, value) => {
                                            exOriginCountryInputList[j].exCabinClass = value.value? value.value : "";
                                            handleExoriginCountryInputChange(event,j);
                                           }}
                                          options={cabinClassList}
                                          autoHighlight
                                          getOptionLabel={(option) => option.label}
                                          renderInput={(params) => (
                                            <TextValidator
                                              {...params}
                                              label="Ex Cabin Class"
                                              value={exOriginCountryInputList[j].exCabinClass}
                                              variant="outlined"
                                              color="secondary"
                                              size="small"
                                              inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password",
                                                required: exOriginCountryInputList[j].exCabinClass && exOriginCountryInputList[j].exCabinClass.length === 0
                                              }}
                                              required={true}
                                            />
                                          )}
                                          style={{ maxWidth: "34vw" }}
                                        />
                                 </div>
                                 <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1"> 
                                    <Autocomplete
                                        id="exFareType"
                                        name="exFareType"
                                        value={
                                          fareTypeList.find((v) => v === exOriginCountryInputList[j].exFareType) ||
                                          null
                                        }
                                        onChange={(event, value) => {
                                          exOriginCountryInputList[j].exFareType = value? value : "";
                                          handleExoriginCountryInputChange(event,j);
                                        }}
                                        options={fareTypeList}
                                        classes={{
                                          option: classes.option,
                                        }}
                                        autoHighlight
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                          <TextValidator
                                            {...params}
                                            label="Ex Fare Type"
                                            value={exOriginCountryInputList[j].exFareType}
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            inputProps={{
                                              ...params.inputProps,
                                              autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                          />
                                        )}
                                      />
                                 </div>
                                 <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <TextValidator
                                      id="exIssuanceFees"
                                      name="exIssuanceFees"
                                      variant="outlined"
                                      label="Ex Issuance Fees"
                                      size="small"
                                      color="secondary"
                                      value={exOriginCountryInputList.exIssuanceFees}
                                      onChange={e => handleExoriginCountryInputChange(e,j)}
                                      //onChange={(event) => setexOriginCountryInputList(event.target.value)}
                                    />
                                 </div>
                                 <div className="search-view-edit-drawer-input config-input-flex-1"> 
                                 {
                                  exOriginCountryInputList.length !==1 &&
                                  <RemoveCircleOutlineIcon style={{fontSize:"31px",color:"red",cursor:"pointer",paddingRight:"5px"}} onClick={() => handleRemoveExoriginCountryRow(j)}/>
                                 }
                                 {
                                    exOriginCountryInputList.length-1 ===j &&
                                    <AddCircleOutlineIcon style={{fontSize:"31px",color:"green", cursor:"pointer",paddingRight:"5px"}} onClick={handleAddExoriginCountryRowClick}/>
                                 }
                                  </div>
                                 </>
                              )}
                              </div>
                                 
                              </>
                            //)
                          }
                          
                      
                        </>
                        )
                   })
                }
            </> */}

    </>);
}