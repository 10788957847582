import React, { useState, useEffect } from "react";
import "./MarkupGroup.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import MarkupGroupTable from "./MarkupGroupTable/MarkupGroupTable";
import MarkupGroupDrawer from "./MarkupGroupDrawer/MarkupGroupDrawer";
import AddMarkupGroupCard from "./AddMarkupGroupCard/AddMarkupGroupCard";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../services/AuthService";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MarkupGroup() {
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("new");
  const [categoryAction, setCategoryAction] = useState("view");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [commissionGroups, setCommissionGroups] = useState([]);
  const [name, setName] = useState("");
  const history = useHistory();
  useEffect(() => {
    if (isUserHasPermission("MarkupGroupRead")) {
      loadCommissionGroup();
    } else {
      history.push("/Unauthorized");
    }
  }, []);

  async function loadCommissionGroup() {
    let filter = "";
    if (name !== "") filter = "?filters=name@=" + name;

    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/MarkupGroup" +
        filter
      )
      .then((response) => {
        response.data.map((item) => {
          if (item.markupGroup.isActive) {
            item.markupGroup.status = "active";
          } else {
            item.markupGroup.status = "inactive";
          }
        });
        setCommissionGroups(response.data);
      })
      .catch((error) => console.log(error));
  }

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };

  const handleSnackbarToggle = () => {
    setDrawer(false);
    setSnackbarOpen(true);
    loadCommissionGroup();
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="supplier-container">
      <div className="supplier-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-supplier-container">
              <div className="supplier-table-container">
                <div className="supplier-title-add-button-container">
                  <div className="supplier-heading">Markup Group</div>
                  <div className="search-icon-button-container">
                    {/* <SearchIcon
                      fontSize="small"
                      className="search-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon> */}
                    {isUserHasPermission("MarkupGroupWrite") ? (
                      <div
                        className="add-supplier-button mt-0px"
                        onClick={() => {
                          setAction("new");
                          setDrawer(true);
                        }}
                      >
                        Add New Group
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="supplier-card-container">
                    <AddMarkupGroupCard
                      setName={setName}
                      loadCommissionGroup={loadCommissionGroup}
                    ></AddMarkupGroupCard>
                  </div>
                </Collapse>
                <MarkupGroupTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                  commissionGroups={commissionGroups}
                ></MarkupGroupTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <MarkupGroupDrawer
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
            action={action}
            setAction={setAction}
            handleSnackbarToggle={handleSnackbarToggle}
          ></MarkupGroupDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {`Setting has been ${action === "new" ? "created" : "updated"}`}
        </Alert>
      </Snackbar>
    </div>
  );
}
