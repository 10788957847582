import React, { useState, useEffect } from "react";
import "./ImportBooking.css";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import SearchImportBookingCard from "./SearchImportBookingCard/SearchImportBookingCard";
import getTax from "../../functions/getTax";
import getPaxTotal from "../../functions/getPaxTotal";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Skeleton from "@material-ui/lab/Skeleton";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import cabinClass from "../../data/CabinClass";
import RiseLoading from "../Loader/RiseLoader";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import loadFop from "../../functions/loadFop";
import { encryptData } from "../../providers/dataEncryptDecryptor";
import endEmailMandatoryClients from "../../data/EndEmailMandatoryClients"

import ItienarayHeader from "./ItienarayHeader";
import NoItienaryDiv from "./NoItienaryDiv";
import FlightImportBooking from "./FlightImportBooking/FlightImportBooking";
import RailImportBooking from "./RailImportBooking/RailImportBooking";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ImportBooking() {
  const railImportFareTemplate = {
    adultBase: 0,
    childBase: 0,
    infantBase: 0,
    seniorBase: 0,
    youthBase: 0,
    adultTax: 0,
    childTax: 0,
    infantTax: 0,
    seniorTax: 0,
    youthTax: 0,
    adultTotal: 0,
    childTotal: 0,
    infantTotal: 0,
    seniorTotal: 0,
    youthTotal: 0,
    adultBookingfee: 0,
    childBookingfee: 0,
    infantBookingfee: 0,
    seniorBookingfee: 0,
    youthBookingfee: 0,
    adultMarkup: 0,
    childMarkup: 0,
    infantMarkup: 0,
    seniorMarkup: 0,
    youthMarkup: 0,
    adultIata: 0,
    childIata: 0,
    infantIata: 0,
    seniorIata: 0,
    youthIata: 0,
    adultPlb: 0,
    childPlb: 0,
    infantPlb: 0,
    seniorPlb: 0,
    youthPlb: 0,
    adultVendorTax: 0,
    childVendorTax: 0,
    infantVendorTax: 0,
    seniorVendorTax: 0,
    youthVendorTax: 0,
    adultVendorTotal: 0,
    childVendorTotal: 0,
    infantVendorTotal: 0,
    seniorVendorTotal: 0,
    youthVendorTotal: 0,
    adultVendorIssuanceFee: 0,
    childVendorIssuanceFee: 0,
    infantVendorIssuanceFee: 0,
    seniorVendorIssuanceFee: 0,
    youthVendorIssuanceFee: 0,
    adultVendorIata: 0,
    childVendorIata: 0,
    infantVendorIata: 0,
    seniorVendorIata: 0,
    youthVendorIata: 0,
    adultVendorPlb: 0,
    childVendorPlb: 0,
    infantVendorPlb: 0,
    seniorVendorPlb: 0,
    youthVendorPlb: 0,
    adultExtra: 0,
    childExtra: 0,
    infantExtra: 0,
    seniorExtra: 0,
    youthExtra: 0,
    adultService: 0,
    childService: 0,
    infantService: 0,
    seniorService: 0,
    youthService: 0,

    clientTotalFare: 0,
    vendorTotalFare: 0,
    clientTotal: 0,
    vendorTotal: 0,
    ClientTotalWithClientROE: 0,
    VendorTotalWithClientROE: 0,

    adultCount: 0,
    childCount: 0,
    infantCount: 0,
    seniorCount: 0,
    youthCount: 0

  };
  const [railImportFareArray, setrailImportFareArray] = useState([railImportFareTemplate, railImportFareTemplate]);
  const [supplierName, setsupplierName] = useState("");
  const [pcc, setPCC] = useState("");
  const [pnr, setPNR] = useState("");
  const [clientId, setclientId] = useState("");
  const [bookingDetails, setbookingDetails] = useState(null);
  const [clientProfiles, setclientProfiles] = useState(null);
  const [pccList, setPccList] = useState([]);
  const [adultCount, setadultCount] = useState(0);
  const [childCount, setchildCount] = useState(0);
  const [infantCount, setinfantCount] = useState(0);
  const [seniorCount, setseniorCount] = useState(0);
  const [youthCount, setyouthCount] = useState(0);
  const [adultBase, setadultBase] = useState(0);
  const [childBase, setchildBase] = useState(0);
  const [infantBase, setinfantBase] = useState(0);
  const [seniorBase, setseniorBase] = useState(0);
  const [youthBase, setyouthBase] = useState(0);
  const [adultTax, setadultTax] = useState(0);
  const [childTax, setchildTax] = useState(0);
  const [infantTax, setinfantTax] = useState(0);
  const [seniorTax, setseniorTax] = useState(0);
  const [youthTax, setyouthTax] = useState(0);
  const [adultBaggage, setadultBaggage] = useState(0);
  const [childBaggage, setchildBaggage] = useState(0);
  const [infantBaggage, setinfantBaggage] = useState(0);
  const [seniorBaggage, setseniorBaggage] = useState(0);
  const [youthBaggage, setyouthBaggage] = useState(0);
  const [adultBookingfee, setadultBookingfee] = useState(0);
  const [childBookingfee, setchildBookingfee] = useState(0);
  const [infantBookingfee, setinfantBookingfee] = useState(0);
  const [seniorBookingfee, setseniorBookingfee] = useState(0);
  const [youthBookingfee, setyouthBookingfee] = useState(0);
  const [adultMarkup, setadultMarkup] = useState(0);
  const [childMarkup, setchildMarkup] = useState(0);
  const [infantMarkup, setinfantMarkup] = useState(0);
  const [seniorMarkup, setseniorMarkup] = useState(0);
  const [youthMarkup, setyouthMarkup] = useState(0);
  const [adultIata, setadultIata] = useState(0);
  const [childIata, setchildIata] = useState(0);
  const [infantIata, setinfantIata] = useState(0);
  const [seniorIata, setseniorIata] = useState(0);
  const [youthIata, setyouthIata] = useState(0);
  const [adultPlb, setadultPlb] = useState(0);
  const [childPlb, setchildPlb] = useState(0);
  const [infantPlb, setinfantPlb] = useState(0);
  const [seniorPlb, setseniorPlb] = useState(0);
  const [youthPlb, setyouthPlb] = useState(0);
  const [adultTotal, setadultTotal] = useState(0);
  const [childTotal, setchildTotal] = useState(0);
  const [infantTotal, setinfantTotal] = useState(0);
  const [seniorTotal, setseniorTotal] = useState(0);
  const [youthTotal, setyouthTotal] = useState(0);
  const [adultVendorTotal, setadultVendorTotal] = useState(0);
  const [childVendorTotal, setchildVendorTotal] = useState(0);
  const [infantVendorTotal, setinfantVendorTotal] = useState(0);
  const [seniorVendorTotal, setseniorVendorTotal] = useState(0);
  const [youthVendorTotal, setyouthVendorTotal] = useState(0);
  const [TotalFare, setTotalFare] = useState(0);
  const [iataType, setiataType] = useState("");
  const [vendorIataType, setVendorIataType] = useState("");
  const [plbType, setPlbType] = useState("");
  const [vendorPlbType, setvendorPlbType] = useState("");
  const [clientBalanceSH, setClientBalanceSH] = useState(false);
  const [clientAvailableBalance, setClientAvailableBalance] = useState(0);
  const [clientTotalWithClientROE, setClientTotalWithClientROE] = useState(0);
  const [vendorTotal, setVendorTotal] = useState(0);
  const [clientTotal, setClientTotal] = useState(0);
  const [vendorTotalWithClientROE, setVendorTotalWithClientROE] = useState(0);
  const [adultVendorIata, setadultVendorIata] = useState(0);
  const [childVendorIata, setchildVendorIata] = useState(0);
  const [infantVendorIata, setinfantVendorIata] = useState(0);
  const [seniorVendorIata, setseniorVendorIata] = useState(0);
  const [youthVendorIata, setyouthVendorIata] = useState(0);
  const [adultVendorPlb, setadultVendorPlb] = useState(0);
  const [childVendorPlb, setchildVendorPlb] = useState(0);
  const [infantVendorPlb, setinfantVendorPlb] = useState(0);
  const [seniorVendorPlb, setseniorVendorPlb] = useState(0);
  const [youthVendorPlb, setyouthVendorPlb] = useState(0);
  const [adultVendorIssuanceFee, setadultVendorIssuanceFee] = useState(0);
  const [childVendorIssuanceFee, setchildVendorIssuanceFee] = useState(0);
  const [infantVendorIssuanceFee, setinfantVendorIssuanceFee] = useState(0);
  const [seniorVendorIssuanceFee, setseniorVendorIssuanceFee] = useState(0);
  const [youthVendorIssuanceFee, setyouthVendorIssuanceFee] = useState(0);
  const [adultVendorTax, setadultVendorTax] = useState(0);
  const [childVendorTax, setchildVendorTax] = useState(0);
  const [infantVendorTax, setinfantVendorTax] = useState(0);
  const [seniorVendorTax, setseniorVendorTax] = useState(0);
  const [youthVendorTax, setyouthVendorTax] = useState(0);
  const [adultExtra, setadultExtra] = useState(0);
  const [childExtra, setchildExtra] = useState(0);
  const [infantExtra, setinfantExtra] = useState(0);
  const [seniorExtra, setseniorExtra] = useState(0);
  const [youthExtra, setyouthExtra] = useState(0);
  const [adultService, setadultService] = useState(0);
  const [childService, setchildService] = useState(0);
  const [infantService, setinfantService] = useState(0);
  const [seniorService, setseniorService] = useState(0);
  const [youthService, setyouthService] = useState(0);
  const [pgCharge, setPGCharge] = useState(0);
  const [mco, setMCO] = useState(0);
  const [mcoParted, setMCOParted] = useState(0);
  const [mcoRemark, setMCORemark] = useState("");
  const [fop, setFOP] = useState("");
  const [clientROE, setClientROE] = useState(1);
  const cabinOptions = cabinClass;
  const [openErrorMessageDialog, setOpenErrorMessageDialog] = useState(false);
  const [searchPNR, setSearchPNR] = useState(false);
  const [isImportBtnDisabled, setisImportBtnDisabled] = useState(false);
  const [openErrorMessageHTML, setOpenErrorMessageHTML] = useState("");
  const [passengerTemplate, setPassengerTemplate] = useState({});
  const [fareTemplate, setFareTemplate] = useState({});
  const [selectedItem, setSelectedItem] = useState();
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("");
  const [selectedFlightIndex, setSelectedFlightIndex] = useState(0);
  const [salesStaff, setsalesStaff] = useState("");
  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [caseID, setCaseID] = useState("");
  const [panCard, setpanCard] = useState("");
  const [fema, setfema] = useState("");
  const [selectedPassengerIndex, setSelectedPassengerIndex] = useState(0);
  const [segGroups, setSegGroups] = useState([]);
  const femaList = ["Corporate", "Retailer"];
  const [editSupplierName, setEditSupplierName] = useState("");
  const [editPCC, setEditPCC] = useState("");
  const [editClientId, setEditClientId] = useState("");
  const [userProfiles, setuserProfiles] = useState(null);
  const [expiryDate, setexpiryDate] = useState(null);
  const [cardNumber, setcardNumber] = useState(null);
  const [bookingRefNo, setBookingRefNo] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [vendorDetail, setVendorDetail] = useState(null);
  const [clientCurrency, setClientCurrency] = useState(null);
  const [vendorArray, setVendorArray] = useState([]);
  const [vendorOrgarray, setVendorOrgarray] = useState([]);
  const [pccOrgarray, setPccOrgarray] = useState([]);
  const [reissue, setReissue] = useState(false);
  const [flightTicketNumber, setFlightTicketNumber] = useState({});
  const [importPNRRemarks, setimportPNRRemarks] = useState("");
  const [value, setValue] = useState("Fresh");
  const [fopOptions, setFopOption] = useState([]);
  const [bookingType, setBookingType] = useState("fitBooking");
  const [productType, setProductType] = useState('Flight');
  const [selectedProductType, setSelectedProductType] = useState('Flight');
  const [passengerFirstName, setPassengerFirstName] = useState("");
  const [passengerLastName, setPassengerLastName] = useState("");
  const [totatVendorPayable, setTotatVendorPayable] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalClientPayable, setTotalClientPayable] = useState(0);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  // const [selectDate, setSelectDate] = useState({
  //   departureDate: "",
  //   today: "",
  //   childLimit: "",
  //   infantLimit: "",
  // });
  const [selectDate, setSelectDate] = useState({
    departureDate: "",
    today: "",
    minchildLimit: "",
    minadultLimit: "",
    minseniorLimit: "",
    mininfantLimit: "",
    minyouthLimit: "",
    maxchildLimit: "",
    maxadultLimit: "",
    maxseniorLimit: "",
    maxinfantLimit: "",
    maxyouthLimit: "",
  });
  const [baggageDetails, setBaggageDetails] = useState([]);
  const sourceList = ["Email", "Call", "WhatsApp"];
  const [source, setSource] = useState("");
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isDuplicateRes, setIsDuplicateRes] = useState("");
  const [duplicatePopup, setDuplicatePopup] = useState(false);


  const history = useHistory();
  useEffect(() => {
    setisImportBtnDisabled(false);
    getClients();
    loadCreditCards();
    getStaffUsers();
    // CalculateTotalFare();
    setPassengerTemplate({
      title: "",
      dob: new Date(),
      firstName: "",
      flightTicketStatus: "",
      genderType: "",
      id: 0,
      lastName: "",
      paxType: "",
      seatPref: "",
      ticketNumber: "",
      passportNumber: "",
      nationality: "",
      cabinBaggage: "",
      handBaggage: "",
      purchaseBaggage: "",
      extra: '',
      seatNo: '',
      coachNo: '',
    });
    setFareTemplate({
      id: 0,
      flightId: 0,
      paxType: "",
      baseFare: 0,
      taxes: [
        {
          id: 0,
          amt: 0,
          code: "Other",
        },
      ],
    });

    let departureDate = new Date();
    if (bookingDetails && bookingDetails?.flights && bookingDetails?.flights[0]?.segGroups[0]?.departureOn) {
      departureDate = bookingDetails?.flights[0]?.segGroups[0]?.departureOn.length > 0
        ? bookingDetails?.flights[0]?.segGroups[0].departureOn.split("T")[0]
        : new Date();
    } else if (bookingDetails && bookingDetails?.railPNRDetails && bookingDetails?.railPNRDetails[0]?.legGroups[0]?.departureOn) {
      departureDate = bookingDetails?.railPNRDetails[0]?.legGroups[0]?.departureOn.length > 0
        ? bookingDetails?.railPNRDetails[0]?.legGroups[0].departureOn.split("T")[0]
        : new Date();
    }

    let tempminChildLimit = new Date(departureDate);
    let tempminInfantLimit = new Date(departureDate);
    let tempminAdultLimit = new Date(departureDate);
    let tempminSeniorLimit = new Date(departureDate);
    let tempminYouthLimit = new Date(departureDate);
    let tempmaxChildLimit = new Date(departureDate);
    let tempmaxInfantLimit = new Date(departureDate);
    let tempmaxAdultLimit = new Date(departureDate);
    let tempmaxSeniorLimit = new Date(departureDate);
    let tempmaxYouthLimit = new Date(departureDate);

    tempminInfantLimit.setFullYear(tempminInfantLimit.getFullYear() - 3);
    tempminChildLimit.setFullYear(tempminChildLimit.getFullYear() - 13);
    tempmaxChildLimit.setFullYear(tempmaxChildLimit.getFullYear() - 4);
    tempminSeniorLimit.setFullYear(tempminSeniorLimit.getFullYear() - 120);
    tempmaxSeniorLimit.setFullYear(tempmaxSeniorLimit.getFullYear() - 60);
    tempminAdultLimit.setFullYear(tempminAdultLimit.getFullYear() - 59);
    tempmaxAdultLimit.setFullYear(tempmaxAdultLimit.getFullYear() - 27);
    tempminYouthLimit.setFullYear(tempminYouthLimit.getFullYear() - 26);
    tempmaxYouthLimit.setFullYear(tempmaxYouthLimit.getFullYear() - 14);

    setSelectDate({
      departureDate,
      today: new Date().toISOString().slice(0, 10),
      minchildLimit: tempminChildLimit.toISOString().slice(0, 10),
      minadultLimit: tempminAdultLimit.toISOString().slice(0, 10),
      minseniorLimit: tempminSeniorLimit.toISOString().slice(0, 10),
      mininfantLimit: tempminInfantLimit.toISOString().slice(0, 10), // Add this line
      minyouthLimit: tempminYouthLimit.toISOString().slice(0, 10),
      maxadultLimit: tempmaxAdultLimit.toISOString().slice(0, 10),
      maxchildLimit: tempmaxChildLimit.toISOString().slice(0, 10),
      maxinfantLimit: new Date(departureDate).toISOString().slice(0, 10),
      maxseniorLimit: tempmaxSeniorLimit.toISOString().slice(0, 10),
      maxyouthLimit: tempmaxYouthLimit.toISOString().slice(0, 10),
    });
  }, []);

  async function getStaffUsers() {
    let filter = "ClinetId==" + 0;
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "ids/User?filters=" + filter)
      .then((response) => {
        var newArray = response.data.filter(function (el) {
          return (
            el.emailConfirmed == true &&
            el.firstName != undefined &&
            el.lastName != undefined &&
            el.title != undefined
          );
        });
        setuserProfiles((userProfiles) => newArray);
      });
  }
  async function loadPNRDetails() {
    setSearchPNR(true);
    setShowLoader(true);
    setBaggageDetails([]);
    setSelectedProductType((selectedProductType) => productType);
    const headers = {
      "Content-Type": "application/json",
    };
    let obj = {
    };
    let url = '';

    if (selectedProductType === "Rail") {
      obj = {
        PNR: pnr,
        SupplierName:
          supplierName === "Travel Fusion" ? "Travelfusion" : supplierName,
        PCC: pcc,
        ClientId: (clientId).toString(),
        FirstName: passengerFirstName,
        LastName: passengerLastName,
      }
      // obj = {
      //   PNR: "K9YVWV",
      //   SupplierName: "ItaloRail",
      //   PCC: "DF",
      //   ClientId: "22299",
      //   FirstName: "Khurshid Cyrus",
      //   LastName: "Shroff"
      // }
      url = "Rail/Booking/GetBooking/v1/RailRetrievePNR"
    } else {
      obj = {
        PNR: pnr,
        Supplier:
          supplierName === "Travel Fusion" ? "Travelfusion" : supplierName,
        PCC: pcc,
        ClientId: clientId,
        IsDuplicateAllow: isDuplicate,
        IssuanceType: reissue ? "ReIssue" : "Fresh"
      }
      url = "Flights/Booking/GetBooking/v1/RetrievePNR"
    }

    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        url,

        JSON.stringify(obj),
        { headers }
      )
      .then((res) => {
        // console.log(res);
        setShowLoader(false);
        if (res.status === 204) {
          setAlert({
            open: true,
            message: "oops!! NO details for this PNR",
            severity: "warning",
          });
        } else {
          if (selectedProductType === "Rail") {
            setRailBookingFareDetails(res?.data === "" ? [] : res?.data);
          } else {
            setFlightBookingFareDetails(res?.data === "" ? [] : res?.data);
            const newTicketNumbers = {};
            res.data.flights[0].passengers.forEach((passenger, index) => {
              newTicketNumbers[`0-${index}`] = passenger.ticketNumber;
            });
            setFlightTicketNumber(newTicketNumbers);
            setSegGroups(res.data.flights[0].segGroups);
            if (res.data.flights[0].segGroups && res.data.flights[0].segGroups.length === 0) {
              setisImportBtnDisabled(true);
              setErrorMessage('Segment info missing');
            } else {
              setisImportBtnDisabled(false);
              setErrorMessage('');
            }



          }
          setbookingDetails((bookingDetails) => res.data);

          setEditSupplierName((editSupplierName) => supplierName);
          setEditPCC((editPCC) => pcc);

        }
        setSearchPNR((searchPNR) => false);
        setShowLoader((showLoader) => false);
      })
      .catch((err) => {
        if (err.response.data.toLowerCase().includes("order already exists")) {
          setbookingDetails(null);
          setIsDuplicateRes(err.response.data);
          setIsDuplicate(true);
          setDuplicatePopup(true);
        }
        else {
          let errorMessage =
            err.response &&
              err.response.data &&
              err.response.data.error_description
              ? err.response.data.error_description
              : err.response && err.response.data
                ? err.response.data
                : err.response
                  ? err.response
                  : err;
          console.log(err);
          setAlert({
            open: true,
            message: "oops!! " + errorMessage,
            severity: "error",
          });
          setIsDuplicate(false);
        }

        setSearchPNR((searchPNR) => false);
        setShowLoader((showLoader) => false);
      });
  }

  const handleDuplicateProceed = () => {
    setDuplicatePopup(false);
    loadPNRDetails();
  }

  const handleDuplicateCancel = () => {
    setDuplicatePopup(false);
    setIsDuplicate(false);
  }

  useEffect(() => {
    if (clientId) {
      let temp = clientProfiles?.find((v) => v?.id === clientId);
      if (temp) {
        setClientCurrency((clientCurrency) => temp.currencycode);
      }
    }
  }, [clientId, clientProfiles]);
  async function getClients() {
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile/GetClientList?sorts=created&filters=isactive==true"
      )
      .then((response) => {
        let tempArray = response.data.sort((a, b) =>
          a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
        );
        setclientProfiles((clientProfiles) => tempArray.sort());
      });
  }
  const loadCreditCards = async () => {

    var fop = await loadFop();
    setFopOption(fop);
  };

  async function loadPCCDetails(supplier) {

    let vendorarray = [];
    let selectedSupplier =
      supplier === "Travel Fusion" ? "Travelfusion" : supplier;
    if (selectedSupplier !== "") {
      await axios
        .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
        .then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            vendorarray.push({
              id: response.data[i].bookingSwitches.id,
              name: String(
                response.data[i].bookingSwitches.vendorName
              ).toLowerCase(),
            });
          }

          let temp = [];
          temp = response.data.map((item, i) => {
            return item.bookingSwitches;
          });
          setVendorOrgarray((vendorOrgarray) => temp);
          axios
            .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
            .then((response) => {
              let tmpArray = [];
              let pccarray = [];
              for (var i = 0; i < response.data.length; i++) {
                let t = i;
                pccarray.push(response.data[t].pccConfigs);
                if (
                  response.data[t].pccConfigs.supplierName ===
                  selectedSupplier &&
                  response.data[t].pccConfigs.isActive === true
                ) {
                  if (
                    vendorarray.some(
                      (item) => item.id === response.data[t].pccConfigs.vendorId
                    )
                  ) {
                    let temp = vendorArray.find(
                      (st) => st.id === response.data[t].pccConfigs.vendorId
                    );
                    let name;
                    if (temp && temp.name === "travel fusion") {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (Travel Fusion)";
                    } else if (
                      response.data[t].pccConfigs.supplierName ===
                      "Travelfusion"
                    ) {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (Travel Fusion)";
                    } else if (temp && temp.name) {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (" +
                        temp.name +
                        ")";
                    } else {
                      name =
                        response.data[t].pccConfigs.pccCode +
                        " (" +
                        response.data[t].pccConfigs.supplierName +
                        ")";
                    }
                    tmpArray.push({
                      value: response.data[t].pccConfigs.pccCode,
                      label: name,
                    });
                  } else {
                    tmpArray.push({
                      value: response.data[t].pccConfigs.pccCode,
                      label: response.data[t].pccConfigs.pccCode,
                    });
                  }
                }
              }

              if (tmpArray.length === 0) {
                setAlert({
                  open: true,
                  message: "There are no Pseudo Codes for this Supplier",
                  severity: "warning",
                });
              }
              setPccOrgarray((pccOrgarray) => pccarray);
              setVendorArray((vendorArray) => vendorarray);
              setPccList((pccList) => tmpArray);
            });
        });
    }
  }


  function setFlightBookingFareDetails(bkngDetails) {
    setadultBase((adultBase) => 0);
    setchildBase((childBase) => 0);
    setinfantBase((infantBase) => 0);
    setseniorBase((seniorBase) => 0);
    setyouthBase((youthBase) => 0);
    setadultTax((adultTax) => 0);
    setchildTax((childTax) => 0);
    setinfantTax((infantTax) => 0);
    setseniorTax((seniorTax) => 0);
    setyouthTax((youthTax) => 0);
    setadultBaggage((adultBaggage) => 0);
    setchildBaggage((childBaggage) => 0);
    setinfantBaggage((infantBaggage) => 0);
    setseniorBaggage((seniorBaggage) => 0);
    setyouthBaggage((youthBaggage) => 0);
    setadultBookingfee((adultBookingfee) => 0);
    setchildBookingfee((childBookingfee) => 0);
    setinfantBookingfee((infantBookingfee) => 0);
    setseniorBookingfee((seniorBookingfee) => 0);
    setyouthBookingfee((youthBookingfee) => 0);
    setadultMarkup((adultMarkup) => 0);
    setchildMarkup((childMarkup) => 0);
    setinfantMarkup((infantMarkup) => 0);
    setseniorMarkup((seniorMarkup) => 0);
    setyouthMarkup((youthMarkup) => 0);
    setadultIata((adultIata) => 0);
    setchildIata((childIata) => 0);
    setinfantIata((infantIata) => 0);
    setseniorIata((seniorIata) => 0);
    setyouthIata((youthIata) => 0);
    setadultPlb((adultPlb) => 0);
    setchildPlb((childPlb) => 0);
    setinfantPlb((infantPlb) => 0);
    setseniorPlb((seniorPlb) => 0);
    setyouthPlb((youthPlb) => 0);

    setadultTotal((adultTotal) => 0);
    setchildTotal((childTotal) => 0);
    setinfantTotal((infantTotal) => 0);
    setseniorTotal((seniorTotal) => 0);
    setyouthTotal((youthTotal) => 0);
    setClientBalanceSH((clientBalanceSH) => false);
    setClientAvailableBalance((clientAvailableBalance) => 0);
    setClientTotalWithClientROE((clientTotalWithClientROE) => 0);
    setVendorTotalWithClientROE((vendorTotalWithClientROE) => 0);
    setClientTotal((clientTotal) => 0);
    setVendorTotal((vendorTotal) => 0);
    setadultVendorIata((adultVendorIata) => 0);
    setchildVendorIata((childVendorIata) => 0);
    setinfantVendorIata((infantVendorIata) => 0);
    setseniorVendorIata((seniorVendorIata) => 0);
    setyouthVendorIata((youthVendorIata) => 0);

    setadultVendorPlb((adultVendorPlb) => 0);
    setchildVendorPlb((childVendorPlb) => 0);
    setinfantVendorPlb((infantVendorPlb) => 0);
    setseniorVendorPlb((seniorVendorPlb) => 0);
    setyouthVendorPlb((youthVendorPlb) => 0);

    setadultVendorIssuanceFee((adultVendorIssuanceFee) => 0);
    setchildVendorIssuanceFee((childVendorIssuanceFee) => 0);
    setinfantVendorIssuanceFee((infantVendorIssuanceFee) => 0);
    setseniorVendorIssuanceFee((seniorVendorIssuanceFee) => 0);
    setyouthVendorIssuanceFee((youthVendorIssuanceFee) => 0);

    setadultVendorTax((adultVendorTax) => 0);
    setchildVendorTax((childVendorPlb) => 0);
    setinfantVendorTax((infantVendorTax) => 0);
    setseniorVendorTax((seniorVendorTax) => 0);
    setyouthVendorTax((youthVendorTax) => 0);

    setadultVendorTotal((adultVendorTotal) => 0);
    setchildVendorTotal((childVendorTotal) => 0);
    setinfantVendorTotal((infantVendorTotal) => 0);
    setseniorVendorTotal((seniorVendorTotal) => 0);
    setyouthVendorTotal((youthVendorTotal) => 0);

    setPGCharge((pgCharge) => "");
    setMCO((mco) => "");
    setMCOParted((mcoParted) => "");
    setMCORemark((mcoRemark) => "");
    setFOP((fop) => "");
    setClientROE((clientROE) => 0);
    if (
      bkngDetails !== undefined &&
      bkngDetails?.flights != undefined &&
      bkngDetails?.flights?.length > 0
    ) {
      setiataType(
        bkngDetails.flights[0]?.commissionDetails?.isIATATypePercentage
          ? "pct"
          : "value"
      );
      setPlbType(
        bkngDetails.flights[0]?.commissionDetails?.isPLBTypePercentage
          ? "pct"
          : "value"
      );
      setVendorIataType(
        bkngDetails.flights[0]?.commissionDetails?.isVendorIATATypePercentage
          ? "pct"
          : "value"
      );
      setvendorPlbType(
        bkngDetails.flights[0]?.commissionDetails?.isVendorPLBTypePercentage
          ? "pct"
          : "value"
      );
      setClientBalanceSH((clientBalanceSH) => true);
      setClientAvailableBalance((clientAvailableBalance) =>
        bkngDetails?.clientBalance ? bkngDetails?.clientBalance : 0
      );
      for (
        var flightIndex = 0;
        flightIndex < bkngDetails?.flights?.length;
        flightIndex++
      ) {
        let flight = bkngDetails?.flights[flightIndex];
        setadultCount((adultCount) => flight.adultCount);
        setchildCount((childCount) => flight.childCount);
        setinfantCount((infantCount) => flight.infantCount);
        setClientROE((clientROE) => flight.clientROE);

        let totalPaxFare = 0;
        let getclientROE = flight.clientROE ? flight.clientROE : 0;
        let adtBaggage = 0,
          chdBaggage = 0,
          infBaggage = 0;
        if (flight.baggages) {
          let temp = [];
          temp = flight.baggages;
          temp.forEach((item) => {
            item.paxType === "ADT"
              ? (adtBaggage += parseFloat(item.amount))
              : item.paxType === "CHD"
                ? (chdBaggage += parseFloat(item.amount))
                : item.paxType === "INF" &&
                (infBaggage += parseFloat(item.amount));
          });
          (flight.adultCount && adtBaggage) > 0
            ? (totalPaxFare += adtBaggage)
            : (totalPaxFare += 0);
          (flight.childCount && chdBaggage) > 0
            ? (totalPaxFare += chdBaggage)
            : (totalPaxFare += 0);
          (flight.infantCount && infBaggage) > 0
            ? (totalPaxFare += infBaggage)
            : (totalPaxFare += 0);
          let abag = 0,
            cbag = 0,
            ibag = 0;
          abag = parseFloat(adtBaggage) / flight.adultCount;
          cbag = parseFloat(chdBaggage) / flight.childCount;
          ibag = parseFloat(infBaggage) / flight.infantCount;

          (flight.adultCount && adtBaggage) > 0 &&
            setadultBaggage((adultBaggage) => abag.toFixed(2));
          (flight.childCount && chdBaggage) > 0 &&
            setchildBaggage((childBaggage) => cbag.toFixed(2));
          (flight.infantCount && infBaggage) > 0 &&
            setinfantBaggage((infantBaggage) => ibag.toFixed(2));
        }

        if (flight.flightFares !== undefined && flight.flightFares.length > 0) {
          for (
            var fareIndex = 0;
            fareIndex < flight.flightFares.length;
            fareIndex++
          ) {
            let obj = flight.flightFares[fareIndex];
            if (obj.paxType === "ADT") {
              setadultBase((adultBase) => parseFloat(obj.baseFare).toFixed(2));
              setadultTax((adultTax) => getTax(obj).toFixed(2));

              let adtTotal = adtBaggage + getPaxTotal(obj, flight.adultCount);
              setadultTotal((adultTotal) => adtTotal);
              setadultVendorTotal((adultVendorTotal) => adtTotal);
              totalPaxFare += getPaxTotal(obj, flight.adultCount);
            } else if (obj.paxType === "CHD") {
              setchildBase((childBase) => parseFloat(obj.baseFare).toFixed(2));
              setchildTax((childTax) => getTax(obj).toFixed(2));
              let chdtotal = chdBaggage + getPaxTotal(obj, flight.childCount);
              setchildTotal((childTotal) => chdtotal);
              setchildVendorTotal((childVendorTotal) => chdtotal);
              totalPaxFare += getPaxTotal(obj, flight.childCount);
            } else if (obj.paxType === "INF") {
              setinfantBase((infantBase) =>
                parseFloat(obj.baseFare).toFixed(2)
              );
              setinfantTax((infantTax) => getTax(obj).toFixed(2));
              let inftotal = infBaggage + getPaxTotal(obj, flight.infantCount);
              setinfantTotal((infantTotal) => inftotal);
              setinfantVendorTotal((infantVendorTotal) => inftotal);
              totalPaxFare += getPaxTotal(obj, flight.infantCount);
            }
          }
          CalculateTotalFare(totalPaxFare, getclientROE);
        }
      }
    }

  }

  function setRailBookingFareDetails(bkngDetails) {
    let array = structuredClone(railImportFareArray);
    if (
      bkngDetails !== undefined &&
      bkngDetails?.railPNRDetails !== undefined &&
      bkngDetails?.railPNRDetails?.length > 0
    ) {
      setiataType(
        bkngDetails?.railPNRDetails[0]?.commissionDetails?.isCommissionTypePercentage
          ? "pct"
          : "value"
      );
      setPlbType(
        bkngDetails?.railPNRDetails[0]?.commissionDetails?.isIncentiveTypePercentage
          ? "pct"
          : "value"
      );
      setVendorIataType(
        bkngDetails?.railPNRDetails[0]?.commissionDetails?.isVendorCommissionTypePercentage
          ? "pct"
          : "value"
      );
      setvendorPlbType(
        bkngDetails?.railPNRDetails[0]?.commissionDetails?.isVendorIncentiveTypePercentage
          ? "pct"
          : "value"
      );

      let totalPaxFare = 0;
      let getclientROE = bkngDetails?.railPNRDetails[0].clientROE ? bkngDetails?.railPNRDetails[0].clientROE : 1;
      setClientROE((clientROE) => getclientROE);
      let details = bkngDetails;
      let rails = bkngDetails?.railPNRDetails.map((rail, index) => {
        let obj = structuredClone(railImportFareTemplate)
        obj.adultCount = (rail.adultCount);
        obj.childCount = (rail.childCount);
        obj.infantCount = (rail.infantCount);
        obj.seniorCount = (rail.seniorCount);
        obj.youthCount = (rail.youthCount);
        obj.ClientROE = (rail.clientROE);


        if (rail.railFares !== undefined && rail.railFares.length > 0) {
          for (
            var railIndex = 0;
            railIndex < rail.railFares.length;
            railIndex++
          ) {
            let obj = rail.railFares[railIndex];
            if (obj.paxType === "ADT") {
              obj.adultBase = (parseFloat(obj.baseFare).toFixed(2));
              let adtTotal = getPaxTotal(obj, rail.adultCount);
              obj.adultTotal = (adtTotal);
              obj.adultVendorTotal = (adtTotal);
              totalPaxFare += Number(getPaxTotal(obj, rail.adultCount));
            } else if (obj.paxType === "CHD") {
              obj.childBase = (parseFloat(obj.baseFare).toFixed(2));
              let chdtotal = getPaxTotal(obj, rail.childCount);
              obj.childTotal = (chdtotal);
              obj.childVendorTotal = (chdtotal);
              totalPaxFare += Number(getPaxTotal(obj, rail.childCount));
            } else if (obj.paxType === "INF") {
              obj.infantBase = (
                parseFloat(obj.baseFare).toFixed(2)
              );
              let inftotal = getPaxTotal(obj, rail.infantCount);
              obj.infantTotal = (inftotal);
              obj.infantVendorTotal = ((infantVendorTotal) => inftotal);
              totalPaxFare += Number(getPaxTotal(obj, rail.infantCount));
            }
            else if (obj.paxType === "SNR") {
              obj.seniorBase = (
                parseFloat(obj.baseFare).toFixed(2)
              );
              let inftotal = getPaxTotal(obj, rail.seniorCount);
              obj.seniorTotal = (inftotal);
              obj.seniorVendorTotal = (inftotal);
              totalPaxFare += Number(getPaxTotal(obj, rail.seniorCount));
            }
            else if (obj.paxType === "YNG") {

              obj.youthBase = (
                parseFloat(obj.baseFare).toFixed(2)
              );
              let inftotal = getPaxTotal(obj, rail.youthCount);
              obj.youthTotal = (inftotal);
              obj.youthVendorTotal = (inftotal);
              totalPaxFare += Number(getPaxTotal(obj, rail.youthCount));
            }
          }
        }
        array[index] = obj;
        return rail;
      })
      CalculateRailTotalFare(array, getclientROE);
      details.rails = rails;
      setbookingDetails((bookingDetails) => details);
      setrailImportFareArray((railImportFareArray) => [...array]);
    }

  }
  function CalculateRailTotalFare(array, getclientROE) {
    let getClientROEVal = 0;
    if (getclientROE !== undefined) {
      getClientROEVal = getclientROE;
    } else {
      getClientROEVal = clientROE;
    }
    let clientPayable = 0;
    let vendorpayable = 0;
    let temp = array.map((item) => {
      item.clientTotalFare =
        (item.adultTotal) +
        (item.childTotal) +
        (item.seniorTotal) +
        (item.youthTotal) +
        (item.infantTotal);

      item.vendorTotalFare =
        item.adultVendorTotal +
        item.childVendorTotal +
        item.seniorVendorTotal +
        item.youthVendorTotal +
        item.infantVendorTotal;

      clientPayable += item.clientTotalFare;
      vendorpayable += item.vendorTotalFare;
      return item;
    })
    setClientTotal((clientTotal) => parseFloat(clientPayable).toFixed(2));
    setVendorTotal((vendorTotal) => parseFloat(vendorpayable).toFixed(2));

    setClientTotalWithClientROE((clientTotalWithClientROE) =>
      (clientPayable * getClientROEVal).toFixed(2)
    );

    setVendorTotalWithClientROE((vendorTotalFare) =>
      (vendorpayable * getClientROEVal).toFixed(2)
    );
    setrailImportFareArray((railImportFareArray) => [...temp]);
  }
  function CalculateTotalFare(totalPaxFare, getclientROE) {
    // let getTotalPaxFareVal = 0,
    let getClientROEVal = 0;
    // if (totalPaxFare !== undefined) {
    //   getTotalPaxFareVal = totalPaxFare.toFixed(2);
    // } else {
    //   getTotalPaxFareVal = (adultTotal + childTotal + infantTotal).toFixed(2);
    // }
    if (getclientROE !== undefined) {
      getClientROEVal = getclientROE;
    } else {
      getClientROEVal = clientROE;
    }
    const getTotalVendorIata = (
      adultVendorIata * adultCount +
      childVendorIata * childCount +
      seniorVendorIata * seniorCount +
      youthVendorIata * youthCount +
      infantVendorIata * infantCount
    ).toFixed(2);
    const getTotalVendorPLB = (
      adultVendorPlb * adultCount +
      childVendorPlb * childCount +
      seniorVendorPlb * seniorCount +
      youthVendorPlb * youthCount +
      infantVendorPlb * infantCount
    ).toFixed(2);
    let clientTotalFare =
      adultTotal * adultCount +
      (childTotal * childCount) +
      (seniorTotal * seniorCount) +
      (youthTotal * youthCount) +
      (infantTotal * infantCount);

    let flightVendorTotalFare =
      adultVendorTotal * adultCount +
      (childVendorTotal * childCount) +
      (infantVendorTotal * infantCount);

    let railVendorTotalFare =
      adultVendorTotal - (adultVendorIata + adultVendorPlb) * adultCount +
      (childVendorTotal - (childVendorIata + childVendorPlb) * childCount) +
      (seniorVendorTotal - (seniorVendorIata + seniorVendorPlb) * seniorCount) +
      (youthVendorTotal - (youthVendorIata + youthVendorPlb) * youthCount) +
      (infantVendorTotal - (infantIata + infantPlb) * infantCount);

    // setClientTotal((clientTotal) => clientTotalFare.toFixed(2));
    // setVendorTotal((vendorTotal) => selectedProductType === "Flight" ? flightVendorTotalFare.toFixed(2) : railVendorTotalFare.toFixed(2));

    // setClientTotalWithClientROE((clientTotalWithClientROE) =>
    //   (clientTotalFare * getClientROEVal).toFixed(2)
    // );

    // setVendorTotalWithClientROE((vendorTotalFare) =>
    //   (vendorTotalFare * getClientROEVal).toFixed(2)
    // );
  }

  // useEffect(() => {
  //   console.log('adultVendorTotal', adultVendorTotal)
  // }, [adultVendorTotal])


  useEffect(() => {
    let getClientROEVal = 0;
    // if (totalPaxFare !== undefined) {
    //   getTotalPaxFareVal = totalPaxFare.toFixed(2);
    // } else {
    //   getTotalPaxFareVal = (adultTotal + childTotal + infantTotal).toFixed(2);
    // }
    // if (getclientROE !== undefined) {
    //   getClientROEVal = getclientROE;
    // } else {
    getClientROEVal = clientROE;
    // }
    let clientTotalFare =
      adultTotal +
      (childTotal) +
      (seniorTotal) +
      (youthTotal) +
      (infantTotal);
    let flightVendorTotalFare =
      adultVendorTotal +
      (childVendorTotal) +
      (infantVendorTotal);

    let railVendorTotalFare =
      adultVendorTotal - (adultVendorIata + adultVendorPlb) * adultCount +
      (childVendorTotal - (childVendorIata + childVendorPlb) * childCount) +
      (seniorVendorTotal - (seniorVendorIata + seniorVendorPlb) * seniorCount) +
      (youthVendorTotal - (youthVendorIata + youthVendorPlb) * youthCount) +
      (infantVendorTotal - (infantIata + infantPlb) * infantCount);

    setClientTotal((clientTotal) => clientTotalFare.toFixed(2));
    setVendorTotal((vendorTotal) => selectedProductType === "Flight" ? flightVendorTotalFare.toFixed(2) : railVendorTotalFare.toFixed(2));

    setClientTotalWithClientROE((clientTotalWithClientROE) =>
      (clientTotalFare * getClientROEVal).toFixed(2)
    );

    setVendorTotalWithClientROE((vendorTotalFare) =>
      (vendorTotalFare * getClientROEVal).toFixed(2)
    );
  }, [adultTotal, childTotal, infantTotal, seniorTotal, youthTotal, infantTotal, adultVendorTotal, childVendorTotal, infantVendorTotal])


  async function OnChangeCalculateRailTotalFare(event, paxtype, field, index) {

    setiataType(
      bookingDetails?.railPNRDetails[0]?.commissionDetails?.isCommissionTypePercentage
        ? "pct"
        : "value"
    );
    setPlbType(
      bookingDetails?.railPNRDetails[0]?.commissionDetails?.isIncentiveTypePercentage
        ? "pct"
        : "value"
    );
    setVendorIataType(
      bookingDetails?.railPNRDetails[0]?.commissionDetails?.isVendorCommissionTypePercentage
        ? "pct"
        : "value"
    );
    setvendorPlbType(
      bookingDetails?.railPNRDetails[0]?.commissionDetails?.isVendorIncentiveTypePercentage
        ? "pct"
        : "value"
    );
    let array = structuredClone(railImportFareArray);
    let obj = structuredClone(array[index]);
    if (paxtype === "ADT") {
      let iataValue = obj.adultIata;
      let plbValue = obj.adultPlb;
      let vendorplbValue = obj.adultVendorPlb;
      let vendoriataValue = obj.adultVendorIata;

      if (field === "base") {
        obj.adultBase = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        obj.adultTax = (event.target.value !== "" ? Number(event.target.value) : 0);
      } else if (field === "baggage") {
        obj.adultBaggage = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        obj.adultBookingfee = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        obj.adultMarkup = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        iataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          iataValue = (obj.adultBase * iataValue) / 100;
        }
        obj.adultIata = (iataValue);
      } else if (field === "plb") {
        plbValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          plbValue = ((obj.adultBase - iataValue) * plbValue) / 100;
        }
        obj.adultPlb = (plbValue);
      } else if (field === "vendorIata") {
        vendoriataValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendoriataValue = (obj.adultBase * vendoriataValue) / 100;
        }
        obj.adultVendorIata = (vendoriataValue);
      } else if (field === "vendorPlb") {
        vendorplbValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          vendorplbValue = ((obj.adultBase - vendoriataValue) * plbValue) / 100;
        }
        obj.adultVendorPlb = (vendorplbValue);
      } else if (field === "vendorIssuanceFee") {
        obj.adultVendorIssuanceFee = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        obj.adultVendorTax = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "service") {
        obj.adultService = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "extra") {
        obj.adultExtra = (
          (event.target.value !== "" ? Number(event.target.value) : 0)
        );
      }
      let totalClientAdultFare =
        ((obj.adultBase ? parseFloat(obj.adultBase) : 0) +
          (obj.adultTax ? parseFloat(obj.adultTax) : 0) +
          (obj.adultService ? parseFloat(obj.adultService) : 0) +
          (obj.adultExtra ? parseFloat(obj.adultExtra) : 0) +
          (obj.adultBaggage ? parseFloat(obj.adultBaggage) : 0) +
          (obj.adultVendorIssuanceFee ? parseFloat(obj.adultVendorIssuanceFee) : 0) +
          (obj.adultVendorTax ? parseFloat(obj.adultVendorTax) : 0) +
          (obj.adultMarkup ? parseFloat(obj.adultMarkup) : 0)
        );
      let totalVendorAdultFare =
        ((obj.adultBase ? parseFloat(obj.adultBase) : 0) +
          (obj.adultTax ? parseFloat(obj.adultTax) : 0) +
          (obj.adultService ? parseFloat(obj.adultService) : 0) +
          (obj.adultExtra ? parseFloat(obj.adultExtra) : 0) +
          (obj.adultBaggage ? parseFloat(obj.adultBaggage) : 0) +
          (obj.adultVendorIssuanceFee ? parseFloat(obj.adultVendorIssuanceFee) : 0) +
          (obj.adultVendorTax ? parseFloat(obj.adultVendorTax) : 0)
        );

      obj.adultTotal = (totalClientAdultFare
        - (parseFloat(obj.adultIata)
          + parseFloat(obj.adultPlb)))
        * obj.adultCount;
      obj.adultVendorTotal = ((totalVendorAdultFare - ((obj.adultVendorIata ? parseFloat(obj.adultVendorIata) : 0) + (obj.adultVendorPlb ? parseFloat(obj.adultVendorPlb) : 0))) * obj.adultCount);
    }
    else if (paxtype === "CHD") {
      let iataValue = obj.childIata;
      let plbValue = obj.childPlb;
      let vendorplbValue = obj.childVendorPlb;
      let vendoriataValue = obj.childVendorIata;

      if (field === "base") {
        obj.childBase = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        obj.childTax = (event.target.value !== "" ? Number(event.target.value) : 0);
      } else if (field === "baggage") {
        obj.childBaggage = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        obj.childBookingfee = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        obj.childMarkup = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        iataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          iataValue = (obj.childBase * iataValue) / 100;
        }
        obj.childIata = (iataValue);
      } else if (field === "plb") {
        plbValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          plbValue = ((obj.childBase - iataValue) * plbValue) / 100;
        }
        obj.childPlb(plbValue);
      } else if (field === "vendorIata") {
        vendoriataValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendoriataValue = (obj.childBase * vendoriataValue) / 100;
        }
        obj.childVendorIata = (vendoriataValue);
      } else if (field === "vendorPlb") {
        vendorplbValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendorplbValue =
            ((obj.childBase - vendoriataValue) * vendorplbValue) / 100;
        }
        obj.childVendorPlb = (vendorplbValue);
      } else if (field === "vendorIssuanceFee") {
        obj.childVendorIssuanceFee = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        obj.childVendorTax = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "service") {
        obj.childService = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "extra") {
        obj.childExtra(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      let totalChildFare =
        ((obj.childBase ? parseFloat(obj.childBase) : 0) +
          (obj.childTax ? parseFloat(obj.childTax) : 0) +
          (obj.childService ? parseFloat(obj.childService) : 0) +
          (obj.childExtra ? parseFloat(obj.childExtra) : 0) +
          (obj.childBaggage ? parseFloat(obj.childBaggage) : 0) +
          // (obj.childMarkup ? parseFloat(obj.childMarkup) : 0) +
          (obj.childVendorIssuanceFee ? parseFloat(obj.childVendorIssuanceFee) : 0) +
          (obj.childVendorTax ? parseFloat(obj.childVendorTax) : 0)
        );

      obj.childTotal = ((((totalChildFare + obj.childMarkup)) -
        ((obj.childIata ? parseFloat(obj.childIata) : 0)
          + (obj.childPlb ? parseFloat(obj.childPlb) : 0))) * obj.childCount);
      obj.childVendorTotal = (
        (totalChildFare - obj.childVendorIata ? parseFloat(obj.childVendorIata) : 0 + obj.childVendorPlb ? parseFloat(obj.childVendorPlb) : 0) * obj.childCount
      );
    }
    else if (paxtype === "INF") {
      if (field === "base") {
        obj.infantBase = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        obj.infantTax = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "baggage") {
        obj.infantBaggage = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        obj.infantBookingfee = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        obj.infantMarkup = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        obj.infantIata = (0);
      } else if (field === "plb") {
        obj.infantPlb = (0);
      } else if (field === "vendorIata") {
        obj.infantVendorIata = (0);
        // vendoriataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        // if (iataType === "pct") {
        //   vendoriataValue = ( infantBase* vendoriataValue) / 100;
        // }
        // obj.infantVendorIata(
        //   vendoriataValue
        // );
      } else if (field === "vendorPlb") {
        obj.infantVendorPlb = (0);
      } else if (field === "vendorIssuanceFee") {
        obj.infantVendorIssuanceFee = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        obj.infantVendorTax = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "service") {
        obj.infantService = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "extra") {
        obj.infantExtra = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      let totalInfantFare =
        ((obj.infantBase ? parseFloat(obj.infantBase) : 0) +
          (obj.infantTax ? parseFloat(obj.infantTax) : 0) +
          (obj.infantService ? parseFloat(obj.infantService) : 0) +
          (obj.infantExtra ? parseFloat(obj.infantExtra) : 0) +
          (obj.infantBaggage ? parseFloat(obj.infantBaggage) : 0) +
          // (obj.infantMarkup ? parseFloat(obj.infantMarkup) : 0) +
          (obj.infantVendorIssuanceFee ? parseFloat(obj.infantVendorIssuanceFee) : 0) +
          (obj.infantVendorTax ? parseFloat(obj.infantVendorTax) : 0)
        );

      obj.infantTotal = (((totalInfantFare + obj.infantMarkup) -
        ((obj.infantIata ? parseFloat(obj.infantIata) : 0)
          + (obj.infantPlb ? parseFloat(obj.infantPlb) : 0))) * obj.infantCount);
      obj.infantVendorTotal = (
        (totalInfantFare - obj.infantVendorIata ? parseFloat(obj.infantVendorIata) : 0 + obj.infantVendorPlb ? parseFloat(obj.infantVendorPlb) : 0) * obj.infantCount
      );
    }
    else if (paxtype === 'YNG') {
      let iataValue = obj.youthIata;
      let plbValue = obj.youthPlb;
      let vendorplbValue = obj.youthVendorPlb;
      let vendoriataValue = obj.youthVendorIata;

      if (field === "base") {
        obj.youthBase = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        obj.youthTax = (event.target.value !== "" ? Number(event.target.value) : 0);
      } else if (field === "baggage") {
        obj.youthBaggage = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        obj.youthBookingfee = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        obj.youthMarkup = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        iataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          iataValue = (obj.youthBase * iataValue) / 100;
        }
        obj.youthIata = (iataValue);
      } else if (field === "plb") {
        plbValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          plbValue = ((obj.youthBase - iataValue) * plbValue) / 100;
        }
        obj.youthPlb = (plbValue);
      } else if (field === "vendorIata") {
        vendoriataValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendoriataValue = (obj.youthBase * vendoriataValue) / 100;
        }
        obj.youthVendorIata = (vendoriataValue);
      } else if (field === "vendorPlb") {
        vendorplbValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          vendorplbValue = ((obj.youthBase - vendoriataValue) * plbValue) / 100;
        }
        obj.youthVendorPlb = (vendorplbValue);
      } else if (field === "vendorIssuanceFee") {
        obj.youthVendorIssuanceFee = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        obj.youthVendorTax = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "service") {
        obj.youthService = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "extra") {
        obj.youthExtra =
          (event.target.value !== "" ? Number(event.target.value) : 0)

      }
      let totalVendorYouthFare =
        ((obj.youthBase ? parseFloat(obj.youthBase) : 0) +
          (obj.youthTax ? parseFloat(obj.youthTax) : 0) +
          (obj.youthService ? parseFloat(obj.youthService) : 0) +
          (obj.youthExtra ? parseFloat(obj.youthExtra) : 0) +
          (obj.youthBaggage ? parseFloat(obj.youthBaggage) : 0) +
          (obj.youthVendorIssuanceFee ? parseFloat(obj.youthVendorIssuanceFee) : 0) +
          (obj.youthVendorTax ? parseFloat(obj.youthVendorTax) : 0)
        );
      let totalClientYouthFare =
        ((obj.youthBase ? parseFloat(obj.youthBase) : 0) +
          (obj.youthTax ? parseFloat(obj.youthTax) : 0) +
          (obj.youthService ? parseFloat(obj.youthService) : 0) +
          (obj.youthExtra ? parseFloat(obj.youthExtra) : 0) +
          (obj.youthBaggage ? parseFloat(obj.youthBaggage) : 0) +
          (obj.youthMarkup ? parseFloat(obj.youthMarkup) : 0) +
          (obj.youthVendorIssuanceFee ? parseFloat(obj.youthVendorIssuanceFee) : 0) +
          (obj.youthVendorTax ? parseFloat(obj.youthVendorTax) : 0)
        );

      obj.youthTotal = ((totalClientYouthFare -
        ((obj.youthIata ? parseFloat(obj.youthIata) : 0)
          + (obj.youthPlb ? parseFloat(obj.youthPlb) : 0))) * obj.youthCount);
      obj.youthVendorTotal = (
        (totalVendorYouthFare - (parseFloat(obj.youthVendorIata) + parseFloat(obj.youthVendorPlb))) * obj.youthCount
      );
    }
    else if (paxtype === 'SNR') {
      let iataValue = obj.seniorIata;
      let plbValue = obj.seniorPlb;
      let vendorplbValue = obj.seniorVendorPlb;
      let vendoriataValue = obj.seniorVendorIata;

      if (field === "base") {
        obj.seniorBase = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        obj.seniorTax = (event.target.value !== "" ? Number(event.target.value) : 0);
      } else if (field === "baggage") {
        obj.seniorBaggage = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        obj.seniorBookingfee = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        obj.seniorMarkup = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        iataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          iataValue = (obj.seniorBase * iataValue) / 100;
        }
        obj.seniorIata = (iataValue);
      } else if (field === "plb") {
        plbValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          plbValue = ((obj.seniorBase - iataValue) * plbValue) / 100;
        }
        obj.seniorPlb = (plbValue);
      } else if (field === "vendorIata") {
        vendoriataValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendoriataValue = (obj.seniorBase * vendoriataValue) / 100;
        }
        obj.seniorVendorIata = (vendoriataValue);
      } else if (field === "vendorPlb") {
        vendorplbValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          vendorplbValue = ((obj.seniorBase - vendoriataValue) * plbValue) / 100;
        }
        obj.seniorVendorPlb = (vendorplbValue);
      } else if (field === "vendorIssuanceFee") {
        obj.seniorVendorIssuanceFee = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        obj.seniorVendorTax = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "service") {
        obj.seniorService = (
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "extra") {
        obj.seniorExtra = (
          (event.target.value !== "" ? Number(event.target.value) : 0)
        );
      }
      let totalSeniorFare =
        ((obj.seniorBase ? parseFloat(obj.seniorBase) : 0) +
          (obj.seniorTax ? parseFloat(obj.seniorTax) : 0) +
          (obj.seniorService ? parseFloat(obj.seniorService) : 0) +
          (obj.seniorExtra ? parseFloat(obj.seniorExtra) : 0) +
          (obj.seniorBaggage ? parseFloat(obj.seniorBaggage) : 0) +
          // (obj.seniorMarkup ? parseFloat(obj.seniorMarkup) : 0) +
          (obj.seniorVendorIssuanceFee ? parseFloat(obj.seniorVendorIssuanceFee) : 0) +
          (obj.seniorVendorTax ? parseFloat(obj.seniorVendorTax) : 0)
        );
      obj.seniorTotal = (((totalSeniorFare + obj.seniorMarkup) -
        ((obj.seniorIata ? parseFloat(obj.seniorIata) : 0)
          + (obj.seniorPlb ? parseFloat(obj.seniorPlb) : 0))) * obj.seniorCount);
      obj.seniorVendorTotal = (
        (totalSeniorFare - obj.seniorVendorIata ? parseFloat(obj.seniorVendorIata) : 0 + obj.seniorVendorPlb ? parseFloat(obj.seniorVendorPlb) : 0) * obj.seniorCount
      );
    }
    array[index] = obj;
    setrailImportFareArray((prev) => array);
    CalculateRailTotalFare(array);
  }
  async function OnChangeCalculateTotalFare(event, paxtype, field) {
    if (selectedProductType === 'Flight') {
      setiataType(
        bookingDetails.flights[0]?.commissionDetails?.isIATATypePercentage
          ? "pct"
          : "value"
      );
      setPlbType(
        bookingDetails.flights[0]?.commissionDetails?.isPLBTypePercentage
          ? "pct"
          : "value"
      );
      setVendorIataType(
        bookingDetails.flights[0]?.commissionDetails?.isVendorIATATypePercentage
          ? "pct"
          : "value"
      );
      setvendorPlbType(
        bookingDetails.flights[0]?.commissionDetails?.isVendorPLBTypePercentage
          ? "pct"
          : "value"
      );
    } else if (selectedProductType === 'Rail') {

      setiataType(
        bookingDetails?.railPNRDetails[0]?.commissionDetails?.isCommissionTypePercentage
          ? "pct"
          : "value"
      );
      setPlbType(
        bookingDetails?.railPNRDetails[0]?.commissionDetails?.isIncentiveTypePercentage
          ? "pct"
          : "value"
      );
      setVendorIataType(
        bookingDetails?.railPNRDetails[0]?.commissionDetails?.isVendorCommissionTypePercentage
          ? "pct"
          : "value"
      );
      setvendorPlbType(
        bookingDetails?.railPNRDetails[0]?.commissionDetails?.isVendorIncentiveTypePercentage
          ? "pct"
          : "value"
      );
    }
    if (paxtype === "ADT") {
      let iataValue = adultIata;
      let plbValue = adultPlb;
      let vendorplbValue = adultVendorPlb;
      let vendoriataValue = adultVendorIata;

      if (field === "base") {
        setadultBase(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        setadultTax(event.target.value !== "" ? Number(event.target.value) : 0);
      } else if (field === "baggage") {
        setadultBaggage(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        setadultBookingfee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        setadultMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        iataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          iataValue = (adultBase * iataValue) / 100;
        }
        setadultIata(iataValue);
      } else if (field === "plb") {
        plbValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          plbValue = ((adultBase - iataValue) * plbValue) / 100;
        }
        setadultPlb(plbValue);
      } else if (field === "vendorIata") {
        vendoriataValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendoriataValue = (adultBase * vendoriataValue) / 100;
        }
        setadultVendorIata(vendoriataValue);
      } else if (field === "vendorPlb") {
        vendorplbValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (plbType === "pct") {
          vendorplbValue = ((adultBase - vendoriataValue) * plbValue) / 100;
        }
        setadultVendorPlb(vendorplbValue);
      } else if (field === "vendorIssuanceFee") {
        setadultVendorIssuanceFee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        setadultVendorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "service") {
        setadultService(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "extra") {
        setadultExtra((adultExtra) =>
          (event.target.value !== "" ? Number(event.target.value) : 0)
        );
      }
    }
    else if (paxtype === "CHD") {
      let iataValue = childIata;
      let plbValue = childPlb;
      let vendorplbValue = childVendorPlb;
      let vendoriataValue = childVendorIata;

      if (field === "base") {
        setchildBase(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        setchildTax(event.target.value !== "" ? Number(event.target.value) : 0);
      } else if (field === "baggage") {
        setchildBaggage(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        setchildBookingfee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        setchildMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        iataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          iataValue = (childBase * iataValue) / 100;
        }
        setchildIata(iataValue);
      } else if (field === "plb") {
        plbValue = event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          plbValue = ((childBase - iataValue) * plbValue) / 100;
        }
        setchildPlb(plbValue);
      } else if (field === "vendorIata") {
        vendoriataValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendoriataValue = (adultBase * vendoriataValue) / 100;
        }
        setchildVendorIata(vendoriataValue);
      } else if (field === "vendorPlb") {
        vendorplbValue =
          event.target.value !== "" ? Number(event.target.value) : 0;
        if (iataType === "pct") {
          vendorplbValue =
            ((childBase - vendoriataValue) * vendorplbValue) / 100;
        }
        setchildVendorPlb(vendorplbValue);
      } else if (field === "vendorIssuanceFee") {
        setchildVendorIssuanceFee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        setchildVendorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "service") {
        setchildService(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "extra") {
        setchildExtra(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
    }
    else if (paxtype === "INF") {
      if (field === "base") {
        setinfantBase(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        setinfantTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "baggage") {
        setinfantBaggage(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        setinfantBookingfee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        setinfantMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        setinfantIata(0);
      } else if (field === "plb") {
        setinfantPlb(0);
      } else if (field === "vendorIata") {
        setinfantVendorIata(0);
        // vendoriataValue = event.target.value !== "" ? Number(event.target.value) : 0;
        // if (iataType === "pct") {
        //   vendoriataValue = ( infantBase* vendoriataValue) / 100;
        // }
        // setinfantVendorIata(
        //   vendoriataValue
        // );
      } else if (field === "vendorPlb") {
        setinfantVendorPlb(0);
      } else if (field === "vendorIssuanceFee") {
        setinfantVendorIssuanceFee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        setinfantVendorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "service") {
        setinfantService(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "extra") {
        setinfantExtra(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
    }
    else if (paxtype === 'YNG') {
      if (field === "base") {
        setyouthBase(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        setyouthTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "baggage") {
        setyouthBaggage(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        setyouthBookingfee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        setyouthMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        setyouthIata(0);
      } else if (field === "plb") {
        setyouthPlb(0);
      } else if (field === "vendorIata") {
        setyouthVendorIata(0);
      } else if (field === "vendorPlb") {
        setyouthVendorPlb(0);
      } else if (field === "vendorIssuanceFee") {
        setyouthVendorIssuanceFee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "vendorTax") {
        setyouthVendorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        );

      }
      else if (field === "service") {
        setyouthService(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "extra") {
        setyouthExtra(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }

    }
    else if (paxtype === 'SNR') {
      if (field === "base") {
        setseniorBase(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "tax") {
        setseniorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        )
      }
      else if (field === "baggage") {
        setseniorBaggage(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "bookingfee") {
        setseniorBookingfee(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "markup") {
        setseniorMarkup(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      } else if (field === "iata") {
        setseniorIata(0);
      } else if (field === "plb") {
        setseniorPlb(0);
      } else if (field === "vendorIata") {
        setseniorVendorIata(0);
      } else if (field === "vendorPlb") {
        setseniorVendorPlb(0);
      } else if (field === "vendorIssuanceFee") {
        setseniorVendorIssuanceFee(
          event.target.value !== "" ? Number(event.target.value) : 0
        )
      } else if (field === "vendorTax") {
        setseniorVendorTax(
          event.target.value !== "" ? Number(event.target.value) : 0
        )
      }
      else if (field === "service") {
        setseniorService(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
      else if (field === "extra") {
        setseniorExtra(
          event.target.value !== "" ? Number(event.target.value) : 0
        );
      }
    }
  }
  useEffect(() => {
    CalculateTotalFare();
  }, [adultCount, childCount, infantCount, youthCount, seniorCount]);

  useEffect(() => {
    // console.log("railImportFareArray", railImportFareArray);
  }, [railImportFareArray]);
  useEffect(() => {
    // console.log("vendorDetail", vendorDetail);
  }, [vendorDetail]);
  useEffect(() => {
    // console.log("supplier Currency", vendorDetail?.currencyCode);
  }, [vendorDetail]);
  useEffect(() => {
    // console.log("client Currency", clientCurrency);
  }, [clientCurrency]);

  useEffect(() => {
    // console.log("pcc", pcc);
    let vendor;
    if (pcc) {
      pccOrgarray?.forEach((element) => {
        if (element.pccCode === pcc) {
          vendorOrgarray.forEach((item) => {
            if (item.id === element.vendorId) {
              vendor = item;
              setVendorDetail(vendor);
            }
          });
        }
      });
    }
  }, [pcc, pccOrgarray, vendorOrgarray]);

  useEffect(() => {
    let totalAudFare =
      (adultBase ? parseFloat(adultBase) : 0) +
      (adultTax ? parseFloat(adultTax) : 0) +
      (adultService ? parseFloat(adultService) : 0) +
      (adultExtra ? parseFloat(adultExtra) : 0) +
      (adultBaggage ? parseFloat(adultBaggage) : 0) +
      (adultMarkup ? parseFloat(adultMarkup) : 0) +
      (adultVendorIssuanceFee ? parseFloat(adultVendorIssuanceFee) : 0) +
      (adultVendorTax ? parseFloat(adultVendorTax) : 0) -
      (adultIata + adultPlb);

    setadultTotal(totalAudFare * adultCount);
    let vendorTotalfare = ((adultBase ? parseFloat(adultBase) : 0) +
      (adultTax ? parseFloat(adultTax) : 0) +
      (adultService ? parseFloat(adultService) : 0) +
      (adultExtra ? parseFloat(adultExtra) : 0) +
      (adultBaggage ? parseFloat(adultBaggage) : 0) +
      (adultVendorIssuanceFee ? parseFloat(adultVendorIssuanceFee) : 0) +
      (adultVendorTax ? parseFloat(adultVendorTax) : 0) - (adultVendorIata + adultVendorPlb)) *
      adultCount;
    setadultVendorTotal(
      vendorTotalfare
    );
  }, [
    adultBase,
    adultTax,
    adultBaggage,
    adultMarkup,
    adultVendorIssuanceFee,
    adultVendorTax,
    adultVendorIata,
    adultVendorPlb,
    adultIata,
    adultPlb,
    adultCount,
    adultService,
    adultExtra,
  ]);

  useEffect(() => {
    let totalChildFare =
      (childBase ? parseFloat(childBase) : 0) +
      (childTax ? parseFloat(childTax) : 0) +
      (childService ? parseFloat(childService) : 0) +
      (childExtra ? parseFloat(childExtra) : 0) +
      (childBaggage ? parseFloat(childBaggage) : 0) +
      (childMarkup ? parseFloat(childMarkup) : 0) +
      (childVendorIssuanceFee ? parseFloat(childVendorIssuanceFee) : 0) +
      (childVendorTax ? parseFloat(childVendorTax) : 0) -
      (childIata + childPlb);
    setchildTotal(totalChildFare * childCount);
    setchildVendorTotal(
      ((childBase ? parseFloat(childBase) : 0) +
        (childTax ? parseFloat(childTax) : 0) +
        (childService ? parseFloat(childService) : 0) +
        (childExtra ? parseFloat(childExtra) : 0) +
        (childBaggage ? parseFloat(childBaggage) : 0) +
        (childVendorIssuanceFee ? parseFloat(childVendorIssuanceFee) : 0) +
        (childVendorTax ? parseFloat(childVendorTax) : 0) - (childVendorIata + childVendorPlb)) *
      childCount
    );
  }, [
    childBase,
    childTax,
    childBaggage,
    childBookingfee,
    childMarkup,
    childVendorIssuanceFee,
    childVendorTax,
    childVendorIata,
    childVendorPlb,
    childIata,
    childPlb,
    childCount,
    childService,
    childExtra,
  ]);

  useEffect(() => {
    let totalinfantFare =
      (infantBase ? parseFloat(infantBase) : 0) +
      (infantTax ? parseFloat(infantTax) : 0) +
      (infantBookingfee ? parseFloat(infantBookingfee) : 0) +
      (infantExtra ? parseFloat(infantExtra) : 0) +
      (infantBaggage ? parseFloat(infantBaggage) : 0) +
      (infantMarkup ? parseFloat(infantMarkup) : 0) +
      (infantVendorIssuanceFee ? parseFloat(infantVendorIssuanceFee) : 0) +
      (infantVendorTax ? parseFloat(infantVendorTax) : 0) -
      (infantIata + infantPlb);
    setinfantTotal(totalinfantFare * infantCount);
    setinfantVendorTotal(
      ((infantBase ? parseFloat(infantBase) : 0) +
        (infantTax ? parseFloat(infantTax) : 0) +
        (infantBaggage ? parseFloat(infantBaggage) : 0) +
        (infantVendorIssuanceFee ? parseFloat(infantVendorIssuanceFee) : 0) +
        (infantVendorTax ? parseFloat(infantVendorTax) : 0) - (infantVendorIata + infantVendorPlb)) *
      infantCount
    );
  }, [
    infantBase,
    infantTax,
    infantBaggage,
    infantBookingfee,
    infantMarkup,
    infantVendorIssuanceFee,
    infantVendorTax,
    infantVendorIata,
    infantVendorPlb,
    infantIata,
    infantPlb,
    infantCount,
    infantExtra,
    infantService,
  ]);
  useEffect(() => {
    let totalseniorFare =
      (seniorBase ? parseFloat(seniorBase) : 0) +
      (seniorTax ? parseFloat(seniorTax) : 0) +
      (seniorExtra ? parseFloat(seniorExtra) : 0) +
      (seniorService ? parseFloat(seniorService) : 0) +
      (seniorBaggage ? parseFloat(seniorBaggage) : 0) +
      (seniorMarkup ? parseFloat(seniorMarkup) : 0) +
      (seniorVendorIssuanceFee ? parseFloat(seniorVendorIssuanceFee) : 0) +
      (seniorVendorTax ? parseFloat(seniorVendorTax) : 0) -
      (seniorIata + seniorPlb);
    setseniorTotal(totalseniorFare * seniorCount);
    setseniorVendorTotal(
      ((seniorBase ? parseFloat(seniorBase) : 0) +
        (seniorTax ? parseFloat(seniorTax) : 0) +
        (seniorExtra ? parseFloat(seniorExtra) : 0) +
        (seniorService ? parseFloat(seniorService) : 0) +
        (seniorBaggage ? parseFloat(seniorBaggage) : 0) +
        (seniorVendorIssuanceFee ? parseFloat(seniorVendorIssuanceFee) : 0) +
        (seniorVendorTax ? parseFloat(seniorVendorTax) : 0) - (seniorVendorIata + seniorVendorPlb)) *
      seniorCount
    );
  }, [
    seniorBase,
    seniorTax,
    seniorBaggage,
    seniorBookingfee,
    seniorMarkup,
    seniorVendorIssuanceFee,
    seniorVendorTax,
    seniorVendorIata,
    seniorVendorPlb,
    seniorIata,
    seniorPlb,
    seniorCount,
    seniorExtra,
    seniorService,
  ]);
  useEffect(() => {
    let totalyouthFare =
      (youthBase ? parseFloat(youthBase) : 0) +
      (youthTax ? parseFloat(youthTax) : 0) +
      (youthService ? parseFloat(youthService) : 0) +
      (youthExtra ? parseFloat(youthExtra) : 0) +
      (youthBaggage ? parseFloat(youthBaggage) : 0) +
      (youthMarkup ? parseFloat(youthMarkup) : 0) +
      (youthVendorIssuanceFee ? parseFloat(youthVendorIssuanceFee) : 0) +
      (youthVendorTax ? parseFloat(youthVendorTax) : 0) -
      (youthIata + youthPlb);
    setyouthTotal(totalyouthFare * youthCount);
    setyouthVendorTotal(
      ((youthBase ? parseFloat(youthBase) : 0) +
        (youthTax ? parseFloat(youthTax) : 0) +
        (youthService ? parseFloat(youthService) : 0) +
        (youthExtra ? parseFloat(youthExtra) : 0) +
        (youthBaggage ? parseFloat(youthBaggage) : 0) +
        (youthVendorIssuanceFee ? parseFloat(youthVendorIssuanceFee) : 0) +
        (youthVendorTax ? parseFloat(youthVendorTax) : 0) - (youthVendorIata + youthVendorPlb)) *
      youthCount
    );
  }, [
    youthBase,
    youthTax,
    youthBaggage,
    youthBookingfee,
    youthMarkup,
    youthVendorIssuanceFee,
    youthVendorTax,
    youthVendorIata,
    youthVendorPlb,
    youthIata,
    youthPlb,
    youthCount,
    youthExtra,
    youthService,
  ]);

  const handleCloseErrorMessageDialog = () => {
    setOpenErrorMessageDialog(false);
  };
  const handleSnackbarToggle = () => {
    setShowLoader(true);
    // console.log("handleSnackbarToggle");
    // console.log(passengerTemplate);
    setDrawer(false);
    setShowLoader(false);
  };

  async function createRailImportBooking() {
    setisImportBtnDisabled(true);
    let railFareList = [];
    let purchaseIds = [];
    bookingDetails.railPNRDetails?.map((item) => {
      purchaseIds.push(item.purchaseId);
    });
    if (railImportFareArray?.length > 0) {
      railImportFareArray.map((item, index) => {
        if (item.adultCount > 0) {
          railFareList.push({
            paxtype: "ADT",
            base: item.adultBase,
            tax: item.adultTax,
            baggage: item.adultBaggage,
            markup: item.adultMarkup,
            bookingfee: item.adultBookingfee,
            clientcommission: item.adultIata,
            clientincentive: item.adultPlb,
            vendorcommission: item.adultVendorIata,
            vendorincentive: item.adultVendorPlb,
            vendorIssuanceFee: item.adultVendorIssuanceFee,
            vendorTax: item.adultVendorTax,
          });
        }
        if (item.childCount > 0) {
          railFareList.push({
            paxtype: "CHD",
            base: item.childBase,
            tax: item.childTax,
            baggage: item.childBaggage,
            markup: item.childMarkup,
            bookingfee: item.childBookingfee,
            clientcommission: item.childIata,
            clientincentive: item.childPlb,
            vendorcommission: item.childVendorIata,
            vendorincentive: item.childVendorPlb,
            vendorIssuanceFee: item.childVendorIssuanceFee,
            vendorTax: item.childVendorTax,
          });
        }
        if (item.infantCount > 0) {
          railFareList.push({
            paxtype: "INF",
            base: item.infantBase,
            tax: item.infantTax,
            baggage: item.infantBaggage,
            markup: item.infantMarkup,
            bookingfee: item.infantBookingfee,
            clientcommission: item.infantIata,
            clientincentive: item.infantPlb,
            vendorcommission: item.infantVendorIata,
            vendorincentive: item.infantVendorPlb,
            vendorIssuanceFee: item.infantVendorIssuanceFee,
            vendorTax: item.infantVendorTax,
          });
        }
        if (item.seniorCount > 0) {
          railFareList.push({
            paxtype: "SNR",
            base: item.seniorBase,
            tax: item.seniorTax,
            baggage: item.seniorBaggage,
            markup: item.seniorMarkup,
            bookingfee: item.seniorBookingfee,
            clientcommission: item.seniorIata,
            clientincentive: item.seniorPlb,
            vendorcommission: item.seniorVendorIata,
            vendorincentive: item.seniorVendorPlb,
            vendorIssuanceFee: item.seniorVendorIssuanceFee,
            vendorTax: item.seniorVendorTax,
          })

        }
        if (item.youthCount > 0) {
          railFareList.push({
            paxtype: "YNG",
            base: item.youthBase,
            tax: item.youthTax,
            baggage: item.youthBaggage,
            markup: item.youthMarkup,
            bookingfee: item.youthBookingfee,
            clientcommission: item.youthIata,
            clientincentive: item.youthPlb,
            vendorcommission: item.youthVendorIata,
            vendorincentive: item.youthVendorPlb,
            vendorIssuanceFee: item.youthVendorIssuanceFee,
            vendorTax: item.youthVendorTax,
          })
        }
      })
    }
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    let railReqObj = {
      "traceId": bookingDetails.traceId,
      "railFare": railFareList,
      "salesStaff": salesStaff,
      "clientEmail": clientEmail,
      "clientName": clientName,
      "clientROE": clientROE,
      "purchaseIds": purchaseIds,
      "railPassengers": bookingDetails.railPNRDetails[0].passengers,
      "supplierName": "ItaloRail",
      "pcc": "DF",
      "clientId": clientId,
      "cardNumber": "5533894001396267",
      "expiryDate": "07/24",
      "bookingRefNo": "",
      "info": "This is for Testing purpose"
    }
    await axios
      .post(
        'https://railapibeta.getfares.com/Rail/Booking/CreatePNR/v1/ImportBooking',
        JSON.stringify({
          traceId: bookingDetails.traceId,
          railFare: railFareList,
          // pgCharge: parseFloat(pgCharge),
          // mco: parseFloat(mco),
          // mcoParted: parseFloat(mcoParted),
          // mcoRemark: mcoRemark,
          fop: String(fop),
          // caseID: caseID,
          salesStaff: salesStaff,
          clientEmail: clientEmail,
          clientName: clientName,
          fema: fema,
          isCorporate: fema === "Corporate" ? true : false,
          panCard: panCard,
          clientROE: clientROE,
          totalFare: clientTotalWithClientROE,
          purchaseIds: purchaseIds,
          // baggages: bookingDetails.flights[selectedFlightIndex].baggages,
          railPassengers: bookingDetails.railPNRDetails[0].passengers,
          // segGroups: segGroups,
          supplierName: supplierName,
          PCC: editPCC,
          clientId: String(clientId),
          expiryDate: expiryDate ? expiryDate : "",
          cardNumber: cardNumber ? cardNumber : "",
          bookingrefno: bookingRefNo ? bookingRefNo : bookingDetails.pnr,
          info: importPNRRemarks,
          // ticketType: reissue ? "R" : "F",
          // address: billingAddress,
          // bookingType: bookingType === "fitBooking" ? 1 : 2
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (typeof response.body === "string") {
          if (response.data.orderId) {
            localStorage.setItem("orderId", encryptData(response.data.orderId));
            window.open("/rail/ViewBookedTickets", "_self", "noreferrer");
          }
        } else {
          if (response.data.orderId) {
            localStorage.setItem("orderId", encryptData(response.data.orderId));
            window.open("/rail/ViewBookedTickets", "_self", "noreferrer");
          }
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setisImportBtnDisabled(false);
        console.log(error);
        setOpenErrorMessageHTML(error.response.data.detail);
        setOpenErrorMessageDialog(true);
        setShowLoader(false);
      });
  }
  async function createFlightImportBooking() {
    setisImportBtnDisabled(true);
    let flightFareList = [];
    let purchaseIds = [];
    // console.log("importPNRRemarks", importPNRRemarks);
    bookingDetails.flights?.map((item) => {
      purchaseIds.push(item.purchaseId);
    });
    if (adultCount > 0) {
      flightFareList.push({
        paxtype: "ADT",
        base: adultBase,
        tax: adultTax,
        baggage: adultBaggage,
        markup: adultMarkup,
        bookingfee: adultBookingfee,
        iata: adultIata,
        plb: adultPlb,
        vendorIATA: adultVendorIata,
        vendorPLB: adultVendorPlb,
        vendorIssuanceFee: adultVendorIssuanceFee,
        vendorTax: adultVendorTax,
      });
    }

    if (childCount > 0) {
      flightFareList.push({
        paxtype: "CHD",
        base: childBase,
        tax: childTax,
        baggage: childBaggage,
        markup: childMarkup,
        bookingfee: childBookingfee,
        iata: childIata,
        plb: childPlb,
        vendorIATA: childVendorIata,
        vendorPLB: childVendorPlb,
        vendorIssuanceFee: childVendorIssuanceFee,
        vendorTax: childVendorTax,
      });
    }

    if (infantCount > 0) {
      flightFareList.push({
        paxtype: "INF",
        base: infantBase,
        tax: infantTax,
        baggage: infantBaggage,
        markup: infantMarkup,
        bookingfee: infantBookingfee,
        iata: 0,
        plb: 0,
        vendorIATA: infantVendorIata,
        vendorPLB: 0,
        vendorIssuanceFee: infantVendorIssuanceFee,
        vendorTax: infantVendorTax,
      });
    }

    const baggagePayload = bookingDetails.flights[selectedFlightIndex].baggages.length
      ? bookingDetails.flights[selectedFlightIndex].baggages
      : baggageDetails.map((baggage, index) => ({
        id: index,
        airline: baggage.airline.Code,
        paxType: baggage.paxType,
        checkInBag: baggage.checkinBag,
        cabinBag: baggage.cabinBag,
        cityPair: baggage.cityPair,
        amount: parseFloat(baggage.amount),
        paxName: baggage.paxName
      }));

    setShowLoader(true);

    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/CreatePNR/v1/ImportBooking",
        JSON.stringify({
          traceId: bookingDetails.traceId,
          flightFare: flightFareList,
          pgCharge: parseFloat(pgCharge),
          mco: parseFloat(mco),
          mcoParted: parseFloat(mcoParted),
          mcoRemark: mcoRemark,
          fop: fop,
          caseID: caseID,
          salesStaff: salesStaff,
          clientEmail: clientEmail,
          clientName: clientName,
          fema: fema,
          isCorporate: fema === "Corporate" ? true : false,
          panCard: panCard,
          clientROE: clientROE,
          totalFare: clientTotalWithClientROE,
          purchaseIds: purchaseIds,
          baggages: baggagePayload,
          passengers: bookingDetails.flights[selectedFlightIndex].passengers.map((passenger, index) => ({
            ...passenger,
            ticketNumber: flightTicketNumber[`${selectedFlightIndex}-${index}`] || passenger.ticketNumber
          })),
          segGroups: segGroups,
          // flight:bookingDetails.flights,
          supplierName: editSupplierName,
          PCC: editPCC,
          clientId: clientId,
          // clientId: editClientId,
          expiryDate: expiryDate ? expiryDate : "",
          cardNumber: cardNumber ? cardNumber : "",
          bookingrefno: bookingRefNo ? bookingRefNo : bookingDetails.pnr,
          info: importPNRRemarks?.trim() + (source ? " %%%" + source + "%%%" : ""),
          ticketType: reissue ? "R" : "F",
          // address: billingAddress,
          bookingType: bookingType === "fitBooking" ? 1 : 2,
          adultCount: adultCount,
          childCount: childCount,
          infantCount: infantCount,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (typeof response.body === "string") {
          if (response.data.orderId) {
            localStorage.setItem("orderId", encryptData(response.data.orderId));
            history.push("/flight/view-booked-tickets");
          }
        } else {
          if (response.data.orderId) {
            localStorage.setItem("orderId", encryptData(response.data.orderId));
            history.push("/flight/view-booked-tickets");
          }
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setisImportBtnDisabled(false);
        console.log(error);
        setOpenErrorMessageHTML(error.response.data.detail);
        setOpenErrorMessageDialog(true);
        setShowLoader(false);
      });
  }
  async function CreateImportBooking() {
    if (selectedProductType === 'Flight') {
      createFlightImportBooking()
    } else {
      createRailImportBooking()
    }

  }
  // const updateItenary = () => {
  //   let purchaseIds=[];
  //   bookingDetails.flights?.map(item=>{
  //     purchaseIds.push(item.purchaseId)
  //   })
  //   // setbookingProcess(true);
  //   flightBooking(
  //     bookingDetails.traceId,
  //     bookingDetails.clientId,
  //     purchaseIds,
  //     bookingDetails.flights[selectedFlightIndex].passengers,
  //     '',
  //     '',
  //     '',
  //     ''
  //   )
  //     .then((data) => {
  //       console.log(data);
  //       // setbookingProcess(false);
  //       // data.orderId ?
  //       //   history.push({ pathname: "/view-booked-tickets/" + data.orderId })
  //       //   :
  //       //   setOpenErrorMessageHTML(data);
  //       // setOpenErrorMessageDialog(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const toggleDrawer = (value) => (event) => {
    // console.log("toggleDrawer");
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(value);
    // if (value === false) {
    //   if (action === 'edit') {
    //     editPassenger();
    //   } else{
    //   addPassenger();
    //   }
    // }
  };

  const handleticketChange = (value, flightIndex, PaxIndex) => {
    const temp = { ...bookingDetails };
    if (selectedProductType === 'Flight') {
      setFlightTicketNumber(prevState => ({
        ...prevState,
        [`${flightIndex}-${PaxIndex}`]: value
      }));
    } else {
      temp.railPNRDetails[flightIndex].passengers[PaxIndex].ticketNumber = value;
    }
    setbookingDetails(temp);
  };
  const handleDOBFieldChange = (value, index, paxIndex) => {
    const temp = { ...bookingDetails };
    if (selectedProductType === 'Flight') {
      temp.flights[index].passengers[paxIndex].dob = value;
    } else {
      // temp.railPNRDetails[index].passengers[paxIndex].dob = value;
      temp.railPNRDetails.map((rail) => {
        rail.passengers[paxIndex].dob = value;
      })
    }
    setbookingDetails(temp);
  };

  const handlecabinClassChange = (
    event,
    value,
    flightIndex,
    segGroupIndex,
    segmentIndex
  ) => {
    const temp = [...segGroups];
    temp[segGroupIndex].segments[segmentIndex].cabinClass = value;
    setSegGroups(temp);
  };
  const handleRailcabinClassChange = (
    event,
    value,
    railIndex,
    legGroupIndex,
    segmentIndex
  ) => {
    const temp = bookingDetails;
    temp.rails[railIndex].legGroups[legGroupIndex].railSegments[segmentIndex].railCabinClass = value;
    setbookingDetails(temp);
  };
  const handlePNRChange = (event, flightIndex, segGroupIndex, segmentIndex) => {
    const temp = [...segGroups];
    temp[segGroupIndex].segments[segmentIndex].pnr = event.target.value;
    setSegGroups(temp);
  };
  const handleRailPNRChange = (event, railIndex, legGroupIndex, segmentIndex) => {
    const temp = bookingDetails;
    temp.rails[railIndex].legGroups[legGroupIndex].railSegments[segmentIndex].pnr = event.target.value;
    setbookingDetails(temp);
  };
  const openAddDrawer = (index1, index2) => {
    // console.log("openAddDrawer");
    setSelectedFlightIndex(index1);
    setSelectedPassengerIndex(index2);
    setSelectedItem("");
    setDrawer(true);
  };
  const openEditDrawer = (index1, index2) => {
    // console.log("openEditDrawer");
    setSelectedFlightIndex(index1);
    setSelectedPassengerIndex(index2);
    setSelectedItem(
      bookingDetails.flights[selectedFlightIndex].passengers[
      selectedPassengerIndex
      ]
    );
    setDrawer(true);
  };
  async function deletePassenger(index1, index2) {
    let passenger = bookingDetails.flights[index1].passengers[index2];
    bookingDetails.flights[index1].passengers.splice(index2, 1);
    let temp = bookingDetails;
    let array = temp?.flights[index1]?.flightFares;
    let count =
      passenger.paxType == "ADT"
        ? adultCount - 1
        : passenger.paxType == "CHD"
          ? childCount - 1
          : passenger.paxType == "INF"
            ? infantCount - 1
            : 0;

    if (count == 0) {
      let result = array.find((item) => item.paxType == passenger.paxType);
      if (result) {
        let fareIndex = array.indexOf(result);
        if (fareIndex) {
          temp?.flights[selectedFlightIndex]?.flightFares.splice(fareIndex, 1);
        }
        // console.log("item already present");
      }
    }

    await setbookingDetails((bookingDetails) => temp);
    updatePaxCount();
    // console.log(bookingDetails.flights[index1].passengers);
  }
  useEffect(() => {
    if (action === "add" || action === "edit") {
      if (passengerTemplate.id != undefined) {
        let test = bookingDetails;
        let temp = fareTemplate;
        if (action === "add") {
          test?.flights[selectedFlightIndex]?.passengers?.push(
            passengerTemplate
          );
        } else if (action === "edit") {
          test.flights[selectedFlightIndex].passengers[selectedPassengerIndex] =
            passengerTemplate;
        }
        let array = test?.flights[selectedFlightIndex]?.flightFares;
        let result = array.find(
          (item) => item.paxType == passengerTemplate.paxType
        );
        if (result) {
          // console.log("item already present");
        } else {
          temp.paxType = passengerTemplate.paxType;
          test?.flights[selectedFlightIndex]?.flightFares.push(temp);
        }
        setbookingDetails((bookingDetails) => test);
        updatePaxCount();
      }
    }
  }, [passengerTemplate]);
  const updatePaxCount = () => {
    const temp = bookingDetails;
    if (temp && temp.flights) {
      let count1 = 0,
        count2 = 0,
        count3 = 0;
      temp?.flights[selectedFlightIndex]?.passengers.map((passenger) => {
        if (passenger.paxType == "ADT") {
          count1++;
        } else if (passenger.paxType == "CHD") {
          count2++;
        } else if (passenger.paxType == "INF") {
          count3++;
        }
      });
      temp.flights[selectedFlightIndex].adultCount = count1;
      temp.flights[selectedFlightIndex].childCount = count2;
      temp.flights[selectedFlightIndex].infantCount = count3;
      setadultCount(temp.flights[selectedFlightIndex]?.adultCount);
      setchildCount(temp.flights[selectedFlightIndex]?.childCount);
      setinfantCount(temp.flights[selectedFlightIndex]?.infantCount);
      setbookingDetails((bookingDetails) => temp);
      setFlightBookingFareDetails(temp);
      setFareTemplate({
        id: 0,
        flightId: 0,
        paxType: "",
        baseFare: 0,
        taxes: [{ id: 0, amt: 0, code: "Other" }],
      });
    }
  };
  useEffect(() => {
    if (bookingDetails?.flights != null) {
      // bookingDetails?.flights[selectedFlightIndex].passengers[selectedPassengerIndex].id =
      setSelectedItem(
        bookingDetails?.flights[selectedFlightIndex].passengers[
        selectedPassengerIndex
        ]
      );
    }
  }, [selectedFlightIndex, selectedPassengerIndex]);

  useEffect(() => {
    setSelectedProductType((selectedProductType) => productType);
  }, [productType]);

  const handleProductTypeChange = (event, newValue) => {
    setProductType((value) => newValue);
    setSelectedProductType((selectedProductType) => newValue);
    if (newValue === 'Rail') {
      setPCC((pcc) => "DF");
      setsupplierName((supplierName) => "ItaloRail");
      setRailBookingFareDetails(bookingDetails);
    } else {
      setPCC((pcc) => "");
      setsupplierName((supplierName) => "");
      setFlightBookingFareDetails(bookingDetails);
    }

  };
  useEffect(() => {
    const baggageByPaxName = {};

    // Group baggage by paxName and sum the amounts
    baggageDetails.forEach(baggage => {
      const key = baggage.paxName;
      const amount = parseFloat(baggage.amount) || 0;
      if (!baggageByPaxName[key]) {
        baggageByPaxName[key] = {
          paxType: baggage.paxType,
          totalAmount: 0,
          count: 0
        };
      }
      baggageByPaxName[key].totalAmount += amount;
      baggageByPaxName[key].count++;
    });

    let adultTotal = 0;
    let childTotal = 0;
    let infantTotal = 0;
    let adultCount = 0;
    let childCount = 0;
    let infantCount = 0;

    // Calculate the total amount per paxName and sum by paxType
    Object.values(baggageByPaxName).forEach(pax => {
      const totalAmount = pax.totalAmount;
      if (pax.paxType === "ADT") {
        adultTotal += totalAmount;
        adultCount++;
      } else if (pax.paxType === "CHD") {
        childTotal += totalAmount;
        childCount++;
      } else if (pax.paxType === "INF") {
        infantTotal += totalAmount;
        infantCount++;
      }
    });

    const adultAverage = adultCount > 0 ? adultTotal / adultCount : 0;
    const childAverage = childCount > 0 ? childTotal / childCount : 0;
    const infantAverage = infantCount > 0 ? infantTotal / infantCount : 0;

    setadultBaggage(adultAverage);
    setchildBaggage(childAverage);
    setinfantBaggage(infantAverage);
  }, [baggageDetails]);



  return (
    <div className="import-booking-container">
      <Snackbar
        open={alert.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={10000}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Tabs
        value={productType}
        textColor="primary"
        indicatorColor="primary"
        onChange={handleProductTypeChange}
        variant="fullWidth"
      >
        <Tab
          className="review-itenary-container-header-details"
          value="Flight"
          label="Flight"
        />
        <Tab
          className="review-itenary-container-header-details"
          value="Rail"
          label="Rail "
        />
      </Tabs>
      <Grid container>
        {/* <Grid item xs={1}></Grid> */}
        <Grid item xs={10} style={{ minWidth: "100%", padding: "0px 5%" }}>
          {
            <div className="flight-itenary">
              <div className="flight-itenary-header">
                <div className="flight-itenary-title-container">
                  Import Booking
                </div>
              </div>
              {clientProfiles !== undefined && clientProfiles?.length > 0 ? (
                <div className="importbooking-search-search-container">
                  <div className="importbooking-search-table-container">
                    <div className="search-title-add-button-container">
                      <div className="search-heading"></div>
                    </div>
                    <div className="importbooking-search-card-container">
                      <SearchImportBookingCard
                        setsupplierName={setsupplierName}
                        setPCC={setPCC}
                        setPNR={setPNR}
                        setclientId={setclientId}
                        clientProfiles={clientProfiles}
                        pccList={pccList}
                        loadPNRDetails={loadPNRDetails}
                        loadPCCDetails={loadPCCDetails}
                        supplierName={supplierName}
                        clientId={clientId}
                        pcc={pcc}
                        pnr={pnr}
                        setClientAvailableBalance={setClientAvailableBalance}
                        clientAvailableBalance={clientAvailableBalance}
                        clientBalanceSH={clientBalanceSH}
                        showLoader={showLoader}
                        setShowLoader={setShowLoader}
                        clientCurrency={clientCurrency}
                        setBookingType={setBookingType}
                        bookingType={bookingType}
                        setReissue={setReissue}
                        setPassengerFirstName={setPassengerFirstName}
                        setPassengerLastName={setPassengerLastName}
                        passengerFirstName={passengerFirstName}
                        passengerLastName={passengerLastName}
                        productType={productType}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="table-loading">
                    <div style={{ width: "100%", margin: "5px" }}>
                      <Skeleton width="100%" height={24} animation="wave" />
                    </div>
                    <div style={{ width: "100%", margin: "5px" }}>
                      <Skeleton width="100%" height={24} animation="wave" />
                    </div>
                    <div style={{ width: "100%", margin: "5px" }}>
                      <Skeleton width="100%" height={24} animation="wave" />
                    </div>
                    <div style={{ width: "100%", margin: "5px" }}>
                      <Skeleton width="100%" height={24} animation="wave" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
          {
            <div>
              {
                (bookingDetails !== undefined && bookingDetails !== null && searchPNR === true) ? (
                  <div>
                    {showLoader ? <RiseLoading isImport={showLoader} selectedProductType={selectedProductType} /> : ""}
                  </div>
                )
                  :
                  (bookingDetails !== undefined && bookingDetails !== null && searchPNR === false) ?
                    (<div>
                      <ValidatorForm
                        onSubmit={CreateImportBooking}
                        onError={(errors) => console.log(errors)}
                      >
                        <ItienarayHeader bookingRefNo={bookingDetails?.pnr} headText={selectedProductType === 'Flight' ? 'Flight Itinerary' : selectedProductType === 'Rail' ? 'Rail Itinerary' : ''} />
                        {selectedProductType === "Flight" &&
                          <FlightImportBooking bookingDetails={bookingDetails} flight={bookingDetails && bookingDetails.flights && bookingDetails.flights[0]} selectedProductType={selectedProductType}
                            handlecabinClassChange={handlecabinClassChange} handlePNRChange={handlePNRChange} handleticketChange={handleticketChange}
                            flightIndex={0} clientId={clientId} setclientId={setclientId} adultBase={adultBase}
                            OnChangeCalculateTotalFare={OnChangeCalculateTotalFare} CalculateTotalFare={CalculateTotalFare} reissue={reissue}
                            segGroups={segGroups} setPassengerTemplate={setPassengerTemplate} vendorDetail={vendorDetail} clientCurrency={clientCurrency}
                            childBase={childBase} adultTax={adultTax} childTax={childTax} infantBase={infantBase} infantTax={infantTax}
                            adultBaggage={adultBaggage} childBaggage={childBaggage} infantBaggage={infantBaggage} adultMarkup={adultMarkup} childMarkup={childMarkup}
                            infantMarkup={infantMarkup} adultIata={adultIata} childIata={childIata} infantIata={infantIata} adultPlb={adultPlb} childPlb={childPlb}
                            infantPlb={infantPlb} adultTotal={adultTotal} childTotal={childTotal} infantTotal={infantTotal} adultVendorTotal={adultVendorTotal}
                            childVendorTotal={childVendorTotal} infantVendorTotal={infantVendorTotal} adultVendorIata={adultVendorIata} childVendorIata={childVendorIata}
                            infantVendorIata={infantVendorIata} adultVendorPlb={adultVendorPlb} childVendorPlb={childVendorPlb} infantVendorPlb={infantVendorPlb} adultVendorIssuanceFee={adultVendorIssuanceFee}
                            childVendorIssuanceFee={childVendorIssuanceFee} infantVendorIssuanceFee={infantVendorIssuanceFee} adultVendorTax={adultVendorTax} childVendorTax={childVendorTax} infantVendorTax={infantVendorTax}
                            clientROE={clientROE} selectedItem={selectedItem} setSelectedItem={setSelectedItem} action={action} setAction={setAction} flightTicketNumber={flightTicketNumber}
                            baggageDetails={baggageDetails} setBaggageDetails={setBaggageDetails} />

                        } {selectedProductType === "Rail" &&
                          <RailImportBooking
                            bookingDetails={bookingDetails}
                            handleRailcabinClassChange={handleRailcabinClassChange} handleRailPNRChange={handleRailPNRChange}
                            reissue={reissue} selectedProductType={selectedProductType}
                            handleticketChange={handleticketChange} handleDOBFieldChange={handleDOBFieldChange} handleSnackbarToggle={handleSnackbarToggle} toggleDrawer={toggleDrawer}
                            clientROE={clientROE} setClientTotal={setClientTotal}
                            setVendorTotalWithClientROE={setVendorTotalWithClientROE} setVendorTotal={setVendorTotal}
                            setVendorIataType={setVendorIataType} setiataType={setiataType} setPlbType={setPlbType} setvendorPlbType={setvendorPlbType}
                            iataType={iataType} plbType={plbType} setPassengerTemplate={setPassengerTemplate} selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem} action={action} setAction={setAction} showLoader={showLoader}
                            drawer={drawer} setDrawer={setDrawer} clientCurrency={clientCurrency} setClientCurrency={setClientCurrency}
                            CalculateRailTotalFare={CalculateRailTotalFare} OnChangeCalculateRailTotalFare={OnChangeCalculateRailTotalFare}
                            setShowLoader={setShowLoader} vendorDetail={vendorDetail} setVendorDetail={setVendorDetail} selectDate={selectDate}
                            setrailImportFareArray={setrailImportFareArray} railImportFareArray={railImportFareArray}

                          />}
                        <div className="import-booking-mco mco-dtls-bg mb-4">
                          <Row className="mx-1">
                            <div
                              className="col-md-3 FOP"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <Autocomplete
                                id="FOP-select"
                                variant="outlined"
                                value={
                                  fop !== ""
                                    ? fopOptions.find((v) => v.id === fop)
                                    : null
                                }
                                onChange={(event, newValue) => {
                                  setFOP(newValue ? newValue.id : "");
                                }}
                                options={fopOptions}
                                getOptionLabel={(option) => option.des}
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    label="FOP"
                                    required
                                    value={fop}
                                    variant="filled"
                                    color="secondary"
                                    size="small"
                                    validators={["required"]}
                                    errorMessages={["required"]}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                )}
                              />
                            </div>
                            {fop === "Passenger Card" && (
                              <div
                                className="col-md-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TextValidator
                                  id="outlined-clientROE-input"
                                  required={
                                    fop == "Passenger Card"
                                      ? ["required"]
                                      : false
                                  }
                                  label="Card number"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  value={cardNumber}
                                  // onBlur={(event) => CalculateTotalFare()}
                                  onChange={(event) =>
                                    setcardNumber(event.target.value)
                                  }
                                  validators={["required"]}
                                  errorMessages={["required"]}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                            )}
                            {fop === "Passenger Card" && (
                              <div
                                className="col-md-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TextValidator
                                  id="outlined-clientROE-input"
                                  required={
                                    fop == "Passenger Card"
                                      ? ["required"]
                                      : false
                                  }
                                  label="Expiry Date"
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  value={expiryDate}
                                  onChange={(event) =>
                                    setexpiryDate(event.target.value)
                                  }
                                  validators={["required"]}
                                  errorMessages={["required"]}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                            )}
                            <div
                              className="col-md-3"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <TextValidator
                                id="outlined-clientROE-input"
                                label="Client ROE"
                                required
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={clientROE}
                                onBlur={() => {
                                  selectedProductType === "Flight" ? CalculateTotalFare() : CalculateRailTotalFare(railImportFareArray);
                                }}
                                onChange={(event) => {
                                  setClientROE(
                                    (clientROE) => event.target.value
                                  );
                                }}
                                validators={["required"]}
                                errorMessages={["required"]}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                            <div
                              className="col-md-3"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <TextValidator
                                id="outlined-clientROE-input"
                                label="Case ID"
                                variant="outlined"
                                size="small"
                                required
                                color="secondary"
                                value={caseID}
                                onChange={(event) =>
                                  setCaseID(event.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                validators={["required", 'matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$']}
                                errorMessages={["This field is required", "This field cannot be blank or contain only spaces"]}
                              />
                            </div>
                            <div
                              className="col-md-3 salesStaff"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              {/* <TextValidator
                                id="outlined-clientROE-input"
                                label="Sales Staff"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={salesStaff}
                                onChange={(event) =>
                                  setsalesStaff(event.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              /> */}
                              <Autocomplete
                                id="FOP-select"
                                variant="outlined"
                                value={
                                  salesStaff !== undefined &&
                                  userProfiles?.find(
                                    (el) => el.id === salesStaff
                                  )
                                }
                                onChange={(event, newValue) => {
                                  setsalesStaff(newValue ? newValue.id : "");
                                }}
                                options={userProfiles}
                                getOptionLabel={(option) =>
                                  option.title +
                                  "." +
                                  option.firstName +
                                  " " +
                                  option.lastName
                                }
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    label="Sales Staff"
                                    required
                                    // salesStaff !== undefined ? userProfiles.find((v) => v.id == salesStaff) : ""
                                    value={salesStaff}
                                    variant="filled"
                                    color="secondary"
                                    size="small"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    validators={["required"]}
                                    errorMessages={["required"]}
                                  />
                                )}
                              />
                            </div>
                            <div
                              className="col-md-3"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <TextValidator
                                required={
                                  endEmailMandatoryClients.includes(clientId.toString())
                                }
                                id="outlined-clientROE-input"
                                label="End Client Name"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={clientName}
                                onChange={(event) =>
                                  setclientName(event.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                            <div
                              className="col-md-3"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <TextValidator
                                // required={
                                //   endEmailMandatoryClients.includes(clientId.toString())
                                // }
                                required
                                id="outlined-clientROE-input"
                                label="END Client Email / Akbar Online OD"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={clientEmail}
                                onChange={(event) =>
                                  setclientEmail(event.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                validators={["required", 'matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$']}
                                errorMessages={["This field is required", "This field cannot be blank or contain only spaces"]}
                              />
                            </div>
                            <div
                              className="col-md-3 fema"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <Autocomplete
                                id="FOP-select"
                                variant="outlined"
                                value={
                                  fema && femaList.find((el) => el === fema)
                                }
                                onChange={(event, newValue) => {
                                  setfema(newValue ? newValue : "");
                                }}
                                options={femaList}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    label="Corporate/Retailer"
                                    value={fema}
                                    variant="filled"
                                    color="secondary"
                                    size="small"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <div
                              className="col-md-3"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <TextValidator
                                required={
                                  fema === "Retailer" ? ["required"] : false
                                }
                                id="outlined-clientROE-input"
                                label="Pan Card"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={panCard}
                                onChange={(event) =>
                                  setpanCard(event.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                validators={[
                                  "matchRegexp:^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
                                ]}
                                errorMessages={[
                                  "Not in valid format - 1st 5 Letters in CAPS, then Next 4 Letters in Numbers & Last Letter in CAPS",
                                ]}
                              />
                            </div>

                            {/* MCO fields */}

                            <div
                              className="col-md-3"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <TextValidator
                                id="outlined-mco-input"
                                label="MCO Number"
                                type="number"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={mco}
                                onChange={(event) => setMCO(event.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />

                            </div>
                            <div
                              className="col-md-3"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <TextValidator
                                id="outlined-pgcharge-input"
                                label="MCO Charge"
                                type="number"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={pgCharge}
                                onChange={(event) => setPGCharge(event.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />

                            </div>
                            <div
                              className="col-md-3 fema"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <TextValidator
                                id="outlined-mcoParted-input"
                                label="MCO Parting"
                                type="number"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={mcoParted}
                                onChange={(event) => setMCOParted(event.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />

                            </div>
                            <div
                              className="col-md-3"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <TextValidator
                                id="outlined-mcoRemark-input"
                                label="MCO Remark"
                                type="text"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={mcoRemark}
                                onChange={(event) => setMCORemark(event.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>

                            {/* MCO fields */}
                            <div
                              className="col-md-3"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <Autocomplete
                                id="FOP-select"
                                variant="outlined"
                                value={source && sourceList.find((el) => el === source)}
                                onChange={(event, newValue) => {
                                  setSource(newValue ? newValue : "");
                                }}
                                options={sourceList}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    label="Source of Booking"
                                    value={source}
                                    variant="filled"
                                    color="secondary"
                                    size="small"
                                    required
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    validators={["required"]}
                                    errorMessages={["required"]}
                                  />
                                )}
                              />

                            </div>
                          </Row>
                          <Row className="mx-1">
                            <div
                              className="col-md-3 align-self-center"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            ></div>
                            <div
                              className="col-md-3 align-self-center"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            ></div>
                            <div
                              className="col-md-3 align-self-center"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <div className="import-booking-fare-item-total">
                                {" "}
                                <span style={{ marginRight: "5px" }}>
                                  Client Payable:
                                </span>{" "}
                                {clientCurrency ? clientCurrency : " "}{" "}
                                {clientTotalWithClientROE}
                              </div>
                            </div>
                            <div
                              className="col-md-3 align-self-center"
                              style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <div className="import-booking-fare-item-total">
                                <span style={{ marginRight: "5px" }}>
                                  Supplier Payable:
                                </span>{" "}
                                {vendorDetail && vendorDetail.currencyCode
                                  ? vendorDetail.currencyCode
                                  : " "}{" "}
                                {vendorTotal}
                              </div>
                            </div>
                          </Row>
                          <div className="import-booking-remarks">
                            <Box
                              component="form"
                              sx={{
                                "& > :not(style)": { m: 1 },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                id="outlined-basic"
                                label="Remarks"
                                multiline
                                maxRows={4}
                                placeholder="Max 300 Characters"
                                sx={{
                                  whiteSpace: "pre-wrap",
                                  maxWidth: "98.5%",
                                  minHeight: "100px !important",
                                }}
                                variant="outlined"
                                value={importPNRRemarks}
                                inputProps={{ maxLength: 300 }}
                                onChange={(event) => {
                                  setimportPNRRemarks(
                                    (importPNRRemarks) => event?.target?.value
                                  );
                                }}
                              />
                            </Box>
                          </div>
                          {clientTotalWithClientROE > clientAvailableBalance ? (
                            <>
                              <Row>
                                <div className="col-6 offset-md-3 mt-4 mb-1 warning-msg-txt text-center">
                                  Unable to create booking, due insufficient
                                  client balance.
                                </div>
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        {clientTotalWithClientROE < clientAvailableBalance ? (
                          <>
                            <div className="create-booking-button-container">
                              {errorMessage && <div style={{ color: 'red', fontSize: '14px' }}>{errorMessage}</div>}
                              <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                style={{
                                  textTransform: "none",
                                  //marginLeft: "415px",
                                }}
                                type="submit"
                                disabled={isImportBtnDisabled}
                              // onClick={() => CreateImportBooking()}
                              >
                                Create Booking
                              </Button>
                              {/* {showLoader && <div style={{ position: 'absolute', left: '50%', top: '75%' }}><CircularProgress /></div>} */}
                            </div>
                            {/* <div className="create-booking-button-container">
                              <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                style={{
                                  textTransform: "none",
                                  //marginLeft: "415px",
                                }}
                                type="submit"
                                disabled={isImportBtnDisabled}
                                onClick={() => updateItenary()}
                              >
                                Update Itenary
                              </Button>
                            </div> */}
                          </>
                        ) : (
                          ""
                        )}
                      </ValidatorForm>
                    </div>)

                    :

                    bookingDetails === null && searchPNR === false ? (
                      <NoItienaryDiv />
                    ) : (
                      <div>
                        {showLoader ? <RiseLoading isImport={showLoader} selectedProductType={selectedProductType} /> : ""}
                      </div>
                    )
                // :
              }
            </div>
            // )
          }
        </Grid>
        {/* <Grid item xs={1}></Grid> */}
      </Grid>
      {/* No Sufficient Client Balance Dialog Box */}
      <Dialog
        open={openErrorMessageDialog}
        onClose={handleCloseErrorMessageDialog}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
        className="error-dialog-box"
      >
        <DialogTitle id="error-dialog-title" className="main-heading-cls">
          Notification
        </DialogTitle>
        <DialogContent className="mt-3 py-2">
          <DialogContentText id="error-dialog-description">
            <Row>
              <div className="col-12 sub-heading-cls mb-2">
                Dear Travel Partner
              </div>
              <div className="normal-txt-cls">
                <p className="sub-heading-cls">{openErrorMessageHTML}</p>
                <p>
                  Kindly contact our Customer Success Group at{" "}
                  <a href="mailto:customersuccess@getfares.com">
                    customersuccess@getfares.com
                  </a>{" "}
                  to assist you and proceed towards a successful booking.
                </p>
              </div>
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="error-dialog-action text-center my-2">
          <Button variant="outlined" onClick={handleCloseErrorMessageDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={duplicatePopup}
        onClose={() => setDuplicatePopup(false)}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
        className="error-dialog-box"
      >
        <DialogTitle id="error-dialog-title" className="main-heading-cls">
          Duplicate Order
        </DialogTitle>
        <DialogContent className="mt-3 py-2">
          <DialogContentText id="error-dialog-description">
            <Row>
              <div className="col-12 sub-heading-cls mb-2">
                Dear Travel Partner
              </div>
              <div className="normal-txt-cls">
                <p className="sub-heading-cls">{openErrorMessageHTML}</p>
                <p>
                  {isDuplicateRes}
                </p>
              </div>
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="error-dialog-action text-center my-2">
          <Button variant="outlined" onClick={handleDuplicateCancel}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleDuplicateProceed}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>



    </div>
  );
}
