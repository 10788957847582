import React, { useState, useEffect } from "react";
import "./MarkupBlock.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import { useParams } from "react-router-dom";
import MarkupBlockTable from "./MarkupBlockTable/MarkupBlockTable";
import MarkupBlockDrawer from "./MarkupBlockDrawer/MarkupBlockDrawer";
import AddMarkupBlockCard from "./AddMarkupBlockCard/AddMarkupBlockCard";
import { createTheme } from "@material-ui/core/styles";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { isUserHasPermission } from "../../../services/AuthService";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MarkupBlock() {
  let { id } = useParams();
  let { name } = useParams();
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("new");
  const [categoryAction, setCategoryAction] = useState("view");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [commissionBlocks, setCommissionBlocks] = useState(null);
  const [supplierCommissionGroup, setSupplierCommissionGroup] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [airline, setAirline] = useState("");
  const [fareType, setfareType] = useState("");
  const [travelType, settravelType] = useState("");
  const [cabinClass, setcabinClass] = useState("");
  const [markupBlockID, setMarkupBlockID] = useState("");
  const [vendorID, setVendorID] = useState("");
  const [rbd, setRbd] = useState("");
  const [pccList, setPccList] = useState([]);
  const [vendorList, setVendor] = useState([]);
  const [page, setPage] = React.useState(1);
  const [alertText, setalertText] = useState("");
  const [mode, setMode] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const history = useHistory();
  useEffect(() => {
    if (isUserHasPermission("MarkupBlockRead")) {
      loadCommissionBlocks(false);
      loadPCCDetails();
      // loadVendor();
    } else {
      history.push("/Unauthorized");
    }
  }, []);
  useEffect(() => { }, [alertText, mode]);
  const theme = createTheme({
    overrides: {
      MuiDrawer: {
        paperAnchorDockedRight: {
          styleOverrides: {
            width: "200% !important",
          },
        },
      },
    },
  });

  async function loadVendor() {
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
      .then((response) => {
        let tmpArray = [];
        for (var i = 0; i < response.data.length; i++) {
          tmpArray.push({
            id: response.data[i].bookingSwitches.id,
            name: response.data[i].bookingSwitches.vendorName,
          });
        }
        setVendor(tmpArray);
      })
      .catch((error) => console.log(error));
  }

  async function loadCommissionBlocks(search) {
    let filter,
      markupBlockIDFilter = "",
      fareTypeFilter = "",
      vendorIDFilter = "";
    if (markupBlockID) {
      markupBlockIDFilter = ",id==" + markupBlockID;
    }
    if (fareType) {
      fareTypeFilter = ",faretype@=" + fareType;
    }
    if (vendorID && vendorID != 0) {
      vendorIDFilter = ",vendorId==" + vendorID;
    }

    if (markupBlockID !== "" || fareType !== "" || vendorID !== "") {
      if (search) {
        setPage(1);
      }
      filter =
        "MarkupGroupId==" +
        id +
        markupBlockIDFilter +
        fareTypeFilter +
        vendorIDFilter +
        "&page=" +
        page;
    } else {
      filter = "MarkupGroupId==" + id + "&page=" + page;
    }
    if (!search) {
      filter = "MarkupGroupId==" + id + "&page=" + page;
    }
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/MarkupBlock?filters=" +
        filter +
        "&pageSize=25"
      )
      .then((response) => {
        setCommissionBlocks(response.data);
      });
  }

  async function loadPCCDetails() {
    let vendorArray = [];
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          vendorArray.push({
            id: response.data[i].bookingSwitches.id,
            name: response.data[i].bookingSwitches.vendorName,
          });
        }
        setVendor(vendorArray);
        axios
          .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
          .then((response) => {
            let tmpArray = [];
            for (var i = 0; i < response.data.length; i++) {
              if (
                vendorArray.some(
                  (item) => item.id === response.data[i].pccConfigs.vendorId
                )
              ) {
                tmpArray.push({
                  value: response.data[i].pccConfigs.pccCode,
                  label:
                    response.data[i].pccConfigs.pccCode +
                    " (" +
                    vendorArray.find(
                      (st) => st.id === response.data[i].pccConfigs.vendorId
                    ).name +
                    ")",
                });
              } else {
                tmpArray.push({
                  value: response.data[i].pccConfigs.pccCode,
                  label: response.data[i].pccConfigs.pccCode,
                });
              }
            }
            //console.log(tmpArray);
            setPccList(tmpArray);
          });
      });
  }

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };

  const handleSnackbarToggle = (text, mode) => {
    setalertText(text);
    setMode(mode);
    setDrawer(false);
    setSnackbarOpen(true);
    loadCommissionBlocks(false);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="supplier-container">
      <div className="supplier-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-supplier-container">
              <div className="supplier-table-container">
                <div className="supplier-title-add-button-container">
                  <div className="supplier-heading">
                    <IconButton
                      aria-label="Back"
                      onClick={() => history.goBack()}
                    >
                      <ArrowBack />
                    </IconButton>

                    <p> Markup Blocks - {name}</p>
                  </div>
                  <div className="search-icon-button-container">
                    <SearchIcon
                      fontSize="small"
                      className="search-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon>
                    {isUserHasPermission("MarkupBlockWrite") ? (
                      <div
                        className="add-supplier-button mt-0px"
                        onClick={() => {
                          setAction("new");
                          setDrawer(true);
                        }}
                      >
                        Add Markup Block
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="supplier-card-container">
                    <AddMarkupBlockCard
                      setMarkupBlockID={setMarkupBlockID}
                      setfareType={setfareType}
                      markupBlockID={markupBlockID}
                      fareType={fareType}
                      setVendorID={setVendorID}
                      vendorID={vendorID}
                      vendorList={vendorList}
                      loadCommissionBlocks={loadCommissionBlocks}
                    ></AddMarkupBlockCard>
                  </div>
                </Collapse>
                <MarkupBlockTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                  commissionBlocks={commissionBlocks}
                  name={name}
                  setPage={setPage}
                  page={page}
                  vendorList={vendorList}
                  loadCommissionBlocks={loadCommissionBlocks}
                  initialLoad={initialLoad}
                  setInitialLoad={setInitialLoad}
                ></MarkupBlockTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        theme={theme}
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <MarkupBlockDrawer
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
            action={action}
            setAction={setAction}
            handleSnackbarToggle={handleSnackbarToggle}
            pccList={pccList}
            name={name}
            groupId={id}
            vendorList={vendorList}
          ></MarkupBlockDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
      >
        {alertText && mode == "error" ? (
          <Alert onClose={handleSnackBarClose} severity="error">
            {alertText}
          </Alert>
        ) : (
          <Alert onClose={handleSnackBarClose} severity="success">
            {alertText}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}

export default MarkupBlock;
