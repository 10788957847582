import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import "../../Configuration/SupplierCommissionBlock/AddSupplierCommissionBlockCard/AddSupplierCommissionBlockCard.css"

export default function SearchPendingQueue({searchClients}) {
  const [supplierName, setSupplierName] = useState("");
  const [approvalStage, setApprovalStage] = useState("");

  const handleSearch = async () => {

    // if(supplierName == "" || approvalStage == ""){
    //   return;
    // } else {
      searchClients(supplierName,approvalStage);
    // }
}
 
  useEffect(()=>{
 handleSearch();
  },[supplierName,approvalStage])
  

  return (
    <div>
      <div className="add-supplier-card-container">
        <div className="add-supplier-card-title">Search Pending Approval</div>
        <div className="create-supplier-contents-container">
          <div className="create-supplier-contents">
            <div className="add-supplier-if-items-container">
              {/* <div className="add-supplier-if-item margin-right-16">
              <div className="add-supplier-if-container">IF</div>
            </div> */}
              <div className="add-supplier-if-item flex-1-input margin-right-16">
                <ValidatorForm onError={(errors) => console.log(errors)}>
                  <TextValidator
                    variant="outlined"
                    label="Vendor Name or Client Name"
                    size="small"
                    color="secondary"
                    value={supplierName}
                    onChange={(event) => setSupplierName(event.target.value)}
                  />
                </ValidatorForm>
              </div>
              <div className="add-supplier-if-item flex-1-input margin-right-16">
                <ValidatorForm onError={(errors) => console.log(errors)}>
                  <TextValidator
                    variant="outlined"
                    label="Approval Stage"
                    size="small"
                    color="secondary"
                    value={approvalStage}
                    onChange={(event) => 
                      setApprovalStage(event.target.value.replace(/\s/g, ""))}
                  />
                </ValidatorForm>
              </div>
            </div>
          </div>
          {/* <div className="add-supplier-button-container">
          
            <div className="add-configuration-button mt-0px"
             onClick={() => handleSearch()} >Search</div>
          </div> */}
        </div>
      </div>
   
    </div>
  )
}
