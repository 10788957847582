import React, { useState, useEffect } from "react";
import "./ManageProfile.css";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { getAllPermissions, checkIfUserStaff, isClientUserHasPermission, getCurrentRole } from "../../services/AuthService";
import Chip from "@material-ui/core/Chip";
import { useHistory } from "react-router-dom";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import Button from "@mui/material/Button";

function ManageProfile() {
  const useStyles = makeStyles((theme) => ({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  }));
  const classes = useStyles();
  const [userinfo, setuserinfo] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [userRole, setUserRole] = useState("");

  async function getUser() {
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "connect/userinfo")
      .then((response) => {
        setuserinfo(response.data);
        getClients(response.data.clientref_id);
      });
  }
  async function getClients(getClientId) {
    let filter = "id==" + getClientId;
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile?sorts=created&filters=" +
        filter
      )
      .then((response) => {
        console.log(response);
        setClientName(response?.data[0]?.clientpforile.clientName);
      });
  }
  useEffect(() => {
    getUser();
    setUserRole(getCurrentRole());
  }, []);

  const getFullName = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user["full_name"] && user["full_name"].length > 0
      ? user["full_name"]
      : "?";
  };

  const getUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user["role"];
  };

  const history = useHistory();
  const openMarkupPage = () => {
    history.push("/AgentMarkup");
  };

  const openPassengerProfilePage = () => {
    history.push("/PassengerProfile");
  };


  return (
    <div className="manage-profile-container">
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <div className="profile-header-user-details-container">
            <div className="profile-header-user-details-avatar-container">
              <Avatar className="avatar-component">
                {getFullName().trim()[0]}
              </Avatar>
            </div>
            <div className="profile-header-user-details-detailed">
              <div className="profile-header-user-details-username">
                {getFullName()}
              </div>
              <div className="profile-header-user-details-user-role">
                {getUserRole()}
              </div>
            </div>
          </div>
          <div className="profile-item">
            <Grid container>
              <Grid item xs={6}>
                <div className="profile-item-title">User Details</div>
              </Grid>
              <Grid item xs={6}>
                <div className="profile-item-user-details-container">
                  <div className="profile-item-user-details">
                    <div className="profile-item-user-details-text">
                      Full Name
                    </div>
                    <div className="profile-item-user-details-subtext">
                      {userinfo ? userinfo.full_name : ""}
                    </div>
                  </div>
                  <div className="profile-item-user-details">
                    <div className="profile-item-user-details-text">
                      Username
                    </div>
                    <div className="profile-item-user-details-subtext">
                      {userinfo ? userinfo.preferred_username : ""}
                    </div>
                  </div>
                  <div className="profile-item-user-details">
                    <div className="profile-item-user-details-text">Email</div>
                    <div className="profile-item-user-details-subtext">
                      {userinfo ? userinfo.email_id : ""}
                    </div>
                  </div>
                  {/* <div className="profile-item-user-details">
                    <div className="profile-item-user-details-text">
                      Phone number
                    </div>
                    <div className="profile-item-user-details-subtext">
                      {userinfo ? 123456789 : ""}
                    </div>
                  </div> */}
                  <div className="profile-item-user-details">
                    <div className="profile-item-user-details-text">Role</div>
                    <div className="profile-item-user-details-subtext">
                      {userinfo ? userinfo.role : ""}
                    </div>
                  </div>
                  {userinfo && userinfo.clientref_id ? (
                    <div className="profile-item-user-details">
                      <div className="profile-item-user-details-text">
                        Client ID
                      </div>
                      <div className="profile-item-user-details-subtext">
                        {userinfo.clientref_id}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {clientName ? (
                    <div className="profile-item-user-details">
                      <div className="profile-item-user-details-text">
                        Client Name
                      </div>
                      <div className="profile-item-user-details-subtext">
                        {clientName}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="profile-item">
            <Grid container>
              <Grid item xs={6}>
                <div className="profile-item-title">Permissions</div>
                {!checkIfUserStaff() && (
                  <>
                    {(isClientUserHasPermission("IsMarkUp") || userRole === "ClientSuperAdmin") && (
                      <div onClick={openMarkupPage} className="d-flex justify-content-between markup-btn">
                        <div>
                          Mark-Up
                        </div>
                        <div>
                          <BadgeOutlinedIcon />
                        </div>
                      </div>
                    )}
                    <div onClick={openPassengerProfilePage} className="d-flex justify-content-between markup-btn">
                      <div>
                        Traveller Profile
                      </div>
                      <div>
                        <BadgeOutlinedIcon />
                      </div>
                    </div>


                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                {getAllPermissions() && <div className="profile-item-user-details-container profile-items-flex">
                  <div className="profile-item-user-details table-column-group">
                    {getAllPermissions() &&
                      getAllPermissions().map((row) => (
                        <Chip className="custom-role-chip" label={row} />
                      ))}
                  </div>
                </div>}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  );
}

export default ManageProfile;
