import React, { useEffect, useState } from "react";
import "./RoleDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
// import "react-multi-email/style.css";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function RoleDrawer({
  toggleDrawer,
  handleSnackbarToggle,
  getRoles,
}) {
  const [roleName, setroleName] = useState();

  const classes = useStyles();

  useEffect(() => {}, []);

  async function handleSubmit() {
    const headers = {
      "Content-Type": "application/json",
    };

    await axios
      .post(process.env.REACT_APP_IDENTITY_SERVER + "ids/Role/", roleName, {
        headers,
      })
      .then((res) => {
        console.log(res);
        handleSnackbarToggle();
      })
      .catch((err) => {
        console.log(err);
        handleSnackbarToggle();
      });

    // your submit logic
    getRoles();
  }

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <React.Fragment>
        <ValidatorForm
          onError={(errors) => console.log(errors)}
          onSubmit={handleSubmit}
        >
          <div className="configuration-heading-container configuration-title-flex-container">
            <div>Add New Role</div>
          </div>
          <div className="configuration-heading-container configuration-title-flex-container">
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <TextValidator
                variant="outlined"
                label="Role Name"
                size="small"
                color="secondary"
                value={roleName}
                onChange={(event) => setroleName(event.target.value)}
                validators={["required"]}
                errorMessages={["This Field is Required."]}
              />
            </div>
          </div>
          <div className="configuration-heading-container configuration-title-flex-container">
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                textTransform: "none",
              }}
              type="submit"
            >
              Add Role
            </Button>
          </div>
        </ValidatorForm>
      </React.Fragment>
    </div>
  );
}
