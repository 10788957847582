import React, { useEffect, useState, Fragment } from "react";
import "./BankInfoDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { FormControl, IconButton, InputLabel, MenuItem, NativeSelect, Select } from "@material-ui/core";
import moment from "moment";
import { TextField } from "@material-ui/core";
import countries from "../../../data/Country";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TimeZones from "../../../data/TimeZones";
import { AirlineSeatLegroomReducedSharp } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { checkIfUserStaff } from "../../../services/AuthService";
import Radio from "@material-ui/core/Radio";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import axios from "axios";


const useStyles = makeStyles({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
});
const statusList = ["true", "false"];
const ibossCtryList = ["IN", "US"];

export default function BankInfoDrawer({
    toggleDrawer,
    selectedItem,
    action,
    setAction,
    handleSnackbarToggle
}) {

    const classes = useStyles();

    const [id, setId] = useState(0);
    const [bankName, setBankName] = useState("");
    const [branch, setBranch] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [accountNumber, setAccountNumber] = useState(0);
    const [accountHolder, setAccountHolder] = useState("");
    const [ifscCode, setIfscCode] = useState("");
    const [address, setAddress] = useState("");
    const [bankCharges, setBankCharges] = useState(0);
    const [bankCountry, setBankCountry] = useState("");
    const [swiftNumber, setSwiftNumber] = useState("");
    const [remarks, setRemarks] = useState("");
    const [ibossCountry, setIbossCountry] = useState("");
    const [ibossBankCode, setIbossBankCode] = useState("");

    useEffect(() => {
        if (action === "edit" || action === "view") {
            setBankName(selectedItem?.bankInfo?.bankName);
            setBranch(selectedItem?.bankInfo?.branch);
            setIsActive(selectedItem?.bankInfo?.isActive);
            setId(selectedItem?.bankInfo?.id);
            setAccountNumber(selectedItem?.bankInfo?.accountNumber);
            setAccountHolder(selectedItem?.bankInfo?.accountHolder);
            setIfscCode(selectedItem?.bankInfo?.ifscCode);
            setAddress(selectedItem?.bankInfo?.address);
            setBankCharges(selectedItem?.bankInfo?.bankCharges);
            setSwiftNumber(selectedItem?.bankInfo?.swiftNumber);
            setBankCountry(selectedItem?.bankInfo?.countryCode);
            setIbossCountry(selectedItem?.bankInfo?.ibossCountry);
            setIbossBankCode(selectedItem?.bankInfo?.iBossBankId);
            setRemarks(selectedItem?.bankInfo?.remarks);
        } else {
            setBankName("");
            setBranch("");
            setIsActive(true);
            setId(0);
            setAccountNumber(0);
            setAccountHolder("");
            setIfscCode("");
            setAddress("");
            setBankCharges(0);
            setSwiftNumber("");
            setBankCountry("");
            setIbossCountry("");
            setIbossBankCode("");
            setRemarks("");
        }
    }, [selectedItem]);

    async function handleUpdate() {
        const headers = {
            "Content-Type": "application/json",
        };
        if (action === "edit" || action === "view") {
            await axios
                .put(
                    process.env.REACT_APP_CORE_URL +
                    "api/Configuration/Account/UpdateBankInfo/" + selectedItem?.bankInfo?.id,
                    JSON.stringify({
                        id: selectedItem?.bankInfo?.id,
                        isActive: String(isActive).toLowerCase() === "true" ? true : false,
                        createdBy: selectedItem?.bankInfo?.createdBy === undefined ? "" : selectedItem?.bankInfo?.createdBy,
                        created: selectedItem?.bankInfo?.created === undefined ? "" : selectedItem?.bankInfo?.created,
                        modifiedBy: "",
                        modified: selectedItem?.bankInfo?.created === undefined ? "" : selectedItem?.bankInfo?.created,
                        BankName: selectedItem?.bankInfo?.bankName === undefined ? "" : selectedItem?.bankInfo?.bankName,
                        Branch: branch === undefined ? "" : branch,
                        AccountNumber: accountNumber === undefined ? 0 : accountNumber,
                        AccountHolder: accountHolder === undefined ? "" : accountHolder,
                        IFSCCode: ifscCode === undefined ? "" : ifscCode,
                        Address: address === undefined ? "" : address,
                        BankCharges: bankCharges === undefined ? 0 : bankCharges,
                        SwiftNumber: swiftNumber === undefined ? "" : swiftNumber,
                        CountryCode: bankCountry === undefined ? "" : bankCountry,
                        IbossCountry: ibossCountry === undefined ? "" : ibossCountry,
                        IBossBankId: ibossBankCode === undefined ? "" : ibossBankCode,
                        Remarks: remarks === undefined ? "" : remarks
                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    handleSnackbarToggle();
                })
                .catch((err) => {
                    console.log(err);
                    handleSnackbarToggle();
                });
        }
        else {
            await axios
                .post(
                    process.env.REACT_APP_CORE_URL + "api/Configuration/Account/AddBankInfo",
                    JSON.stringify({
                        id: 0,
                        isActive: String(isActive).toLowerCase() === "true" ? true : false,
                        createdBy: "",
                        created: moment().format(),
                        modifiedBy: "",
                        modified: moment().format(),
                        BankName: bankName === undefined ? "" : bankName,
                        Branch: branch === undefined ? "" : branch,
                        AccountNumber: accountNumber === undefined ? 0 : accountNumber,
                        AccountHolder: accountHolder === undefined ? "" : accountHolder,
                        IFSCCode: ifscCode === undefined ? "" : ifscCode,
                        Address: address === undefined ? "" : address,
                        BankCharges: bankCharges === undefined ? 0 : bankCharges,
                        SwiftNumber: swiftNumber === undefined ? "" : swiftNumber,
                        CountryCode: bankCountry === undefined ? "" : bankCountry,
                        IbossCountry: ibossCountry === undefined ? "" : ibossCountry,
                        IBossBankId: ibossBankCode === undefined ? "" : ibossBankCode,
                        Remarks: remarks === undefined ? "" : remarks
                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    // console.log(res);
                    handleSnackbarToggle();
                })
                .catch((err) => {
                    console.log(err);
                    handleSnackbarToggle();
                });
        }
    }

    return (
        <div className="ticket-drawer-container">
            <div className="close-button-container">
                <CloseIcon
                    className="icon-fill pointer"
                    fontSize="medium"
                    onClick={toggleDrawer(false)}
                ></CloseIcon>
            </div>
            <div className="bankInfo-heading-container bankInfo-title-flex-container">
                <div>BankInfo</div>
                {action === "view" && (
                    <div className="bankInfo-heading-icons">
                        <IconButton fontSize="small">
                            <EditRoundedIcon
                                fontSize="small"
                                className="icon-blue"
                                onClick={() => setAction("edit")}
                            ></EditRoundedIcon>
                        </IconButton>
                    </div>
                )}
            </div>
            <ValidatorForm onError={(errors) => console.log(errors)} onSubmit={handleUpdate}>
                <div className="bankInfo-view-edit-drawer-container">
                    <div className="bankInfo-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Bank Name"
                                    size="small"
                                    color="secondary"
                                    value={bankName}
                                    onChange={(event) => setBankName(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                    required
                                />
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Branch"
                                    size="small"
                                    color="secondary"
                                    value={branch}
                                    onChange={(event) => setBranch(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                    required
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer margin-right-16 config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    Bank Name
                                </div>
                                <div className="view-bankInfo-drawer-description ">
                                    {bankName}
                                </div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    Branch
                                </div>
                                <div className="view-bankInfo-drawer-description">
                                    {branch}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="bankInfo-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Account Number"
                                    size="small"
                                    color="secondary"
                                    value={accountNumber}
                                    onChange={(event) => setAccountNumber(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                    required
                                />
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Account Holder"
                                    size="small"
                                    color="secondary"
                                    value={accountHolder}
                                    onChange={(event) => setAccountHolder(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                    required
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer margin-right-16 config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    Account Number
                                </div>
                                <div className="view-bankInfo-drawer-description ">
                                    {accountNumber}
                                </div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    Account Holder
                                </div>
                                <div className="view-bankInfo-drawer-description">
                                    {accountHolder}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="bankInfo-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="IFSC Code"
                                    size="small"
                                    color="secondary"
                                    value={ifscCode}
                                    onChange={(event) => setIfscCode(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                    required
                                />
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Address"
                                    size="small"
                                    color="secondary"
                                    value={address}
                                    onChange={(event) => setAddress(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                    required
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer margin-right-16 config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    IFSC Code
                                </div>
                                <div className="view-bankInfo-drawer-description ">
                                    {ifscCode}
                                </div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    Address
                                </div>
                                <div className="view-bankInfo-drawer-description ">
                                    {address}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="bankInfo-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Bank Charges"
                                    size="small"
                                    color="secondary"
                                    value={bankCharges}
                                    onChange={(event) => setBankCharges(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                    required
                                />
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input config-input-flex-1">
                                <Autocomplete
                                    id="country-select-demo"
                                    value={countries.find((v) => v.code === bankCountry) || null}
                                    onChange={(event, newValue) => {
                                        setBankCountry(newValue ? newValue.code : "");
                                    }}
                                    options={countries}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) =>
                                        option.label + " (" + option.code + ")"
                                    }
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option.label} ({option.code})
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            label="Country"
                                            value={bankCountry}
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                            validators={["required"]}
                                            errorMessages={["This Field is Required."]}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer margin-right-16 config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    Bank Charges
                                </div>
                                <div className="view-bankInfo-drawer-description ">
                                    {bankCharges}
                                </div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    Bank Country
                                </div>
                                <div className="view-bankInfo-drawer-description">
                                    {bankCountry}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="bankInfo-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Swift Number"
                                    size="small"
                                    color="secondary"
                                    value={swiftNumber}
                                    onChange={(event) => setSwiftNumber(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                    required
                                />
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Remarks"
                                    size="small"
                                    color="secondary"
                                    value={remarks}
                                    onChange={(event) => setRemarks(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                    required
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer margin-right-16 config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    Swift Number
                                </div>
                                <div className="view-bankInfo-drawer-description ">
                                    {swiftNumber}
                                </div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    Remarks
                                </div>
                                <div className="view-bankInfo-drawer-description">
                                    {remarks}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="bankInfo-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <Autocomplete
                                    id="ibosscountry-select"
                                    value={
                                        ibossCtryList.find((v) => v === ibossCountry) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        setIbossCountry(newValue ? newValue : "");
                                    }}
                                    options={ibossCtryList}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) =>
                                        option
                                    }
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            label="IBossCountry"
                                            value={ibossCountry}
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                            required
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="IBoss Bank Code"
                                    size="small"
                                    color="secondary"
                                    value={ibossBankCode}
                                    onChange={(event) => setIbossBankCode(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                    required
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer margin-right-16 config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    IBoss Country
                                </div>
                                <div className="view-bankInfo-drawer-description ">
                                    {ibossCountry}
                                </div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    IBoss Bank Code
                                </div>
                                <div className="view-bankInfo-drawer-description">
                                    {ibossBankCode}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="bankInfo-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <FormControl fullWidth>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Status
                                    </InputLabel>
                                    <NativeSelect
                                        variant="outlined"
                                        label="Status"
                                        size="small"
                                        color="secondary"
                                        value={isActive}
                                        onChange={(event) => setIsActive(event.target.value)}
                                    >
                                        <option value={true}>true</option>
                                        <option value={false}>false</option>

                                    </NativeSelect>
                                </FormControl>
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="bankInfo-view-edit-drawer-input config-input-flex-1">

                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer margin-right-16 config-input-flex-1">
                                <div className="view-bankInfo-drawer-title">
                                    Status
                                </div>
                                <div className="view-bankInfo-drawer-description ">
                                    {isActive !== undefined && isActive !== null && isActive.toString()}
                                </div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-bankInfo-drawer config-input-flex-1">

                            </div>
                        )}
                    </div>
                </div>
                {action !== "view" && (
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            style={{
                                textTransform: "none",
                                marginLeft: "25px",
                                marginRight: "25px",
                                marginBottom: "25px",
                                width: "500px"
                            }}
                            type="submit"
                        >
                            {action === "edit" ? "Update" : "Create"}
                        </Button>
                    </div>
                )}
            </ValidatorForm>
        </div>
    );
}
