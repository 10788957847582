import React, { Fragment, useState, useEffect, useRef } from "react";
import {
    Button,
    Container,
    Grid,
    Table,
    TableCell,
    TableRow,
    Divider,
    Paper,
} from "@material-ui/core";
import SearchBar from "../MyQueue/SearchBar/SearchBar";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { formatDate } from "../../services/dateFormatter";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom";
import RiseLoading from "../Loader/RiseLoader";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function OfflineImportReport() {
    let location = useLocation();
    const [DateFrom, setDateFrom] = useState();
    const [filters, setFilters] = useState([]);
    const [DateTo, setDateTo] = useState();
    const [inputDateFrom, setinputDateFrom] = useState();
    const [isPageNav, setPageNav] = useState(false);
    const [inputDateTo, setinputDateTo] = useState();
    const [validationmessage, setValidationMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const csvLink = useRef();
    const [exportresults, setExportResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [initialLoad, setInitialLoad] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [top] = useState(new URLSearchParams(location.search).get("top") ?? 8);
    const [skip, setSkip] = useState(
        new URLSearchParams(location.search).get("skip") ?? 0
    );
    const [q, setQ] = useState(
        new URLSearchParams(location.search).get("q") ?? ""
    );
    useEffect(() => {
        validateDate();
    }, [DateFrom, inputDateFrom, DateTo, inputDateTo]);

    let myqueueExportExcel = () => {
        setIsLoading(true);
        const filters = [
            {
                field: "DateFrom",
                value: moment(DateFrom, "DD/MM/YYYY").format("YYYY-MM-DD") + "T23:59:00.000Z",  // Correct internal value
                displayvalue: moment(DateFrom, "DD/MM/YYYY").format("DD/MM/YYYY"),  // Correct display value
            },
            {
                field: "DateTo",
                value: moment(DateTo, "DD/MM/YYYY").format("YYYY-MM-DD") + "T23:59:00.000Z",  // Correct internal value
                displayvalue: moment(DateTo, "DD/MM/YYYY").format("DD/MM/YYYY"),  // Correct display value
            }
        ];
        // const body = {
        //     q: clear === "clear" ? "" : term,
        //     top: top,
        //     skip: skiprecord,
        //     filters: updateFilter?.length ? updateFilter : filters,
        //   };
        const body = { q: q, top: 5000, skip: 0, filters: filters };
        const headers = { "Content-Type": "application/json" };
        axios
            .post(
                process.env.REACT_APP_CORE_URL + "Flights/Booking/GetBooking/v1/GetBookingReports",
                body,
                { headers }
            )
            .then((response) => {
                var resultlist = [];

                if (
                    response &&
                    response.data &&
                    response.data.passengerDetailsListInfos &&
                    response.data.passengerDetailsListInfos.length > 0
                ) {
                    response.data.passengerDetailsListInfos.forEach((item, index) => {
                        const CreatedOnDate =
                            item.createdonDate !== null
                                ? item.createdonDate.split("T")[0]
                                : null;
                        const CreatedOnTime =
                            item.createdonDate !== null
                                ? moment(item.createdonDate).utc().format("hh:mm:ss")
                                // ? (item.document.CreatedOnDate.split("T")[1]).split("Z")[0]
                                : null;
                        const onwardDate =
                            item.onwardDate !== null
                                ? item.onwardDate.split("T")[0]
                                : null;
                        const TicketedDate =
                            item.ticketedDate !== null
                                ? item.ticketedDate.split("T")[0]
                                : null;
                        const returnDate =
                            item.returnDate !== null
                                ? item.returnDate.split("T")[0]
                                : null;
                        let SalesData = {
                            // Adjust the field names according to your data mode
                            "Created Date": CreatedOnDate,
                            "Created Time": CreatedOnTime,
                            "Client Name": item.clientName,
                            "Ticketed Date": TicketedDate,
                            "Ticketed Month": item.ticketedMonth,
                            "Order Ref No": item.orderRefNumber,
                            "Booking Status": item.bookingStatus,
                            "Current Status": item.currentStatus,
                            "Gds PNR": item.gdsPNR,
                            "Supplier": item.supplier,
                            "PCC": item.pcc,
                            "Origin": item.origin,
                            "Origin Country": item.originCountry,
                            "Destination": item.destination,
                            "Destination Country": item.destinationCountry,
                            "Client Type": item.clientType,
                            "Vendor Name": item.vendorName,
                            "Country Code": item.countryCode,
                            "Journey Type": item.journeyType,
                            "Booking Type": item.bookingType,
                            "Onward Date": onwardDate,
                            "Return Date": returnDate,
                            "Fare Basis": item.fareBasis,
                            "Client IBoss": item.clientIboss,
                            "Booking Source": item.bookingSource,
                            "Vendor Amount": item.vendorAmount,
                            "Profit": item.profit,
                            "Total Fare": item.totalFare,
                            "Airline Name": item.airlineName,
                            "Airline PNR": item.airlinePNR,
                            "Cabin Class": item.cabinClass,
                            "Pax Count": item.paxCount,
                            "ETicketNumber": item.eTicketNumber,
                            "Passenger Name": item.passengerName,
                            "End-ClientName": item.clientName,
                            "End-ClientEmail": item.clientEmail,
                            "CaseId": item.caseId,
                            "SourceOfBooking": item.sourceOfBooking, // Assuming this field exists in the response
                            "SalesStaffName": item.salesStaffName,
                            "FOP": item.fop,

                        };
                        resultlist.push(SalesData);
                    });
                    setExportResults(resultlist);
                    setTimeout(() => {
                        setIsLoading(false);
                        csvLink.current.link.click();
                        setIsLoading((isLoading) => false);
                    }, 3000);
                } else {
                    setErrorMessage("No Data Found...");
                    setSnackbarOpen(true);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error.message);
                setIsLoading(false);
            });


    }
    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };
    // useEffect(() => {

    //     var skip = (currentPage - 1) * top;
    //     setSkip(skip);

    //     if (filters.length !== 0 && currentPage !== "1") {
    //         if (!isPageNav) {
    //             setCurrentPage(1);
    //         }

    //     }
    //     if (filters.length !== 0 && currentPage === "1" && isPageNav === true) {
    //         if (!isPageNav) {
    //             setCurrentPage(1);
    //         }

    //     }

    // }, [currentPage, isPageNav, top]);
    let postSearchHandler = (searchTerm, dateFrom, dateTo, serviceChange) => {
        if (validateDate()) {
            const updateFilter = filters.map((element) =>
                element.field === "DateFrom"
                    ? {
                        field: "DateFrom",
                        value:
                            dateFrom instanceof Date
                                ? moment(dateFrom).format("YYYY-MM-DD") + "T00:00:00.000Z"
                                : "",
                        displayvalue: dateFrom,
                    }
                    : element.field === "DateTo"
                        ? {
                            field: "DateTo",
                            value:
                                dateTo instanceof Date
                                    ? moment(dateTo).format("YYYY-MM-DD") + "T23:59:00.000Z"
                                    : "",
                            displayvalue: dateTo,
                        }
                        : element
            );

            if (filters.findIndex((x) => x.field === "DateFrom") === -1) {
                updateFilter.push({
                    field: "DateFrom",
                    value:
                        dateFrom instanceof Date
                            ? moment(dateFrom).format("YYYY-MM-DD") + "T00:00:00.000Z"
                            : "",
                    displayvalue: dateFrom,
                });
            }

            if (filters.findIndex((x) => x.field === "DateTo") === -1) {
                updateFilter.push({
                    field: "DateTo",
                    value:
                        dateTo instanceof Date
                            ? moment(dateTo).format("YYYY-MM-DD") + "T23:59:00.000Z"
                            : "",
                    displayvalue: dateTo,
                });
            }
            setQ((q) => searchTerm);
            setFilters((filters) => updateFilter);

        }
    };
    const validateDate = () => {
        let valid = true;
        if (
            (inputDateTo === undefined || inputDateTo === null) &&
            (inputDateFrom === null || inputDateFrom === undefined)
        ) {
            setValidationMessage("");
        } else if (
            new Date(inputDateFrom) !== null &&
            (inputDateTo === undefined || inputDateTo === null)
        ) {
            setValidationMessage("Please select To Date.");
            valid = false;
        } else if (
            (inputDateFrom === null || inputDateFrom === undefined) &&
            new Date(inputDateTo) !== null
        ) {
            setValidationMessage("Please select From Date.");
            valid = false;
        } else if (new Date(inputDateFrom) === new Date(inputDateTo)) {
            valid = true;
            setValidationMessage("");
        } else if (new Date(inputDateFrom) > new Date(inputDateTo)) {
            setValidationMessage("From date should not be greater than  To Date");
            valid = false;
        } else {
            setValidationMessage("");
        }
        return valid;
    };

    return (
        <Container className="Offline&ImportReports">
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackBarClose}
            >
                <Alert onClose={handleSnackBarClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <div className="trans-head-container">
                <div className="trans-heading">Offline & Import Client Detail Report</div>
            </div>
            <Grid container spacing={0} className="searchSticky">
                <Grid item xs={12}>
                    <Paper
                        style={{
                            padding: "10px",
                            paddingBottom: "20px",
                            marginTop: "10px",
                            borderRadius: "10px",
                        }}
                    >
                        <SearchBar
                            postSearchHandler={postSearchHandler}
                            q={q}
                            setQ={setQ}
                            DateFrom={DateFrom}
                            DateTo={DateTo}
                            inputDateTo={inputDateTo}
                            inputDateFrom={inputDateFrom}
                            setDateFrom={setDateFrom}
                            setDateTo={setDateTo}
                            setinputDateFrom={setinputDateFrom}
                            setinputDateTo={setinputDateTo}
                            filters={filters}
                            setPageNav={setPageNav}
                            setFilters={setFilters}
                            setCurrentPage={setCurrentPage}
                            isPageNav={isPageNav}
                            initialLoad={initialLoad}
                            flag={"OfflineImportReport"}
                            data={"ClientName,ClientId,VendorName,PCC"}
                            myqueueExportExcel={myqueueExportExcel}
                        ></SearchBar>
                        <div className="invalid">{validationmessage}</div>
                    </Paper>
                </Grid>
            </Grid>

            <div className="trans-client-container">

                <CSVLink
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                    data={exportresults}
                    filename="OfflineImportReport"
                />
                {isLoading && <RiseLoading excelExport={isLoading} />}
            </div>
        </Container>
    );
}


