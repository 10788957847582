import React, { useState, useEffect } from 'react';
import "./BookingSwitchesTable.css";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from '../../../../hooks/useConfigurationTableCell';
import useConfigurationTableRow from '../../../../hooks/useConfigurationTableRow';
import Paper from "@material-ui/core/Paper";


export default function BookingSwitchesTable({ openDrawer, setAction, bookingSwitches }) {
    const StyledTableRow = useConfigurationTableRow;
    const StyledTableCell = useConfigurationTableCell;
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <TableContainer component={Paper} className="table-paper-styles">
                <Table className="table-background">
                    <TableHead>
                        <TableRow>
                            <TableCell>Config Name</TableCell>
                            <TableCell>Search Supplier</TableCell>
                            <TableCell>Search PCC</TableCell>
                            <TableCell>Booking Supplier</TableCell>
                            <TableCell>Booking PCC</TableCell>
                            <TableCell align="right">Modified</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bookingSwitches !== undefined && bookingSwitches.length > 0 ?
                            bookingSwitches.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            ).map((config) => (
                                <StyledTableRow
                                    key={JSON.stringify(config.bookingSwitches)}
                                    onClick={() => {
                                        setAction("view");
                                        openDrawer(config.bookingSwitches);
                                    }}
                                >
                                    <StyledTableCell component="th" scope="row">
                                        <div className="table-cell-title">
                                            {config.bookingSwitches.bookingSwitchName}
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <div className="table-cell-title">
                                            {config.bookingSwitches.searchSupplier}
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <div className="table-cell-title">{config.bookingSwitches.searchPCC}</div>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <div className="table-cell-title">{config.bookingSwitches.bookingSupplier}</div>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <div className="table-cell-title">{config.bookingSwitches.bookingPCC}</div>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <div className="table-cell-description">Created by <span style={{color:"black"}}>{config.createdBy}</span></div>
                                        <div className="table-cell-description">
                                            created at {config.bookingSwitches.created.split("T")[0]}
                                        </div>
                                        <div className="table-cell-description">
                                            modified at {config.bookingSwitches.modified.split("T")[0]}
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                            :
                            <div>
                                loading...
                            </div>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={bookingSwitches.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(event, newPage) => handleChangePage(event, newPage)}
                onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
            />
        </div>
    );
}
