import React from "react";
import { Row } from "react-bootstrap";
import { getMiniRulesData, filterAdultCancellation, filterChildCancellation, filterInfantCancellation } from "../../functions/getMiniRulesData";


const Cancellation = ({ currentTicket, currencyCode, selectedItinerary }) => {
    return (
        <>
            {currentTicket.fareGroups[selectedItinerary].miniRules &&
                getMiniRulesData(currentTicket.fareGroups[selectedItinerary].miniRules, "Cancellation")
                    .length === 0 || currentTicket?.airline == "HR" ? (
                <div className="">
                    <table className="fareDetailsTable mb-3">
                        <tr>
                            <th className="top_headers">Pax</th>
                            <th className="top_headers">
                                Applicable For
                            </th>
                            <th className="top_headers">Amount</th>
                            <th className="top_headers">Remarks</th>
                        </tr>
                        <tr>
                            {currentTicket.adtNum >= 1 ? (
                                <>
                                    <td className="right">ADT</td>

                                    <td className="right">
                                        Before and After
                                    </td>

                                    <td className="right">-</td>
                                    <td className="right">
                                        Non-Refundable
                                    </td>
                                </>
                            ) : (
                                ""
                            )}
                        </tr>

                        <tr>
                            {currentTicket.chdNum >= 1 ? (
                                <>
                                    <td className="right">CHD</td>
                                    <td className="right">
                                        Before and After
                                    </td>
                                    <td className="right">-</td>
                                    <td className="right">
                                        Non-Refundable
                                    </td>
                                </>
                            ) : (
                                ""
                            )}
                        </tr>
                        <tr>
                            {currentTicket.infNum >= 1 ? (
                                <>
                                    <td className="right">INF</td>

                                    <td className="right">
                                        Before and After
                                    </td>

                                    <td className="right">-</td>
                                    <td className="right">
                                        Non-Refundable
                                    </td>
                                </>
                            ) : (
                                ""
                            )}
                        </tr>
                    </table>
                    <Row className="mx-0 pb-2">
                        <div className="col-12 flt-highlight-label px-0 py-1">
                            <span className="badge flt-imp-badge-label mx-2">
                                Important Note
                            </span>
                            <ul className="mt-2">
                                <li className="flt-imp-msg">
                                    Additional admin fee applicable.
                                </li>
                                <li className="flt-imp-msg">
                                    Please contact the operations team for
                                    further assistance and clarity on this
                                    if required.
                                </li>
                                <li className="flt-imp-msg">
                                    ROE (Rate of Exchange) is always based
                                    on the current rates.{" "}
                                </li>
                            </ul>
                        </div>
                    </Row>
                </div>
            ) : (
                <div>
                    {currentTicket.adtNum >= 1 ||
                        currentTicket.chdNum >= 1 ||
                        currentTicket.infNum >= 1 ? (
                        <div>
                            {currentTicket.adtNum >= 1 &&
                                currentTicket.fareGroups[selectedItinerary].miniRules &&
                                currentTicket.fareGroups[selectedItinerary].miniRules.length &&
                                currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                    filterAdultCancellation
                                ).length > 0 && (
                                    <>
                                        <div className="">
                                            <table className="fareDetailsTable mb-3">
                                                <tr>
                                                    <th className="top_headers">
                                                        Pax
                                                    </th>
                                                    <th className="top_headers">
                                                        Applicable For
                                                    </th>
                                                    <th className="top_headers">
                                                        Amount
                                                    </th>
                                                    <th className="top_headers">
                                                        Remarks
                                                    </th>
                                                </tr>
                                                {currentTicket.fareGroups[selectedItinerary].miniRules &&
                                                    currentTicket.fareGroups[selectedItinerary].miniRules.length &&
                                                    currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                        filterAdultCancellation
                                                    ).length > 0 &&
                                                    currentTicket.fareGroups[selectedItinerary].miniRules
                                                        .filter(
                                                            filterAdultCancellation
                                                        )
                                                        .map(
                                                            (
                                                                listItem,
                                                                index,
                                                                element
                                                            ) =>
                                                                listItem.apply ? (
                                                                    <tr>
                                                                        <>
                                                                            {index == 0 && (
                                                                                <th
                                                                                    className="left_headers"
                                                                                    width="10%"
                                                                                    rowSpan={
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.length &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                                                            filterAdultCancellation
                                                                                        ).length
                                                                                    }
                                                                                >
                                                                                    ADT
                                                                                </th>
                                                                            )}
                                                                            {/* <th className="left_headers" width="110" >{listItem.paxType}</th> */}
                                                                            <td
                                                                                className="left_headers"
                                                                                width="20%"
                                                                            >
                                                                                {listItem.apply}
                                                                            </td>
                                                                            {currentTicket.adtNum >=
                                                                                1 ? (
                                                                                <td
                                                                                    className="right"
                                                                                    width="10%"
                                                                                >
                                                                                    {!currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ]
                                                                                        .refundable &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                                                            filterAdultCancellation
                                                                                        ).length <= 0
                                                                                        ? "-"
                                                                                        : listItem?.canAmt !==
                                                                                            undefined &&
                                                                                            listItem?.canAmt !==
                                                                                            0
                                                                                            ? currencyCode +
                                                                                            "  " +
                                                                                            listItem?.canAmt
                                                                                            : listItem?.canAmt ===
                                                                                                0 &&
                                                                                                !currentTicket
                                                                                                    .fareGroups[
                                                                                                    selectedItinerary
                                                                                                ]
                                                                                                    .refundable
                                                                                                ? "-" :
                                                                                                listItem?.canAmt ===
                                                                                                    0 &&
                                                                                                    currentTicket
                                                                                                        .fareGroups[
                                                                                                        selectedItinerary
                                                                                                    ].refundable && listItem?.remarks
                                                                                                    ? 0
                                                                                                    : "-"}
                                                                                </td>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {currentTicket.adtNum >=
                                                                                1 ? (
                                                                                <td
                                                                                    className="right"
                                                                                    width="30%"
                                                                                >
                                                                                    {!currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ]
                                                                                        .refundable &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                                                            filterAdultCancellation
                                                                                        ).length <= 0 || !currentTicket
                                                                                            .fareGroups[
                                                                                            selectedItinerary
                                                                                        ]
                                                                                            .refundable ?
                                                                                        "Non Refundable"
                                                                                        : listItem?.remarks !=
                                                                                            undefined &&
                                                                                            listItem?.remarks !=
                                                                                            ""
                                                                                            ? listItem?.remarks
                                                                                            : listItem?.remarks !=
                                                                                                undefined &&
                                                                                                listItem?.remarks ==
                                                                                                ""
                                                                                                ? "NIL"
                                                                                                : "NIL"}
                                                                                </td>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </>
                                                                    </tr>
                                                                ) : (
                                                                    ""
                                                                )
                                                        )}
                                            </table>
                                        </div>
                                    </>
                                )}
                            {currentTicket.chdNum >= 1 &&
                                currentTicket.fareGroups[selectedItinerary].miniRules &&
                                currentTicket.fareGroups[selectedItinerary].miniRules.length &&
                                currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                    filterChildCancellation
                                ).length > 0 && (
                                    <>
                                        <div className="">
                                            <table className="fareDetailsTable mb-3">
                                                <tr>
                                                    <th className="top_headers">
                                                        Pax
                                                    </th>
                                                    <th className="top_headers">
                                                        Applicable For
                                                    </th>
                                                    <th className="top_headers">
                                                        Amount
                                                    </th>
                                                    <th className="top_headers">
                                                        Remarks
                                                    </th>
                                                </tr>
                                                {currentTicket.fareGroups[selectedItinerary].miniRules &&
                                                    currentTicket.fareGroups[selectedItinerary].miniRules.length &&
                                                    currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                        filterChildCancellation
                                                    ).length > 0 &&
                                                    currentTicket.fareGroups[selectedItinerary].miniRules
                                                        .filter(
                                                            filterChildCancellation
                                                        )
                                                        .map(
                                                            (
                                                                listItem,
                                                                index,
                                                                element
                                                            ) =>
                                                                listItem.apply ? (
                                                                    <tr>
                                                                        <>
                                                                            {index == 0 && (
                                                                                <th
                                                                                    className="left_headers"
                                                                                    width="10%"
                                                                                    rowSpan={
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.length &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                                                            filterChildCancellation
                                                                                        ).length
                                                                                    }
                                                                                >
                                                                                    CHD
                                                                                </th>
                                                                            )}
                                                                            {/* <th className="left_headers" width="110" >{listItem.paxType}</th> */}
                                                                            <td
                                                                                className="left_headers"
                                                                                width="20%"
                                                                            >
                                                                                {listItem.apply}
                                                                            </td>
                                                                            {currentTicket.chdNum >=
                                                                                1 ? (
                                                                                <td
                                                                                    className="right"
                                                                                    width="10%"
                                                                                >
                                                                                    {!currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ]
                                                                                        .refundable &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                                                            filterChildCancellation
                                                                                        ).length <= 0
                                                                                        ? "-"
                                                                                        : listItem?.canAmt !==
                                                                                            undefined &&
                                                                                            listItem?.canAmt !==
                                                                                            0
                                                                                            ? currencyCode +
                                                                                            "  " +
                                                                                            listItem?.canAmt
                                                                                            : listItem?.canAmt ===
                                                                                                0 &&
                                                                                                !currentTicket
                                                                                                    .fareGroups[
                                                                                                    selectedItinerary
                                                                                                ]
                                                                                                    .refundable
                                                                                                ? "-" :
                                                                                                listItem?.canAmt ===
                                                                                                    0 &&
                                                                                                    currentTicket
                                                                                                        .fareGroups[
                                                                                                        selectedItinerary
                                                                                                    ].refundable && listItem?.remarks
                                                                                                    ? 0
                                                                                                    : "-"}
                                                                                </td>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {currentTicket.chdNum >=
                                                                                1 ? (
                                                                                <td
                                                                                    className="right"
                                                                                    width="30%"
                                                                                >
                                                                                    {!currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ]
                                                                                        .refundable &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                                                            filterChildCancellation
                                                                                        ).length <= 0 || !currentTicket
                                                                                            .fareGroups[
                                                                                            selectedItinerary
                                                                                        ]
                                                                                            .refundable ?
                                                                                        "Non Refundable"
                                                                                        : listItem?.remarks !=
                                                                                            undefined
                                                                                            ? listItem?.remarks
                                                                                            : listItem?.remarks !=
                                                                                                undefined
                                                                                                ? "NIL"
                                                                                                : "NIL"}
                                                                                </td>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </>
                                                                    </tr>
                                                                ) : (
                                                                    ""
                                                                )
                                                        )}
                                            </table>
                                        </div>
                                    </>
                                )}
                            {currentTicket.infNum >= 1 &&
                                currentTicket.fareGroups[selectedItinerary].miniRules &&
                                currentTicket.fareGroups[selectedItinerary].miniRules.length &&
                                currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                    filterInfantCancellation
                                ).length > 0 && (
                                    <>
                                        <div className="">
                                            <table className="fareDetailsTable mb-3">
                                                <tr>
                                                    <th className="top_headers">
                                                        Pax
                                                    </th>
                                                    <th className="top_headers">
                                                        Applicable For
                                                    </th>
                                                    <th className="top_headers">
                                                        Amount
                                                    </th>
                                                    <th className="top_headers">
                                                        Remarks
                                                    </th>
                                                </tr>
                                                {currentTicket.fareGroups[selectedItinerary].miniRules &&
                                                    currentTicket.fareGroups[selectedItinerary].miniRules.length &&
                                                    currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                        filterInfantCancellation
                                                    ).length > 0 &&
                                                    currentTicket.fareGroups[selectedItinerary].miniRules
                                                        .filter(
                                                            filterInfantCancellation
                                                        )
                                                        .map(
                                                            (
                                                                listItem,
                                                                index,
                                                                element
                                                            ) =>
                                                                listItem.apply ? (
                                                                    <tr>
                                                                        <>
                                                                            {index == 0 && (
                                                                                <th
                                                                                    className="left_headers"
                                                                                    width="10%"
                                                                                    rowSpan={
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.length &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                                                            filterInfantCancellation
                                                                                        ).length
                                                                                    }
                                                                                >
                                                                                    INF
                                                                                </th>
                                                                            )}
                                                                            {/* <th className="left_headers" width="110" >{listItem.paxType}</th> */}
                                                                            <td
                                                                                className="left_headers"
                                                                                width="20%"
                                                                            >
                                                                                {listItem.apply}
                                                                            </td>
                                                                            {currentTicket.infNum >=
                                                                                1 ? (
                                                                                <td
                                                                                    className="right"
                                                                                    width="10%"
                                                                                >
                                                                                    {!currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ]
                                                                                        .refundable &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                                                            filterInfantCancellation
                                                                                        ).length <= 0
                                                                                        ? "-"
                                                                                        : listItem?.canAmt !==
                                                                                            undefined &&
                                                                                            listItem?.canAmt !==
                                                                                            0
                                                                                            ? currencyCode +
                                                                                            "  " +
                                                                                            listItem?.canAmt
                                                                                            : listItem?.canAmt ===
                                                                                                0 &&
                                                                                                !currentTicket
                                                                                                    .fareGroups[
                                                                                                    selectedItinerary
                                                                                                ]
                                                                                                    .refundable
                                                                                                ? "-" :
                                                                                                listItem?.canAmt ===
                                                                                                    0 &&
                                                                                                    currentTicket
                                                                                                        .fareGroups[
                                                                                                        selectedItinerary
                                                                                                    ].refundable && listItem?.remarks
                                                                                                    ? 0
                                                                                                    : "-"}
                                                                                </td>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {currentTicket.infNum >=
                                                                                1 ? (
                                                                                <td
                                                                                    className="right"
                                                                                    width="30%"
                                                                                >
                                                                                    {!currentTicket
                                                                                        .fareGroups[
                                                                                        selectedItinerary
                                                                                    ]
                                                                                        .refundable &&
                                                                                        currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                                                                            filterInfantCancellation
                                                                                        ).length <= 0 || !currentTicket
                                                                                            .fareGroups[
                                                                                            selectedItinerary
                                                                                        ]
                                                                                            .refundable ?
                                                                                        "Non Refundable"
                                                                                        : listItem?.remarks !=
                                                                                            undefined
                                                                                            ? listItem?.remarks
                                                                                            : listItem?.remarks !=
                                                                                                undefined
                                                                                                ? "NIL"
                                                                                                : "NIL"}
                                                                                </td>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </>
                                                                    </tr>
                                                                ) : (
                                                                    ""
                                                                )
                                                        )}
                                            </table>
                                        </div>
                                    </>
                                )}
                            {currentTicket.fareGroups[selectedItinerary].miniRules &&
                                currentTicket.fareGroups[selectedItinerary].miniRules.length &&
                                (currentTicket.fareGroups[selectedItinerary].miniRules.filter(filterAdultCancellation)
                                    .length > 0 ||
                                    currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                        filterChildCancellation
                                    ).length > 0 ||
                                    currentTicket.fareGroups[selectedItinerary].miniRules.filter(
                                        filterInfantCancellation
                                    ).length > 0) ? (
                                <Row className="mx-0 pb-2">
                                    <div className="col-12 flt-highlight-label px-0 py-1">
                                        <span className="badge flt-imp-badge-label mx-2">
                                            Important Note
                                        </span>
                                        <ul className="mt-2">
                                            <li className="flt-imp-msg">
                                                Additional admin fee applicable.
                                            </li>
                                            <li className="flt-imp-msg">
                                                Please contact the operations
                                                team for further assistance and
                                                clarity on this if required.
                                            </li>
                                            <li className="flt-imp-msg">
                                                ROE (Rate of Exchange) is always
                                                based on the current rates.{" "}
                                            </li>
                                        </ul>
                                    </div>
                                </Row>
                            ) : (
                                ""
                            )}
                        </div>
                    ) : (
                        <div className="">
                            <table className="fareDetailsTable mb-3">
                                <tr>
                                    <th className="top_headers"></th>
                                    {currentTicket.adtNum >= 1 ? (
                                        <td className="right">ADT</td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket.chdNum >= 1 ? (
                                        <td className="right">CHD</td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket.infNum >= 1 ? (
                                        <td className="right">INF</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <th
                                        className="left_headers"
                                        width="175"
                                    >
                                        Before Trip
                                    </th>
                                    {currentTicket.adtNum >= 1 ? (
                                        <td className="right">
                                            Non Refundable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket.chdNum >= 1 ? (
                                        <td className="right">
                                            Non Refundable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket.infNum >= 1 ? (
                                        <td className="right">
                                            Non Refundable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <th
                                        className="left_headers"
                                        width="175"
                                    >
                                        After Trip
                                    </th>
                                    {currentTicket.adtNum >= 1 ? (
                                        <td className="right">
                                            Non Refundable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket.chdNum >= 1 ? (
                                        <td className="right">
                                            Non Refundable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {currentTicket.infNum >= 1 ? (
                                        <td className="right">
                                            Non Refundable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                            </table>
                            <Row className="mx-0 pb-2">
                                <div className="col-12 flt-highlight-label px-0 py-1">
                                    <span className="badge flt-imp-badge-label mx-2">
                                        Important Note
                                    </span>
                                    <ul className="mt-2">
                                        <li className="flt-imp-msg">
                                            Additional admin fee applicable.
                                        </li>
                                        <li className="flt-imp-msg">
                                            Please contact the operations team
                                            for further assistance and clarity
                                            on this if required.
                                        </li>
                                        <li className="flt-imp-msg">
                                            ROE (Rate of Exchange) is always
                                            based on the current rates.{" "}
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default Cancellation