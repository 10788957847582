import React, { useEffect, useRef, useState } from "react";
import "./addPassengerDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import countries from "../../data/Country";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

export default function AddPassengerDrawer({
  toggleDrawer,
  selectedItem,
  action,
  handleSnackbarToggle,
  setPassengerTemplate,
  selectDate,
  initialBagagge,
  onwardSegments,
  returnSegments,
  passengers,
  index,
}) {
  const genders = ["Male", "Female", "Transgender"];
  const titles = ["Mr", "Mrs", "Ms", "Mx", "Mstr", "Miss"];
  const pax = ["ADT", "CHD", "INF"];
  const [title, settitle] = useState("");
  const [dob, setdob] = useState("");
  const [firstName, setfirstName] = useState("");
  const [genderType, setgenderType] = useState("");
  const [lastName, setlastName] = useState("");
  const [paxType, setpaxType] = useState("");
  const [ticketNumber, setticketNumber] = useState("");
  const [passportNumber, setpassportNumber] = useState("");
  const [baggages, setBaggages] = useState([{}]);
  // const [nationality, setnationality] = useState("");
  // const [handBaggage, sethandBaggage] = useState("");
  // const [purchaseBaggage, setpurchaseBaggage] = useState("");
  // const [cabinBaggage, setcabinBaggage] = useState("");
  const [formError, setformError] = useState(false);
  const [bagSegs, setBagSegs] = useState([]);
  const addSegmentForm = useRef();

  useEffect(() => {
    let segs = [];
    if (onwardSegments && onwardSegments.length > 0) {
      onwardSegments.map((segment) => {
        let segobj = segment.originAirport + segment.destinationAirport;
        segs.push(segobj);
      });
    }
    if (returnSegments && returnSegments.length > 0) {
      returnSegments.map((segment) => {
        let segobj = segment.originAirport + segment.destinationAirport;
        segs.push(segobj);
      });
    }
    setBagSegs((bagSegs) => segs);
    if (action === "edit" || action === "view") {
      let selected = structuredClone(passengers[index]);
      console.log(selected);
      if (selected) {
        setticketNumber((ticketNumber) =>
          selected.ticketNumber ? selected.ticketNumber : ""
        );
        setpassportNumber((passportNumber) =>
          selected.passportNumber ? selected.passportNumber : ""
        );
        // setnationality(
        //   selected.nationality ? selected.nationality : ""
        // );
        // sethandBaggage(selected.handBaggage ? selected.handBaggage : '');
        // setpurchaseBaggage(selected.purchaseBaggage ? selected.purchaseBaggage : '');
        // setcabinBaggage(selected.cabinBaggage ? selected.cabinBaggage : '');
        setpaxType((paxType) => (selected.paxType ? selected.paxType : ""));
        setlastName((lastName) => (selected.lastName ? selected.lastName : ""));
        setgenderType((genderType) =>
          selected.genderType === "M"
            ? "Male"
            : selected.genderType === "F"
              ? "Female"
              : selected.genderType === "T"
                ? "Transgender"
                : ""
        );

        setfirstName((firstName) =>
          selected.firstName ? selected.firstName : ""
        );
        setdob(
          selected.dob
            ? moment(new Date(selected.dob)).format("YYYY-MM-DD")
            : ""
        );
        setBaggages((baggages) => (selected.baggage ? selected.baggage : []));
        settitle((title) => (selected.title ? selected.title : "Mr"));
      }
    } else {
      resetForm();
    }
  }, [toggleDrawer, action]);

  async function handleUpdate() {
    // if (dob && ticketNumber && cabinBaggage) {
    if (
      title &&
      firstName.trim() &&
      genderType &&
      lastName.trim() &&
      paxType &&
      passportNumber.trim() &&
      ticketNumber.trim()
    ) {
      let passenger = {
        title: title,
        dob: dob,
        firstName: firstName,
        flightTicketStatus: "",
        genderType:
          genderType === "Male"
            ? "M"
            : genderType === "Female"
              ? "F"
              : genderType === "Transgender"
                ? "T"
                : "NA",
        id: 0,
        lastName: lastName,
        paxType: paxType,
        seatPref: "",
        ticketNumber: ticketNumber,
        passportNumber: passportNumber,
        baggage: baggages,
        inputType: "M",
      };

      await setPassengerTemplate(passenger);
      await resetForm();
      await handleSnackbarToggle();
    } else {
      setformError(true);
    }
  }

  const addBaggaeRow = () => {
    let temp = structuredClone(baggages);
    temp.push({
      checkInBag: "",
      cabinBag: "",
      cityPair: "",
      amount: 0,
    });
    setBaggages((baggages) => temp);
  };
  const deleteBaggageRow = (index) => {
    let temp = structuredClone(baggages);
    temp.splice(index, 1);
    setBaggages((baggages) => temp);
  };
  const handleBaggageEnter = (index, field, value) => {
    let temp = structuredClone(baggages);
    let bag = structuredClone(temp[index]);
    bag[field] = structuredClone(value);
    temp[index] = structuredClone(bag);
    setBaggages((baggages) => structuredClone(temp));
  };
  async function resetForm() {
    setticketNumber("");
    setpassportNumber("");
    // setnationality("");
    // sethandBaggage("");
    // setpurchaseBaggage("");
    // setcabinBaggage("");
    setpaxType("");
    setlastName("");
    setgenderType("");
    setfirstName("");
    setdob("");
    settitle("");
    setBaggages((baggages) => []);
    setformError(false);
  }

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <div className="vendor-heading-container vendor-title-flex-container">
        <div>Add Passenger</div>
      </div>
      <ValidatorForm
        // onSubmit={handleUpdate}
        // ref={addSegmentForm}
        onError={(errors) => console.log(errors)}
      >
        <div className="vendor-view-edit-drawer-container addPassenger">
          <div className="vendor-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="timezone-select-demo"
                  value={paxType}
                  onChange={(event, newValue) => {
                    setpaxType(newValue);
                    setdob("");
                  }}
                  required
                  options={pax}
                  // autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Pax Type"
                      value={paxType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      // inputProps={{
                      //   ...params.inputProps,
                      //   autoComplete: "new-password", // disable autocomplete and autofill
                      // }}
                      required
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="timezone-select-demo"
                  value={title}
                  onChange={(event, newValue) => {
                    settitle(newValue);
                  }}
                  options={titles}
                  required
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Title"
                      value={title}
                      variant="outlined"
                      required
                      color="secondary"
                      size="small"
                      // inputProps={{
                      //   ...params.inputProps,
                      //   autoComplete: "new-password", // disable autocomplete and autofill
                      // }}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <div className="vendor-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="First Name"
                  size="small"
                  color="secondary"
                  value={firstName}
                  required
                  onChange={(event) => setfirstName(event.target.value)}
                  validators={["matchRegexp:^[a-zA-Z ]+$", "matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$"]}
                  errorMessages={["Only Alphabets are allowed", "This field cannot be blank or contain only spaces"]}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Last Name"
                  size="small"
                  color="secondary"
                  value={lastName}
                  required
                  onChange={(event) => setlastName(event.target.value)}
                  validators={["matchRegexp:^[a-zA-Z ]+$", "matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$"]}
                  errorMessages={["Only Alphabets are allowed", "This field cannot be blank or contain only spaces"]}
                />
              </div>
            )}
          </div>
          <div className="vendor-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input margin-right-16  config-input-flex-1">
                <TextValidator
                  label="Date of birth"
                  placeholder=""
                  variant="outlined"
                  size="small"
                  color="secondary"
                  type="date"
                  value={dob}
                  validators={["required"]}
                  errorMessages={["required"]}
                  InputProps={{
                    inputProps: {
                      min:
                        paxType === "ADT"
                          ? ""
                          : paxType === "CHD"
                            ? selectDate.childLimit
                            : selectDate.infantLimit,
                      max:
                        paxType === "ADT"
                          ? selectDate.childLimit
                          : paxType === "CHD"
                            ? selectDate.infantLimit
                            : selectDate.today,
                    },
                  }}
                  onChange={(event) => {
                    console.log("date change", event.target.value);
                    setdob(event.target.value);
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input config-input-flex-1">
                <Autocomplete
                  id="timezone-select-demo"
                  value={genderType}
                  onChange={(event, newValue) => {
                    setgenderType(newValue);
                  }}
                  options={genders}
                  autoHighlight
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Gender"
                      value={genderType}
                      required
                      variant="outlined"
                      color="secondary"
                      size="small"
                    // inputProps={{
                    //   ...params.inputProps,
                    //   autoComplete: "new-password", // disable autocomplete and autofill
                    // }}
                    // validators={["required"]}
                    // errorMessages={["this field is required"]}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <div className="vendor-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Ticket Number/EMD"
                  size="small"
                  required
                  color="secondary"
                  value={ticketNumber}
                  onChange={(event) => setticketNumber(event.target.value)}
                  validators={["required", 'matchRegexp:^[0-9A-Za-z/]*$']}
                  errorMessages={["This field is required", "Only alphanumeric characters and '/' are allowed"]}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input  config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Passport Number"
                  size="small"
                  color="secondary"
                  value={passportNumber}
                  required
                  onChange={(event) => setpassportNumber(event.target.value)}
                  // required={isPassMandate}
                  validators={["matchRegexp:^[a-zA-Z0-9]+$"]}
                  errorMessages={["No special characters allowed"]}
                // validators={["required", "matchRegexp:^[a-zA-Z0-9]+$"]}
                // errorMessages={["required", "No special characters allowed"]}
                />
              </div>
            )}
          </div>
          {/* <div className="vendor-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Hand Baggage"
                  size="small"
                  color="secondary"
                  value={handBaggage}
                  onChange={(event) => sethandBaggage(event.target.value)}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input  config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="cabin Baggage"
                  size="small"
                  required
                  color="secondary"
                  value={cabinBaggage}
                  onChange={(event) => setcabinBaggage(event.target.value)}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </div>
            )}
          </div>
          <div className="vendor-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Purchase Baggage"
                  size="small"
                  color="secondary"
                  value={purchaseBaggage}
                  onChange={(event) => setpurchaseBaggage(event.target.value)}
                // validators={["required"]}
                // errorMessages={["this field is required"]}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="timezone-select-demo"
                  value={
                    nationality
                  }
                  onChange={(event, newValue) => {
                    setnationality(newValue);
                  }}
                  options={countries.filter(item => item.code !== 'All')}
                  autoHighlight
                  getOptionLabel={(option) => option === "" ? "" : option.label + " (" + option.code + ")"}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      required={passportNumber ? ["required"] : false}
                      label="Nationality"
                      value={nationality}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      // inputProps={{
                      //   ...params.inputProps,
                      //   autoComplete: "new-password", // disable autocomplete and autofill
                      // }}
                      validators={["required"] }
                      errorMessages={["this field is required"]}
                    />
                  )}
                />
              </div>
            )}
          </div> */}
          <div>
            <div className="addBagDiv">
              <div className="vendor-heading-container vendor-title-flex-container">
                Baggage
              </div>
              <Button
                variant="outlined"
                color="primary"
                className="addButton"
                // onClick={() => addPassenger(flightIndex)}
                onClick={() => {
                  addBaggaeRow();
                }}
              >
                Add
              </Button>
            </div>
            <Table>
              {/* <TableHead>
                <TableRow>
                  <TableCell>CheckIn Baggage</TableCell>
                  <TableCell>Cabin Baggage</TableCell>
                  <TableCell>City Pair</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                {baggages &&
                  baggages.length > 0 &&
                  baggages.map((bag, index) => (
                    <TableRow>
                      <TableCell>
                        <TextValidator
                          variant="outlined"
                          label="CheckIn Baggage"
                          size="small"
                          color="secondary"
                          value={bag.checkInBag}
                          onChange={(event) =>
                            handleBaggageEnter(
                              index,
                              "checkInBag",
                              event.target.value
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextValidator
                          variant="outlined"
                          label="Cabin Baggage"
                          size="small"
                          color="secondary"
                          value={bag.cabinBag}
                          onChange={(event) =>
                            handleBaggageEnter(
                              index,
                              "cabinBag",
                              event.target.value
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {/* <TextValidator
                        variant="outlined"
                        label="City Pair"
                        size="small"
                        color="secondary"
                        value={bag.cityPair}
                        onChange={(event) =>
                          handleBaggageEnter(
                            index,
                            "cityPair",
                            event.target.value
                          )
                        }
                      /> */}
                        <Autocomplete
                          id="timezone-select-demo"
                          value={bag.cityPair}
                          onChange={(event, newValue) =>
                            handleBaggageEnter(index, "cityPair", newValue)
                          }
                          options={bagSegs}
                          getOptionLabel={(option) =>
                            option === "" ? "" : option
                          }
                          required
                          autoHighlight
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label="City Pair"
                              value={bag.cityPair}
                              variant="outlined"
                              required
                              color="secondary"
                              size="small"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            // validators={["required"]}
                            // errorMessages={["this field is required"]}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <TextValidator
                          variant="outlined"
                          label="Amount"
                          size="small"
                          color="secondary"
                          type="number"
                          required
                          value={bag.amount}
                          onChange={(event) =>
                            handleBaggageEnter(
                              index,
                              "amount",
                              parseInt(event.target.value)
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          className="deleteIcon"
                          onClick={async () => {
                            await deleteBaggageRow(index);
                          }}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
        <div>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            // type="submit"
            style={{
              textTransform: "none",
              marginLeft: "25px",
              marginRight: "25px",
              marginBottom: "25px",
              width: "500px",
            }}
            onClick={() => handleUpdate()}
          >
            {action === "edit" ? "Update" : "Add"}
          </Button>
        </div>
      </ValidatorForm>
      {formError ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <span style={{ fontSize: "16px", color: "red", paddingLeft: "10px" }}>
            *Please fill all required fields
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
