import React, { useState } from "react";
import "./Configuration.css";
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CategoryCard from "./CategoryCard/CategoryCard";
import ConfigurationTable from "./ConfigurationTable/ConfigurationTable";
import Drawer from "@material-ui/core/Drawer";
import CategoryDrawer from "./CategoryDrawer/CategoryDrawer";
import ConfigurationDrawer from "./ConfigurationDrawer/ConfigurationDrawer";
import AddConfigurationCard from "./AddConfigurationCard/AddConfigurationCard";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Configuration() {
  const [searchCategory, setSearchCategory] = useState("");
  const [drawer, setDrawer] = useState(false);
  const [categoryDrawer, setCategoryDrawer] = useState(false);
  const [categoryEditDrawer, setCategoryEditDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedCategory, setSelectedCategory] = useState({
    categoryTitle: "Search - 2021",
    categoryDescription: "2021 US Best Category",
    createdBy: "Satheesh Kumar",
    createdOn: "May 18, 2021",
  });
  const [action, setAction] = useState("view");
  const [categoryAction, setCategoryAction] = useState("view");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const handleSnackbarToggle = () => {
    setDrawer(false);
    setCategoryEditDrawer(false);
    setSnackbarOpen(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const toggleCategoryEditDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setCategoryEditDrawer(value);
  };

  const toggleCategoryDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setCategoryDrawer(value);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
  };

  const handleSearchCategoryChange = (value) => {
    setSearchCategory(value);
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };
  return (
    <div className="configuration-container">
      <div className="configuration-content-container">
        <div
          className="category-drawer-opener"
          onClick={() => setCategoryDrawer(true)}
        >
          <ChevronRightRoundedIcon className="category-expand-icon-margin"></ChevronRightRoundedIcon>
        </div>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-configuration-container">
              <div className="configuration-table-container">
                <div className="configuration-title-add-button-container">
                  <div className="configuration-heading">
                    Configurations for {selectedCategory.categoryTitle}
                  </div>
                  <div className="search-icon-button-container">
                    <SearchIcon
                      fontSize="small"
                      className="search-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon>
                    <div
                      className="add-configuration-button mt-0px"
                      onClick={() => {
                        setAction("new");
                        setDrawer(true);
                      }}
                    >
                      Add New Configuration
                    </div>
                  </div>
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="configuration-card-container">
                    <AddConfigurationCard></AddConfigurationCard>
                  </div>
                </Collapse>
                <ConfigurationTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                ></ConfigurationTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <ConfigurationDrawer
          selectedItem={selectedItem}
          toggleDrawer={toggleDrawer}
          action={action}
          setAction={setAction}
          handleSnackbarToggle={handleSnackbarToggle}
        ></ConfigurationDrawer>
      </Drawer>
      <Drawer
        anchor="right"
        open={categoryEditDrawer}
        onClose={toggleCategoryEditDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <CategoryDrawer
          selectedItem={selectedCategory}
          toggleDrawer={toggleCategoryEditDrawer}
          action={categoryAction}
          setAction={setCategoryAction}
          handleSnackbarToggle={handleSnackbarToggle}
        ></CategoryDrawer>
      </Drawer>
      <Drawer
        anchor="left"
        open={categoryDrawer}
        onClose={toggleCategoryDrawer(false)}
        BackdropProps={{ invisible: true }}
        // variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="search-category-container">
          <div className="shadow-inner"></div>
          <div className="configuration-heading-container mt-8">
            <div className="configuration-heading">CATEGORIES</div>
            <div className="configuration-heading-icons">
              <IconButton fontSize="small">
                <EditRoundedIcon
                  fontSize="small"
                  className="icon-blue"
                  onClick={() => {
                    setCategoryAction("edit");
                    setCategoryDrawer(false);
                    setCategoryEditDrawer(true);
                  }}
                ></EditRoundedIcon>
              </IconButton>
              <IconButton fontSize="small">
                <AddRoundedIcon
                  fontSize="small"
                  className="icon-blue"
                  onClick={() => {
                    setCategoryAction("new");
                    setCategoryDrawer(false);
                    setCategoryEditDrawer(true);
                  }}
                ></AddRoundedIcon>
              </IconButton>
            </div>
          </div>
          <div className="pr-pl-24 mt-16">
            <ValidatorForm onError={(errors) => console.log(errors)}>
              <TextValidator
                label="Search Category"
                variant="filled"
                size="small"
                color="secondary"
                value={searchCategory}
                onChange={(event) =>
                  handleSearchCategoryChange(event.target.value)
                }
              />
            </ValidatorForm>
          </div>
          <div className="pr-pl-24 mt-16">
            <CategoryCard selected={false}></CategoryCard>
            <CategoryCard selected={true}></CategoryCard>
            <CategoryCard selected={false}></CategoryCard>
          </div>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {`Setting has been ${action === "new" ? "created" : "updated"}`}
        </Alert>
      </Snackbar>
    </div>
  );
}
