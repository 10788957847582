import React, { useState, useEffect } from "react";
import "./MyQueue.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import MyQueueTable from "./MyQueueTable/MyQueueTable";
import MyQueueDrawer from "./MyQueueDrawer/MyQueueDrawer";
import axios from "axios";

import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../services/AuthService";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MyQueue() {
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("new");
  const [categoryAction, setCategoryAction] = useState("view");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [pccList, setPccList] = useState([]);
  const [vendorList, setVendor] = useState([]);
  const [clientList, setClient] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (isUserHasPermission("MyBookingQueueRead")) {
      loadCategories();
      loadPCCDetails();
      loadVendor();
      loadClient();
    } else {
      history.push("/Unauthorized");
    }
  }, []);

  async function loadPCCDetails() {
    let vendorArray = [];
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          vendorArray.push({
            id: response.data[i].bookingSwitches.id,
            name: response.data[i].bookingSwitches.vendorName,
          });
        }
        axios
          .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
          .then((response) => {
            let tmpArray = [];
            tmpArray.push({ value: "All", label: "All" });
            for (var i = 0; i < response.data.length; i++) {
              if (
                vendorArray.some(
                  (item) => item.id === response.data[i].pccConfigs.vendorId
                )
              ) {
                tmpArray.push({
                  value: response.data[i].pccConfigs.pccCode,
                  label:
                    response.data[i].pccConfigs.pccCode +
                    " (" +
                    vendorArray.find(
                      (st) => st.id === response.data[i].pccConfigs.vendorId
                    ).name +
                    ")",
                });
              } else {
                tmpArray.push({
                  value: response.data[i].pccConfigs.pccCode,
                  label: response.data[i].pccConfigs.pccCode,
                });
              }
            }
            console.log(tmpArray);
            setPccList(tmpArray);
          });
      });
  }

  async function loadCategories() {
    let filter = "";
    if (name !== "") filter = "?filters=name@=" + name;

    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/MyBookingQueue" +
        filter
      )
      .then((response) => {
        response.data.map((item) => {
          if (item.myBookingQueue.isActive) {
            item.myBookingQueue.status = "active";
          } else {
            item.myBookingQueue.status = "inactive";
          }
        });
        setCategories(response.data);
      })
      .catch((error) => console.log(error));
  }

  async function loadVendor() {
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
      .then((response) => {
        let tmpArray = [];
        tmpArray.push({ id: "0", name: "All" });
        for (var i = 0; i < response.data.length; i++) {
          tmpArray.push({
            id: response.data[i].bookingSwitches.id,
            name: response.data[i].bookingSwitches.vendorName,
          });
        }
        setVendor(tmpArray);
      })
      .catch((error) => console.log(error));
  }

  async function loadClient() {
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/ClientProfile")
      .then((response) => {
        let tmpArray = [];
        tmpArray.push({ id: "0", name: "All" });
        for (var i = 0; i < response.data.length; i++) {
          if (response.data[i].clientpforile.isActive) {
            tmpArray.push({
              id: response.data[i].clientpforile.id,
              name: response.data[i].clientpforile.clientName,
            });
          }
        }
        setClient(tmpArray);
      })
      .catch((error) => console.log(error));
  }

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };

  const handleSnackbarToggle = () => {
    setDrawer(false);
    setSnackbarOpen(true);
    loadCategories();
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="search-container">
      <div className="search-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-search-container">
              <div className="search-table-container">
                <div className="search-title-add-button-container">
                  <div className="search-heading">My Queues</div>
                  <div className="search-icon-button-container">
                    {isUserHasPermission("MyBookingQueueWrite") ? (
                      <div
                        className="add-search-button mt-0px"
                        onClick={() => {
                          setAction("new");
                          setDrawer(true);
                        }}
                      >
                        Add New Queue
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <MyQueueTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                  categories={categories}
                ></MyQueueTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <MyQueueDrawer
          selectedItem={selectedItem}
          toggleDrawer={toggleDrawer}
          action={action}
          setAction={setAction}
          handleSnackbarToggle={handleSnackbarToggle}
          pccList={pccList}
          vendorList={vendorList}
          clientList={clientList}
        ></MyQueueDrawer>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {`Setting has been ${action === "new" ? "created" : "updated"}`}
        </Alert>
      </Snackbar>
    </div>
  );
}
