import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import TopUp from "./TopUp";
import RechargeTransactions from "./RechargeTransactions";
import "./TopUp.css";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PaymentStatus from "../../data/paymentStatus";
import Grid from "@mui/material/Grid";
import axios from "axios";
import CurrencyCodeComponent from "../../providers/CurrencyCode";
import getTransactionDetails from "../../functions/GetTransactionDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function WalletTopUp() {
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useState({
    paymentId: "",
    paymentStatus: "",
    rechargeRefNo: "",
    pgRefNo: "",
    amount: 0,
    currency: "",
    failureMessage: "",
  });
  const location = useLocation();
  console.log("location", location);
  React.useEffect(() => {
    if (location.pathname.split("/").length === 3) {
      let temp = location.pathname.split("/");
      getTransactionDetails(temp[2]).then((response) => {
        setState((prev) => {
          return {
            ...prev,
            paymentId: response.paymentId,
            paymentStatus: response.paymentStatus,
            rechargeRefNo: response.rechargeRefNo,
            pgRefNo: response.pgRefNo,
            amount: response ? response.amount : 0,
            currency: response ? response.currency : "",
            failureMessage: response ? response.failureMessage : "",
          };
        });
        setOpen(true);
        console.log("paymentState", state);
      });
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    window.history.replaceState(null, null, `${window.location.origin}/Recharge`);
    setOpen(false);
  };
  return (
    <Grid container className=" topupContainer p-2">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3
            className={PaymentStatus[state.paymentStatus]}
            style={{ textAlign: "center" }}
          >
            {" "}
            Top up {PaymentStatus[state.paymentStatus]}{" "}
          </h3>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <p style={{ color: "#000" }} id="alert-dialog-description">
            your wallet top up {PaymentStatus[state.paymentStatus]} for amount{" "}
            <span className="walletTopupAmountText">
              <strong>
                <CurrencyCodeComponent
                  style={{ float: "none" }}
                  currencyCode={state.currency}
                />{" "}
                {state.amount}
              </strong>
            </span>
          </p>
          <p style={{ color: "#000" }} id="alert-dialog-description">
            Order Reference: <strong>{state.paymentId}</strong>
            <br />
            TransactionID: <strong>{state.pgRefNo}</strong>
            <br />
            {PaymentStatus[state.paymentStatus] === "Success" && state.rechargeRefNo ? (
              <>
                Recharge Ref No: <strong>{state.rechargeRefNo}</strong>
              </>
            ) : (
              <>
                Fail reason: <strong>{state.failureMessage}</strong>
              </>
            )}
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            autoFocus
            sx={{ textTransform: "capitalize" }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        style={{ Height: "100%", Width: "100%" }}
        sx={{ flexGrow: 1, display: "flex" }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            Width: "100%",
            backgroundColor: "#cfcccc47",
            borderRight: 1,
            borderColor: "white",
            borderWidth: "5px",
            flexGrow: 1,
          }}
        >
          <Tab
            className={value === 0 ? "tab-select" : "tab-unselect"}
            label="Top Up My Wallet"
            {...a11yProps(0)}
          >
            <Button variant="contained">Top Up My Wallet</Button>
          </Tab>
          <Tab
            className={value === 1 ? "tab-select" : "tab-unselect"}
            label="Recharge Transactions Details"
            {...a11yProps(1)}
          >
            <Button variant="contained">Recharge Transactions Details</Button>
          </Tab>
        </Tabs>
        <TabPanel value={value} index={0}>
          <TopUp paymentState={state} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RechargeTransactions />
        </TabPanel>
      </Box>
    </Grid>
  );
}
