import React, { useEffect } from "react";
import { Button } from "@mui/joy";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@material-ui/core";
import dayjs from "dayjs";
import moment from "moment";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import cabinClass from "../../data/CabinClass";
import SampleItineraryText from "../../data/SampleItineraryText";
dayjs.extend(utc); // Use the UTC plugin
const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const bookingToCabinMap = {
  F: "First",
  R: "First",
  A: "First",
  P: "First",
  C: "Business",
  I: "Business",
  D: "Business",
  J: "Business",
  Z: "Business",
};
const OfflineItineraryParser = ({
  toggleItineraryDrawer,
  setItineraryDrawer,
  type,
  setOnwardSegments,
  setReturnSegments,
  setItineraries, setItineraryText, itineraries, itineraryText, error, setError
}) => {

  const textFile = new Blob([SampleItineraryText], { type: "text/plain" });
  const downloadLink = URL.createObjectURL(textFile);
  useEffect(() => {
    setItineraryText((prevState) => '');
    setItineraries([]);
  }, [])
  useEffect(() => {
    console.log("itineraries", itineraries);
  }, [itineraries]);
  function checkAndCleanArrivalTime(arrivalTime) {

    const specialFormatRegex = /^#?\d{4}(A\+1|P\+1)?$/;
    if (specialFormatRegex.test(arrivalTime)) {
      const cleanedTime = arrivalTime.replace(/[#AP]\+1/g, "");
      return cleanedTime.match(/\d+/)[0];
    } else {
      return null;
    }
  }

  const combineDateAndTime = (toDate, timeStamp, isNextDayArrival) => {
    // debugger;
    const currentDate = dayjs();
    const currentMonth = currentDate.month();
    let date = Number(toDate.slice(0, 2));
    let monthString = toDate.slice(2, 5);
    let month = months.indexOf(monthString.toUpperCase());
    let year = Number(currentDate.year());
    let hours, minutes;
    hours = parseInt(timeStamp.slice(0, 2));
    minutes = parseInt(timeStamp.slice(2, 4));
    if (isNextDayArrival) {
      date = date + 1;
    }
    let combinedDateTime = currentDate
      .set("hour", hours)
      .set("minute", minutes)
      .set("month", month)
      .set("year", year)
      .set("date", date);
    // debugger;
    // if (combinedDateTime.month() < currentMonth) {
    //   combinedDateTime = combinedDateTime.set("year", year + 1);
    // }
    return combinedDateTime;
  };

  const convertToStandardTime = (timeStamp, Date, isNextDayArrival) => {
    // debugger;
    if (timeStamp) {
      const isSpecialFormat = /^#?\d{4}(A\+1)?$/.test(timeStamp);
      let resultDateTime = null;
      if (isSpecialFormat) {
        let time = checkAndCleanArrivalTime(timeStamp);
        resultDateTime = combineDateAndTime(Date, time, isNextDayArrival);
      } else {
        resultDateTime = combineDateAndTime(Date, timeStamp, isNextDayArrival);
      }
      // debugger;
      return resultDateTime;
    } else {
      return null;
    }
  };

  const parseItinerary = (lines, parser) => {
    setError("");
    let parsedData = [];
    const datePattern = /\b\d{1,2}[A-Z]{3}\b/ig;
    const airportPattern = /([A-Z]{3})([A-Z]{3})/i;
    const timePattern = /#?\d{3,4}[APap]?(?:\+1)?/g;
    const itineraryLinePattern = /^[A-Z]{1}[A-Z0-9]{1}\s?\d+/;

    for (let line of lines) {
      line = line.replace(/^\d+(\.\s*|\s+)/, '').trim();
      if (!line) continue;
      let airlineCode = null;
      let flightNumber = null;
      let bookingClassCode = null;
      if (!itineraryLinePattern.test(line)) continue;
      const flightMatch = line.match(/\b([A-Z]{2})\s*(\d{1,4})\s*([A-Z]?)\b/);
      if (flightMatch) {
        airlineCode = flightMatch[1];
        flightNumber = flightMatch[2];
        bookingClassCode = flightMatch[3] || null;
        line = line.replace(flightMatch[0], '').trim();
      }
      // debugger;
      const dateMatches = line.match(datePattern);
      const departureDate = dateMatches ? dateMatches[0] : null;
      const arrivalDate = dateMatches && dateMatches[1] ? dateMatches[1] : departureDate;
      const timeMatches = line.match(timePattern);
      let departureTime = null;
      let arrivalTime = null;
      if (timeMatches && timeMatches.length >= 1) {
        departureTime = timeMatches[0];
        arrivalTime = timeMatches.length > 1 ? timeMatches[1] : departureTime;
      }
      let isNextDayArrival = false;
      if (arrivalTime && arrivalTime.includes('#')) {
        arrivalTime = arrivalTime.replace('#', '');
        isNextDayArrival = true;
      }
      if (arrivalTime && arrivalTime.includes('+1')) {
        arrivalTime = arrivalTime.replace('+1', '');
        isNextDayArrival = true;
      }
      // debugger;
      departureTime = formatTime(departureTime);
      arrivalTime = formatTime(arrivalTime);
      const airportMatches = line.match(airportPattern);
      const departureAirport = airportMatches ? airportMatches[1] : null;
      const destinationAirport = airportMatches ? airportMatches[2] : null;
      // debugger;
      const formattedDepartureDateTime = convertToStandardTime(departureTime, departureDate, false);
      const adjustedArrivalDateTime = convertToStandardTime(arrivalTime, arrivalDate, isNextDayArrival);
      const cabinClassLabel = bookingToCabinMap[bookingClassCode] || "Economy";
      const cabinClassValue = cabinClass.find(v => v.label === cabinClassLabel)?.value;
      const data = {
        airline: airlineCode,
        flightNo: flightNumber,
        bookingClass: bookingClassCode,
        cabinClass: cabinClassValue,
        cabinClassLabel: cabinClassLabel,
        depTime: formattedDepartureDateTime,
        arrTime: adjustedArrivalDateTime,
        depDate: formattedDepartureDateTime,
        arrDate: adjustedArrivalDateTime,
        originAirport: departureAirport,
        destinationAirport: destinationAirport,
        paxCount: 1,
        airlinePNR: "",
        gdsPNR: "",
      };
      parsedData.push(data);
    }

    return parsedData.filter(itinerary => itinerary !== null);
  };
  const formatTime = (time) => {
    // debugger;
    if (!time) return null;
    let isPM = time.toLowerCase().includes('p');
    let isAM = time.toLowerCase().includes('a');
    let formattedTime;
    let hours;
    let minutes;
    if (time.length === 4) {
      if (time.includes('P') || time.includes('A') || time.includes('p') || time.includes('a')) {
        hours = time.slice(0, time.length - 3);
        minutes = time.slice(1, 3);
      }
      else {
        hours = time.slice(0, time.length - 2);
        minutes = time.slice(-2);
      }
      if (isPM && hours !== '12') {
        hours = parseInt(hours) + 12;
      } else if (isAM && hours === '12') {
        hours = '0';
      }
    } if (time.length === 3) {
      hours = time.slice(0, time.length - 3);
      minutes = time.slice(1, -1);
      if (isPM && hours !== '12') {
        hours = parseInt(hours) + 12;
      } else if (isAM && hours === '12') {
        hours = '0';
      }
      formattedTime = `${hours}:${minutes}`;
    } else if (time.length === 5) {
      hours = time.slice(0, time.length - 3);
      minutes = time.slice(2, -1);
      if (isPM && hours !== '12') {
        hours = parseInt(hours) + 12;
      } else if (isAM && hours === '12') {
        hours = '0';
      }
    } else if (time.length > 5) {
      hours = time.slice(0, time.length - 4);
      minutes = time.slice(2, -2);
      if (isPM && hours !== '12') {
        hours = parseInt(hours) + 12;
      } else if (isAM && hours === '12') {
        hours = '0';
      }
    }
    if (hours.length === 1) {
      hours = '0' + hours;
    }
    formattedTime = `${hours}${minutes}`;
    // debugger;
    return formattedTime;
  };

  const parseItineraryText = () => {
    if (itineraryText && itineraryText.length > 0) {
      const lines = itineraryText.split("\n");
      let parsedData = [];
      const parser = {
        name: "Amadeus",
        identifier: "",
      };
      parsedData = parseItinerary(lines, parser);
      console.log("parsedData", parsedData);
      // const currentDate = dayjs();
      // const pastItinerary = parsedData.some(itinerary => itinerary.depDate.isBefore(currentDate));
      // if (pastItinerary) {
      //   setError("The itinerary contains a past date. Please enter a valid itinerary text.");
      //   setItineraries([]);
      // } else {
      if (parsedData.length === 0) {
        setError("No valid itineraries found. Please check the format of your text.");
        setItineraries([]);
      } else {
        setError(null);
        setItineraries(parsedData);
      }
      // }
    }
  };

  const addItienaryToSegments = () => {
    if (itineraries.length > 0) {
      if (type === "onward") {
        setOnwardSegments((onwardSegments) => [...itineraries]);
      } else {
        setReturnSegments((returnSegments) => [...itineraries]);
      }
      setItineraryDrawer(false);
    }
  };

  return (
    <Paper
      style={{
        padding: "16px",
        marginBottom: "16px",
        maxWidth: "80vw",
        overflowX: "auto",
      }}>
      <div className='segment-drawer-container'>
        <div className='vendor-heading-container vendor-title-flex-container'>
          <div className='' style={{ width: '100%', textAlign: 'center' }}>
            <CloseIcon
              className='icon-fill pointer'
              style={{ float: 'left' }}
              fontSize='medium'
              onClick={toggleItineraryDrawer(false)}>
            </CloseIcon>{" "}
            Parse GDS Itinerary
          </div>
        </div>
        <div style={{ padding: "0 16px 0px 24px", marginTop: "16px" }}>
          <p style={{ textTransform: "capitalize", color: "red" }}>
            {" "}
            Note: the system is limitted to parse the Onward / Return Itinerary
            Texts in seperation for All GDS
          </p>
          <p style={{ textTransform: "capitalize", color: "red" }}>
            Ensure to Enter Onward / return Itinerary Text in respective
            sections
          </p>
          <p style={{ textTransform: "capitalize", color: "red" }}>
            Ensure to Enter Valid Itinerary Text in a valid format.
          </p>
          <p style={{ textTransform: "capitalize", color: "red" }}>
            Ensure to Enter only Itinerary Text in the area.
          </p>
          <br />
          <textarea
            rows='5'
            cols='50'
            value={itineraryText}
            onChange={(e) => setItineraryText(e.target.value)}
            placeholder='Paste itinerary text here'
          />
          <br />
          <Button
            variant='outlined'
            className="parseItineraryBtn"
            disabled={!itineraryText || itineraryText?.length === 0}
            onClick={() => parseItineraryText(itineraryText)}>
            Parse Itinerary
          </Button>
          {itineraries && itineraries?.length > 0 ? (
            <Button
              variant='outlined'
              style={{
                float: "right",
                marginLeft: "10px",
                color: "#d63384",
                borderColor: "#d63384",
              }}
              onClick={addItienaryToSegments}>
              Add to Segment Data
            </Button>
          ) : (
            ""
          )}
          <br />
          <br />
          {error && <p style={{ color: "red" }}>{error}</p>}
          <br />
          {(itineraries.length > 0 && !error) && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Airline</TableCell>
                  <TableCell>Flight No</TableCell>
                  <TableCell>Origin Airport</TableCell>
                  <TableCell>Destination Airport</TableCell>
                  <TableCell>Departure</TableCell>
                  <TableCell>Arrival</TableCell>
                  <TableCell>Cabin Class</TableCell>
                  <TableCell>Booking Class</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itineraries.map((itinerary, index) => (
                  <TableRow key={`${index + "qw"}`}>
                    <TableCell>{itinerary.airline}</TableCell>
                    <TableCell>{itinerary.flightNo}</TableCell>
                    <TableCell>{itinerary.originAirport}</TableCell>
                    <TableCell>{itinerary.destinationAirport}</TableCell>
                    <TableCell>
                      {
                        itinerary?.depDate && moment(itinerary?.depDate?.$d).format("DD/MM/YYYY") +
                        " " +
                        moment(itinerary?.depDate?.$d).format("HH:mm")
                      }
                    </TableCell>
                    <TableCell>
                      {itinerary?.arrDate && moment(itinerary?.arrDate?.$d).format("DD/MM/YYYY") +
                        " " +
                        moment(itinerary?.arrDate?.$d).format("HH:mm")}
                    </TableCell>
                    <TableCell>{itinerary.cabinClassLabel}</TableCell>
                    <TableCell>{itinerary.bookingClass}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default OfflineItineraryParser;
