import React, { useEffect, useState } from "react";
import "./ConfigurationDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { IconButton } from "@material-ui/core";

export default function ConfigurationDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
}) {
  const [originCountry, setOriginCountry] = useState("");
  const [destinationCountry, setDestinationCountry] = useState("");
  const [originAirport, setOriginAirport] = useState("");
  const [destinationAirport, setDestinationAirport] = useState("");
  const [exOriginCountry, setExOriginCountry] = useState("");
  const [exDestinationCountry, setExDestinationCountry] = useState("");
  const [exOriginAirport, setExOriginAirport] = useState("");
  const [exDestinationAirport, setExDestinationAirport] = useState("");
  const [cabinClass, setCabinClass] = useState("");
  const [tripType, setTripType] = useState("");
  const [supplier, setSupplier] = useState("");
  const [pcc, setPcc] = useState("");
  const [included, setIncluded] = useState("");
  const [excluded, setExcluded] = useState("");
  const [accountCode, setAccountCode] = useState("");
  const [corporateCode, setCorporateCode] = useState("");
  const [ifDirectSearch, setIfDirectSearch] = useState(false);
  const [thenDirectSearch, setThenDirectSearch] = useState(false);

  useEffect(() => {
    if (action === "edit" || action === "view") {
      setOriginCountry("India");
      setDestinationCountry("United States");
      setOriginAirport("All Airports");
      setDestinationAirport("All Airports");
      setExOriginCountry("India");
      setExDestinationCountry("United States");
      setExOriginAirport("All Airports");
      setExDestinationAirport("All Airports");
      setCabinClass("Economy");
      setTripType("Direct Search");
      setSupplier("Sabre");
      setPcc("U4JR");
      setIncluded("BA, AA, AI");
      setExcluded("BA, AA, AI");
      setAccountCode("1234");
      setCorporateCode("56789");
    } else {
      setOriginCountry("");
      setDestinationCountry("");
      setOriginAirport("");
      setDestinationAirport("");
      setExOriginCountry("");
      setExDestinationCountry("");
      setExOriginAirport("");
      setExDestinationAirport("");
      setCabinClass("");
      setTripType("");
      setSupplier("");
      setPcc("");
      setIncluded("");
      setExcluded("");
      setAccountCode("");
      setCorporateCode("");
    }
  }, [toggleDrawer, action]);

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <div className="configuration-heading-container configuration-title-flex-container">
        <div>Configuration</div>
        {action === "view" && (
          <div className="configuration-heading-icons">
            <IconButton fontSize="small">
              <EditRoundedIcon
                fontSize="small"
                className="icon-blue"
                onClick={() => setAction("edit")}
              ></EditRoundedIcon>
            </IconButton>
          </div>
        )}
      </div>
      <div className="configuration-view-edit-drawer-container">
        <div className="configuration-view-edit-drawer-if-container">
          <div className="configuration-view-edit-drawer-if-line"></div>
          <div>IF</div>
          <div className="configuration-view-edit-drawer-if-line"></div>
        </div>
        <div className="configuration-view-edit-drawer-input-container">
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Origin Country"
                  size="small"
                  color="secondary"
                  value={originCountry}
                  onChange={(event) => setOriginCountry(event.target.value)}
                />
              </ValidatorForm>
            </div>
          )}
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Ex Origin Country"
                  size="small"
                  color="secondary"
                  value={exOriginCountry}
                  onChange={(event) => setExOriginCountry(event.target.value)}
                />
              </ValidatorForm>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Origin Country
              </div>
              <div className="view-configuration-drawer-description ">
                {originCountry}
              </div>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Ex Origin Country
              </div>
              <div className="view-configuration-drawer-description">
                {exOriginCountry}
              </div>
            </div>
          )}
        </div>
        <div className="configuration-view-edit-drawer-input-container">
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Destination Country"
                  size="small"
                  color="secondary"
                  value={destinationCountry}
                  onChange={(event) =>
                    setDestinationCountry(event.target.value)
                  }
                />
              </ValidatorForm>
            </div>
          )}
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Ex Destination Country"
                  size="small"
                  color="secondary"
                  value={exDestinationCountry}
                  onChange={(event) =>
                    setExDestinationCountry(event.target.value)
                  }
                />
              </ValidatorForm>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Destination Country
              </div>
              <div className="view-configuration-drawer-description ">
                {destinationCountry}
              </div>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Ex Destination Country
              </div>
              <div className="view-configuration-drawer-description">
                {exDestinationCountry}
              </div>
            </div>
          )}
        </div>
        <div className="configuration-view-edit-drawer-input-container">
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Origin Airport"
                  size="small"
                  color="secondary"
                  value={originAirport}
                  onChange={(event) => setOriginAirport(event.target.value)}
                />
              </ValidatorForm>
            </div>
          )}
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Ex Origin Airport"
                  size="small"
                  color="secondary"
                  value={exOriginAirport}
                  onChange={(event) => setExOriginAirport(event.target.value)}
                />
              </ValidatorForm>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Origin Airport
              </div>
              <div className="view-configuration-drawer-description ">
                {originAirport}
              </div>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Ex Origin Airport
              </div>
              <div className="view-configuration-drawer-description">
                {exOriginAirport}
              </div>
            </div>
          )}
        </div>
        <div className="configuration-view-edit-drawer-input-container">
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Destination Airport"
                  size="small"
                  color="secondary"
                  value={destinationAirport}
                  onChange={(event) =>
                    setDestinationAirport(event.target.value)
                  }
                />
              </ValidatorForm>
            </div>
          )}
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Ex Destination Airport"
                  size="small"
                  color="secondary"
                  value={exDestinationAirport}
                  onChange={(event) =>
                    setExDestinationAirport(event.target.value)
                  }
                />
              </ValidatorForm>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Destination Airport
              </div>
              <div className="view-configuration-drawer-description ">
                {destinationAirport}
              </div>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Ex Destination Airport
              </div>
              <div className="view-configuration-drawer-description">
                {exDestinationAirport}
              </div>
            </div>
          )}
        </div>
        <div className="configuration-view-edit-drawer-input-container">
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Cabin Class"
                  size="small"
                  color="secondary"
                  value={cabinClass}
                  onChange={(event) => setCabinClass(event.target.value)}
                />
              </ValidatorForm>
            </div>
          )}
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Trip Type"
                  size="small"
                  color="secondary"
                  value={tripType}
                  onChange={(event) => setTripType(event.target.value)}
                />
              </ValidatorForm>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
              <div className="view-configuration-drawer-title">Cabin Class</div>
              <div className="view-configuration-drawer-description ">
                {cabinClass}
              </div>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer config-input-flex-1">
              <div className="view-configuration-drawer-title">Trip Type</div>
              <div className="view-configuration-drawer-description">
                {tripType}
              </div>
            </div>
          )}
        </div>
        <div className="configuration-view-edit-drawer-if-container config-margin-top-32">
          <div className="configuration-view-edit-drawer-if-line"></div>
          <div>THEN</div>
          <div className="configuration-view-edit-drawer-if-line"></div>
        </div>
        <div className="configuration-view-edit-drawer-input-container">
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Supplier"
                  size="small"
                  color="secondary"
                  value={supplier}
                  onChange={(event) => setSupplier(event.target.value)}
                />
              </ValidatorForm>
            </div>
          )}
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="PCC"
                  size="small"
                  color="secondary"
                  value={pcc}
                  onChange={(event) => setPcc(event.target.value)}
                />
              </ValidatorForm>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
              <div className="view-configuration-drawer-title">Supplier</div>
              <div className="view-configuration-drawer-description ">
                {supplier}
              </div>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer config-input-flex-1">
              <div className="view-configuration-drawer-title">PCC</div>
              <div className="view-configuration-drawer-description">{pcc}</div>
            </div>
          )}
        </div>
        <div className="configuration-view-edit-drawer-input-container">
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Included"
                  size="small"
                  color="secondary"
                  value={included}
                  onChange={(event) => setIncluded(event.target.value)}
                />
              </ValidatorForm>
            </div>
          )}
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Excluded"
                  size="small"
                  color="secondary"
                  value={excluded}
                  onChange={(event) => setExcluded(event.target.value)}
                />
              </ValidatorForm>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
              <div className="view-configuration-drawer-title">Included</div>
              <div className="view-configuration-drawer-description ">
                {included}
              </div>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer config-input-flex-1">
              <div className="view-configuration-drawer-title">Excluded</div>
              <div className="view-configuration-drawer-description">
                {excluded}
              </div>
            </div>
          )}
        </div>
        <div className="configuration-view-edit-drawer-input-container">
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Account Code"
                  size="small"
                  color="secondary"
                  value={accountCode}
                  onChange={(event) => setAccountCode(event.target.value)}
                />
              </ValidatorForm>
              <p>
                *Pls use <span className="text-danger">"~"</span> instead of{" "}
                <span className="text-danger">Coma</span> for multiple Account
                codes
              </p>
            </div>
          )}
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Corporate Code"
                  size="small"
                  color="secondary"
                  value={corporateCode}
                  onChange={(event) => setCorporateCode(event.target.value)}
                />
              </ValidatorForm>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Account Code
              </div>
              <div className="view-configuration-drawer-description ">
                {accountCode}
              </div>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Corporate Code
              </div>
              <div className="view-configuration-drawer-description">
                {corporateCode}
              </div>
            </div>
          )}
        </div>
      </div>
      {action !== "view" && (
        <div
          className="add-configuration-button-container-update-new"
          onClick={() => handleSnackbarToggle()}
        >
          <div className="add-configuration-button-container-update-new-button">
            {action === "edit" ? "Update" : "Create"}
          </div>
        </div>
      )}
    </div>
  );
}
