import React, { useState, useEffect } from "react";
import "./Reco.css";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import RecoDetails from "./RecoDetails/RecoDetails";
import Drawer from "@material-ui/core/Drawer";
import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../services/AuthService";
import RiseLoading from "../../Loader/RiseLoader";
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function Reco() {
  const [drawer, setDrawer] = useState(false);
  const [fsh, setFsh] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1);
    loadFlightSearchDetails();
  };

  useEffect(() => {
    if (isUserHasPermission("FlightReconcilliationRead")) {
      loadFlightSearchDetails();
    } else {
      history.push("/Unauthorized");
    }
  }, []);

  async function loadFlightSearchDetails() {
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };

    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/FlightReconcilliation",
        JSON.stringify({
          pageSize: pageSize,
          pageNumber: pageNumber,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        setShowLoader(false);
        console.log(response.data.flightRecos);
        setTotalCount(response.data.count);
        setFsh(response.data.flightRecos);
      });
    setShowLoader(false);
  }

  function handleViewPage(id) {
    history.push("/FSHDetails?id=" + id);
  }

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };

  return (
    <Container style={{ marginTop: "20px" }}>
      <div className="search-heading" style={{ marginBottom: "10px" }}>
        Flight Reconcilliation
      </div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Airline</TableCell>
              <TableCell align="right">ClientName</TableCell>
              <TableCell align="right">VendorName</TableCell>
              <TableCell align="right">Order Ref Id</TableCell>
              <TableCell align="right">Vendor Ref Id</TableCell>
              <TableCell align="right">Client Total</TableCell>
              <TableCell align="right">Vendor Total</TableCell>
              <TableCell align="right">RevenueType</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fsh.map((row) => (
              <StyledTableRow
                key={JSON.stringify(row)}
                onClick={() => openDrawer(row)}
              >
                <TableCell align="right">{row.validatingAirline}</TableCell>
                <TableCell align="right">{row.clientName}</TableCell>
                <TableCell align="right">{row.vendorName}</TableCell>
                <TableCell align="right">{row.orderRefId}</TableCell>
                <TableCell align="right">{row.vendorRefId}</TableCell>
                <TableCell align="right">{row.clientTotal}</TableCell>
                <TableCell align="right">{row.vendorTotal}</TableCell>
                <TableCell align="right">Profit</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50]}
        component="div"
        count={totalCount}
        rowsPerPage={pageSize}
        page={pageNumber - 1}
        onChangePage={(event, newPage) => handleChangePage(event, newPage)}
      />
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <RecoDetails
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
          ></RecoDetails>
        </div>
      </Drawer>
      {showLoader && <RiseLoading Search={showLoader} />}
    </Container>
  );
}

export default Reco;
