import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";
import getTotalFare from "../../functions/getTotalFare";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export default function BrandedFareTable({
  result, search,
  selectedItinerary,
  setSelectedItinerary,
}) {
  const BootstrapTooltip1 = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const ispriorityAvailable = result.fareGroups[0].pricedDescription.find(
    (s) => {
      if (s.type === "AirportPriority") return true;
    }
  );

  // console.log(ispriorityAvailable)

  return (
    <div className="brandFareDetailsDiv">
      <Table className="fullHeight fareDetailsTable mb-3">
        <TableHead>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              <p className="fs14 textTransCap">Brand Type</p>
            </TableCell>
            {result.fareGroups.map((item, index) => (
              item.pricedDescription.length > 0 && item.fares.length > 0 ? (
                <TableCell
                  key={`brand-type-${index}`}
                  className="fareDetailsHeadCell"
                  onClick={() => setSelectedItinerary(index)}
                >
                  <p className="fs14 textTransCap">
                    {item?.priceClass?.toLowerCase()}
                  </p>
                </TableCell>
              ) : null
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">Selection</TableCell>
            {result.fareGroups.map((item, index) => (
              item.pricedDescription.length > 0 && item.fares.length > 0 ? (
                <TableCell
                  key={`selection-${index}`}
                  className="fareDetailsBodyCell"
                  onClick={() => setSelectedItinerary(index)}
                >
                  {index === selectedItinerary ? (
                    <p className="fs16 textTransCap ">&#9989;</p>
                  ) : (
                    <p className="fs14 textTransCap colorBlack">
                      <AdjustIcon style={{ width: "18px" }} />
                    </p>
                  )}
                </TableCell>
              ) : null
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              <p className="fs14 textTransCap">Fares</p>
            </TableCell>
            {result.fareGroups.map((item, index) => (
              item.pricedDescription.length > 0 && item.fares.length > 0 ? (
                <TableCell
                  key={`fares-${index}`}
                  className="fareDetailsBodyCell"
                >
                  <p className="fs14 textTransCap">
                    {getTotalFare(
                      item,
                      result.adtNum,
                      result.chdNum,
                      result.infNum
                    ).total.toFixed(2)}
                  </p>
                </TableCell>
              ) : null
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              <p className="fs14 textTransCap">Carry On</p>
            </TableCell>
            {result.fareGroups.map((fare, index) => (
              fare?.pricedDescription && fare?.pricedDescription?.length > 0 ? (
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {fare?.pricedDescription &&
                      fare?.pricedDescription?.length &&
                      fare?.pricedDescription?.filter((obj) => obj.type === "CarryOn")
                        .length > 0 ? (
                      fare?.pricedDescription
                        .filter((obj) => obj.type === "CarryOn")
                        .map((item) => (
                          <>
                            <div className="d-flex justify-content-center align-items-center">
                              <div>
                                {item.type === "CarryOn" &&
                                  (result.vendor === "1A" ||
                                    result.vendor === "1S" ||
                                    result.vendor === "1G") ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "CarryOn" &&
                                  item.status === "Included" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "CarryOn" &&
                                  item.status === "Chargeable" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "CarryOn" &&
                                  item.status === "NotIncluded" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "CarryOn" &&
                                  (item.status === "None" ||
                                    (item.status !== "Included" &&
                                      item.status !== "Chargeable" &&
                                      item.status !== "NotIncluded")) && (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.description}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </div>
                              {
                                search.type === 'RoundTrip' ?
                                  <div>
                                    {
                                      // item.type === "CarryOn" &&
                                      //   (result.vendor === "1A" ||
                                      //     result.vendor === "1S" ||
                                      //     result.vendor === "1G") ? (
                                      //   <BootstrapTooltip1
                                      //     title={item.returnDescription}
                                      //   >
                                      //     <p className="fs14 textTransCap colorGreen">
                                      //       &#10004;
                                      //     </p>
                                      //   </BootstrapTooltip1>
                                      // ) : 
                                      item.type === "CarryOn" &&
                                        item.returnStatus === "Included" ? (
                                        <BootstrapTooltip1
                                          title={item.returnDescription}
                                        >
                                          <p className="fs14 textTransCap colorGreen">
                                            &#10004;
                                          </p>
                                        </BootstrapTooltip1>
                                      ) : item.type === "CarryOn" &&
                                        item.returnStatus === "Chargeable" ? (
                                        <BootstrapTooltip1
                                          title={item.returnDescription}
                                        >
                                          <p className="fs14 textTransCap colorRed">
                                            &#36;
                                          </p>
                                        </BootstrapTooltip1>
                                      ) : item.type === "CarryOn" &&
                                        item.returnStatus === "NotIncluded" ? (
                                        <BootstrapTooltip1
                                          title={item.returnDescription}
                                        >
                                          <p className="fs14 textTransCap colorBlack">
                                            &#10799;
                                          </p>
                                        </BootstrapTooltip1>
                                      ) : (
                                        item.type === "CarryOn" &&
                                        (item.returnStatus === "None" ||
                                          (item.returnStatus !== "Included" &&
                                            item.returnStatus !== "Chargeable" &&
                                            item.returnStatus !== "NotIncluded")) && (
                                          <BootstrapTooltip1
                                            title={item.returnDescription}
                                          >
                                            <p className="fs14 textTransCap colorBlack">
                                              {item.returnDescription}
                                            </p>
                                          </BootstrapTooltip1>
                                        )
                                      )}
                                  </div>
                                  : ''
                              }
                            </div>
                          </>
                        ))
                    ) : (
                      <>
                        {result.content === "GDS" ? (
                          <p className="fs14 textTransCap colorGreen">&#10004;</p>
                        ) : (
                          <p className="fs14 textTransCap colorBlack">&#10799;</p>
                        )}
                      </>
                    )}
                  </>
                </TableCell>) : null
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              <p className="fs14 textTransCap">Checked Bag</p>
            </TableCell>
            {result.fareGroups.map((fare, index) => (
              fare?.pricedDescription && fare?.pricedDescription?.length > 0 ? (
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {result?.vendor === "G9" && fare?.priceClass === "Basic" ? (
                      <p className="fs14 textTransCap colorBlack">&#10799;</p>
                    ) : fare?.pricedDescription &&
                      fare?.pricedDescription?.length &&
                      fare?.pricedDescription?.filter(
                        (obj) => obj.type === "CheckInBag"
                      ).length > 0 ? (
                      fare?.pricedDescription
                        .filter((obj) => obj.type === "CheckInBag")
                        .map((item) => (
                          <>
                            <div className="d-flex justify-content-center align-items-center">
                              <div>
                                {item.type === "CheckInBag" &&
                                  item.status === "Included" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "CheckInBag" &&
                                  item.status === "Chargeable" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "CheckInBag" &&
                                  item.status === "NotIncluded" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "CheckInBag" &&
                                  (item.status === "None" ||
                                    (item.status !== "Included" &&
                                      item.status !== "Chargeable" &&
                                      item.status !== "NotIncluded")) && (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.description}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </div>
                              <div>
                                {item.type === "CheckInBag" &&
                                  item.returnStatus === "Included" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "CheckInBag" &&
                                  item.returnStatus === "Chargeable" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "CheckInBag" &&
                                  item.returnStatus === "NotIncluded" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "CheckInBag" &&
                                  (item.returnStatus === "None" ||
                                    (item.returnStatus !== "Included" &&
                                      item.returnStatus !== "Chargeable" &&
                                      item.returnStatus !== "NotIncluded")) && (
                                    <BootstrapTooltip1
                                      title={item.returnDescription}
                                    >
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.returnDescription}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        ))
                    ) : (
                      <>
                        {result.vendor !== "G9" && fare?.baggages ? (
                          fare?.baggages.filter((item) => item.paxType === "ADT")
                            .length > 0 ? (
                            <p className="fs14 textTransCap colorGreen">
                              &#10004;
                            </p>
                          ) : (
                            <p className="fs14 textTransCap colorBlack">
                              &#10799;
                            </p>
                          )
                        ) : (
                          <p className="fs14 textTransCap colorBlack">&#10799;</p>
                        )}
                      </>
                    )}
                  </>
                </TableCell>) : null
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              <p className="fs14 textTransCap">Seat Selection</p>
            </TableCell>
            {result.fareGroups.map((fare, index) => (
              fare?.pricedDescription && fare?.pricedDescription?.length > 0 ? (
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {fare?.pricedDescription &&
                      fare?.pricedDescription?.length &&
                      fare?.pricedDescription?.filter((obj) => obj.type === "Seat")
                        .length > 0 ? (
                      fare?.pricedDescription
                        .filter((obj) => obj.type === "Seat")
                        .map((item) => (
                          <>
                            <div className="d-flex justify-content-center align-items-center">
                              <div>
                                {item.type === "Seat" &&
                                  item.status === "Included" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Seat" &&
                                  item.status === "Chargeable" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Seat" &&
                                  item.status === "NotIncluded" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "Seat" &&
                                  (item.status === "None" ||
                                    (item.status !== "Included" &&
                                      item.status !== "Chargeable" &&
                                      item.status !== "NotIncluded")) && (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.description}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </div>
                              <div>
                                {item.type === "Seat" &&
                                  item.returnStatus === "Included" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Seat" &&
                                  item.returnStatus === "Chargeable" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Seat" &&
                                  item.returnStatus === "NotIncluded" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "Seat" &&
                                  (item.returnStatus === "None" ||
                                    (item.returnStatus !== "Included" &&
                                      item.returnStatus !== "Chargeable" &&
                                      item.returnStatus !== "NotIncluded")) && (
                                    <BootstrapTooltip1
                                      title={item.returnDescription}
                                    >
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.returnDescription}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        ))
                    ) : (
                      <p className="fs14 textTransCap colorBlack">&#10799;</p>
                    )}
                  </>
                </TableCell>) : null
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              <p className="fs14 textTransCap">Date Change</p>
            </TableCell>
            {result.fareGroups.map((fare, index) => (
              fare?.pricedDescription && fare?.pricedDescription?.length > 0 ? (
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {result?.vendor === "G9" && fare?.priceClass === "Basic" ? (
                      <p className="fs14 textTransCap colorBlack">&#10799;</p>
                    ) : fare?.pricedDescription &&
                      fare?.pricedDescription?.length &&
                      fare?.pricedDescription?.filter(
                        (obj) => obj.type === "ReBooking"
                      ).length > 0 ? (
                      fare?.pricedDescription
                        .filter((obj) => obj.type === "ReBooking")
                        .map((item) => (
                          <>
                            <div className="d-flex justify-content-center align-items-center">
                              <div>
                                {item.type === "ReBooking" &&
                                  item.status === "Included" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "ReBooking" &&
                                  item.status === "Chargeable" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "ReBooking" &&
                                  item.status === "NotIncluded" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "ReBooking" &&
                                  (item.status === "None" ||
                                    (item.status !== "Included" &&
                                      item.status !== "Chargeable" &&
                                      item.status !== "NotIncluded")) && (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.description}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </div>
                              <div>
                                {item.type === "ReBooking" &&
                                  item.returnStatus === "Included" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "ReBooking" &&
                                  item.returnStatus === "Chargeable" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "ReBooking" &&
                                  item.returnStatus === "NotIncluded" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "ReBooking" &&
                                  (item.returnStatus === "None" ||
                                    (item.returnStatus !== "Included" &&
                                      item.returnStatus !== "Chargeable" &&
                                      item.returnStatus !== "NotIncluded")) && (
                                    <BootstrapTooltip1
                                      title={item.returnDescription}
                                    >
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.returnDescription}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        ))
                    ) : (
                      <>
                        {fare.miniRules.find(
                          (item) =>
                            item.paxType === "ADT" &&
                            item.changeAllowed === true &&
                            item.apply === "Before" &&
                            item.exgAmt > 0
                        ) ? (
                          <p className="fs14 textTransCap colorRed">&#36;</p>
                        ) : fare.miniRules.find(
                          (item) =>
                            item.paxType === "ADT" &&
                            item.changeAllowed === true &&
                            item.apply === "Before" &&
                            item.exgAmt === 0
                        ) ? (
                          <p className="fs14 textTransCap colorGreen">&#10004;</p>
                        ) : (
                          <p className="fs14 textTransCap colorBlack">&#10799;</p>
                        )}
                      </>
                    )}
                  </>
                </TableCell>) : null
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              <p className="fs14 textTransCap">Cancellation</p>
            </TableCell>
            {result.fareGroups.map((fare, index) => (
              fare?.pricedDescription && fare?.pricedDescription?.length > 0 ? (
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {result.vendor === "G9" ? (
                      <p className="fs14 textTransCap colorBlack">&#10799;</p>
                    ) : fare?.pricedDescription &&
                      fare?.pricedDescription?.length &&
                      fare?.pricedDescription?.filter(
                        (obj) => obj.type === "Refund"
                      ).length > 0 ? (
                      fare?.pricedDescription
                        .filter((obj) => obj.type === "Refund")
                        .map((item) => (
                          <>
                            <div className="d-flex justify-content-center align-items-center">
                              <div>
                                {item.type === "Refund" &&
                                  item.status === "Included" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Refund" &&
                                  item.status === "Chargeable" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Refund" &&
                                  item.status === "NotIncluded" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "Refund" &&
                                  (item.status === "None" ||
                                    (item.status !== "Included" &&
                                      item.status !== "Chargeable" &&
                                      item.status !== "NotIncluded")) && (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.description}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </div>
                              <div>
                                {item.type === "Refund" &&
                                  item.returnStatus === "Included" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Refund" &&
                                  item.returnStatus === "Chargeable" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Refund" &&
                                  item.returnStatus === "NotIncluded" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "Refund" &&
                                  (item.returnStatus === "None" ||
                                    (item.returnStatus !== "Included" &&
                                      item.returnStatus !== "Chargeable" &&
                                      item.returnStatus !== "NotIncluded")) && (
                                    <BootstrapTooltip1
                                      title={item.returnDescription}
                                    >
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.returnDescription}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        ))
                    ) : (
                      <>
                        {fare.miniRules.find(
                          (item) =>
                            item.paxType === "ADT" &&
                            item.cancelAllowed === true &&
                            item.apply === "Before" &&
                            item.canAmt > 0
                        ) ? (
                          <p className="fs14 textTransCap colorRed">&#36;</p>
                        ) : fare.miniRules.find(
                          (item) =>
                            item.paxType === "ADT" &&
                            item.cancelAllowed === true &&
                            item.apply === "Before" &&
                            item.canAmt === 0
                        ) ? (
                          <p className="fs14 textTransCap colorGreen">&#10004;</p>
                        ) : fare.refundable === true ? (
                          <p className="fs14 textTransCap colorRed">&#36;</p>
                        ) : (
                          <p className="fs14 textTransCap colorBlack">&#10799;</p>
                        )}
                      </>
                    )}
                  </>
                </TableCell>) : null
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              <p className="fs14 textTransCap">Meals</p>
            </TableCell>
            {result.fareGroups.map((fare, index) => (
              fare?.pricedDescription && fare?.pricedDescription?.length > 0 ? (
                <TableCell className="fareDetailsBodyCell">
                  <>
                    {fare?.pricedDescription &&
                      fare?.pricedDescription?.length &&
                      fare?.pricedDescription?.filter((obj) => obj.type === "Meal")
                        .length > 0 ? (
                      fare?.pricedDescription
                        .filter((obj) => obj.type === "Meal")
                        .map((item) => (
                          <>
                            <div className="d-flex justify-content-center align-items-center">
                              <div>
                                {item.type === "Meal" &&
                                  item.status === "Included" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Meal" &&
                                  item.status === "Chargeable" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Meal" &&
                                  item.status === "NotIncluded" ? (
                                  <BootstrapTooltip1 title={item.description}>
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "Meal" &&
                                  (item.status === "None" ||
                                    (item.status !== "Included" &&
                                      item.status !== "Chargeable" &&
                                      item.status !== "NotIncluded")) && (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.description}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </div>
                              <div>
                                {item.type === "Meal" &&
                                  item.returnStatus === "Included" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorGreen">
                                      &#10004;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Meal" &&
                                  item.returnStatus === "Chargeable" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorRed">
                                      &#36;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : item.type === "Meal" &&
                                  item.returnStatus === "NotIncluded" ? (
                                  <BootstrapTooltip1
                                    title={item.returnDescription}
                                  >
                                    <p className="fs14 textTransCap colorBlack">
                                      &#10799;
                                    </p>
                                  </BootstrapTooltip1>
                                ) : (
                                  item.type === "Meal" &&
                                  (item.returnStatus === "None" ||
                                    (item.returnStatus !== "Included" &&
                                      item.returnStatus !== "Chargeable" &&
                                      item.returnStatus !== "NotIncluded")) && (
                                    <BootstrapTooltip1
                                      title={item.returnDescription}
                                    >
                                      <p className="fs14 textTransCap colorBlack">
                                        {item.returnDescription}
                                      </p>
                                    </BootstrapTooltip1>
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        ))
                    ) : (
                      <p className="fs14 textTransCap colorBlack">&#10799;</p>
                    )}
                  </>
                </TableCell>) : null
            ))}
          </TableRow>
          {result.vendor === "F3" ||
            result.vendor === "G9" ||
            result.vendor === "QP" ? null : (
            <>
              <TableRow>
                {result.vendor !== "G9" && (
                  <TableCell className="fareDetailsHeadCell">
                    <p className="fs14 textTransCap">Wifi</p>
                  </TableCell>
                )}

                {result.vendor === "G9"
                  ? ""
                  : result.vendor !== "G9" &&
                  result.fareGroups.map((fare, index) => (
                    fare?.pricedDescription && fare?.pricedDescription?.length > 0 ? (
                      <TableCell className="fareDetailsBodyCell">
                        <>
                          {fare?.pricedDescription &&
                            fare?.pricedDescription?.length &&
                            fare?.pricedDescription?.filter(
                              (obj) => obj.type === "Wifi"
                            ).length > 0 ? (
                            fare?.pricedDescription
                              .filter((obj) => obj.type === "Wifi")
                              .map((item) => (
                                <>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                      {item.type === "Wifi" &&
                                        item.status === "Included" ? (
                                        <BootstrapTooltip1
                                          title={item.description}
                                        >
                                          <p className="fs14 textTransCap colorGreen">
                                            &#10004;
                                          </p>
                                        </BootstrapTooltip1>
                                      ) : item.type === "Wifi" &&
                                        item.status === "Chargeable" ? (
                                        <BootstrapTooltip1
                                          title={item.description}
                                        >
                                          <p className="fs14 textTransCap colorRed">
                                            &#36;
                                          </p>
                                        </BootstrapTooltip1>
                                      ) : item.type === "Wifi" &&
                                        item.status === "NotIncluded" ? (
                                        <BootstrapTooltip1
                                          title={item.description}
                                        >
                                          <p className="fs14 textTransCap colorBlack">
                                            &#10799;
                                          </p>
                                        </BootstrapTooltip1>
                                      ) : (
                                        item.type === "Wifi" &&
                                        (item.status === "None" ||
                                          (item.status !== "Included" &&
                                            item.status !== "Chargeable" &&
                                            item.status !== "NotIncluded")) && (
                                          <BootstrapTooltip1
                                            title={item.description}
                                          >
                                            <p className="fs14 textTransCap colorBlack">
                                              {item.description}
                                            </p>
                                          </BootstrapTooltip1>
                                        )
                                      )}
                                    </div>
                                    <div>
                                      {item.type === "Wifi" &&
                                        item.returnStatus === "Included" ? (
                                        <BootstrapTooltip1
                                          title={item.returnDescription}
                                        >
                                          <p className="fs14 textTransCap colorGreen">
                                            &#10004;
                                          </p>
                                        </BootstrapTooltip1>
                                      ) : item.type === "Wifi" &&
                                        item.returnStatus === "Chargeable" ? (
                                        <BootstrapTooltip1
                                          title={item.returnDescription}
                                        >
                                          <p className="fs14 textTransCap colorRed">
                                            &#36;
                                          </p>
                                        </BootstrapTooltip1>
                                      ) : item.type === "Wifi" &&
                                        item.returnStatus === "NotIncluded" ? (
                                        <BootstrapTooltip1
                                          title={item.returnDescription}
                                        >
                                          <p className="fs14 textTransCap colorBlack">
                                            &#10799;
                                          </p>
                                        </BootstrapTooltip1>
                                      ) : (
                                        item.type === "Wifi" &&
                                        (item.returnStatus === "None" ||
                                          (item.returnStatus !== "Included" &&
                                            item.returnStatus !==
                                            "Chargeable" &&
                                            item.returnStatus !==
                                            "NotIncluded")) && (
                                          <BootstrapTooltip1
                                            title={item.returnDescription}
                                          >
                                            <p className="fs14 textTransCap colorBlack">
                                              {item.returnDescription}
                                            </p>
                                          </BootstrapTooltip1>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </>
                              ))
                          ) : (
                            <p className="fs14 textTransCap colorBlack">
                              &#10799;
                            </p>
                          )}
                        </>
                      </TableCell>) : null
                  ))}
              </TableRow>
            </>
          )}
          {ispriorityAvailable !== undefined ? (
            <>
              <TableRow>
                <TableCell className="fareDetailsHeadCell">
                  <p className="fs14 textTransCap">Airport Priority</p>
                </TableCell>
                {result.fareGroups.map((fare, index) => (
                  <TableCell className="fareDetailsBodyCell">
                    <>
                      {fare?.pricedDescription &&
                        fare?.pricedDescription?.length &&
                        fare?.pricedDescription?.filter(
                          (obj) => obj.type === "AirportPriority"
                        ).length > 0 ? (
                        fare?.pricedDescription
                          .filter((obj) => obj.type === "AirportPriority")
                          .map((item) => (
                            <>
                              <div className="d-flex justify-content-center align-items-center">
                                <div>
                                  {item.type === "AirportPriority" &&
                                    item.status === "Included" ? (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorGreen">
                                        &#10004;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : item.type === "AirportPriority" &&
                                    item.status === "Chargeable" ? (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorRed">
                                        &#36;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : item.type === "AirportPriority" &&
                                    item.status === "NotIncluded" ? (
                                    <BootstrapTooltip1 title={item.description}>
                                      <p className="fs14 textTransCap colorBlack">
                                        &#10799;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : (
                                    item.type === "AirportPriority" &&
                                    (item.status === "None" ||
                                      (item.status !== "Included" &&
                                        item.status !== "Chargeable" &&
                                        item.status !== "NotIncluded")) && (
                                      <BootstrapTooltip1
                                        title={item.description}
                                      >
                                        <p className="fs14 textTransCap colorBlack">
                                          {item.description}
                                        </p>
                                      </BootstrapTooltip1>
                                    )
                                  )}
                                </div>
                                <div>
                                  {item.type === "AirportPriority" &&
                                    item.returnStatus === "Included" ? (
                                    <BootstrapTooltip1
                                      title={item.returnDescription}
                                    >
                                      <p className="fs14 textTransCap colorGreen">
                                        &#10004;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : item.type === "AirportPriority" &&
                                    item.returnStatus === "Chargeable" ? (
                                    <BootstrapTooltip1
                                      title={item.returnDescription}
                                    >
                                      <p className="fs14 textTransCap colorRed">
                                        &#36;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : item.type === "AirportPriority" &&
                                    item.returnStatus === "NotIncluded" ? (
                                    <BootstrapTooltip1
                                      title={item.returnDescription}
                                    >
                                      <p className="fs14 textTransCap colorBlack">
                                        &#10799;
                                      </p>
                                    </BootstrapTooltip1>
                                  ) : (
                                    item.type === "AirportPriority" &&
                                    (item.returnStatus === "None" ||
                                      (item.returnStatus !== "Included" &&
                                        item.returnStatus !== "Chargeable" &&
                                        item.returnStatus !==
                                        "NotIncluded")) && (
                                      <BootstrapTooltip1
                                        title={item.returnDescription}
                                      >
                                        <p className="fs14 textTransCap colorBlack">
                                          {item.returnDescription}
                                        </p>
                                      </BootstrapTooltip1>
                                    )
                                  )}
                                </div>
                              </div>
                            </>
                          ))
                      ) : (
                        <>
                          {result.vendor !== "G9" && fare?.baggages ? (
                            fare?.baggages.filter(
                              (item) => item.paxType === "ADT"
                            ).length > 0 ? (
                              <p className="fs14 textTransCap colorGreen">
                                &#10004;
                              </p>
                            ) : (
                              <p className="fs14 textTransCap colorBlack">
                                &#10799;
                              </p>
                            )
                          ) : (
                            <p className="fs14 textTransCap colorBlack">
                              &#10799;
                            </p>
                          )}
                        </>
                      )}
                    </>
                  </TableCell>
                ))}
              </TableRow>
            </>
          ) : null}
          <TableRow>
            <TableCell
              colSpan={result.fareGroups.length + 1}
              className="fareDetailsHeadCell"
            >
              <p className="fs14 textTransCap ">
                <span className="colorGreen"> &#10004;</span> - Included{" "}
                <span className="colorRed">&#36;</span> - Chargable{" "}
                <span className="colorBlack">&#10799;</span> - Not Included
              </p>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
