import HTTPMethod from "http-method-enum";
import sendProtectedJSONRequest from "./sendProtectedJSONRequest";
import sanitizeOriginDestinations from "./sanitizeOriginDestinations";
import axios from "axios";
import { checkIfUserStaff } from "../services/AuthService";

export default function getSearchResults(search) {
  const isStaff = checkIfUserStaff();
  const headers = {
    "Content-Type": "application/json",
  };
  const searchTerm = {
    origin: search.from,
    destination: search.to,
    departureDate: search.depart,
    returnDate: search.return,
    adult: search.adult,
    children: search.children,
    infant: search.infant,
    class: search.class,
    directFlights: search.directFlights,
    brandedFares: search.brandedFares,
    includeCarrier: search.includeCarrier,
    type: search.type,
    preference: search?.preference?.value,
    nationality: search.nationality,
    clientId: search.clientId,
  }
  window.dataLayer.push({
    event: 'search_event',  // Custom event name
    search_term: searchTerm,  // Custom search data
  });
  if (isStaff === true) {
    return axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Search/v1/B2B",
        JSON.stringify({
          flightSearchRq: {
            originDestinations: sanitizeOriginDestinations(search),
            adultCount: search.adult,
            childCount: search.children,
            infantCount: search.infant,
            cabinClass: search.class,
            cabinPreferenceType: "Preferred",
            stopOver: search.directFlights ? "DirectFlight" : "None",
            IsBrandFareEnable: search.brandedFares,
            includeCarrier: search.includeCarrier ? search.includeCarrier : "",
            airTravelType: search.type,
            includeBaggage: true,
            includeMiniRules: true,
            specialFareTypes: [search?.preference?.value],
            nationality: search.nationality,
          },
          clientId: search.clientId,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        debugger;
        if (typeof response.body === "string") {
          return JSON.parse(response.data);
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        debugger;
        console.log(error);
        window.dataLayer.push({
          event: 'apiError',
          errorMessage: error.response.data,
          response: error.response ? error.response.data : 'Network Error',
          errorUrl: process.env.REACT_APP_CORE_URL + "Flights/Search/v1/B2B",
          statusCode: error.response.status ? error.response.status : 'Network Error',
        });
      });
  } else {
    return axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Search/v1",
        JSON.stringify({
          originDestinations: sanitizeOriginDestinations(search),
          adultCount: search.adult,
          childCount: search.children,
          infantCount: search.infant,
          cabinClass: search.class,
          cabinPreferenceType: "Preferred",
          stopOver: search.directFlights ? "DirectFlight" : "None",
          IsBrandFareEnable: search.brandedFares,
          includeCarrier: search.includeCarrier ? search.includeCarrier : "",
          airTravelType: search.type,
          includeBaggage: true,
          includeMiniRules: true,
          specialFareTypes: [search?.preference?.value],
          nationality: search.nationality,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (typeof response.body === "string") {
          return JSON.parse(response.data);
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        window.dataLayer.push({
          event: 'apiError',
          errorMessage: error.response.data,
          response: error.response ? error.response.data : 'Network Error',
          errorUrl: process.env.REACT_APP_CORE_URL + "Flights/Search/v1",
          statusCode: error.response.status ? error.response.status : 'Network Error',
        });
        console.log(error);
      });
  }
}
