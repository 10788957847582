import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch } from "react-redux";
import { login } from "../../Redux/AuthSlice";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios";
import { setAuthHeader } from "../../utils/axiosHeaders";
import "./Signin.css";
import logo from "../../assets/gf.png";
import { checkIfUserStaff, getCurrentRole, isClientUserHasPermission } from "../../services/AuthService";
import SignInHead from "./SignInHead";
import SignInHero from "./SignInHero";

const newsletterUseStyles = makeStyles(() => ({
  textField: {
    background: "rgba(255, 255, 255, 0.8)",
    borderBottom: "none",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(12),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    borderBottom: "1px solid #d3d3d3",
  },
  newsletterInput: {
    "& .MuiFilledInput-root": {
      background: "rgb(232, 241, 250)",
    },
  },
}));

export default function SignIn({ setUser, setDisplayHeader }) {
  const history = useHistory();
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    setDisplayHeader(false);

    if (urlParams !== "" && urlParams.get("accesstoken") !== null) {
      localStorage.setItem("token", urlParams.get("accesstoken"));
      localStorage.setItem("refreshtoken", urlParams.get("refreshtoken"));
      setAuthHeader(urlParams.get("accesstoken"));
      setUser(urlParams.get("accesstoken"));
      getUser();
    }
  }, []);
  async function getUser() {
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "connect/userinfo")
      .then((response) => {
        console.log(response);
        localStorage.setItem("user", JSON.stringify(response.data));
        setDisplayHeader(true);
        if (response.data.clientref_id === "0") {
          history.push("/Dashboard");
        } else {
          const userRole = getCurrentRole();

          if ((isClientUserHasPermission("IsSearch", "FlightbookDetail") && !checkIfUserStaff()) || userRole === "ClientSuperAdmin" || checkIfUserStaff()) {
            history.push("/flight");
          }
          else {
            history.push("/Unauthorized");
          }
        }
      });
  }

  return (
    <React.Fragment>
      <SignInHead
        setUser={setUser}
        setAlert={setAlert}
        setDisplayHeader={setDisplayHeader}
      />
      <SignInHero />
      <Snackbar
        open={alert.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={10000}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
