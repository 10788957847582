import CryptoJS from 'crypto-js';

function encryptData(data) {
  try {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), 'superSecretKey').toString();
    return encrypted;
  } catch (error) {
    throw new Error('Encryption failed: ' + error.message);
  }
};
function decryptData(data) {
  try {
    const bytes = CryptoJS.AES.decrypt(data, 'superSecretKey');
    if (bytes.toString()) {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    } else {
      throw new Error('Decryption failed: Empty bytes');
    }
  } catch (error) {
    throw new Error('Decryption failed: ' + error.message);
  }
};

export { encryptData, decryptData }