import React, { useState, useEffect } from "react";
import "./UserTable.css";
import ConfigurationMock from "../../../data/mock/ConfigurationMock";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import useConfigurationTableRow from "../../../hooks/useConfigurationTableRow";
import useConfigurationTableCell from "../../../hooks/useConfigurationTableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import countries from "../../../data/Country";
import { Button } from "@material-ui/core";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { IconButton, OutlinedInput, InputAdornment } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Row, Col, Nav, Tab } from "react-bootstrap";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { checkIfUserStaff } from "../../../services/AuthService";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

export default function UserTable({
  openAddUserDrawer,
  setAction,
  userProfiles,
  setPage,
  page,
  getClients,
  flag,
}) {

  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [checked, setChecked] = React.useState({
    isActive: true,
    notRegistered: false,
    notActive: false,
  });
  const [renderData, setRenderData] = useState([]);
  const [sortedData, setSortedData] = useState([]);

  const handleChange = (event) => {
    setChecked((prevState) => {
      return { ...prevState, [event.target.name]: event.target.checked };
    });
  };

  // useEffect(() => {
  //   if (userProfiles) {
  //     if (checked.isActive & checked.notActive && checked.notRegistered) {
  //       let temp1 = userProfiles.filter(
  //         (user) => user.isEnabled === true && user.emailConfirmed === true
  //       );
  //       let temp2 = userProfiles.filter(
  //         (user) => user.emailConfirmed === true && user.isEnabled === false
  //       );
  //       let temp3 = userProfiles.filter(
  //         (user) =>
  //           user.emailConfirmed === false &&
  //           (user.isEnabled === false || user.isEnabled === true)
  //       );
  //       setRenderData((prevData) => temp1.concat(temp2.concat(temp3)));
  //     } else if (checked.isActive && checked.notRegistered) {
  //       let temp1 = userProfiles.filter(
  //         (user) => user.isEnabled === true && user.emailConfirmed === true
  //       );
  //       let temp2 = userProfiles.filter(
  //         (user) =>
  //           user.emailConfirmed === false &&
  //           (user.isEnabled === false || user.isEnabled === true)
  //       );
  //       setRenderData((prevData) => temp1.concat(temp2));
  //     } else if (checked.isActive && checked.notActive) {
  //       let temp1 = userProfiles.filter(
  //         (user) => user.isEnabled === true && user.emailConfirmed === true
  //       );
  //       let temp2 = userProfiles.filter(
  //         (user) => user.emailConfirmed === true && user.isEnabled === false
  //       );
  //       setRenderData((prevData) => temp1.concat(temp2));
  //     } else if (checked.notActive && checked.notRegistered) {
  //       let temp1 = userProfiles.filter(
  //         (user) => user.emailConfirmed === true && user.isEnabled === false
  //       );
  //       let temp2 = userProfiles.filter(
  //         (user) =>
  //           user.emailConfirmed === false &&
  //           (user.isEnabled === false || user.isEnabled === true)
  //       );
  //       setRenderData((prevData) => temp1.concat(temp2));
  //     } else if (checked.isActive) {
  //       console.log(
  //         "checked.isActive",
  //         userProfiles.filter(
  //           (user) => user.isEnabled === true && user.emailConfirmed === true
  //         )
  //       );
  //       setRenderData((prevData) =>
  //         userProfiles.filter(
  //           (user) => user.isEnabled === true && user.emailConfirmed === true
  //         )
  //       );
  //     } else if (checked.notRegistered) {
  //       console.log(
  //         "checked.notActive",
  //         userProfiles.filter(
  //           (user) =>
  //             user.emailConfirmed === false &&
  //             (user.isEnabled === false || user.isEnabled === true)
  //         )
  //       );
  //       setRenderData((prevData) =>
  //         userProfiles.filter(
  //           (user) =>
  //             user.emailConfirmed === false &&
  //             (user.isEnabled === false || user.isEnabled === true)
  //         )
  //       );
  //     } else if (checked.notActive) {
  //       console.log(
  //         "checked.notRegistered",
  //         userProfiles.filter(
  //           (user) => user.emailConfirmed === true && user.isEnabled === false
  //         )
  //       );
  //       setRenderData((prevData) =>
  //         userProfiles.filter(
  //           (user) => user.emailConfirmed === true && user.isEnabled === false
  //         )
  //       );
  //     } else {
  //       setRenderData((prevData) => userProfiles);
  //     }
  //   }
  //   console.log("renderData", renderData);
  //   console.log("checked", checked);
  //   return () => { };
  // }, [checked, userProfiles]);

  useEffect(() => {
    if (userProfiles) {
      if (checked.isActive && checked.notActive) {
        const activeUsers = userProfiles.filter(user => user.isEnabled === true);
        const inactiveUsers = userProfiles.filter(user => user.isEnabled === false);
        setRenderData([...activeUsers, ...inactiveUsers]);
      } else if (checked.isActive) {
        const activeUsers = userProfiles.filter(user => user.isEnabled === true);
        setRenderData(activeUsers);
      } else if (checked.notActive) {
        const inactiveUsers = userProfiles.filter(user => user.isEnabled === false);
        setRenderData(inactiveUsers);
      } else {
        setRenderData(userProfiles);
      }
    }
    return () => { };
  }, [checked, userProfiles]);

  useEffect(() => {
    setSortedData([...renderData].sort((a, b) => (b.isSuperAdmin ? 1 : -1)))
  }, [renderData])


  const [searchedVal, setSearchedVal] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setSearchedVal("");
    setPage(0);
    getClients();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="user-listing">
      <div>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value={checked.isActive}
              control={
                <Checkbox
                  name="isActive"
                  checked={checked.isActive}
                  onChange={handleChange}
                />
              }
              label="Active"
              labelPlacement="end"
            />

            <FormControlLabel
              value={checked.notActive}
              control={
                <Checkbox
                  name="notActive"
                  checked={checked.notActive}
                  onChange={handleChange}
                />
              }
              label="InActive"
              labelPlacement="end"
            />
            {/* <FormControlLabel
              value={checked.notRegistered}
              control={
                <Checkbox
                  name="notRegistered"
                  checked={checked.notRegistered}
                  onChange={handleChange}
                />
              }
              label="Not Registered"
              labelPlacement="end"
            /> */}
          </FormGroup>
        </FormControl>
      </div>
      {sortedData ? (
        sortedData.length ? (
          <React.Fragment>
            <TableContainer component={Paper} className="table-paper-styles">
              <Table className="table-background">
                <TableHead>
                  <TableRow>
                    <TableCell className="py-2">
                      <OutlinedInput
                        id="keyword-search"
                        onChange={(e) => {
                          setSearchedVal(e.target.value);
                          setPage(0);
                        }}
                        placeholder="Keyword Search by First Name or Last Name or Email ID or Email confirmation Status(yes/no)"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton>
                              <SearchOutlinedIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.filter((row) => {
                    if (
                      searchedVal.toString().toLowerCase() == "yes" ||
                      searchedVal.toString().toLowerCase() == "no"
                    ) {
                      return (
                        row.status.toLowerCase() ==
                        searchedVal.toString().toLowerCase()
                      );
                    } else {
                      return (
                        row.firstName
                          ?.toLowerCase()
                          .includes(searchedVal.toString().toLowerCase()) ||
                        row.lastName
                          ?.toLowerCase()
                          .includes(searchedVal.toString().toLowerCase()) ||
                        row.email
                          ?.toLowerCase()
                          .includes(searchedVal.toString().toLowerCase())
                      );
                    }
                  }).length === 0 ? (
                    <>
                      <StyledTableRow>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Alert severity="error">No record found.</Alert>
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ) : (
                    sortedData
                      .filter((row) => {
                        if (
                          searchedVal.toString().toLowerCase() == "yes" ||
                          searchedVal.toString().toLowerCase() == "no"
                        ) {
                          return (
                            row.status.toLowerCase() ==
                            searchedVal.toString().toLowerCase()
                          );
                        } else {
                          return (
                            row.firstName
                              ?.toLowerCase()
                              .includes(searchedVal.toString().toLowerCase()) ||
                            row.lastName
                              ?.toLowerCase()
                              .includes(searchedVal.toString().toLowerCase()) ||
                            row.email
                              ?.toLowerCase()
                              .includes(searchedVal.toString().toLowerCase())
                          );
                        }
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow
                          key={JSON.stringify(row)}
                          onClick={() => {
                            setAction("view");
                            openAddUserDrawer(row);
                          }}
                        >
                          <StyledTableCell>
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <div className="d-flex align-items-center">
                                  <div className="user-card-name">
                                    {row.title && row.title}{" "}
                                    {row.firstName && row.firstName}{" "}
                                    {row.lastName && row.lastName}
                                  </div>
                                  {row.isSuperAdmin && !checkIfUserStaff() ? <div className="ms-5">
                                    <Chip
                                      label="Master Client"
                                      color="primary"
                                      size="small"
                                      style={{ backgroundColor: "#a0155a" }}
                                    /></div> : ""}
                                </div>

                                <div className="user-card-email">{row.email}</div>

                                <div className="user-card-email-confirmed">
                                  {row.isEnabled ? (
                                    <Chip
                                      label="Active"
                                      color="primary"
                                      size="small"
                                      style={{ backgroundColor: "green" }}
                                    />
                                  ) : (
                                    <>
                                      <Chip
                                        label="In Active"
                                        color="primary"
                                        size="small"
                                        style={{ backgroundColor: "red" }}
                                      />
                                    </>
                                  )}
                                </div>

                                <div className="user-card-dob">{moment(row.dob).format("DD/MM/YYYY")}</div>
                              </div>
                              <div className="user-creation-history d-flex">
                                <div className="me-4">
                                  <div>{row.createdBy ? `Created By: ` + row.createdBy : ""}</div>
                                  <div>{row.created ? `Created On: ` + row.created : ""}</div>
                                  <div>{row.modifiedBy ? `Modified By: ` + row.modifiedBy : ""}</div>
                                  <div>{row.modified ? `Modified On: ` + row.modified : ""}</div>
                                </div>
                                {/* <div>
                                  <div>{row.activatedBy ? `Activated By: ` + row.activatedBy : ""}</div>
                                  <div>{row.activatedOn ? `Activated On: ` + row.activatedOn : ""}</div>
                                  <div>{row.deActivatedBy ? `Deactivated By: ` + row.deActivatedBy : ""}</div>
                                  <div>{row.deActivatedOn ? `Deactivated On: ` + row.deActivatedOn : ""}</div>
                                </div> */}
                              </div>
                            </div>

                          </StyledTableCell>
                          {/* <StyledTableCell component="th" scope="row">
                        <div className="table-cell-title">
                          {row.title && row.title} {row.firstName && row.firstName} {row.lastName && row.lastName}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                        {row.emailConfirmed ? (
                            <Chip
                              label="yes"
                              color="primary"
                              size="small"
                              style={{ backgroundColor: "green" }}
                            />
                          ) : (
                            <Chip
                              label="No"
                              color="primary"
                              size="small"
                              style={{ backgroundColor: "red" }}
                            />
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {row.dob}
                        </div>
                      </StyledTableCell> */}
                        </StyledTableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={
                sortedData.filter((row) => {
                  if (
                    searchedVal.toString().toLowerCase() == "yes" ||
                    searchedVal.toString().toLowerCase() == "no"
                  ) {
                    return (
                      row.status.toLowerCase() ==
                      searchedVal.toString().toLowerCase()
                    );
                  } else {
                    return (
                      row.firstName
                        ?.toLowerCase()
                        .includes(searchedVal.toString().toLowerCase()) ||
                      row.lastName
                        ?.toLowerCase()
                        .includes(searchedVal.toString().toLowerCase()) ||
                      row.email
                        ?.toLowerCase()
                        .includes(searchedVal.toString().toLowerCase())
                    );
                  }
                }).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={(event, newPage) =>
                handleChangePage(event, newPage)
              }
              onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
              //onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
              ActionsComponent={TablePaginationActions}
            />
          </React.Fragment>
        ) : (
          <>
            {flag === "client" ? (
              <Alert severity="error">
                No users exist for this client, Please add atleast one user to
                this client.
              </Alert>
            ) : (
              <>
                <Alert severity="error">No users exist .</Alert>
              </>
            )}
          </>
        )
      ) : (
        // <CircularProgress style={{ marginLeft: "110px", marginTop: "100px" }} />
        // <Alert className="mx-4 my-3" severity="error">
        //   Please add the client First
        // </Alert>
        //    <Alert className="mx-4 my-2" severity="error">
        //    No users exist for this client, Please add atleast one user to this client.
        //  </Alert>
        <div>
          <div className="table-loading">
            <div style={{ width: "100%", margin: "5px" }}>
              <Skeleton width="100%" height={24} animation="wave" />
            </div>
            <div style={{ width: "100%", margin: "5px" }}>
              <Skeleton width="100%" height={24} animation="wave" />
            </div>
            <div style={{ width: "100%", margin: "5px" }}>
              <Skeleton width="100%" height={24} animation="wave" />
            </div>
            <div style={{ width: "100%", margin: "5px" }}>
              <Skeleton width="100%" height={24} animation="wave" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
