import React, { useEffect, useState } from "react";
import "./SupplierCommissionGroupDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { CircularProgress, FormControl, IconButton, InputLabel, MenuItem, NativeSelect, Select } from "@material-ui/core";
import moment from "moment";
import { TextField } from "@material-ui/core";
import countries from "../../../../data/Country";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TimeZones from "../../../../data/TimeZones";
import { AirlineSeatLegroomReducedSharp } from "@material-ui/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../../services/AuthService";

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

export default function SupplierCommissionGroupDrawer({
    toggleDrawer,
    selectedItem,
    action,
    setAction,
    handleSnackbarToggle,
}) {

    const classes = useStyles();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [id, setId] = useState(0);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (action === "edit" || action === "view") {
            setName(selectedItem.name);
            setDescription(selectedItem.description);
            setIsActive(selectedItem.isActive);
            setId(selectedItem.id);
        } else {
            setName("");
            setDescription("");
            setIsActive(true);
            setId(0)
        }
    }, [toggleDrawer, action]);

    async function handleUpdate() {
        setShowLoader(true);
        const headers = {
            "Content-Type": "application/json",
        };
        if (action === "edit" || action === "view") {
            await axios
                .put(
                    process.env.REACT_APP_CORE_URL +
                    "api/Configuration/SupplierCommissionGroup/" + selectedItem.id,
                    JSON.stringify({
                        id: selectedItem.id,
                        isActive: String(isActive).toLowerCase() === "true" ? true : false,
                        createdBy: selectedItem.createdBy === undefined ? "" : selectedItem.createdBy,
                        created: selectedItem.created === undefined ? "" : selectedItem.created,
                        modifiedBy: "",
                        modified: selectedItem.created === undefined ? "" : selectedItem.created,
                        name: name === undefined ? "" : name,
                        description: description === undefined ? "" : description
                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    handleSnackbarToggle(true, "Successfully Updated");
                    setShowLoader(false);
                })
                .catch((err) => {
                    handleSnackbarToggle(false, JSON.stringify(err.response.data));
                    setShowLoader(false);
                });
        }
        else {
            setShowLoader(true);
            await axios
                .post(
                    process.env.REACT_APP_CORE_URL + "api/Configuration/SupplierCommissionGroup",
                    JSON.stringify({
                        id: 0,
                        isActive: String(isActive).toLowerCase() === "true" ? true : false,
                        createdBy: "",
                        created: moment().format(),
                        modifiedBy: "",
                        modified: moment().format(),
                        name: name === undefined ? "" : name,
                        description: description === undefined ? "" : description
                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    handleSnackbarToggle(true, "Successfully Created");
                    setShowLoader(false);
                })
                .catch((err) => {
                    handleSnackbarToggle(false, JSON.stringify(err.response.data));
                    setShowLoader(false);
                });
        }
    }

    return (
        <div className="ticket-drawer-container">
            <div className="close-button-container">
                <CloseIcon
                    className="icon-fill pointer"
                    fontSize="medium"
                    onClick={toggleDrawer(false)}
                ></CloseIcon>
            </div>
            <div className="supplier-heading-container supplier-title-flex-container">
                <div>Supplier Commission Group</div>
                {action === "view" && (
                    <div className="supplier-heading-icons">
                        {isUserHasPermission('SupplierCommissionGroupWrite') ? <IconButton fontSize="small">
                            <EditRoundedIcon
                                fontSize="small"
                                className="icon-blue"
                                onClick={() => setAction("edit")}
                            ></EditRoundedIcon>
                        </IconButton> : ''}
                    </div>
                )}
            </div>
            {action === "view" && (
                <>
                    {
                        isUserHasPermission('SupplierCommissionBlockRead')
                            ?
                            <div className="configuration-heading-container configuration-title-flex-container">
                                <Button
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    style={{
                                        textTransform: "none",
                                        marginLeft: "auto",
                                    }}
                                    component={Link}
                                    to={"/SupplierCommissionBlock/" + id + "/" + name}
                                >
                                    Manage Commission Block
                                </Button>
                            </div>
                            :
                            ''
                    }
                </>

            )}
            <ValidatorForm onError={(errors) => console.log(errors)} onSubmit={handleUpdate}>
                <div className="supplier-view-edit-drawer-container">
                    <div className="supplier-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Group Name"
                                    size="small"
                                    color="secondary"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                />
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Description"
                                    size="small"
                                    color="secondary"
                                    value={description}
                                    onChange={(event) => setDescription(event.target.value)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                                <div className="view-supplier-drawer-title">
                                    Group Name
                                </div>
                                <div className="view-supplier-drawer-description ">
                                    {name}
                                </div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer config-input-flex-1">
                                <div className="view-supplier-drawer-title">
                                    Description
                                </div>
                                <div className="view-supplier-drawer-description">
                                    {description}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="supplier-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <FormControl fullWidth>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Status
                                    </InputLabel>
                                    <NativeSelect
                                        variant="outlined"
                                        label="Status"
                                        size="small"
                                        color="secondary"
                                        value={isActive}
                                        onChange={(event) => setIsActive(event.target.value)}
                                    >
                                        <option value={true}>true</option>
                                        <option value={false}>false</option>

                                    </NativeSelect>
                                </FormControl>
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input config-input-flex-1">
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                                <div className="view-supplier-drawer-title">
                                    Status
                                </div>
                                <div className="view-supplier-drawer-description">
                                    {isActive.toString()}
                                </div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer config-input-flex-1">

                            </div>
                        )}
                    </div>
                </div>
                {action !== "view" && (
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            style={{
                                textTransform: "none",
                                marginLeft: "25px",
                                marginRight: "25px",
                                marginBottom: "25px",
                                width: "500px"
                            }}
                            type="submit"
                        >
                            {action === "edit" ? "Update" : "Create"}
                        </Button>
                        {showLoader && <div style={{position:'absolute',top:'20%',left:'50%'}}><CircularProgress /></div>}
                    </div>
                )}
            </ValidatorForm>
        </div>
    );
}
