import React from 'react'
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
const images = require.context("../airline_logo", true);

export function AirlineLogo(details,h,w) {
    let imgSrc;
    try {
        if(details?.val.toLowerCase().includes("sg")){
            imgSrc = images(`./${"SG"}.webp`);
        }else if(details?.val.toLowerCase().includes("6e")){
            imgSrc = images(`./${"6E"}.webp`);
        }else{
            imgSrc = images(`./${details?.val}.webp`);
        }
      // imgSrc = images(`./${details?.val}.png`).default;
    } catch (err) {
      console.log(err);
    }
    return (
      <>
        {imgSrc !== undefined ? (
          <img style={{borderRadius:"2px"}} alt=""height={h} width={w} src={imgSrc} />
        ) : (
          <FlightTakeoffIcon className="primary-fill" fontSize="small" />
        )}
      </>
    );
}


 export function AirlineLogoForSearch(details) {
    
    if(details?.val?.toLowerCase().includes("sg")){
        return  images(`./${"SG"}.webp`);
    }else if(details?.val.toLowerCase().includes("6e")){
        return  images(`./${"6E"}.webp`);
    }else{
        return images(`./${details?.val}.webp`);
    }
}

 export function  OnlyAirlineLogo  (details)  {
    let imgSrc;
    try {
        if(details?.val.toLowerCase().includes("sg")){
            imgSrc = images(`./${"SG"}.webp`);
        }else if(details?.val.toLowerCase().includes("6e")){
            imgSrc = images(`./${"6E"}.webp`);
        }else{
            imgSrc = images(`./${details?.val}.webp`);
        }
      // imgSrc = images(`./${details?.val}.png`).default;
    } catch (err) {
      console.log(err);
    }

    return (
      <>
        {imgSrc !== undefined ? (
          <>
            <img className="flt-ic" alt="" src={imgSrc} />
          </>
        ) : (
          <>
            <FlightTakeoffIcon className="primary-fill" fontSize="small" />
          </>
        )}
      </>
    );
  };

